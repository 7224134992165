<template>
    <div>
        <b-container class="mt-3">
            <b-form-row>
                <b-col>
                    <h4>
                        {{ $t("chiusura_campagna") }}
                    </h4>

                    <p class="font-weight-light">
                        {{
                            currentAzienda != null
                                ? currentAzienda.ragione_sociale
                                : ""
                        }}
                        ({{
                            currentAzienda != null ? currentAzienda.cuaa : ""
                        }}), stabilimento
                        {{
                            currentStabilimento != null
                                ? '"' + currentStabilimento.descrizione + '"'
                                : ""
                        }}
                        ({{
                            currentStabilimento != null
                                ? currentStabilimento.icqrf
                                : ""
                        }})
                    </p>

                    <p>
                        La chiusura viene chiesta al SIAN che effettuerà
                        automaticamente le registrazioni chiusura e riapertura
                        (GIIN) della campagna per lo stabilimento.
                    </p>

                    <div v-if="situazioneCurrentStabilimento.stato == 'aperto'">
                        <b-alert show variant="warning" class="mt-3"
                            >La campagna dello <b>stabilimento</b> va chiusa
                            (campagna aziendale corrente:
                            {{
                                dataInizioCampagna.toLocaleDateString("it-IT")
                            }}-{{
                                dataFineCampagna.toLocaleDateString("it-IT")
                            }}).
                        </b-alert>
                    </div>

                    <div
                        v-if="
                            situazioneCurrentStabilimento.stato == 'chiuso' &&
                            !isCampagnaAziendaleRiaperta
                        "
                    >
                        <b-alert
                            show
                            variant="warning"
                            class="mt-3"
                            v-if="!isCampagnaAziendaleRiaperta"
                        >
                            <p>
                                La campagna dello <b>stabilimento</b> è stata
                                chiusa
                            </p>
                            <p>
                                La campagna <b>aziendale</b> va aperta (campagna
                                aziendale corrente:
                                {{
                                    dataInizioCampagna.toLocaleDateString(
                                        "it-IT"
                                    )
                                }}-{{
                                    dataFineCampagna.toLocaleDateString(
                                        "it-IT"
                                    )
                                }}).
                            </p>
                        </b-alert>
                    </div>
                    <!--------------------- bottone richiesta chiusura campagna stabilimento ---------------------->
                    <b-form-group>
                        <b-btn
                            variant="primary"
                            class="mt-2"
                            :disabled="
                                situazioneCurrentStabilimento.stato == 'chiuso'
                            "
                            @click.prevent="handleChiusuraCampagna"
                        >
                            <template v-if="loadingRichiestaChiusura">
                                <b-spinner small></b-spinner>
                                Richiesta in corso...
                            </template>
                            <template v-else
                                >Richiedi chiusura stabilimento
                                <b>{{ currentStabilimento.icqrf }}</b>
                            </template>
                        </b-btn>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <p class="lead mt-3">
                ATTENZIONE! <b>Dopo</b> avere richiesto la chiusura al SIAN è
                necessario verificare lo stato della richiesta.
            </p>

            <b-form-row class="mt-3">
                <b-col>
                    <b-form-group>
                        <b-input-group-append>
                            <b-btn
                                @click.prevent="handleVerificaCampagna"
                                :disabled="verificaCampagnaInCorso"
                                variant="primary"
                            >
                                <template v-if="verificaCampagnaInCorso">
                                    <b-spinner small></b-spinner>
                                    Verifica in corso...
                                </template>
                                <template v-else>Verifica chiusura </template>
                            </b-btn>
                        </b-input-group-append>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <hr />
        </b-container>
    </div>
</template>

<script>
/*

August 08, 2023 

in intercampagna 

- campagna precedente stabilimento aperta, campagna aziendale aperta
- campagna precedente stabilimento aperta, campagna aziendale chiusa
La campagna dello stabilimento va chiusa
La campagna aziendale non può essere aperta (devono essere chiuse le campagne di tutti gli stabilimenti)

- campagna precedente stabilimento chiusa, campagna aziendale chiusa
La campagna precedente dello stabilimento è stata chiusa
La campagna aziendale va aperta

- campagna precedente stabilimento chiusa, campagna aziendale aperta
La campagna precedente dello stabilimento è stata chiusa
La campagna aziendale è stata aperta il ...


non in intercampagna



*/

import axios from "axios";
import { DateTime } from "luxon";
import { useState } from "@u3u/vue-hooks";
import { computed } from "@vue/composition-api";
import { mapMutations } from "vuex";

import { showAlert } from "../../utils/eSianUtils.js";
import { verificaChiusuraCampagna } from "./verificaChiusuraCampagna.js";
import {
    isInCampagnaEstesa,
    calcolaDataInizioCampagnaInformale,
    calcolaDataFineCampagna,
    calcolaDataInizioCampagna,
    calcolaDataInizioCampagnaPrecedente,
    calcolaDataFineCampagnaPrecedente,
} from "./campagnaUtils.js";
import { _situazioneCurrentStabilimento } from "@/components/strumenti/Strumenti.vue";

export default {
    name: "ChiusuraCampagna",

    props: {
        azienda_id: Number,
        stabilimento_id: Number,
    },

    setup(props, context) {
        const {
            statoStabilimenti,
            isCampagnaAziendaleAperta,
            isCampagnaAziendaleRiaperta,
            currentAzienda,
            verificaCampagnaInCorso,
            currentStabilimento,
        } = useState("loginModule", [
            "statoStabilimenti",
            "isCampagnaAziendaleAperta",
            "isCampagnaAziendaleRiaperta",
            "currentAzienda",
            "verificaCampagnaInCorso",
            "currentStabilimento",
        ]);
        const { showForzaApertura } = useState("strumentiModule", [
            "showForzaApertura",
        ]);

        const situazioneCurrentStabilimento = computed(() =>
            _situazioneCurrentStabilimento()
        );

        const DateToLocaleStringLong = (value) => {
            var dt = DateTime.fromJSDate(value);
            return dt.toLocaleString(DateTime.DATE_FULL);
        };

        // let dataFineCampagnaPrecedente = calcolaDataFineCampagnaPrecedente()
        // let dataFineCampagna = calcolaDataFineCampagna()
        // let dataInizioCampagna = calcolaDataInizioCampagna()

        const isIntercampagna = computed(() => {
            return isInCampagnaEstesa();
        });

        // const uno = computed(() => {
        //     return (
        //         isCampagnaAziendaleAperta.value &&
        //         situazioneCurrentStabilimento.value.stato == "aperto" &&
        //         !isIntercampagna.value
        //     );
        // });

        // const due = computed(
        //     () =>
        //         isCampagnaAziendaleAperta.value &&
        //         situazioneCurrentStabilimento.value.stato == "chiuso" &&
        //         !isIntercampagna.value
        // );

        // const tre = computed(
        //     () =>
        //         isCampagnaAziendaleAperta.value &&
        //         situazioneCurrentStabilimento.value.stato == "chiuso" &&
        //         isIntercampagna.value
        // );

        return {
            // uno,
            // due,
            // tre,
            isIntercampagna,
            verificaCampagnaInCorso,
            situazioneCurrentStabilimento,
            showForzaApertura,
            statoStabilimenti,
            isCampagnaAziendaleAperta,
            isCampagnaAziendaleRiaperta,
            currentAzienda,
            currentStabilimento,
            calcolaDataInizioCampagnaInformale,
            calcolaDataFineCampagna,
            calcolaDataInizioCampagna,
            calcolaDataInizioCampagnaPrecedente,
            calcolaDataFineCampagnaPrecedente,
            DateToLocaleStringLong,
        };
    },

    data() {
        return {
            dataFineCampagnaPrecedente: calcolaDataFineCampagnaPrecedente(),
            dataInizioCampagnaPrecedente: calcolaDataInizioCampagnaPrecedente(),
            loadingVerificaChiusura: false,
            loadingRichiestaChiusura: false,
            dataFineCampagna: calcolaDataFineCampagna(),
            dataInizioCampagna: calcolaDataInizioCampagna(),
        };
    },

    methods: {
        ...mapMutations("strumentiModule", ["setShowForzaApertura"]),

        handleChiusuraCampagna() {
            /* il periodo di richiesta chiusura non è tra il 1/8 e il 10/9 */
            // const periodoChiusuraOK = isPeriodoChiusuraOK(new Date());
            /* Se si sta chiudendo la campagna... */
            // if (!periodoChiusuraOK.valid) {
            //   showAlert(
            //     { message: periodoChiusuraOK.data.message, variant: "error" },
            //     this
            //   );
            //   return;
            // }
            this.$bvModal
                .msgBoxConfirm(
                    `Si conferma la richiesta al SIAN di chiusura della campagna (${this.dataInizioCampagnaPrecedente.toLocaleDateString(
                        "it-IT"
                    )}-${this.dataFineCampagnaPrecedente.toLocaleDateString(
                        "it-IT"
                    )}) per l'azienda: ${this.currentAzienda.cuaa} (${
                        this.currentAzienda.icqrf
                    })?`,
                    {
                        title: "Conferma richiesta chiusura campagna",
                        //size: "sm",
                        //buttonSize: 'sm',
                        okVariant: "primary",
                        cancelVariant: "light",
                        okTitle: "Conferma",
                        cancelTitle: "Annulla",
                        headerClass: "p-2 border-bottom-0",
                        footerClass: "p-2 border-top-0",
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((value) => {
                    if (value == true) {
                        this.handleChiusuraCampagnaOk();
                    }
                })
                .catch((err) => {
                    showAlert({ error: err }, this);
                });
        },

        // verifica lo stato di chiusura direttamente sul SIAN
        // ovvero l'esito della richiesta di chiusura
        handleVerificaCampagna() {
            verificaChiusuraCampagna(
                this.azienda_id,
                this.stabilimento_id,
                this,
                true
            );
        },

        /*
       RICHIESTA DI CHIUSURA (non per la verifica)
       servizio SIAN:  ChiudiCampagnaPrecSiRPV
    */
        handleChiusuraCampagnaOk() {
            this.loadingRichiestaChiusura = true;
            const url = `/api/sian/chiusura/${this.azienda_id}/${this.stabilimento_id}`;
            axios
                .get(url)
                .then((response) => {
                    showAlert(
                        {
                            message: `${response.data.message} (${response.data.codice})`,
                            variant: setVariant(response),
                        },
                        this
                    );
                    this.loadingRichiestaChiusura = false;
                })
                .catch((error) => {
                    this.loadingRichiestaChiusura = false;
                    if (error.response) {
                        showAlert({ error: error }, this);
                    }
                });
        },
    },
};

const setVariant = (response) => {
    if (response.data.codice == "H66") {
        return "info";
    } else if (response.data.codice == "H66" || response.data.codice == "B60") {
        return "warning";
    } else {
        return "success";
    }
};
</script>

<i18n>
{
  "en": {
  },
  "it": {
    "conferma": "Conferma",
    "testo_chiusura_campagna": "La richiesta di chiusura della campagna in corso può essere trasmessa esclusivamente dal 1 agosto al 10 settembre di ogni anno.",
    "chiusura_campagna": "Richiesta chiusura campagna stabilimento",
    "testo_verifica_campagna": "Verifica lo stato della campagna in corso.",
    "verifica": "Verifica chiusura",
    "verifica_campagna": "Verifica della chiusura"
  }
}
</i18n>
