
<div>
    <b-container class="mt-3 mb-5">
        <!-- <pre>2. isCampagnaAziendaleRiaperta {{ isCampagnaAziendaleRiaperta }}</pre> -->

        <!------------------- contenuto dello slot da AperturaCampagna.vue -------------------->
        <slot></slot>

        <b-form-row class="mt-3" v-if="!apertura">
            <b-col sm="12" lg="6">
                <b-form-group
                    description="Data da cui ripristinare gli archivi. Le operazioni verranno ripristinate da questa data (i dati in anagrafica ed i prodotti saranno tutti ripristinati)."
                    :label="$t('data_inizio') + ' (data di inizio)'"
                    :state="v$.form.data_inizio.$error ? false : null"
                    :invalid-feedback="
                        v$.form.data_inizio.$error &&
                        v$.form.data_inizio.$errors.length > 0
                            ? v$.form.data_inizio.$errors[0].$response
                                ? v$.form.data_inizio.$errors[0].$response
                                      .data.message
                                : v$.form.data_inizio.$errors[0].$message
                            : null
                    "
                >
                    <b-form-datepicker
                        :state="v$.form.data_inizio.$error ? false : null"
                        v-model="form.data_inizio"
                        locale="it-IT"
                        label-help
                        start-weekday="1"
                        hide-header
                        value-as-date
                        :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                        }"
                        :disabled="verificaCampagnaInCorso"
                        placeholder="Scegliere una data..."
                    />
                </b-form-group>
            </b-col>

            <b-col sm="12" lg="6">
                <b-form-group
                    description="Le giacenze verranno calcolate a questa data."
                    :label="$t('data_fine') + ' (data di fine)'"
                    :state="v$.form.data_fine.$error ? false : null"
                    :invalid-feedback="
                        v$.form.data_fine.$error &&
                        v$.form.data_fine.$errors.length > 0
                            ? v$.form.data_fine.$errors[0].$response
                                ? v$.form.data_fine.$errors[0].$response
                                      .data.message
                                : v$.form.data_fine.$errors[0].$message
                            : null
                    "
                >
                    <b-form-datepicker
                        :state="v$.form.data_fine.$error ? false : null"
                        v-model="form.data_fine"
                        locale="it-IT"
                        label-help
                        start-weekday="1"
                        hide-header
                        value-as-date
                        :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                        }"
                        :disabled="verificaCampagnaInCorso"
                        placeholder="Scegliere una data..."
                    />
                </b-form-group>
            </b-col>
        </b-form-row>

        <hr class="mt-0" />

        <div class="text-left">
            <!--------------------------------- bottone apertura/ripristino ---------------------------------->
            <b-button
                @click="onReset"
                :disabled="ripristinoArchivi"
                variant="light"
                class="mr-2"
                v-if="!apertura"
                >Annulla</b-button
            >

            <!------------------ in caso di APERTURA --------------------->
            <b-button
                @click="onSubmit"
                v-if="apertura"
                variant="primary"
                :disabled="
                    isCampagnaAziendaleRiaperta ||
                    (statoStabilimenti.stabilimenti_aperti != 0 &&
                        !form.forzaApertura)
                "
                >Apertura campagna</b-button
            >

            <!------------------ in caso di RIPRISTINO --------------------->
            <b-button
                @click="onSubmit"
                v-else
                :variant="v$.$invalid ? 'danger' : 'primary'"
                :disabled="v$.$invalid || ripristinoArchivi"
                >Ripristino archivi dal SIAN</b-button
            >
        </div>

        <b-alert show class="mt-3" variant="warning" v-if="!apertura">
            <b-form-checkbox
                @change="handleForzaRipristino"
                v-model="form.forzaRipristino"
            >
                Ripristino dati dal SIAN
                <b>
                    anche in presenza di dati trasmessi e non ancora
                    verificati
                </b>
            </b-form-checkbox>
            <span class="small"
                >Da utilizzarsi solo in caso in cui non sia stato possibile
                verificare i dati trasmessi.</span
            >
        </b-alert>

        <!------------------------- checkbox apertura forzata ----------------------------->
        <!-- <b-alert show class="mt-3" variant="warning" v-if="apertura && !isCampagnaAziendaleRiaperta && !nuovaCampagnaForzata"> -->
        <!-- v-if="apertura && !isCampagnaAziendaleRiaperta && statoStabilimenti.stabilimenti_aperti != 0 && !nuovaCampagnaForzata" -->
        <b-alert
            show
            class="mt-5"
            variant="danger"
            v-if="
                apertura &&
                !isCampagnaAziendaleRiaperta &&
                statoStabilimenti.stabilimenti_aperti != 0
            "
        >
            <b-form-checkbox
                @change="handleForzaApertura"
                v-if="apertura"
                v-model="form.forzaApertura"
            >
                Sblocco bottone apertura della campagna aziendale
                <b>
                    anche in presenza di stabilimenti con campagna ancora
                    aperta
                </b>
            </b-form-checkbox>
            <span class="small"
                >Da utilizzarsi solo in caso di stabilimenti per cui la
                richiesta di chiusura campagna abbia dato esito negativo (ad
                esempio, stabilimenti con registro chiuso).</span
            >
        </b-alert>
    </b-container>

    <!------------------------------- modale di conferma ripristino ------------------------------>
    <b-modal
        id="ripristinaModal"
        centered
        :title="
            apertura
                ? 'Conferma apertura'
                : 'Ripristino degli archivi dal SIAN'
        "
        @ok="handleRipristinaOk"
        ok-variant="danger"
        :ok-title="apertura ? 'Conferma apertura' : $t('Conferma')"
        :cancel-title="$t('annulla')"
        cancel-variant="primary"
        headerClass="p-2 border-bottom-0"
        footerClass="p-2 border-top-0"
    >
        <span v-if="!apertura" v-html="$t('conferma_ripristina')"></span>
        <span v-else>Conferma apertura</span>
    </b-modal>

    <!------------------------------- modale di ripristino in corso ------------------------------>
    <b-modal
        id="loadingModalRipristina"
        @hide="handleHideRipristina"
        class="text-center"
        centered
        hide-header
        hide-footer
    >
        <b-spinner class="mr-1" small :label="loadingMessage.ripristina" />
        {{ loadingMessage.ripristina }}
    </b-modal>
</div>
