export const soggettoModule = {
  namespaced: true,
  state: {
    sideMenuTitle: null,
    optionsComune: [],
    optionsCategoriaSoggetto: [
      { codice: "DE", descrizione: "Soggetto destinatario" },
      // { codice: "AC", descrizione: "Soggetto acquirente" },
      // { codice: "CO", descrizione: "Soggetto committente" },
      { codice: "TR", descrizione: "Soggetto trasportatore" }
    ],
    optionsTipoSoggetto: [
      { codice: "IT", descrizione: "Soggetto italiano" },
      { codice: "UE", descrizione: "Soggetto Unione Europea" },
      { codice: "EX", descrizione: "Soggetto Extra UE" }
    ],
  },
  mutations: {
    setSideMenuTitle(state, payload) { 
      state.sideMenuTitle = payload;
    },
    setOptionsComune(state, payload) { 
      state.optionsComune = payload;
    },
    setOptionsTipoSoggetto(state, payload) { 
      state.optionsTipoSoggetto = payload;
    },
    setOptionsCategoriaSoggetto(state, payload) { 
      state.optionsCategoriaSoggetto = payload;
    },
  },
};
