
<div>
  <b-container class="mt-3">

    <h4>
      Richiesta di dichiarazione di giacenza
    </h4>
    <p class="font-weight-light">
      {{ currentAzienda != null ? currentAzienda.ragione_sociale : ''  }} ({{ currentAzienda != null ? currentAzienda.cuaa : ""  }}), stabilimento {{ currentStabilimento != null ? currentStabilimento.descrizione : '' }} ({{ currentStabilimento != null ? currentStabilimento.icqrf : '' }})
    </p>
    <p class="lead">
      Richiesta al SIAN della dichiarazione di giacenza per lo stabilimento {{ currentStabilimento != null ? currentStabilimento.icqrf : '' }}. Può essere effettuata solo dopo la chiusura della campagna dello stabilimento.
    </p>

    <b-form-row class="mt-3">
      <b-col>

        <b-form-group>
          <b-input-group-append>
            <b-btn
              @click.prevent="useDichiarazioneGiacenza"
              :disabled="loadingDichiarazioneGiacenza || !isCampagnaChiusaCurrentStabilimento"
              :variant="!isCampagnaChiusaCurrentStabilimento ? 'secondary' : 'primary'"
            >
              <template v-if="loadingDichiarazioneGiacenza">
                Richiesta in corso...
                <b-spinner small></b-spinner>
              </template>
              <template
                v-else
              >Dichiarazione di giacenza</template>
            </b-btn>
          </b-input-group-append>
          <b-form-text>Verrà scaricata la dichiarazione (file PDF) e mostrati i dati della dichiarazione.</b-form-text>
        </b-form-group>

      </b-col>
    </b-form-row>

    <p class="lead mt-3">
      Visualizzazione dei dati della dichiarazione di giacenza per lo stabilimento {{ currentStabilimento != null ? currentStabilimento.icqrf : '' }} (Identificativo atto AGEA e Num. ricevuta accettazione).    
    </p>

    <b-form-row class="mt-3">
      <b-col>
        <b-form-group>
          <b-input-group-append>
            <b-btn
              @click.prevent="useGetDatiDichiarazioneGiacenza"
              :disabled="loadingDichiarazioneGiacenza || !isCampagnaChiusaCurrentStabilimento"
              :variant="!isCampagnaChiusaCurrentStabilimento ? 'secondary' : 'primary'"
            >
              Visualizza dati dichiarazione 
            </b-btn>
          </b-input-group-append>
          <b-form-text>Disponibili solo dopo avere richiesto la dichiarazione di giacenza.</b-form-text>
        </b-form-group>

      </b-col>
    </b-form-row>

  </b-container>

  <b-modal id="DatiDichiarazione" centered title="Dati dichiarazione" hide-footer>
    <b-container>
      <b-form-row class="mt-1">
        <b-col>
          <p> Azienda: <b>{{ currentAzienda != null ? currentAzienda.ragione_sociale : '' }}</b> </p>
          <p> Stabilimento: <b>{{ currentStabilimento != null ? currentStabilimento.icqrf : '' }}</b> </p>
          <p> Identificativo atto AGEA: <b>{{ IdentificativoAttoAgea }}</b> </p>
          <p> Num. ricevuta accettazione: <b>{{ numRicevutaAccettazione }}</b> </p>
        </b-col>
      </b-form-row>
    </b-container>
  </b-modal>

</div>
