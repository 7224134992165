
<div>
  <b-row>
    <b-col class="mt-2 mb-4 lead">
      <!----------------- CONCESSIONARIO ---------------------->
      <span v-if="amministrazioneConcessionario">
        <span class="text-muted">Concessionario</span>
        <span class="font-weight-bold">{{utente.concessionario.codice}}</span>
        /
        <span class="text-normal">{{utente.email}}</span>
        <!-- <span class="text-muted">({{ utente.first_name }} {{ utente.last_name }})</span> -->
      </span>

      <!----------------- CLIENTE ---------------------->
      <span v-else-if="amministrazioneCliente">
        <span class="text-muted">Cliente</span>
        <span class="font-weight-bold">{{utente.cliente.codice}}</span>
        /
        <span class="text-normal">{{utente.email}}</span>
        <!-- <span class="text-muted">({{ utente.first_name }} {{ utente.last_name }})</span> -->
        /
        <span class="text-normal">Concessionario</span>
        <span class="text-muted">{{utente.concessionario.codice}}</span>
      </span>
    </b-col>
  </b-row>

  <b-tabs>
    <b-tab active title="Dati generali">
      <table class="table table-striped mt-3">

        <!----------------- CONCESSIONARIO ---------------------->
        <template v-if="amministrazioneConcessionario">
          <thead>
            <tr>
              <th width="25%">Ragione sociale</th>
              <td>{{utente.concessionario.ragione_sociale}}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Ident. fisc.</th>
              <td>{{utente.concessionario.identificativo_fiscale}}</td>
            </tr>
            <tr>
              <th>Indirizzo</th>
              <td>{{utente.concessionario.indirizzo}}</td>
            </tr>
            <tr>
              <th>Email amministrativo</th>
              <td>{{utente.concessionario.email_ammvo}}</td>
            </tr>
            <tr>
              <th>Telefono</th>
              <td>{{utente.concessionario.telefono}}</td>
            </tr>
            <tr>
              <th>N. clienti</th>
              <td>{{utente.concessionario.numero_clienti}}</td>
            </tr>
            <tr>
              <th>Stato</th>
              <td>{{utente.stato}}</td>
            </tr>
          </tbody>
        </template>

        <!----------------- CLIENTE ---------------------->
        <template v-else-if="amministrazioneCliente">
          <thead>
            <tr class>
              <th>Parametro</th>
              <th>Valore</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Aziende max</td>
              <td>{{utente.cliente.numero_aziende_max}}</td>
            </tr>
            <tr>
              <td>Aziende PMI max</td>
              <td>{{utente.cliente.numero_aziende_free_max}}</td>
            </tr>
            <tr>
              <td>Operatori max</td>
              <td>{{utente.cliente.numero_utenti_max}}</td>
            </tr>
            <tr>
              <td>N. aziende attive</td>
              <td>{{utente.cliente.numero_aziende}}</td>
            </tr>
            <tr>
              <td>N. operatori attivi</td>
              <td>{{utente.cliente.numero_operatori}}</td>
            </tr>
            <tr>
              <td>Stato</td>
              <td>{{utente.stato}}</td>
            </tr>
          </tbody>
        </template>
      </table>
    </b-tab>

    <!----------------- CLIENTE ---------------------->
    <b-tab title="Servizi acquistati" v-if="amministrazioneCliente">
      <table class="table mt-3">
        <tr>
          <th>Servizio</th>
          <th>Codice</th>
        </tr>
        <tr v-for="(servizio, i) in utente.cliente.servizi_ammessi" :key="i">
          <td>{{servizio.note}}</td>
          <td>{{servizio.codice}}</td>
        </tr>
      </table>
    </b-tab>

    <!-- <b-tab title="Operatori cliente" v-if="amministrazioneCliente"> -->
    <!--   <table class="table mt-3"> -->
    <!--     <tr> -->
    <!--       <th>e-mail</th> -->
    <!--       <th>Nome e cognome</th> -->
    <!--       <th>Ruolo</th> -->
    <!--     </tr> -->
    <!--     <tr v-for="operatore in utente.operatori" :key="operatore.id"> -->
    <!--       <td> -->
    <!--         <a :href="'mailto:'+operatore.email">{{ operatore.email }}</a> -->
    <!--       </td> -->
    <!--       <td>{{ operatore.first_name }} {{ operatore.last_name }}</td> -->
    <!--       <td :class="getRuoloClass(operatore)">{{ getRuolo(operatore) }}</td> -->
    <!--     </tr> -->
    <!--   </table> -->
    <!-- </b-tab> -->

    <!----------------- CONCESSIONARIO ---------------------->
    <b-tab title="Operatore" v-if="amministrazioneConcessionario">
      <table class="table mt-3">
        <tr class>
          <th>e-mail</th>
          <th>Nome e cognome</th>
        </tr>
        <tr>
          <td v-b-tooltip.hover :title="'Last login: ' + utente.last_login">{{utente.email}}</td>
          <td>{{utente.first_name}} {{utente.last_name}}</td>
        </tr>
      </table>
    </b-tab>
  </b-tabs>
</div>
