<template>
  <span v-html="sintesiSoggetto"></span>
</template>

<script>

export default {
  name: 'sintesi-soggetto',
  props: {
    'codice': { required: true}, 
    'ragione_sociale': { required: false}, 
    'nome_persona_fisica': { required: false}, 
    'cognome_p_fisica': { required: false},
    'stato': { required: true},
    'identificativo_fiscale': { required: true}
  },
  computed: {
    sintesiSoggetto: 
      function () { 
        var _sintesiSoggetto = {};
        if (this.codice == null) {
          _sintesiSoggetto.codice = '';
        } else if (this.codice != '') {
          _sintesiSoggetto.codice = '<span class="text-muted">' + this.codice + '</span>';
        }; 
        if (this.ragione_sociale == null) {
          _sintesiSoggetto.ragione_sociale = '';
        } else if (this.cod_dop_igp != '') {
          _sintesiSoggetto.ragione_sociale = '<span class="text-muted">.&nbsp;</span>' + this.$filters.truncate(this.ragione_sociale, 64);       
        };
        if (this.nome_persona_fisica == null) {
          _sintesiSoggetto.nome_persona_fisica = '';
        } else if (this.nome_persona_fisica != '') {
          _sintesiSoggetto.nome_persona_fisica = '<span class="">.&nbsp;' + this.nome_persona_fisica + '</span>';       
        };
        if (this.cognome_p_fisica == null) {
          _sintesiSoggetto.cognome_p_fisica = '';
        } else if (this.cognome_p_fisica != '') {
          _sintesiSoggetto.cognome_p_fisica = '<span class="">&nbsp;' + this.cognome_p_fisica + '</span>';       
        };
        // December 14, 2021 
        if (!this.identificativo_fiscale) {
          _sintesiSoggetto.identificativo_fiscale = '';
        } else if (this.identificativo_fiscale) {
          _sintesiSoggetto.identificativo_fiscale = '<span class="">&nbsp;(' + this.identificativo_fiscale + ')</span>';       
        };
        // Aug 23, 2019
        if (this.stato != null && Object.entries(this.stato).length === 0 && this.stato.constructor === Object) {
          _sintesiSoggetto.stato = '';
        } else if (this.stato != null && this.stato.descrizione != '') {
          _sintesiSoggetto.stato = '<span class="">.&nbsp;' + this.stato.descrizione + '</span>';       
        };

        var __sintesiSoggetto = '';
        var elemento = '';
        for (elemento in _sintesiSoggetto) {
          __sintesiSoggetto = __sintesiSoggetto + _sintesiSoggetto[elemento];
        };
        return __sintesiSoggetto;
      },
  },
}
</script>
