const ElencoProdotti = () => import("../components/prodotto/ElencoProdotti.vue");
const Prodotto = () => import("../components/prodotto/Prodotto.vue");

export const prodotto_routes = [
  {
    path: "/elenco-prodotti",
    name: "elenco-prodotti",
    component: ElencoProdotti,
    meta: { title: 'Elenco Prodotti - e-Sian', requiresAuth: true,},
    props: true,
  },
  {
    path: "/modifica-prodotto/:id",
    name: "modifica-prodotto",
    component: Prodotto,
    meta: { title: 'Modifica prodotto' + '- e-Sian', requiresAuth: true, },
    props: true,
  },
  {
    path: "/clone-prodotto/:id",
    name: "clone-prodotto",
    component: Prodotto,
    meta: { title: 'Copia prodotto' + '- e-Sian', requiresAuth: true, },
    props: true,
  },
  {
    path: "/view-prodotto/:id",
    name: "view-prodotto",
    component: Prodotto,
    meta: { title: 'Visualizza prodotto' + '- e-Sian', requiresAuth: true, },
    props: true,
  },
  {
    path: "/aggiungi-prodotto",
    name: "aggiungi-prodotto",
    component: Prodotto,
    meta: { title: 'Aggiunta prodotto' + '- e-Sian', requiresAuth: true, },
    props: true,
  },
  // {
  //   path: "/elenco-giacenze-sian",
  //   name: "elenco-giacenze-sian",
  //   component: ElencoGiacenzeSian,
  //   meta: { title: 'Elenco giacenze SIAN' + '- e-Sian', requiresAuth: true, },
  //   props: true,
  // },
]
