<template>
  <table class="table table-sm">
    <thead>
      <tr>
        <th width="30%" class="border-top-0">Azienda</th>
        <th width="1%" class="border-top-0"></th>
        <th width="15%" class="border-top-0">Stabilimento</th>
        <th class="border-top-0">Num. MVV</th>
        <th class="border-top-0">Data validazione</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="avviso in avvisiDiSistemaMVV">
        <tr :key="avviso.id">
          <td>
            {{ avviso.azienda.ragione_sociale }} <span class="text-muted">({{ avviso.azienda.cuaa }})</span>
          </td>
          <td>
            <b-link @click="useGoToAzienda(avviso.azienda.cuaa, avviso.stabilimento.icqrf, 'mvv-list')">
              <font-awesome-icon icon="arrow-circle-right" />
            </b-link>
          </td>                           
          <td> {{ avviso.stabilimento.descrizione }} <span class="text-muted">({{ avviso.stabilimento.icqrf }})</span></td>                
          <td class="text-nowrap">
            {{avviso.result.numMvv}}
          </td>
          <td>
            {{ formatDate(avviso.result.dataValid) }}
            <!-- +++{{avviso.result.dataValid }} -->
          </td>
        </tr>              
      </template>
    </tbody>
  </table>
</template>

<script>
import { DateTime } from "luxon";

import {goToAzienda} from './goToAzienda.js'

export default {
    name: "TabellaAvvisiMvv",
    props: {
        avvisiDiSistemaMVV: {
            type: Array,
            required: true,
        },
    },
    setup(props, context) {
        const useGoToAzienda = (cuaa, icqrf, route) => goToAzienda(context, cuaa, icqrf, route, "#ricevuti")
        const formatDate = (date) => DateTime.fromISO(date).toLocaleString() // return DateTime.fromFormat(date, "yyyy-MM-ddZZ").toLocaleString()
        return {
            formatDate,
            useGoToAzienda,
        }
    }
}
</script>
