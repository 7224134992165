<template>
    <b-container class="mt-3">
        <h4>Controllo giacenze</h4>
        <b-form>
            <b-form-row>
                <b-col>
                    <b-form-group
                        label="Data controllo"
                        :state="v$.form.data.$error ? false : null"
                        :invalid-feedback="
                            v$.form.data.$error &&
                            v$.form.data.$errors.length > 0
                                ? v$.form.data.$errors[0].$response
                                    ? v$.form.data.$errors[0].$response.data
                                          .message
                                    : v$.form.data.$errors[0].$message
                                : null
                        "
                    >
                        <b-form-datepicker
                            :disabled="loadingElencoGiacenzeSian"
                            v-model="form.data"
                            locale="it-IT"
                            label-help
                            start-weekday="1"
                            hide-header
                            :state="v$.form.data.$error ? false : null"
                            value-as-date
                            :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            }"
                        />
                        <b-form-text>
                            Esclude le registrazioni delle campagne precedenti
                            (storiche). Considera solo quelle comprese nella
                            campagna in corso (dal
                            {{
                                DateJSToLocaleStringShort(
                                    dataInizioCampagnaInCorso
                                )
                            }}
                            al
                            {{
                                DateJSToLocaleStringShort(
                                    dataFineCampagnaInCorso
                                )
                            }})
                            <!-- ++{{isCampagnaAziendaleRiaperta}} -->
                        </b-form-text>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <hr />

            <b-btn class="mr-2" @click="onClose" variant="light">Chiudi</b-btn>

            <b-btn
                @click="onReset"
                class="mr-2"
                :disabled="loadingElencoGiacenzeSian"
            >
                Annulla
            </b-btn>

            <b-btn
                @click="onSubmit"
                :disabled="loading || loadingElencoGiacenzeSian || v$.$invalid"
                :variant="v$.$invalid ? 'danger' : 'primary'"
            >
                <span v-if="!loading">Conferma</span>
                <b-spinner v-else small class="ml-1"></b-spinner>
            </b-btn>

            <!-- <b-btn -->
            <!--     @click="_elencaGiacenzeSian" -->
            <!--     :disabled="loadingElencoGiacenzeSian || v$.$invalid" -->
            <!--     class="ml-1 float-right" -->
            <!--     variant="light" -->
            <!-- > -->
            <!--     Stampa SIAN -->
            <!--     <b-spinner -->
            <!--         v-if="loadingElencoGiacenzeSian" -->
            <!--         small -->
            <!--         class="ml-1" -->
            <!--     ></b-spinner> -->
            <!-- </b-btn> -->
        </b-form>
    </b-container>
</template>

<script>
// Dec 22, 2019
import axios from "axios";
import { DateTime } from "luxon";
import { ref } from "@vue/composition-api";
import { useMutations, useState } from "@u3u/vue-hooks";
import { useVuelidate } from "@vuelidate/core";

import { richiesto } from "@/utils/validators/validators.js";
import {
    calcolaDataInizioCampagna,
    calcolaDataFineCampagna,
    isInCampagnaEstesa,
} from "@/components/strumenti/campagnaUtils.js";
import { showAlert } from "../../utils/eSianUtils.js";
import { DateJSToLocaleStringShort } from "@/components/prodotto/SintesiProdotto.vue";

import {
    elencaGiacenzeSian,
    loadingElencoGiacenzeSian,
} from "./ElencaGiacenzeSian.vue";

export const prodotti_non_tovati_su_e_sian = ref();
export const prodotti_committente_non_tovati_su_e_sian = ref();
// export const dataControlloGiacenze = null;

/* imposta solo la data */
export default {
    name: "data-controllo-giacenze",

    setup(props, context) {
        // August 03, 2023
        const _elencaGiacenzeSian = async () => {
            await elencaGiacenzeSian(
                // form.value.data,
                currentAzienda.value,
                currentStabilimento.value,
                context.root
            );
            context.root.$bvModal.hide("modaleDataControlloGiacenze");
        };

        const { setDataControlloGiacenze } = useMutations("prodottoModule", [
            "setDataControlloGiacenze",
        ]);
        const {
            isCampagnaAziendaleRiaperta,
            currentAzienda,
            currentStabilimento,
        } = useState("loginModule", [
            "isCampagnaAziendaleRiaperta",
            "currentAzienda",
            "currentStabilimento",
        ]);
        const {
            setProdottiPropriProblematici,
            setProdottiCommittentiProblematici,
        } = useMutations("prodottoModule", [
            "setProdottiPropriProblematici",
            "setProdottiCommittentiProblematici",
        ]);

        const loading = ref(false);

        const form = ref({
            // data: isCampagnaAziendaleRiaperta.value ? new Date() : calcolaDataFineCampagna({format: "DT"}).minus({year: 1}).toJSDate(),
            data: isCampagnaAziendaleRiaperta.value
                ? new Date()
                : calcolaDataFineCampagna(),
        });

        const controlloData = (value) => {
            if (
                value < calcolaDataInizioCampagna() ||
                value >
                    (isCampagnaAziendaleRiaperta.value
                        ? new Date()
                        : calcolaDataFineCampagna())
            ) {
                // if (value < calcolaDataInizioCampagna() || value > (isCampagnaAziendaleRiaperta.value ? new Date() : calcolaDataFineCampagna({format: "DT"}).minus({year: 1}).toJSDate())) {
                return {
                    $valid: false,
                    data: {
                        message: `La data di inizio deve essere compresa nella campagna in corso.`,
                    },
                };
            } else {
                return {
                    $valid: true,
                    data: {
                        message: "",
                    },
                };
            }
        };

        const rules = {
            form: {
                data: { richiesto, controlloData },
            },
        };
        // un "ref" va passato tra "{}"
        const v$ = useVuelidate(rules, { form }, { $autoDirty: true });

        const onSubmit = () => {
            setDataControlloGiacenze(DateTime.fromJSDate(form.value.data));
            context.root.$bvModal.hide("modaleDataControlloGiacenze");

            setProdottiPropriProblematici([]);
            setProdottiCommittentiProblematici([]);
            prodotti_non_tovati_su_e_sian.value = [];
            prodotti_committente_non_tovati_su_e_sian.value = [];
            // chiama la funzione che esegue il controllo
            controllaGiacenzeSian(context.root);
        };

        const onReset = () => {
            // form.value.data = isCampagnaAziendaleRiaperta.value ? new Date() : calcolaDataFineCampagna({format: "DT"}).minus({year: 1}).toJSDate();
            form.value.data = isCampagnaAziendaleRiaperta.value
                ? new Date()
                : calcolaDataFineCampagna();
        };
        const onClose = () => {
            context.root.$bvModal.hide("modaleDataControlloGiacenze");
        };

        return {
            // August 03, 2023
            _elencaGiacenzeSian,
            loadingElencoGiacenzeSian,

            v$,
            onClose,
            onReset,
            onSubmit,
            loading,
            form,
            dataInizioCampagnaInCorso: calcolaDataInizioCampagna(),
            dataFineCampagnaInCorso: isCampagnaAziendaleRiaperta.value
                ? new Date()
                : calcolaDataFineCampagna(),
            // dataFineCampagnaInCorso: isCampagnaAziendaleRiaperta.value ? new Date() : calcolaDataFineCampagna({format: "DT"}).minus({year: 1}).toJSDate(),
            DateJSToLocaleStringShort,
            isCampagnaAziendaleRiaperta,
        };
    },
};

// Oct 08, 2019-Aug 04, 2021
/* acquisisce i risultati del controllo */
const controllaGiacenzeSian = async (root) => {
    const { dataControlloGiacenze } = useState("prodottoModule", [
        "dataControlloGiacenze",
    ]);
    const { loadingSidebar } = useState("sidebarModule", ["loadingSidebar"]);
    const { setLoadingSidebar } = useMutations("sidebarModule", [
        "setLoadingSidebar",
    ]);
    const {
        setProdottiPropriProblematici,
        setProdottiCommittentiProblematici,
    } = useMutations("prodottoModule", [
        "setProdottiPropriProblematici",
        "setProdottiCommittentiProblematici",
    ]);
    const { currentAzienda, currentStabilimento } = useState("loginModule", [
        "currentAzienda",
        "currentStabilimento",
    ]);

    const source = ref(
        "/" + currentAzienda.value.cuaa + "/" + currentStabilimento.value.icqrf
    );
    setLoadingSidebar({
        ...loadingSidebar,
        ["controllaGiacenzeSian" + source.value]: true,
    });

    let data_giacenza = dataControlloGiacenze.value.toISODate();

    const options = {
        method: "POST",
        url: `/api/sian/GiacSiRPV/elenca/${currentAzienda.value.azienda_id}/${currentStabilimento.value.id}`,
        params: { data_giacenza: data_giacenza },
    };

    try {
        const response = await axios.request(options);
        if (response.data[0].content) {
            showAlert(
                {
                    message: response.data[0].content.message,
                    variant: "warning",
                },
                root
            );
        } else {
            const giacenzeSian = response.data[0].results.giacenze;
            prodotti_non_tovati_su_e_sian.value =
                response.data[0].results.prodotti_non_tovati_su_e_sian;
            prodotti_committente_non_tovati_su_e_sian.value =
                response.data[0].results.prodotti_committente_non_tovati_su_e_sian;
            setProdottiPropriProblematici(
                giacenzeSian
                    ? giacenzeSian.filter((x) => x.committente == null)
                    : []
            );
            setProdottiCommittentiProblematici(
                giacenzeSian
                    ? giacenzeSian.filter((x) => x.committente != null)
                    : []
            );
            if (giacenzeSian == null) {
                showAlert(
                    {
                        message:
                            "Controllo giacenze non eseguibile per questo stabilimento",
                        variant: "warning",
                    },
                    root
                );
            } else if (giacenzeSian.length == 0) {
                showAlert(
                    {
                        message: `Le giacenze del SIAN e quelle di e-Sian alla data ${data_giacenza} coincidono (Nota: i raspi non sono inclusi nei prodotti la cui giacenza viene controllata)`,
                        variant: "success",
                    },
                    root
                );
            } else if (giacenzeSian.length > 0) {
                showAlert(
                    {
                        message: `Le giacenze del SIAN e quelle di e-Sian alla data ${data_giacenza} non coincidono`,
                        variant: "error",
                    },
                    root
                );
            }
        }
        setLoadingSidebar({ ...loadingSidebar, controllaGiacenzeSian: false });
    } catch (error) {
        if (error.response != null && error.response.status == 409) {
            showAlert(
                { message: error.response.data.detail, variant: "warning" },
                root
            );
        } else {
            showAlert({ error: error }, root);
        }
        setLoadingSidebar({ ...loadingSidebar, controllaGiacenzeSian: false });
    }
};
</script>
