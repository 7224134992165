import axios from "axios";
/*
 * soggetto
 */
export const isCodiceSoggettoUnico = (value, args) => {
    if (value != args.valore_iniziale) {
        const options = {
            method: 'post',
            url: `/api/validate/codice_soggetto_unico/${args.azienda}`,
            data: {
                codice: value.toUpperCase(),
                trasportatore: args.trasportatore,
            }
        }
        return axios.request(options)
            .then(response => ({
                valid: response.data.valid,
                data: {
                    message: response.data.message
                }
            }))
            .catch(error => {
                console.log(error);
            });
    } else {
        return {
            valid: true,
            data: {
                message: '' 
            }
        }
    }
};

// disattivato il Jun 18, 2020
export const isIdFiscaleSoggettoUnico = (value, args) => {
  // solo in modifica?...
  if (value != args.valore_iniziale && args.action == 'edit') {
  //const token = sessionStorage.getItem("token");
    const path = `/api/validate/id_fiscale_soggetto_unico/${value}/${args.azienda}`;
    return axios 
      .get(path)
      .then(response => ({ // handler
        valid: response.data.valid,
        data: {
          message: response.data.message
        }
      }))
      .catch(error => {
        console.log(error);
      });
  } else {
    return { 
      valid: true,
      data: {
        message: '' 
      }
    }
  }
};

// soggetto
// export const isCFRequired = (value, args) => {
//   var required = null;
//   if (value == args.valore_iniziale && args.action == 'edit') {
//     if (args.tipo_soggetto != null) {
//       if (args.tipo_soggetto.codice == "IT") {
//         required = true;
//       } else if (args.piva == null) {
//         required = true;
//       } else {
//         required = false;
//       }
//       return {
//         valid: required,
//         data: {
//           message: "Il codice fiscale è richiesto."
//         }
//       };
//     } else {
//       return {
//         valid: true,
//         data: {
//           message: ""
//         }
//       };
//     }
//   } else {
//     return {
//       valid: true,
//       data: {
//         message: ""
//       }
//     };
//   }
// };

export const isNotItalia = (value, args) => {
  if (value != null) {
    if (args.tipo_soggetto != "IT" && value.codice == args.codice_italia) {
      return {
        valid: false,
        data: {
          message:
            "Se il tipo soggetto non è italiano, lo stato non può essere l'Italia."
        }
      };
    } else {
      return {
        valid: true,
        data: {
          message: ""
        }
      };
    }
  }
};


