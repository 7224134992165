<template>
    <b-container fluid class="h-100 v-100">
        <b-row class="h-100" align-h="center">
            <b-col align-self="center" md="8" lg="4">
                <b-card
                    :title="$t('titolo')"
                    class="mt-3 text-white"
                    style="background: rgba(0,0,0,0.3);"
                >
                    <b-card-body>
                        <p>{{$t('forgot_done')}}</p>
                        <p>{{$t('forgot_done_1')}}</p>
                    </b-card-body>
                    <b-card-footer class="text-right">
                        <b-button @click="onReset" type="reset" variant="light">{{$t('annulla')}}</b-button>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<i18n>
{
  "en": {
   },
  "it": {
  "forgot_done": "Ti abbiamo inviato le istruzioni per impostare la password, all'indirizzo e-mail indicato.",
  "forgot_done_1": "Se non ricevi il messaggio e-mail, accertati di aver inserito l'indirizzo con cui ti sei registrato e controlla la cartella dello spam (posta indesiderata).",
      "annulla": "Chiudi",
      "titolo": "Reimpostazione password"
  }
}
</i18n>

<script>
import axios from 'axios'

export default {
    name: 'password-reset-done',

    created() {
        if (this.$route.params.email && this.$route.params.from_contract) {
            this.sendPasswordResetData(this.$route.params.email, this.$route.params.from_contract)
        }
    },

    methods: {
        sendPasswordResetData(email, from_contract) {
            let root_api = null
            root_api = import.meta.env.VITE_APP_ROOT_API
            const options = {
                method: 'post',
                url: `${root_api}/api/password/reset/`,
                data: {
                    email: email,
                    from_contract: from_contract,
                }
            }
            axios.request(options)
                .then(() => null)
                .catch((error) => {
                    showAlert({error: error}, this)
                });
        },

        onReset(evt) {
            evt.preventDefault();
            this.$router.push({name: 'home-unauthenticated'});
        },
    },
};
</script>
