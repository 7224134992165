import { DateTime } from "luxon";
import { helpers } from "@vuelidate/validators";

import {
  calcolaDataFineCampagnaPrecedente,
} from "@/components/strumenti/campagnaUtils.js";

// Jul 21, 2021
// https://vuelidate-next.netlify.app/custom_validators.html#extra-parameters
export const controlloDataInizioStorici = (param) => (value) => !helpers.req(value) || _controlloDataInizioStorici(value, param)
export const controlloDataFineStorici = (param) => (value) => !helpers.req(value) || _controlloDataFineStorici(value, param)

// l'extra parametro passato (oltre a "value", il valore della variabile
// modificata), "param", è l'intero "form" (da cui estrarrò i valori correnti della data
// inizio e fine)
// 
// NOTA: "param" deve essere un "ref", ad esempio:
// const extraParam = ref(["ciccio", "pasticcio"])
// ...
// data_inizio: {richiesto,  controlloData: controlloDataInizioStorici(extraParam) },
// ...
const _controlloDataInizioStorici = (value, form) => {
    // qui aggiungo un altro parametro ("data_inizio") che a a questo punto può
    // ovviamente non essere un "ref"
    return controlloDataStorici(value, form, "data_inizio");
}

const _controlloDataFineStorici = (value, form) => {
    return controlloDataStorici(value, form, "data_fine");
}

const controlloDataStorici = (value, form, tipo_data) => {
    let data_inizio = null
    let data_fine = null

    if (tipo_data == 'data_inizio') {
        data_inizio = DateTime.fromJSDate(value);
        data_fine = DateTime.fromJSDate(form.value.data_fine)
    } else {
        data_inizio = DateTime.fromJSDate(form.value.data_inizio)
        data_fine = DateTime.fromJSDate(value);
    }
    let dataFineCampagnaPrecedente = calcolaDataFineCampagnaPrecedente()

    if (tipo_data == 'data_inizio' && data_inizio < DateTime.fromISO("2017-01-01")) {
        return {
            $valid: false,
            data: {
                message: `La data di inizio deve essere successiva al 1 gennaio 2017.`,
            },
        };
    } else if (tipo_data == 'data_inizio' && data_inizio > dataFineCampagnaPrecedente) {
        return {
            $valid: false,
            data: {
                message: `La data di inizio deve essere inferiore o uguale alla data di fine della campagna precedente a quella in corso (${dataFineCampagnaPrecedente.toLocaleString()}).`,
            },
        };
    } else if (tipo_data == 'data_fine' && data_fine < DateTime.fromISO("2017-01-01")) {
        return {
            $valid: false,
            data: {
                message: `La data di fine deve essere successiva al 1 gennaio 2017.`,
            },
        };
    } else if (tipo_data == 'data_fine' && data_fine > dataFineCampagnaPrecedente) {
        return {
            $valid: false,
            data: {
                message: `La data di fine deve essere inferiore o uguale alla data di fine della campagna precedente a quella in corso (${dataFineCampagnaPrecedente.toLocaleString()}).`,
            },
        };
    } else if (tipo_data == 'data_inizio' && data_fine < data_inizio) {
        return {
            $valid: false,
            data: {
                message: `La data di inizio deve essere inferiore a quella di fine.`,
            },
        };
    } else if (tipo_data == 'data_fine' && data_fine < data_inizio) {
        return {
            $valid: false,
            data: {
                message: `La data di fine deve essere superiore a quella di inizio.`,
            },
        };
    } else {
        return {
            $valid: true,
            data: {
                message: "",
            },
        };
    }
};    
