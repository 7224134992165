import { DateTime } from "luxon";

export const mvvModule = {
    namespaced: true,
    state: {
        newSent: false,
        newReceived: false,
        giacenzaNegativaDaMvv: {
            //mvv_id: null,
            //prodotto_id: [],
            valore: false,
            messaggio: "",
        },
        loadingCreateCasd: { riga: null, valore: false },
        isInBozzaMVV: false,
        formFilterReceived: {
            NumMVV: "",
            CUAASped: "",
            CodIcqrfSped: "",
            DataValIni: DateTime.local().minus({ months: 1 }).toJSDate(),
            DataValFine: new Date(),
        },
        formCheckReceived: {
            CodiceIcqrf: "",
            DataValidazioneInizio: DateTime.local()
                .minus({ months: 1 })
                .toJSDate(),
            DataValidazioneFine: new Date(),
        },
        formFilterSent: {
            NumMVV: "",
            CUAADest: "",
            CodIcqrfDest: "",
            DataValIni: DateTime.local().minus({ days: 7 }).toJSDate(),
            DataValFine: new Date(),
        },
        formCheckSent: {
            CodiceIcqrf: "",
            DataValidazioneInizio: DateTime.local()
                .minus({ days: 7 })
                .toJSDate(),
            DataValidazioneFine: new Date(),
        },
        form: {
            id: null, // id
            SoggDest: null, // destinatario
            SoggLDest: null, // destinatario se non lo stesso di SoggDest
            SoggVend: null, // committente
            SoggAcq: null, // cliente
            CodiceTrasportatore: null, // trasportatore
            LdestCodICRF: null,
            LspedIndirizzo: null,
            FlagArt33: false,
            FlagArt29: false,
            EmailDestinatario: null,
            DataTrasp: null,
            OraTrasp: null,
            MinutiTrasp: null,
            EmailCondTras: null,
            PrefissoInt: null, //'0039',
            Cellulare: null,
            TipmeCod: null,
            MezzoTarga: null,
            RimorchioTarga: null,
            CondNome: null,
            CondCognome: null,
            TitrCod: null,
            ProdottiMvv: [
                {
                    CodTipoProdMVV: null,
                    CodiceProdotto: null,
                    nomenclaturaCombinata: null,
                    CodNC1: null,
                    CodNC2: null,
                    CodNC3: null,
                    UniMis: null,
                    Quant: null,
                    NumColli: null,
                    NumImb: null,
                    UniMisCapacitaImb: null,
                    CapacitaImb: null,
                    NoteDes: null,
                    CodTenoreZucc: null,
                    TitoloAlcolPot: null,
                    TitoloAlcolEff: null,
                    TitoloAlcolTot: null,
                    Lotto: null,
                    GgInvecchiamento: null,
                    CertificatoOrigine: null,
                },
            ],
            destinatarioUgualeSpeditore: false,
            luogoConsegna: false,
            AltreInfo: null,
            NoteAltreAttes: null,
            TipoCertOrigine: null,
            StatoCert: null,
            ListaEbacchus: [],
            LinguaCertificato: null,
            FlagUe: false,
            NoteUlteriori: null,
            IdentLocaleMVV: null,
            Indirizzo: null,
            CAP: null,
            Comune: null,
            Provincia: null,
            // emergenza...
            NumMvvCartaceo: null,
            DataTraspMvvCartaceo: null,
            FlagPecEmerg: null,
        },

        Dirty: false,
        erroreInDestinatario: false,
        erroreInProdotti: false,
        erroreInTrasporto: false,
        mvvReceivedUrl: "",
        mvvSentUrl: "",
        soggettoUpdated: false,
        soggettoTrasportatoreUpdated: false,
        idSoggettoCreated: null,
        idSoggettoTrasportatoreCreated: null,
        mvvDettaglioDest: null,
        numberDraft: 0,
        numberReceived: 0,
        numberSent: 0,
        numberReceivedChecked: 0,
        numberSentChecked: 0,
        runningDraft: false,
        runningReceived: false,
        runningSent: false,
        ricezioneData: {},
        mvvInfoData: {},
        row: [],
        mvvSentList: [],
        mvvReceivedList: [],
        mvvSentCheckList: [],
        mvvReceivedCheckList: [],
        printingMvv: false,
        mvv_in_info_modal: null,
        infoMvv: null,
        // CodTenoreZucc: [
        //   { Codice: "1",  Descrizione: "Spumanti dosaggio zero" },
        //   { Codice: "2",  Descrizione: "Spumanti extra brut" },
        //   { Codice: "3",  Descrizione: "Spumanti brut" },
        //   { Codice: "4",  Descrizione: "Spumanti extra dry" },
        //   { Codice: "5",  Descrizione: "Spumanti secco, asciutto, dry" },
        //   { Codice: "6",  Descrizione: "Spumanti demi-sec abboccato" },
        //   { Codice: "7",  Descrizione: "Spumanti dolce" },
        //   { Codice: "11", Descrizione: "Vini secco, asciutto" },
        //   { Codice: "12", Descrizione: "Vini demi-sec abboccato" },
        //   { Codice: "13", Descrizione: "Vini amabile" },
        //   { Codice: "14", Descrizione: "Vini dolce" },
        //   { Codice: "21", Descrizione: "Vini Frizzanti secco" },
        //   { Codice: "22", Descrizione: "Vini Frizzanti semisecco abboccato" },
        //   { Codice: "23", Descrizione: "Vini Frizzanti amabile" },
        //   { Codice: "24", Descrizione: "Vini Frizzanti dolce" },
        //   { Codice: "31", Descrizione: "Vini Liquorosi secco" },
        //   { Codice: "32", Descrizione: "Vini Liquorosi semisecco amabile" },
        //   { Codice: "33", Descrizione: "Vini Liquorosi dolce" },
        // ],
        TipoCertOrigine: [
            { Codice: "A", Descrizione: "Tipo A" },
            { Codice: "B", Descrizione: "Tipo B" },
            { Codice: "C", Descrizione: "Tipo C" },
        ],
        LinguaCertificato: [
            { Codice: "ENG", Descrizione: "Inglese" },
            { Codice: "SPA", Descrizione: "Spagnolo" },
            { Codice: "FRA", Descrizione: "Francese" },
            { Codice: "DEU", Descrizione: "Tedesco" },
            { Codice: "CIN", Descrizione: "Cinese" },
        ],
        StatoVal: [
            { Codice: "01", Descrizione: "Bozza" },
            {
                Codice: "02",
                Descrizione: "Pesentato: in attesa di validazione",
            },
            { Codice: "03", Descrizione: "Validato" },
            { Codice: "04", Descrizione: "Non Validato" },
            { Codice: "05", Descrizione: "Annullato" },
            { Codice: "06", Descrizione: "Accettato dal destinatario" },
            { Codice: "07", Descrizione: "Respinto dal destinatario" },
            {
                Codice: "08",
                Descrizione: "Accettato con revisione quantitativi",
            },
            {
                Codice: "09",
                Descrizione:
                    "Validato non preso in carico per assenza codice ICQRF",
            },
            {
                Codice: "10",
                Descrizione: "Validato non preso in carico dal destinatario",
            },
        ],
        CodTipoProdMVV: [
            { Cod_tipo_prod: "01", Descr_tipo_prod: "Uve" },
            { Cod_tipo_prod: "02", Descr_tipo_prod: "Sfuso DOC/IGP" },
            {
                Cod_tipo_prod: "03",
                Descr_tipo_prod: "Sfuso generico/varietale",
            },
            { Cod_tipo_prod: "04", Descr_tipo_prod: "Imbottigliato DOC/IGP" },
            {
                Cod_tipo_prod: "05",
                Descr_tipo_prod: "Imbottigliato generico/varietale",
            },
            { Cod_tipo_prod: "06", Descr_tipo_prod: "Altri Prodotti" },
        ],
        UniMis: [
            { Cod_uni_mis: "l", Descr_uni_mis: "Litri" },
            { Cod_uni_mis: "kg", Descr_uni_mis: "Kilogrammi" },
            { Cod_uni_mis: "hl", Descr_uni_mis: "Ettolitri" },
            { Cod_uni_mis: "ton", Descr_uni_mis: "Tonnellate" },
        ],
        TitrCod: [
            {
                Cod_tipo_causale: "nn",
                Descr_tipo_causale: "Vendita/Invio a Conto lavoro",
            },
            { Cod_tipo_causale: "aa", Descr_tipo_causale: "Vendita" },
            { Cod_tipo_causale: "bb", Descr_tipo_causale: "Conto Lavorazione" },
            { Cod_tipo_causale: "cc", Descr_tipo_causale: "Reso Conto Lavoro" },
            {
                Cod_tipo_causale: "dd",
                Descr_tipo_causale: "Reso Conto Lavoro-Vendita",
            },
            {
                Cod_tipo_causale: "ee",
                Descr_tipo_causale: "Reso Conto Lavoro - invio a Conto lavoro",
            },
            {
                Cod_tipo_causale: "ff",
                Descr_tipo_causale: "Trasferimento tra depositi aziendali",
            },
            { Cod_tipo_causale: "gg", Descr_tipo_causale: "Conferimento" },
            { Cod_tipo_causale: "hh", Descr_tipo_causale: "Campionatura" },
            { Cod_tipo_causale: "ii", Descr_tipo_causale: "Omaggio" },
            {
                Cod_tipo_causale: "ll",
                Descr_tipo_causale:
                    "Trasferimento prodotti denaturati o non conformi",
            },
            {
                Cod_tipo_causale: "mm",
                Descr_tipo_causale: "Vendita a soggetto senza codice ICQRF",
            },
            {
                Cod_tipo_causale: "oo",
                Descr_tipo_causale: "Trasferimento a conto deposito",
            },
            {
                Cod_tipo_causale: "pp",
                Descr_tipo_causale: "Reso da conto deposito",
            },
            {
                Cod_tipo_causale: "qq",
                Descr_tipo_causale: "Reso",
            },
        ],
        TipmeCod: [
            { Cod_tipo_mezzo: "01", Descr_tipo_mezzo: "Bilico" },
            { Cod_tipo_mezzo: "02", Descr_tipo_mezzo: "Autotreno" },
            { Cod_tipo_mezzo: "03", Descr_tipo_mezzo: "Camion" },
            {
                Cod_tipo_mezzo: "04",
                Descr_tipo_mezzo: "Camion Telonato Con Cisterne Mobili",
            },
            {
                Cod_tipo_mezzo: "05",
                Descr_tipo_mezzo: "Trattore agricolo con rimorchi",
            },
            { Cod_tipo_mezzo: "06", Descr_tipo_mezzo: "Furgone" },
            { Cod_tipo_mezzo: "07", Descr_tipo_mezzo: "Autovettura" },
            { Cod_tipo_mezzo: "08", Descr_tipo_mezzo: "Vagone" },
            { Cod_tipo_mezzo: "09", Descr_tipo_mezzo: "Vagone-Cisterna" },
            { Cod_tipo_mezzo: "10", Descr_tipo_mezzo: "Aereo" },
            { Cod_tipo_mezzo: "11", Descr_tipo_mezzo: "Nave" },
            { Cod_tipo_mezzo: "12", Descr_tipo_mezzo: "Enodotto" },
        ],
        matrice: [
            { categoria: "01", tiprcod: [1] },
            { categoria: "02", tiprcod: [1] },
            { categoria: "03", tiprcod: [1] },
            { categoria: "04", tiprcod: [2, 3] },
            { categoria: "05", tiprcod: [3] },
            { categoria: "06", tiprcod: [3] },
            { categoria: "07", tiprcod: [2, 3, 4, 5] },
            { categoria: "08", tiprcod: [2, 3, 4, 5] },
            { categoria: "09", tiprcod: [2, 3, 4, 5] },
            { categoria: "10", tiprcod: [3, 5] },
            { categoria: "11", tiprcod: [3] },
            { categoria: "12", tiprcod: [2, 3] },
            { categoria: "13", tiprcod: [2, 3] },
            { categoria: "14", tiprcod: [2, 3] },
            { categoria: "15", tiprcod: [6] },
            { categoria: "16", tiprcod: [6] },
            { categoria: "17", tiprcod: [6] },
            { categoria: "18", tiprcod: [1] },
            { categoria: "19", tiprcod: [6] },
            { categoria: "20", tiprcod: [6] },
            { categoria: "21", tiprcod: [2, 3, 4, 5] },
            { categoria: "22", tiprcod: [2, 3, 4, 5] },
            { categoria: "23", tiprcod: [2, 3, 4, 5] },
            { categoria: "24", tiprcod: [2, 3, 4, 5] },
            { categoria: "25", tiprcod: [2, 3, 4, 5] },
            { categoria: "26", tiprcod: [2, 3, 4, 5] },
            { categoria: "27", tiprcod: [3, 5] },
            { categoria: "28", tiprcod: [2, 3, 4, 5] },
            { categoria: "29", tiprcod: [3, 5] },
            { categoria: "30", tiprcod: [2, 3, 4, 5] },
            { categoria: "31", tiprcod: [2, 3, 4, 5] },
            { categoria: "32", tiprcod: [6] },
            { categoria: "33", tiprcod: [6] },
            { categoria: "34", tiprcod: [6] },
            { categoria: "35", tiprcod: [6] },
            { categoria: "37", tiprcod: [6] },
            { categoria: "38", tiprcod: [6] },
            { categoria: "39", tiprcod: [6] },
            { categoria: "40", tiprcod: [] },
            { categoria: "41", tiprcod: [] },
            { categoria: "42", tiprcod: [] },
            { categoria: "101", tiprcod: [] },
            { categoria: "102", tiprcod: [] },
            { categoria: "103", tiprcod: [] },
            { categoria: "104", tiprcod: [] },
            { categoria: "105", tiprcod: [] },
            { categoria: "106", tiprcod: [] },
            { categoria: "107", tiprcod: [] },
            { categoria: "108", tiprcod: [] },
            { categoria: "110", tiprcod: [] },
            { categoria: "111", tiprcod: [] },
            { categoria: "112", tiprcod: [] },
            { categoria: "113", tiprcod: [] },
            { categoria: "114", tiprcod: [] },
            { categoria: "115", tiprcod: [] },
            { categoria: "116", tiprcod: [] },
            { categoria: "117", tiprcod: [] },
            { categoria: "118", tiprcod: [] },
            { categoria: "119", tiprcod: [] },
            { categoria: "120", tiprcod: [] },
            { categoria: "121", tiprcod: [] },
            { categoria: "122", tiprcod: [] },
            { categoria: "123", tiprcod: [] },
            { categoria: "124", tiprcod: [] },
            { categoria: "125", tiprcod: [] },
            { categoria: "126", tiprcod: [] },
            { categoria: "128", tiprcod: [] },
            { categoria: "129", tiprcod: [] },
            { categoria: "130", tiprcod: [] },
            { categoria: "131", tiprcod: [6] },
            { categoria: "132", tiprcod: [6] },
            { categoria: "133", tiprcod: [6] },
            { categoria: "134", tiprcod: [] },
            { categoria: "200", tiprcod: [6] },
            { categoria: "201", tiprcod: [] },
            { categoria: "202", tiprcod: [] },
            { categoria: "203", tiprcod: [] },
            { categoria: "204", tiprcod: [] },
            { categoria: "205", tiprcod: [] },
            { categoria: "206", tiprcod: [] },
            { categoria: "207", tiprcod: [] },
            { categoria: "208", tiprcod: [] },
            { categoria: "209", tiprcod: [] },
        ],
    },

    mutations: {
        setNewSent(state, payload) {
            state.newSent = payload;
        },
        setNewReceived(state, payload) {
            state.newReceived = payload;
        },
        // December 03, 2020
        setGiacenzaNegativaDaMvv(state, payload) {
            state.giacenzaNegativaDaMvv = payload;
        },
        // December 02, 2020
        setLoadingCreateCasd(state, payload) {
            state.mvvSentCheckList = payload;
        },
        // November 26, 2020
        setMvvSentCheckList(state, payload) {
            state.mvvSentCheckList = payload;
        },
        setMvvReceivedCheckList(state, payload) {
            state.mvvReceivedCheckList = payload;
        },
        setFormCheckSent(state, payload) {
            state.formCheckSent = payload;
        },
        setFormCheckReceived(state, payload) {
            state.formCheckReceived = payload;
        },
        // November 06, 2020
        setIsInBozzaMVV(state, payload) {
            state.isInBozzaMVV = payload;
        },
        setForm(state, payload) {
            state.form = payload;
        },
        setFormFilterSent(state, payload) {
            state.formFilterSent = payload;
        },
        setFormFilterReceived(state, payload) {
            state.formFilterReceived = payload;
        },

        setDirty(state, payload) {
            state.Dirty = payload;
        },
        setErroreInDestinatario(state, payload) {
            state.erroreInDestinatario = payload;
        },
        setErroreInProdotti(state, payload) {
            state.erroreInProdotti = payload;
        },
        setErroreInTrasporto(state, payload) {
            state.erroreInTrasporto = payload;
        },
        setMvvSentUrl(state, payload) {
            state.mvvSentUrl = payload;
        },
        setMvvReceivedUrl(state, payload) {
            state.mvvReceivedUrl = payload;
        },
        setSoggettoUpdated(state, payload) {
            state.soggettoUpdated = payload;
        },
        setSoggettoTrasportatoreUpdated(state, payload) {
            state.soggettoTrasportatoreUpdated = payload;
        },
        setIdSoggettoCreated(state, payload) {
            state.idSoggettoCreated = payload;
        },
        setIdSoggettoTrasportatoreCreated(state, payload) {
            state.idSoggettoTrasportatoreCreated = payload;
        },
        setMvvDettaglioDest(state, payload) {
            state.mvvDettaglioDest = payload;
        },
        setNumberDraft(state, payload) {
            state.numberDraft = payload;
        },
        setNumberSent(state, payload) {
            state.numberSent = payload;
        },
        setNumberReceived(state, payload) {
            state.numberReceived = payload;
        },
        setRunningDraft(state, payload) {
            state.runningDraft = payload;
        },
        setRunningSent(state, payload) {
            state.runningSent = payload;
        },
        setRunningReceived(state, payload) {
            state.runningReceived = payload;
        },
        setRicezioneData(state, payload) {
            // Dec 22, 2019
            state.ricezioneData = payload;
        },
        setMvvInfoData(state, payload) {
            state.mvvInfoData = payload;
        },
        setRow(state, payload) {
            state.row = payload;
        },
        setMvvSentList(state, payload) {
            state.mvvSentList = payload;
        },
        setMvvReceivedList(state, payload) {
            state.mvvReceivedList = payload;
        },
        setInfoMvv(state, payload) {
            state.infoMvv = payload;
        },
        setPrintingMvv(state, payload) {
            state.printingMvv = payload;
        },
        setMvv_in_info_modal(state, payload) {
            state.mvv_in_info_modal = payload;
        },
    },
};
