const Clienti = () => import("../components/amministrazione/clienti/Clienti.vue");
const Gruppi = () => import("../components/amministrazione/gruppi/Gruppi.vue");
const Aziende = () => import("../components/amministrazione/aziende/Aziende.vue");
const Importazione = () => import("../components/amministrazione/importazione/Importazione.vue");
const Avvisi = () => import("../components/amministrazione/avvisi/Avvisi.vue");
const Operatori = () => import("../components/amministrazione/operatori/Operatori.vue");
import AziendeEsportazione from '../components/amministrazione/aziende/AziendeEsportazione.vue';
import ClientiEsportazione from '../components/amministrazione/clienti/ClientiEsportazione.vue';

export const amministrazione_routes = [
  {
    path: "/amministrazione/clienti",
    name: "clienti",
    component: Clienti,
    meta: { title: 'Amministrazione Concessionario: gestione clienti - e-Sian', requiresAuth: true },
  },
  {
    path: "/amministrazione/aziende",
    name: "aziende",
    component: Aziende,
    meta: { title: 'Amministrazione Cliente: gestione aziende - e-Sian', requiresAuth: true },
  },
  {
    path: "/amministrazione/gruppi",
    name: "gruppi",
    component: Gruppi,
    meta: { title: 'Amministrazione Cliente: gestione gruppi aziendali - e-Sian', requiresAuth: true },
  },
  {
    path: "/amministrazione/operatori",
    name: "operatori",
    component: Operatori,
    meta: { title: 'Amministrazione Operatori - e-Sian', requiresAuth: true },
  },
  {
    path: "/amministrazione/importazione",
    name: "importazione",
    component: Importazione,
    meta: { title: 'Amministrazione Importazione - e-Sian', requiresAuth: true },
  },  {
    path: "/amministrazione/avvisi",
    name: "aziende-avvisi",
    component: Avvisi,
    meta: { title: 'Amministrazione avvisi - e-Sian', requiresAuth: true },
  },
  {
    path: "/amministrazione/aziende/esportazione",
    name: "aziende-esportazione",
    component: AziendeEsportazione,
    meta: { title: 'Amministrazione Aziende esportazione - e-Sian', requiresAuth: true },
  },
  {
    path: "/amministrazione/clienti/esportazione",
    name: "clienti-esportazione",
    component: ClientiEsportazione,
    meta: { title: 'Amministrazione Clienti esportazione - e-Sian', requiresAuth: true },
  },
]
