
<!-- <div class="sidebar" :style="systemMessageSidebarStyleObject"> -->
<div class="sidebar">
    <h6>{{ menuTitle }}</h6>
    <hr />

    <!-- {{ loadingSidebar}} -->
    <b-nav vertical>
        <!-------------------------------------------------------------------------------->
        <div v-for="(menuItem, index) in menuItems" :key="index">
    <!-- ++{{ menuItem.action + source }} -->
            <b-nav-item
                @click.stop="emitMenuAction(menuItem.action)"
                :title="menuItem.tooltip"
                :disabled="
                    disableMenuItem(menuItem) ||
                    loadingSidebar[menuItem.action + source]
                "
            >
                <template
                    v-if="
                        menuItem.action.startsWith('trasmetti') &&
                        loadingSidebar[menuItem.action + source]
                    "
                >
                    <b-spinner class="mr-2" small></b-spinner>
                </template>
                <template
                    v-else-if="
                        menuItem.action.startsWith('verifica') &&
                        loadingSidebar[menuItem.action + source]
                    "
                >
                    <b-spinner class="mr-2" small></b-spinner>
                </template>
                <template
                    v-else-if="
                        menuItem.action.startsWith('controlla') &&
                        loadingSidebar[menuItem.action + source]
                    "
                >
                    <b-spinner class="mr-2" small></b-spinner>
                </template>
                <template v-else>
                    <font-awesome-icon
                        v-if="menuItem.icon"
                        :icon="menuItem.icon"
                        class="mr-2"
                    />
                </template>

                <template v-if="menuItem.action == 'divider'">
                    <b-dd-divider class="dropdown-divider" />
                </template>
                <template v-else>
                    <span v-html="menuItem.text"></span>
                    <!-- {{menuItem}} -->
                </template>
            </b-nav-item>
        </div>
        <!-------------------------------------------------------------------------------->

        <!------------------------- CANCELLAZIONE REGISTRAZIONI -------------------------->
        <b-nav-item
            v-if="$route.name == 'elenco-registrazioni'"
            @click.stop="handleExtraAction('cancella')"
            v-b-tooltip.hover.topright
            title="Cancella registrazioni selezionate"
            :disabled="
                !menuSelectedRegistrazioni || disableExtraAction('cancella')
            "
        >
            <template
                v-if="loadingSidebar['cancellaRegistrazioni' + source]"
            >
                <b-spinner class="mr-2" small></b-spinner>
            </template>
            <template v-else>
                <font-awesome-icon icon="trash" class="mr-2" /> </template
        >
            Cancella
        </b-nav-item>
        <!-------------------------------------------------------------------------------->

        <!------------------------- IMPORTAZIONE REGISTRAZIONI -------------------------->
        <b-dd-divider v-if="$route.name == 'elenco-registrazioni'" />
        <b-nav-item
            v-if="$route.name == 'elenco-registrazioni'"
            @click.stop="handleExtraAction('importa')"
            v-b-tooltip.hover.topright
            title="Importa operazioni"
            :disabled="disableExtraAction('importa')"
        >
            <template v-if="loadingSidebar['importaRegistrazioni' + source]">
                <b-spinner class="mr-2" small></b-spinner>
            </template>
            <template v-else>
                <font-awesome-icon
                    icon="file-import"
                    class="mr-2"
                /> </template
            >Importa
        </b-nav-item>
        <!-------------------------------------------------------------------------------->

        <!-------------------------------- VINIFICAZIONE AUTOMATICA -------------------------------->
        <b-nav-item
            v-if="
                checkUserAccess(utente, {
                    servizio: 'VINIFICAZIONE_AUTOMATICA',
                }) && $route.name == 'elenco-registrazioni'
            "
            @click.stop="handleExtraAction('vinificazione-list')"
            v-b-tooltip.hover.topright
            title="Vinificazioni automatiche"
        >
            <font-awesome-icon
                icon="wine-bottle"
                class="mr-2 text-vinificazione"
            />
            Vinifica...
        </b-nav-item>
        <!-------------------------------------------------------------------------------->
    </b-nav>

    <!------------- Saldo ------------------>
    <!-- <div class="mt-5 text-right" v-if="showSaldi"> -->
    <!--   <!-1- <b-link class="small text-muted" v-b-toggle.saldi>Nascondi...</b-link> -1-> -->
    <!--   <!-1- <b-collapse id="saldi" visible appear> -1-> -->
    <!--     <table class="table table-sm w-100"> -->
    <!--       <tr> -->
    <!--         <td colspan="2" class="text-center small">Saldi</td> -->
    <!--       </tr> -->
    <!--       <tr> -->
    <!--         <td style="width: 95%" class="text-right mr-0 pr-0"> -->
    <!--           <span :class="{ -->
    <!--             'text-danger': saldoRegistrazioneKG < 0, -->
    <!--             'text-success': saldoRegistrazioneKG > 0 -->
    <!--           }"> -->
    <!--             {{ !isNaN(saldoRegistrazioneKG) ? saldoRegistrazioneKG : 0 | NumberToLocaleString }}</span> -->
    <!--         </td> -->
    <!--         <td class="text-left text-muted ml-0 pl-1" style="width: 5%"> -->
    <!--           KG -->
    <!--         </td> -->
    <!--       </tr> -->
    <!--       <tr> -->
    <!--         <td style="width: 95%" class="text-right mr-0 pr-0"> -->
    <!--           <span :class="{ -->
    <!--                         'text-danger': saldoRegistrazioneL < 0, -->
    <!--                         'text-success': saldoRegistrazioneL > 0 -->
    <!--                         }">{{ !isNaN(saldoRegistrazioneL) ? saldoRegistrazioneL : 0 | NumberToLocaleString }}</span> -->
    <!--         </td> -->
    <!--         <td class="text-left text-muted ml-0 pl-1" style="width: 5%"> -->
    <!--           L -->
    <!--         </td> -->
    <!--       </tr> -->
    <!--     </table> -->
    <!--   <!-1- </b-collapse> -1-> -->
    <!-- </div> -->

    <!---------------------------------------------------
   Pubblicità 
    ----------------------------------------------------->
    <!-- <div class="ads"> -->
    <!--   <div class="heading"> -->
    <!--     Pubblicità -->
    <!--   </div> -->
    <!--   <div v-for="(dato, i) in pubblicita_dati" :key="i"> -->
    <!--     <pubblicita -->
    <!--       :is_authenticated="isAuthenticated" -->
    <!--       :titolo="dato.titolo" -->
    <!--       :routes="dato.routes" -->
    <!--       :link="dato.link" -->
    <!--     > -->
    <!--       <span v-html="dato.testo" /> -->
    <!--     </pubblicita> -->
    <!--   </div> -->
    <!-- </div> -->
</div>
