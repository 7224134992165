export const archivioModule = {
    namespaced: true,
    state: {
        cancelSource: null,
        ricaricaElencoProdotti: false,
        ricaricaElencoSoggetti: false,
        ricaricaElencoVasi: false,
        ricaricaElencoVigne: false,
        ricaricaElencoRegistrazioni: false,
        sourceArchivio: 'archiviazione',
        isChangedArchivio: false,
        isChangedTags: false,
        target: {
            comunicazione: null,
            mvv: null,
            prodotto: null,
            registrazione: null,
            soggetto: null,
            trasportatore: null,
            vaso: null,
            vigna: null,
        }
    },
    mutations: {
        setCancelSource(state, payload) {
            state.cancelSource = payload;
        },
        setRicaricaElencoRegistrazioni(state, payload) {
            state.ricaricaElencoRegistrazioni = payload;
        },
        setRicaricaElencoProdotti(state, payload) {
            state.ricaricaElencoProdotti = payload;
        },
        setRicaricaElencoVasi(state, payload) {
            state.ricaricaElencoVasi = payload;
        },
        setRicaricaElencoVigne(state, payload) {
            state.ricaricaElencoVigne = payload;
        },
        setRicaricaElencoSoggetti(state, payload) {
            state.ricaricaElencoSoggetti = payload;
        },
        setSourceArchivio(state, payload) {
            state.sourceArchivio = payload;
        },
        setIsChangedArchivio(state, payload) {
            state.isChangedArchivio = payload;
        },
        setIsChangedTags(state, payload) {
            state.isChangedTags = payload;
        },
        setTarget(state, payload) {
            state.target.comunicazione = payload.comunicazione
            state.target.mvv = payload.mvv
            state.target.prodotto = payload.prodotto
            state.target.registrazione = payload.registrazione
            state.target.soggetto = payload.soggetto
            state.target.trasportatore = payload.trasportatore
            state.target.vaso = payload.vaso
            state.target.vigna = payload.vigna
        }
    },
};
