/*
 * Apr 09, 2020
 *
 * DEFINIZIONI
 * ===========
 *
 * Ci possono essere uno o più concessionari.
 *
 * I clienti/utenti sono associati ad un concessionario di vendita.
 *
 * Un cliente/utente può avere in gestione i registri di una o più aziende.
 * Ogni azienda può avere uno o più stabilimenti.
 *
 * Gli utenti/operatori sono associati ad una o più aziende su cui possono operare
 * (registrare operazioni vitivinicole, utilizzare una o più funzionalità del sistema).
 *
 * I dati di un'azienda sono associati ad uno stabilimento o all'azienda nel suo complesso,
 * come i soggetti.
 *
 * Le aziende sono associate ad un cliente/utente.
 *
 * REGOLE DI ACCESSO
 * =================
 *
 * Può l'utente/operatore accedere al servizio MVV?
 * checkUserAccess(utente, {servizio: 'MVV'})
 *
 * Dipende dalla categoria del cliente/utente cui appartiene (è associato) l'utente/operatore.
 * Solo se il cliente ha acquistato i servizi MVV gli operatori delle aziende associate
 * al cliente potranno accedere al servizio MVV.
 *
 * Può l'utente/operatore utilizzare una certa funzione che è in un certo stato di sviluppo BETA?
 * checkUserAccess(utente, {stato: 'BETA'})
 *
 * Solo se il suo stato_utente (ad es. BETA) è minore o uguale allo stato del servizio (ad es. PROD).
 *
 */

/*
 * ENFORCEMENT DELLE AUTORIZZAZIONI
 * January 07, 2021 
 *
 * l'utente potrebbe NON accedere ad una funzionalità, micro-funzionalità,
 * perchè appartiene ad una certa categoria:
 *
 * if (!checkUserAccess(this.utente, {categoria: 'READONLY'})) {
 *   ...funzionalità da escludere...
 * }
 *
 * oppure accedere perchè è abilitato ad un certo servizio (cioè ad un
 * insieme di funzionalità che vengono esposte, nell'interfaccia
 * amministrativa, al cliente e all'azienda, macro-funzionalità):
 *
 * if (checkUserAccess(this.utente, {servizio: 'MVV'}) {
 *  ...servizio da attivare ...
 *  }
 *
 * NON ESPOSTA ALL'INTERFACCIA AMMINISTRATIVA
 *
 * se l'utente ha lo stato BETA può accedere alla funzionalità
 *
 * if (checkUserAccess(this.utente, {stato: 'BETA'})) {
 *    ...funzionalità da attivare ...
 * }
 *
 */

/* 
 * PARAMS
 * - categoria = categoria_utente (SUPER, READONLY, ...)
 * - stato = stato_servizio_utente (BETA, PROD, ...)
 * - servizio = categoria_utente (MVV, IUS, RVV, ...)
 */


export function checkUserAccess(utente, params) {

    if (utente == null || params == null) {
        return false;
    }

    if (utente.categorie.includes("SUPER")) {
        return true; // l'amministratore ha accesso a tutto
    }

    /*
     * se l'utente ha uno stato ALFA può accedere a servizi in stato ALFA, BETA
     * o PROD; se ha uno stato BETA, può accedere a servzii in stato BETA o
     * PROD
     */
    if (params.stato != null) {
        // if (params.stato == "BETA") { debugger }
        return utente.stato <= params.stato;
    }

    if (params.categoria != null && utente.categorie.includes(params.categoria)) {
        return true;
    }

    // se ha il servizio dato e se ha uno stato che consente di accedere al
    // servizio dato
    let abilitatoAlServizio = false
    if (params.servizio != null && utente.servizi != null) {
        /*
         * per il servizio XML e ARC si controlla anche che l'azienda l'abbia acquistato
         *
         */
        if (params.servizio == "XML") { 
            if (params.azienda?.servizi_ammessi?.find(x => x.codice === 'XML')) {
                abilitatoAlServizio = utente.servizi.some(x => x.codice == params.servizio)
            }
        } else if (params.servizio == "ARC") { 
            if (params.azienda?.servizi_ammessi?.find(x => x.codice === 'ARC')) {
                abilitatoAlServizio = utente.servizi.some(x => x.codice == params.servizio)
            }
        } else {
            abilitatoAlServizio = utente.servizi.some(x => x.codice == params.servizio)
        }

        // if (abilitatoAlServizio && optionsServizio.value) {
        //     const { optionsServizio } = useState("amministrazioneModule", ["optionsServizio"])
        //     let statoDelServizio = optionsServizio.value.find(x => x.codice = params.servizio)
        //     if (statoDelServizio) {
        //         let abilitatoAlloStatoDelServizio = (utente.stato <= statoDelServizio.stato)
        //         return abilitatoAlloStatoDelServizio
        //     }
        // }
        
        return abilitatoAlServizio
    }
    return false
}
