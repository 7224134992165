export const vinificazioneModule = {
  namespaced: true,
  state: {
    avvisoImportazioneStruttura: false,
    optionsStruttura: false,
    numberOfStruttura: 0,
  },
  mutations: {
    // Jun 07, 2021 - usato in getProgress
    setAvvisoImportazioneStruttura(state, payload) { state.avvisoImportazioneStruttura = payload },

    setOptionsStruttura(state, payload) { 
      state.optionsStruttura = payload;
    },
    setNumberOfStruttura(state, payload) { 
      state.numberOfStruttura = payload;
    },
  },
};
