<template>
  <div>
    <b-container>
      <h4 class="mt-3">{{ $t('cancella_azienda') }}</h4>

      <p class="lead">{{ $t('testo_cancella_azienda') }}</p>

      <b-form-row class="mt-4">
        <b-col>
          <b-form-group>
            <b-btn
              variant="primary"
              @click.prevent="handleCancellaAzienda('cancella')"
            >Cancella dati di prova</b-btn>
          <b-form-text>
            {{ idTrasmissione != "" ? 'Id trasmissione: ' + idTrasmissione : "" }}
          </b-form-text>
          </b-form-group>
        </b-col>
      </b-form-row>

      <h4 class="mt-3">{{ $t('verifica_cancellazione') }}</h4>

      <p class="lead">{{ $t('testo_verifica_cancellazione') }}</p>

      <b-form-row class="mt-4">
        <b-col>
          <b-form-group>
            <b-btn
              :disabled="idTrasmissione==''"
              variant="primary"
              @click.prevent="handleCancellaAzienda('cancella_verifica')"
            >Verifica cancellazione</b-btn>
          <b-form-text>
            {{ idTrasmissione == "" ? 'Id trasmissione non disponibile. Richiedere eventualmente la cancellazione dei dati di prova.' : "" }}
{{ idTrasmissione != "" ? 'Id trasmissione: ' + idTrasmissione : "" }}
          </b-form-text>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import { mapMutations, mapState } from "vuex";

import { showAlert } from "../../utils/eSianUtils.js";

export default {
  name: "CancellaAzienda",

  props: {
    azienda_id: Number,
    stabilimento_id: Number,
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapState("loginModule", ["currentAzienda"]),
    ...mapState("strumentiModule", ["idTrasmissione"]),
  }, 

  methods: {
    
    ...mapMutations("strumentiModule",["setIdTrasmissione"]),

    handleCancellaAzienda(azione) {
      if (azione == 'cancella') {
        this.$bvModal
          .msgBoxConfirm(`Si conferma la cancellazione dei dati di prova dell'azienda: ${this.currentAzienda.cuaa} (${this.currentAzienda.icqrf})?`, {
            title: "Conferma",
            //size: "sm",
            //buttonSize: 'sm',
            okVariant: "danger",
            cancelVariant: "light",
            okTitle: "Conferma",
            cancelTitle: "Annulla",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value == true) {
              this.handleCancellaAziendaOk(azione);
            }
          })
          .catch(error => {
            showAlert({ error: error }, this);
            // An error occurred
          });
      } else {
        this.handleCancellaAziendaOk(azione);
      }
    },

    handleCancellaAziendaOk(azione) {
      const url = `api/sian/CancAzieSiRPV/${azione}/${this.azienda_id}/${
        this.stabilimento_id
      }?id_trasmissione=${this.idTrasmissione}`;
      axios
        .post(url)
        .then(response => {
          const codice = response.data[0].content.codice
          let messaggio = response.data[0].content.messaggio
          if (codice == "G04") { // All clear!
            this.setIdTrasmissione(response.data[0].content.id_trasmissione)
            //this.idTrasmissione = response.data[0].content.id_trasmissione
            messaggio = messaggio + "ATTENZIONE! Per allineare gli archivi e-Sian con quelli SIAN e quindi cancellare i dati ancora presenti nel sistema e-Sian, è necessario procedere adesso al ripristino degli archivi."
          } else if (codice == "012") {
            this.setIdTrasmissione(response.data[0].content.id_trasmissione)
            //this.idTrasmissione = response.data[0].content.id_trasmissione
          } 
          showAlert({
            message: messaggio,
            variant: this.parseCodice(codice)
          }, this);
        })
        .catch(error => {
          if (error.response) {
            this.showAlert({ error: error });
          }
        });
    },

    parseCodice(codice) {
      var variant = null;
      switch (codice) {
        case "012":
        case "000":
          variant = "info";
          break;
        case "G04":
          variant = "success";
          break;
        default:
          variant = "error";
      }
      return variant;
    }
  }
};
</script>

<i18n>
{
  "en": {
  },
  "it": {
    "conferma": "Conferma",
    "cancella_azienda": "Richiesta di cancellazione dati di prova",
    "testo_cancella_azienda": "Richiesta di cancellazione dei dati di prova dell'azienda, e di tutti i suoi stabilimenti, dagli archivi SIAN.",
    "verifica_cancellazione": "Verifica della cancellazione",
    "testo_verifica_cancellazione": "Dopo aver richiesto la cancellazione dei dati di prova dell'azienda, è possibile verificarne l'esito (se sia in attesa di elaborazione, completata, ecc.)."
  }
}
</i18n>
