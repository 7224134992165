const ArchivioList = () => import("../components/archiviazione/ArchivioList.vue");
const ArchivioCreate = () => import("../components/archiviazione/ArchivioCreate.vue");

export const archivio_routes = [
  {
    path: "/archivio",
    name: "archivio-list",
    component: ArchivioList,
    meta: { title: 'Documenti in archivio - e-Sian', layout: "no-side-bar", requiresAuth: true, },
  },
  {
    path: "/archivio-create",
    name: "archivio-create",
    component: ArchivioCreate,
    meta: { title: 'Nuovo documento da archiviare - e-Sian', layout: "no-side-bar", requiresAuth: true, },
  },
]
