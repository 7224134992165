<template>
  <div>
  
    <!-- <pubblicita horizontal --> 
    <!--   titolo="Cicio" -->
    <!--   link="" -->
    <!--   :is_authenticated="is_authenticated" -->
    <!--   :routes="['home',]"> -->

    <!--   Non sei soddisfatto della qualità dei tuoi tappi? Scegli <b>TAPS</b>. Scegli il meglio. -->

    <!-- </pubblicita> -->

    <!-- Privacy -->

  <!-- {{ color }} -->
  <p class="fixed-bottom text-right small mt-5 mr-3 mb-1" :class="color">
    &copy;MMXVIII-MMXXII Atlantic Sky 
    <!-- Corporation -->
  </p>

  </div>
</template>

<script>
//import pubblicita from "../../components/pubblicita/pubblicita.vue";

export default {
  components: {
    //pubblicita,
  },

  props: {
    is_authenticated: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'text-muted'
    }
  }

}
</script>

<style>
/* .mt-10 { */
/*   margin-top: 5rem; */
/* } */
</style>
