// Apr 08, 2020
import { DateTime } from "luxon";
import { mapMutations } from "vuex";

import { EventBus } from "../EventBus.js";

export const ResetAllSearch = {
  methods: {
    ...mapMutations("operazioneModule", [
      "setModalSearch",
      "setModalSearchPath",
      "setSearchRegistrazioneParams",
    ]),
    ...mapMutations("prodottoModule", [
      "setCodiceCommittente",
      "setGiacenzaCorrenteCompute",
      "setGiacenzaCorrenteDate",
      "setVisualizzaGiacenzeCommittenti",
      "setVisualizzaGiacenzePropri",
      "setVisualizzaGiacenzePath",
      "setModalProdottoSearch",
      "setModalProdottoSearchPath",
      "setProdottoAddendumPath",
      "setProdottoSearchParams",
      "setSearchByCommittente",
      "setVisualizzaGiacenzeParams",
      "setVisualizzaProdottoSearchPath",
    ]),
    ...mapMutations("mvvModule", [
      "setMvvSentList",
      "setMvvReceivedList",
      "setNumberReceived",
      "setNumberSent",
      "setNumberDraft",
    ]),

    resetAllSearch() {
      EventBus.$emit("resetModalProdottoSearch");
      EventBus.$emit("resetModalSearch");

      this.setCodiceCommittente({ codiceCommittente: null });
      this.setVisualizzaGiacenzePath("")
      this.setGiacenzaCorrenteDate(DateTime.local());
      this.setGiacenzaCorrenteCompute(0);
      this.setVisualizzaGiacenzePropri(true);
      this.setVisualizzaGiacenzeCommittenti(false);
      this.setModalProdottoSearch(false);
      this.setModalProdottoSearchPath("");
      this.setModalSearch(false);
      this.setModalSearchPath("");
      this.setMvvReceivedList([]);
      this.setMvvSentList([]);
      this.setNumberDraft(0);
      this.setNumberReceived(0);
      this.setNumberSent(0);
      this.setProdottoAddendumPath("");
      this.setSearchByCommittente({ searchByCommittente: false });
      this.setVisualizzaProdottoSearchPath("");
      this.setSearchRegistrazioneParams({
        data_inizio: "",
        data_fine: "",
        codice_registrazione: "",
        num_registrazione: "",
        num_documento: "",
        data_documento: "",
        cliente: "",
        committente: "",
        query: "",
        annata: "",
      });
      this.setProdottoSearchParams({
        annata: "",
        committente: null,
        classificazione: null,
        categoria: null,
        pratiche_enologiche: null,
        query: "",
      });
      this.setVisualizzaGiacenzeParams({
        data_giacenza: new Date(),
        giacenze_propri: false,
        giacenze_committenti: false,
      });
    },
  },

  beforeDestroy() {
    EventBus.$off("resetModalProdottoSearch");
    EventBus.$off("resetModalSearch");
  },
};
