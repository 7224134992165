const StrutturaViticola = () => import("../components/vinificazione/StrutturaViticola.vue");
const StrutturaImportazione = () => import("../components/vinificazione/StrutturaImportazione.vue");
const Impostazioni = () => import("../components/vinificazione/Impostazioni.vue");
const Vinificazione = () => import("../components/vinificazione/Vinificazione.vue");
const VinificazioneList = () => import("../components/vinificazione/VinificazioneList.vue");

export const vinificazione_routes = [
  {
    path: "/vinificazione/list",
    name: "vinificazione-list",
    component: VinificazioneList,
    props: true,
    meta: { title: 'Elenco vinificazioni - e-Sian', requiresAuth: true,},
  },
  // {
  //   path: "/vinificazione/update",
  //   name: "vinificazione-update",
  //   component: Vinificazione,
  //   props: true,
  //   meta: { title: 'Aggiorna vinificazione - e-Sian', requiresAuth: true, },
  // },
  {
    path: "/vinificazione",
    name: "vinificazione",
    component: Vinificazione,
    props: true,
    meta: { title: 'Vinificazione - e-Sian', requiresAuth: true, },
  },  
  {
    path: "/vinificazione/struttura",
    name: "struttura-viticola",
    component: StrutturaViticola,
    props: true,
    meta: { title: 'Struttura viticola - e-Sian', requiresAuth: true, },
  },
  {
    path: "/vinificazione/impostazioni",
    name: "impostazioni",
    component: Impostazioni,
    props: true,
    meta: { title: 'Impostazioni vinificazione - e-Sian', requiresAuth: true, },
  },
  {
    path: "/struttura-importazione",
    name: "struttura-importazione",
    component: StrutturaImportazione,
    meta: { title: 'Importazione dati struttura viticola' + '- e-Sian', requiresAuth: true, },
    props: true,
  },
]
