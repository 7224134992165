import { DateTime, Interval } from "luxon";
import { helpers } from "@vuelidate/validators";
import {
    calcolaDataFineCampagna,
    calcolaDataInizioCampagna,
} from "@/components/strumenti/campagnaUtils.js"

// vedi validators storici per la spiegazione della logica 
export const controlloDataInizioRipristina = (param) => (value) =>
    !helpers.req(value) || _controlloDataInizioRipristina(value, param);
export const controlloDataFineRipristina = (param) => (value) =>
    !helpers.req(value) || _controlloDataFineRipristina(value, param);

const _controlloDataInizioRipristina = (value, form) => {
    // Jul 22, 2021
    let data_inizio = DateTime.fromJSDate(value)
    let data_fine = DateTime.fromJSDate(form.value.data_fine)
    if (data_inizio > data_fine) {
        return {
            $valid: false,
            data: {
                message: `La data di inizio deve essere inferiore a quella di fine.`,
            },
        };
    }
    if ( data_inizio < calcolaDataInizioCampagna({ format: "DT" }) || data_inizio > calcolaDataFineCampagna({ format: "DT" }).plus({ day: 1})) {
        return {
            $valid: false,
            data: {
                message: `La data di inizio campagna deve essere compresa nella campagna in corso.`,
            },
        };
    };
    return { $valid: true, data: { message: "" } };
};

const _controlloDataFineRipristina = (value, form) => {
    let data_inizio = DateTime.fromJSDate(form.value.data_inizio)
    let data_fine = DateTime.fromJSDate(value)
    if (data_fine < data_inizio) {
        return {
            $valid: false,
            data: {
                message: `La data di fine deve essere superiore a quella di inizio.`,
            },
        };
    } 
    if ( data_fine < calcolaDataInizioCampagna({ format: "DT" }) || data_fine > calcolaDataFineCampagna({ format: "DT" }).plus({ day: 1})) {
        return {
            $valid: false,
            data: {
                message: `La data di fine campagna deve essere compresa nella campagna in corso.`,
            },
        };
     }
 
    return { $valid: true, data: { message: "" } };
};    
