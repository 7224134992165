import axios from "axios";

import { showAlert } from "../../utils/eSianUtils.js";


export default async function(avvisoTipo, avvisoId, root) {
    const options = {
        method: 'patch',
        url: "/api/avviso/dismiss",
        data: { 
            tipo: avvisoTipo, 
            id: avvisoId 
        }
    }
    try {
        const response = await axios.request(options)
        showAlert({ message: response.data.message, variant: 'info' }, root) 
    } catch (error) {
        showAlert({ error: error }, root)
    }
}
