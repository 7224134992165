
<b-container>
  <b-form-row class="mt-3">
    <b-col> 
      <b-form-group 
        :label="$t('indirizzo')" 
        :label-class="{ 'font-weight-bold': false }"
        :state="validateState('indirizzo')" 
        :invalid-feedback="errors.first('indirizzo')"> 
        <b-form-textarea 
          :rows="4" 
          :max-rows="4" 
          v-model="form.indirizzo" 
          :placeholder="$t('indirizzo_placeholder')" 
          data-vv-name="indirizzo" 
          data-vv-as="Indirizzo" 
          :state="validateState('indirizzo')" 
          v-validate="{ 
            required: true, 
            isASCII: [],
            max:'250',
          }"
          :disabled="disabledState('indirizzo', form)" /> 
         <!-- <b-form-text v-show="form.tipo_soggetto ? (form.tipo_soggetto.codice == 'IT' ? true : false) : true">NON includere il CAP, ma inserirlo eventualmente di seguito. -->
         </b-form-text>
      </b-form-group> 
    </b-col>
  </b-form-row>
  <b-form-row>
    <b-col> 
      <b-form-group 
        :label="$t('cap')" 
        :label-class="{ 'font-weight-bold': false }"
        :state="validateState('cap')" 
        :invalid-feedback="errors.first('cap')"> 
        <b-form-input 
          type="text" 
          v-model="form.cap" 
          :placeholder="$t('cap')" 
          v-validate="{ required: false, max: '5', }"                  
          data-vv-name="cap"
          data-vv-as="CAP" 
          :state="validateState('cap')" 
          :disabled="disabledState('cap', form)" /> 
      </b-form-group> 
    </b-col>
    <b-col> 
      <b-form-group 
        :label="$t('comune')" 
        :label-class="{ 'font-weight-bold': false }"
        :state="validateState('comune')" 
        :invalid-feedback="errors.first('comune')"> 
        <multiselect 
          :custom-label="customComuneLabel" 
          :multiple="false" 
          v-model="form.comune" 
          track-by="codice" 
          label="descrizione" 
          :options="optionsComune" 
          :placeholder="$t('comune_placeholder')" 
          data-vv-name="comune" 
          data-vv-as="Comune" 
          :show-labels="false" 
          :class="{ 'errore': errors.has('comune') }" 
          v-validate="{ 
            required: false 
          }" 
          :disabled="disabledState('comune', form)"> 
        <template slot="noResult">{{ $t("message.nessun_risultato") }}</template> 
        </multiselect> 
      </b-form-group> 
    </b-col>
    <!-- <b-col> --> 
    <!--   <b-form-group --> 
    <!--     :label="$t('stato')" --> 
    <!--   > --> 
    <!--     <b-form-input --> 
    <!--       plaintext -->
    <!--       type="text" --> 
    <!--       v-model="form.stato.descrizione" --> 
    <!--     /> --> 
    <!--   </b-form-group> --> 
    <!-- </b-col> -->
  </b-form-row>
</b-container>
