<template>
    <b-container class="border h-100">
        <p class="mt-1 mb-3">&copy;MMXVIII-present Atlantic Sky Corporation</p>
        <h5>Build</h5>
        <ul>
            <li>
                Front-end:
                <b>{{
                    build_frontend ? build_frontend.substr(0, 7) : "n.d."
                }}</b>
                ({{ build_frontend ? branch_frontend : "n.d." }})
            </li>
            <li>
                Back-end:
                <b>{{ build_backend ? build_backend.substr(0, 7) : "n.d." }}</b>
                ({{ build_frontend ? branch_backend : "n.d." }})
            </li>
            <li>
                Time: <b>{{ build_time }}</b>
            </li>
        </ul>
    </b-container>
</template>

<script>
import { mapState } from "vuex";

import { categoriaUtente } from "../utente/categoriaUtente.js";
import ReleaseNotes from "../login/ReleaseNotes.vue";

export default {
    name: "SideBarText",

    components: {
        ReleaseNotes,
    },

    data() {
        return {
            categoriaUtente,
            //checkUserAccess,
            versione: import.meta.env.VITE_APP_VERSIONE,
            build_backend: import.meta.env.VITE_APP_BACKEND_LAST_COMMIT,
            build_frontend: import.meta.env.VITE_APP_FRONTEND_LAST_COMMIT,
            branch_backend: import.meta.env.VITE_APP_BACKEND_LAST_COMMIT_BRANCH,
            branch_frontend: import.meta.env
                .VITE_APP_FRONTEND_LAST_COMMIT_BRANCH,
            build_time: import.meta.env.VITE_APP_BUILD_TIME,
            versione_api: import.meta.env.VITE_APP_VERSIONE_API,
        };
    },

    computed: {
        ...mapState("loginModule", ["utente"]),
    },
};
</script>
