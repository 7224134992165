
<b-container>
    <!-- ++{{ cuaa }} -->
    <!-- ++{{ form.namespace }} -->
    <b-form-row class="mt-3">
        <b-col>
            <b-form-group
                label="Prefisso"
                :label-class="{ 'font-weight-bold': false }"
            >
                <b-form-input
                    type="text"
                    v-model="parametri_codici_aziendali.namespace"
                    placeholder="Prefisso(namespace)"
                />
            </b-form-group>
        </b-col>
    </b-form-row>
</b-container>
