const Mvv = () => import("../components/mvv/Mvv.vue");
const MvvList = () => import("../components/mvv/MvvList.vue");

export const mvv_routes = [
  {
    path: "/mvv/list",
    name: "mvv-list",
    component: MvvList,
    props: true,
    meta: { title: 'Elenco MVV-E - e-Sian', requiresAuth: true,},
  },
  {
    path: "/mvv/update",
    name: "mvv-update",
    component: Mvv,
    props: true,
    meta: { title: 'Aggiorna MVV-E - e-Sian', requiresAuth: true, },
  },
  {
    path: "/mvv",
    name: "mvv",
    component: Mvv,
    props: true,
    meta: { title: 'MVV-E - e-Sian', requiresAuth: true, },
  },
]
