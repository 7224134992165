<template>
    <b-container class="mt-3 h-100">

      <!-- <legenda-operatori /> -->
      
  <b-alert
      :show="alertText != null" 
        variant='warning'
        dismissible
        @dismissed="alertText = null"
    >{{alertText}}</b-alert>


        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Email (nome e cognome)</th>
                        <th v-if="amministrazioneCliente">Aziende</th>
                        <th v-else-if="amministrazioneConcessionario">Cliente</th>
                        <!-- <th>Ruolo</th> -->
                        <th>Servizi</th>
                        <th>Categorie</th>
                        <th>Attivo</th>
                        <th>Stato</th>
                        <th>Contratto</th>
                        <th>Password</th>
                        <th>Note</th>
                    </tr>
                </thead>

                <tbody v-if="optionsOperatore.length > 0">
                    <tr
                        v-for="(Operatore, i) in optionsOperatore"
                        :key="i"
                        :class="{
                            'text-muted': Operatore.email == utente.email,
                        }"
                    >
                        <td class="pl-2 pr-0 mr-0">
                            <operatori-contextual-menu
                                :operatore="Operatore"
                                :index="i"
                                :elencoOperatori="true"
                            />
                        </td>

                        <td v-b-tooltip.hover :title="Operatore.categorie.join(', ')">
                            <span class="text-nowrap">
                                <!-- <font-awesome-icon -->
                                <!--     class="mr-1" -->
                                <!--     icon="circle" -->
                                <!--     :style="getCategoria(Operatore).style" -->
                                <!-- /> -->
                                <a :href="'mailto:' + Operatore.email">{{Operatore.email}}</a>
                            </span>
                            <br />({{Operatore.first_name}} {{Operatore.last_name}})
                            <br /><b-badge 
                                    class="ml-0" 
                                    :variant="getCategoria(Operatore).variant" 
                                    :style="getCategoria(Operatore).style"
                                    :title="getCategoria(Operatore).title"
                                    v-b-tooltip.hover
                                >{{getCategoria(Operatore).text}}
                            </b-badge>                        
                        </td>

                        <!------------------------------------- Aziende/Cliente --------------------------------->
                        <td v-if="amministrazioneCliente">
                            <b-btn
                                variant="link"
                                @click="handleOperatoreAziende(Operatore)"
                                v-if="Operatore.aziende_di_cui_e_operatore.length > 0"
                            >
                                <span
                                    class="text-nowrap"
                                >N. {{Operatore.aziende_di_cui_e_operatore.length}} aziende...</span>
                            </b-btn>

                            <span
                                v-if="Operatore.aziende_di_cui_e_operatore.length == 0"
                                class="text-white"
                            >L'operatore non è associato ad alcuna azienda</span>
                        </td>
                        <td v-else-if="amministrazioneConcessionario">{{Operatore.cliente.codice}}</td>

                        <!------------------------------------- ruolo --------------------------------->
                        <!-- <td> -->
                        <!--   <span :class="getRuoloClass(Operatore)"> -->
                        <!--     {{ getRuolo(Operatore) }} -->
                        <!--   </span> -->
                        <!-- </td> -->

                        <!------------------------------------- servizi --------------------------------->
                        <td>
                            <ul class="list-unstyled">
                                <span v-for="(servizio, index) in Operatore.servizi" :key="index">
                                    <li v-if="servizio != null">{{servizio.codice}}</li>
                                    <!-- <li v-if="servizio != null">{{servizio.note }}</li> -->
                                </span>
                            </ul>
                        </td>

                        <!-- ----------------------------------- categorie ------------------------------- -->
                        <td>
                            <ul class="list-unstyled">
                                <!-- <span v-for="(categoria, index) in Operatore.categorie.filter(x=>x!='CLIENTE'&&x!='ADMIN'&&x!='STAFF')" :key="index"> -->
                                <span
                                    v-for="(categoria, index) in Operatore.categorie"
                                    :key="index"
                                >
                                    <li>{{categoria}}</li>
                                </span>
                            </ul>
                        </td>

                        <td
                            v-b-tooltip.hover
                            :title="'Data iscrizione: ' + Operatore.date_joined"
                            :class="{'text-danger': !Operatore.is_active}"
                        >{{Operatore.is_active ? 'Si' : 'No'}}</td>

                        <td>{{Operatore.stato}}</td>
                        
                        <td
                            :class="{'text-danger': !Operatore.contratto_accettato}"
                        >{{Operatore.contratto_accettato ? 'Si' : 'No'}}</td>
                        <td
                            :class="{'text-danger': !Operatore.password_first_reset}"
                        >{{Operatore.password_first_reset ? 'Si' : 'No'}}</td>
                        <td>
                            <span
                                :id="'popover-note-' + i"
                            >{{Operatore.note != null ? 'Note...' : ''}}</span>
                        </td>

                        <b-popover
                            :target="'popover-note-' + i"
                            triggers="hover focus"
                            v-if="Operatore.note != null"
                        >{{Operatore.note}}</b-popover>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="9" class="lead text-center">Nessun operatore</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <b-modal
            size="lg"
            id="operatoreAziendeModal"
            ok-only
            lazy
            centered
            hide-footer
            ok-title="Chiudi"
            ok-variant="light"
            :title="'Aziende dell\'operatore  ' + currentOperatore.email"
            headerClass="p-3 border-bottom-0"
        >
            <operatore-aziende />
        </b-modal>

        <div class="overflow-auto mt-3 mb-3">
            <b-pagination
                :disabled="numberOfOperatore <= 10"
                :total-rows="numberOfOperatore"
                :per-page="10"
                @change="getPage"
            ></b-pagination>
        </div>
    </b-container>
</template>


<script>
import OperatoriContextualMenu from "./OperatoriContextualMenu.vue"
import OperatoreAziende from "./OperatoreAziende.vue"
import {useState, useMutations} from '@u3u/vue-hooks';
// import { ref } from "@vue/composition-api";
// import { checkUserAccess } from "../../utils/access.js";
import {
    loadOptionsOperatore,
    getRuolo,
    getRuoloClass,
} from './functions';
import { alertText } from '../clienti/functions.js';
import LegendaOperatori from "./LegendaOperatori.vue";

//const fields = [
//  { key: 'contextual_menu', label: '' },
//  { key: 'is_active', label: 'Attivo' },
//  { key: 'email', sortable: true },
//  { key: 'ruolo', label: 'Ruolo' },
//  { key: 'servizi', label: 'Servizi' },
//  { key: 'aziende_cliente', label: 'az_li' },
//  { key: 'contratto_accettato', label: 'Contratto' },
//  { key: 'password_first_reset', label: 'Password' },
//  { key: 'note', label: 'Note' },
//]

export default {
    name: "operatori-elenco",

    components: {
        OperatoriContextualMenu,
        OperatoreAziende,
        LegendaOperatori,
    },

    setup(props, context) {
        const {utente} = useState("loginModule", ["utente"]);
        const {currentOperatore, numberOfOperatore, optionsOperatore, amministrazioneCliente, amministrazioneConcessionario} = useState("amministrazioneModule", ["currentOperatore", "optionsOperatore", "amministrazioneCliente", "amministrazioneConcessionario", "numberOfOperatore"]);

        const getPage = (pageNum) => {
            loadOptionsOperatore(context, pageNum)
        }

        const handleOperatoreAziende = (operatore) => {
            const {setCurrentOperatore} = useMutations("amministrazioneModule", ["setCurrentOperatore"])
            setCurrentOperatore(operatore)
            context.root.$bvModal.show("operatoreAziendeModal");
        }

        return {
            alertText,

            getCategoria,
            getPage,
            numberOfOperatore,
            currentOperatore,
            handleOperatoreAziende,
            getRuolo,
            getRuoloClass,
            utente,
            optionsOperatore,
            amministrazioneConcessionario,
            amministrazioneCliente,
        };
    }
};


export const getCategoria = (operatore) => {
    if (operatore.categorie) {

        const API = operatore.categorie.includes('API')
        const operatoreAziendale = operatore.categorie.includes('AZIENDA')
        const operatoreAmministrativo = operatore.categorie.includes('ADMIN')
        const operatoreCliente = operatore.categorie.includes('STAFF')

        if (API) { 
            return {
                variant: 'secondary',
                style: {color: 'black'},
                text: 'API',
                title: "Utente API."
            }
        }
        if (operatoreAziendale) { 
            return{
                variant: 'warning',
                style: {color: 'white'},
                text: 'Aziendale',
                title: "Operatore aziendale: può operare esclusivamente sulle aziende."
            }
        }
        if (operatoreAmministrativo) { 
            return{
                variant: 'primary',
                style:  {color: 'white'},
                text: 'Amministrativo',
                title: "Operatore amministrativo del cliente: può creare operatori del cliente e operatori aziendali. Non può operare sulle aziende."
            }
        }
        if (operatoreCliente) { 
            return{
                variant: 'success',
                style:  {color: 'white'},
                text: 'Cliente',
                title: "Operatore del cliente: può operare sulle aziende."
            }
        }
    }
}


</script>
