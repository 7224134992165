<template>
  <table class="table table-sm">
    <thead>
      <tr>
        <th width="30%" class="border-top-0">Azienda</th>
        <th width="1%" class="border-top-0"></th>
        <th width="15%" class="border-top-0">Stabilimento</th>
        <th class="border-top-0">Stato</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="avviso in avvisiDiSistemaTrasmissioni">
        <tr :key="avviso.id">
          <td>
            {{ avviso.azienda.ragione_sociale }} <span class="text-muted">({{ avviso.azienda.cuaa }})</span>
          </td>
          <td>
            <b-link @click="useGoToAzienda(avviso.azienda.cuaa, avviso.stabilimento.icqrf, 'home')">
              <font-awesome-icon icon="arrow-circle-right" />
            </b-link>
          </td>                           
          <td> {{ avviso.stabilimento.descrizione }} <span class="text-muted">({{ avviso.stabilimento.icqrf }})</span></td>                
          <td>
            <table class="table vertical-border table-sm">
              <thead>
                <tr style="border-top: hidden;">
                  <td style="border-left: hidden;"></td>
                  <td class="text-right font-italic">Operazioni</td>
                  <td class="text-right font-italic">Prodotti</td>
                  <td class="text-right font-italic">Soggetti</td>
                  <td class="text-right font-italic">Vasi</td>
                  <td style="text-right border-right: hidden;" class="font-italic">Vigne</td>
                </tr>
              </thead>
              <tbody>
                <template v-for="risultato, l in Object.entries(avviso.result.risultati)">
                <tr :key="l">
                  <td style="border-left: hidden;" v-html="getData(risultato).titolo"></td>
                  <td class="text-right font-weight-bold" :class="getData(risultato).classe">{{getData(risultato).data.registrazioni}}</td>                          
                  <td class="text-right font-weight-bold" :class="getData(risultato).classe">{{getData(risultato).data.prodotti}}</td>                          
                  <td class="text-right font-weight-bold" :class="getData(risultato).classe">{{getData(risultato).data.soggetti}}</td>                          
                  <td class="text-right font-weight-bold" :class="getData(risultato).classe">{{getData(risultato).data.vasi}}</td>                          
                  <td style="border-right: hidden;" class="text-right font-weight-bold" :class="getData(risultato).classe">{{getData(risultato).data.vigne}}</td>                    
                </tr>
                </template>
              </tbody>
            </table>
          </td>
        </tr>              
      </template>
    </tbody>
  </table>
</template>

<script charset="utf-8">
import {goToAzienda} from './goToAzienda.js'

export default {
    name: "TabellaAvvisiTrasmissioni",
    props: {
        avvisiDiSistemaTrasmissioni: {
            type: Array,
            required: true,
        },
    },
    setup(props, context) {
      const useGoToAzienda = (cuaa, icqrf, route) => goToAzienda(context, cuaa, icqrf, route)
      return {
        getData,
        useGoToAzienda,
      }
    }
}


const getData = (dato) => {
  let titolo = ""
  let data = {} 
  let classe = ""
  if (dato[0] == "da_trasmettere") {
    titolo = "Da&nbsp;trasmettere"
    data = dato[1]
    classe = ""
  }
  if (dato[0] == "da_completare") {
    titolo = "Da&nbsp;verificare"
    data = dato[1]
    classe = ""
  }
  if (dato[0] == "da_correggere") {
    titolo = "Da&nbsp;correggere"
    data = dato[1]
    classe = "text-danger"
  }

  return {titolo: titolo, data: data, classe: classe}
}
</script>

<style>
.table-avvisi table thead tr th {
    border-top: none;
}
</style>>
