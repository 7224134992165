import { mapMutations } from "vuex";

import { EventBus } from '../../EventBus.js';


export const SidebarMenu = {

  created() {

    const menuItems = [{ 
      action: 'elencoSoggetti', 
      text: this.trasportatore ? 'Trasportatori' : 'Soggetti', 
      tooltip: this.$i18n.t('elenco_soggetti_tooltip'), 
      icon: "arrow-left"
    }]

    if (!this.inModal) {
      this.setMenuItems(menuItems)
      this.setMenuTitle(this.$i18n.t(this.update_soggetto ? 'titolo_update_sidebar' : 'titolo_sidebar'))
    }
  },

  mounted() {

    EventBus.$on('sideBarAction', (sideBarAction) => {
      if (sideBarAction == 'elencoSoggetti') {
        this.$router.push({ name: 'elenco-soggetti', params: { trasportatore: this.trasportatore }});
      }
    });
  },

  methods: {
    ...mapMutations("sidebarModule", ["setLoadingSidebar", 'setMenuItems', 'setMenuTitle', 'setMenuItem']),
  },

  beforeDestroy() {

    if (!this.inModal) { // se esce dal modal non deve spegnere il listener perche spegne anche quello di Registrazione
      EventBus.$off('sideBarAction');
    }
  },

}
