
<b-container fluid class="h-100 v-100">
  <ValidationObserver ref="observer" v-slot="{ invalid, dirty }">
    <b-row class="h-100" align-h="center">
      <b-col align-self="center" md="8" lg="4">
        <b-card
          :title="$t('titolo')"
          class="mt-3 text-white"
          style="background: rgba(0,0,0,0.3);"
        >
          <p>
            {{ $t('forgot') }}, oppure
            <b-link
              :to="{ name: 'home' }"
              class="text-white font-weight-bold"
            >tornare alla pagina di identificazione (login)</b-link>
          </p>

          <ValidationProvider
            name="Password"
            :rules="{ required: true, min:8, max:4096 }"
            v-slot="{ errors }"
            mode="lazy"
            vid="Password"
          >
            <b-form-group
              :label="$t('new_password1')"
              label-for="form-new_password1-input"
              label-class="font-weight-bold pt-0"
              :state="errors[0] ? false : null"
              :invalid-feedback="errors[0]"
            >
              <b-input-group>
              <b-form-input
                :type="tipoCampoPwd[0]"
                v-model="form.new_password1"
                :placeholder="$t('new_password1_placeholder')"
                :state="errors[0] ? false : null"
              />
              <b-input-group-append>
                <b-button @click="useShowPassword(0)" variant='light'>
                  <font-awesome-icon icon="eye" :title="btnText[0]" />
                </b-button>
              </b-input-group-append>
              </b-input-group>       
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            name="Password (di controllo)"
            rules="required|min:8|max:4096|confirmed:Password"
            v-slot="{ valid, errors }"
            mode="lazy"
          >
            <b-form-group
              id="form-new_password2-group"
              :label="$t('new_password2')"
              label-for="form-new_password2-input"
              label-class="font-weight-bold pt-0"
              :state="errors[0] ? false : null"
              :invalid-feedback="errors[0]"
            >
              <!-- <template v-slot:description> -->
              <!--   <span class="text-white mt-1"> -->
              <!--   Cliccare "Annulla" per azzerare i valori immessi -->
              <!--   </span> -->
              <!-- </template> -->
              <b-input-group>
              <b-form-input
                name="Password"
                :type="tipoCampoPwd[1]"
                v-model="form.new_password2"
                :placeholder="$t('new_password2_placeholder')"
                :state="errors[0] ? false : null"
              />
              <b-input-group-append>
                <b-button @click="useShowPassword(1)" variant='light'>
                  <font-awesome-icon icon="eye" :title="btnText[0]" />
                </b-button>
              </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </ValidationProvider>

          <hr class="text-right mt-2" />

          <p class="text-white small">
            Cliccare "Annulla" per azzerare i valori immessi
          </p>

          <!------------------------------- bottoni ----------------------------->
          <b-form-group class="text-right">
            <b-button
              @click="onReset"
              class="mr-2"
              type="reset"
              variant="secondary"
            >{{ $t('annulla') }}</b-button>
            <b-button
              @click="onSubmit"
              type="submit"
              :disabled="invalid || !dirty"
              :variant="invalid ? 'danger' : 'primary'"
            >
            {{ $t('conferma') }}<b-spinner small class="ml-1" v-show="resetLoading" />
            </b-button>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
  </ValidationObserver>

  <b-alert
    v-model="show"
    class="position-fixed fixed-top m-0 rounded-0"
    style="z-index: 2000;"
    variant="warning"
    dismissible
  >
    {{ loginMessage }}
    <ul>
      <li v-if="errore != ''">{{ errore }} <b-link class="text-warning" :to="{ name: 'password-reset', params: { email: null, from_contract: false } }"> Per favore, richiedere un nuovo link</b-link>.</li>
      <li v-if="errore1 != ''">{{ errore1 }}. Per favore cambiarla.</li>
      <li v-if="errore2 != ''">{{ errore2 }}. Per favore cambiarla.</li>
    </ul>
  </b-alert>

</b-container>
