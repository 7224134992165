<template>
  <b-container fluid class="h-100 v-100">
    <ValidationObserver ref="observer" v-slot="{ invalid, dirty }">
      <b-row class="h-100" align-h="center">
        <b-col align-self="center" md="8" lg="4">
          <b-card
            :title="$t('titolo')"
            class="mt-3 text-white"
            style="background: rgba(0,0,0,0.3);"
          >
            <p>
              {{ $t('forgot') }}, oppure
              <b-link
                :to="{ name: 'home' }"
                class="text-white font-weight-bold"
              >tornare alla pagina di identificazione (login)</b-link>
            </p>

            <ValidationProvider
              name="Password"
              :rules="{ required: true, min:8, max:4096 }"
              v-slot="{ errors }"
              mode="lazy"
              vid="Password"
            >
              <b-form-group
                :label="$t('new_password1')"
                label-for="form-new_password1-input"
                label-class="font-weight-bold pt-0"
                :state="errors[0] ? false : null"
                :invalid-feedback="errors[0]"
              >
                <b-input-group>
                <b-form-input
                  :type="tipoCampoPwd[0]"
                  v-model="form.new_password1"
                  :placeholder="$t('new_password1_placeholder')"
                  :state="errors[0] ? false : null"
                />
                <b-input-group-append>
                  <b-button @click="useShowPassword(0)" variant='light'>
                    <font-awesome-icon icon="eye" :title="btnText[0]" />
                  </b-button>
                </b-input-group-append>
                </b-input-group>       
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              name="Password (di controllo)"
              rules="required|min:8|max:4096|confirmed:Password"
              v-slot="{ valid, errors }"
              mode="lazy"
            >
              <b-form-group
                id="form-new_password2-group"
                :label="$t('new_password2')"
                label-for="form-new_password2-input"
                label-class="font-weight-bold pt-0"
                :state="errors[0] ? false : null"
                :invalid-feedback="errors[0]"
              >
                <!-- <template v-slot:description> -->
                <!--   <span class="text-white mt-1"> -->
                <!--   Cliccare "Annulla" per azzerare i valori immessi -->
                <!--   </span> -->
                <!-- </template> -->
                <b-input-group>
                <b-form-input
                  name="Password"
                  :type="tipoCampoPwd[1]"
                  v-model="form.new_password2"
                  :placeholder="$t('new_password2_placeholder')"
                  :state="errors[0] ? false : null"
                />
                <b-input-group-append>
                  <b-button @click="useShowPassword(1)" variant='light'>
                    <font-awesome-icon icon="eye" :title="btnText[0]" />
                  </b-button>
                </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </ValidationProvider>

            <hr class="text-right mt-2" />

            <p class="text-white small">
              Cliccare "Annulla" per azzerare i valori immessi
            </p>

            <!------------------------------- bottoni ----------------------------->
            <b-form-group class="text-right">
              <b-button
                @click="onReset"
                class="mr-2"
                type="reset"
                variant="secondary"
              >{{ $t('annulla') }}</b-button>
              <b-button
                @click="onSubmit"
                type="submit"
                :disabled="invalid || !dirty"
                :variant="invalid ? 'danger' : 'primary'"
              >
              {{ $t('conferma') }}<b-spinner small class="ml-1" v-show="resetLoading" />
              </b-button>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </ValidationObserver>

    <b-alert
      v-model="show"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="warning"
      dismissible
    >
      {{ loginMessage }}
      <ul>
        <li v-if="errore != ''">{{ errore }} <b-link class="text-warning" :to="{ name: 'password-reset', params: { email: null, from_contract: false } }"> Per favore, richiedere un nuovo link</b-link>.</li>
        <li v-if="errore1 != ''">{{ errore1 }}. Per favore cambiarla.</li>
        <li v-if="errore2 != ''">{{ errore2 }}. Per favore cambiarla.</li>
      </ul>
    </b-alert>

  </b-container>
</template>

<script>
import axios from 'axios'
import { ref } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapState, mapMutations } from "vuex";
import { showAlert } from "../../utils/eSianUtils.js";


const showPassword = (i=0, tipoCampoPwd, btnText, context) => {
  if(tipoCampoPwd.value[i] === 'password') {
    context.root.$set(tipoCampoPwd.value, i, 'text')
    btnText.value[i] = 'Nascondi la password'
  } else {
    context.root.$set(tipoCampoPwd.value, i, 'password')
    btnText.value[i] = 'Mostra la password'
  }
}


export default {
  name: "password-reset-confirm",

  components: {
    ValidationProvider,
    ValidationObserver
  },

  setup(props, context) {
    const tipoCampoPwd = ref(['password', 'password'])
    const btnText = ref(['Mostra la password', 'Mostra la password'])
    const useShowPassword = (i) => showPassword(i, tipoCampoPwd, btnText, context)

    return {
      useShowPassword, tipoCampoPwd, btnText
    }
  },  

  computed: {
    ...mapState("loginModule", ["resetLoading"]),
  },

  data() {
    return {
      show: false,
      variant: "warning",
      loginMessage: `Reimpostazione della password non andata a buon fine. `, 
      //loginMessage: `Reimpostazione della password non andata a buon fine. La nuova password immessa potrebbe non essere accettabile per il sistema (troppo corta, troppo simile alla  email o alla password da cambiare, ecc.). Si prega inoltre di controllare di avere utilizzato il link nell'ultima mail ricevuta in ordine di tempo e di non riutilizzare link già utilizzati in precedenza. In qualsiasi caso, `, 
      errore: '',
      errore1: '',
      errore2: '',
      form: {
        new_password1: null,
        new_password2: null
      }
    };
  },

  /* beforeRouteEnter(to, from, next) { */
  /*   const answer = window.confirm('Do you really want to leave? you have unsaved changes!') */
  /*   if (answer) { */
  /*     next() */
  /*   } else { */
  /*     next(false) */
  /*   } */
  /* }, */

  beforeDestroy() {
    this.setResetLoading(false)
  },

  methods: {
    ...mapMutations("loginModule", ["setResetLoading"]),

    async onSubmit(evt) {
      evt.preventDefault();
      this.setResetLoading(true)
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.setResetLoading(false)
        return;
      }

      let root_api = null
      if (import.meta.env.VITE_APP_SCALEWAY == 1) {
        root_api = import.meta.env.VITE_APP_SCALEWAY_ROOT_API
      } else {
        root_api = import.meta.env.VITE_APP_ROOT_API
      }
      const path = `${root_api}/api/password/reset/confirm/`;

      let payload = {
        new_password1: this.form.new_password1,
        new_password2: this.form.new_password2,
        uid: this.$route.params.uidb64,
        token: this.$route.params.token
      };
      axios.post(path, payload)
        .then(response => {
          this.$router.push({ name: "password-reset-confirm-done" });
        })
        .catch(error => {
          this.setResetLoading(false)
          if (error.response.status == 400) {
            if (error.response.data["new_password1"]) {
              this.errore1 = error.response.data["new_password1"][0]
            }
            if (error.response.data["new_password2"]) {
              this.errore2 = error.response.data["new_password2"][0]
            }                
            if (error.response.data["token"]) {
              this.errore = "Non riutilizzare link già utilizzati in precedenza."
            }            
            this.show = true
            //showAlert({ message: "Reimpostazione della password non andata a buon fine.", variant: "error" }, this);
          } else {
            showAlert({ error: error }, this);
          }
        });
    },

    onReset(evt) {
      evt.preventDefault();
      this.setResetLoading(false)
      this.show = false
      this.errore = "";
      this.errore1 = "";
      this.errore2 = "";
      this.form.new_password1 = null;
      this.form.new_password2 = null;
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
      // this.$router.push({ name: "home" });
    }
  }
};
</script>

<i18n>
{
  "en": {
   },
  "it": {
      "forgot": "Immettere la nuova password",
      "new_password1_placeholder": "Immettere la nuova password... ",
      "new_password1": "Password",
      "new_password2_placeholder": "Immettere la nuova password... ",
      "new_password2": "Password (uguale alla precedente)",
      "annulla": "Annulla",
      "conferma": "Conferma",
      "titolo": "Reimpostazione password"
  }
}
</i18n>


