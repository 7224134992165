const ComunicazioniList = () => import("../components/comunicazioni/ComunicazioniList.vue");

export const comunicazioni_routes = [
  {
    path: "/comunicazioni/list",
    name: "comunicazioni-list",
    component: ComunicazioniList,
    props: true,
    meta: { title: 'Elenco comunicazioni - e-Sian', requiresAuth: true,},
  },
]
