
<div>
    <b-navbar-nav>

        <!----------------------------------------------------------------
        CONCESSIONARIO
        --------------------------------------------------------------->
        <template
            v-if="checkUserAccess(utente, { categoria: 'CONCESSIONARIO' })"
        >
            <b-nav-item :to="{ name: 'clienti' }" v-b-tooltip.hover title="Elenco clienti" >Clienti</b-nav-item >

            <b-nav-item :to="{ name: 'operatori' }" title="Elenco operatori dei clienti" >Operatori</b-nav-item >

            <!-- <b-nav-item :to="{name: 'avvisi'}" v-show="false">Avvisi</b-nav-item> -->

            <!-- <b-nav-item :to="{name: 'clienti-fatturazione'}" v-show="false">Fatturazione</b-nav-item> -->

            <!-- <b-nav-item :to="{ name: 'clienti-esportazione' }" disabled>Eportazione</b-nav-item> -->
        </template>

        <!----------------------------------------------------------------
        CLIENTE
        --------------------------------------------------------------->
        <template v-if="checkUserAccess(utente, { categoria: 'CLIENTE' })">

            <b-nav-item :to="{ name: 'aziende' }">Aziende</b-nav-item>

            <b-nav-item :to="{ name: 'gruppi' }">Gruppi</b-nav-item>

            <b-nav-item :to="{ name: 'operatori' }">Operatori</b-nav-item>

            <b-nav-item
                v-if="checkUserAccess(utente, { servizio: 'AVV' })"
                :to="{ name: 'aziende-avvisi' }"
                >Avvisi<b-badge variant="success" class="ml-1"
                    >New!</b-badge
                >
            </b-nav-item>

            <!-- <b-nav-item :to="{name: 'aziende-fatturazione'}" v-show="false"> -->
            <!--     Fatturazione -->
            <!-- </b-nav-item> -->

            <!-- <b-nav-item :to="{ name: 'aziende-esportazione' }" v-show="false"> -->
            <!--     Eportazione -->
            <!-- </b-nav-item> -->

            <!-- <b-nav-item -->
            <!--     v-if="!checkUserAccess(utente, { stato: 'ALFA' })" -->
            <!--     :to="{ name: 'importazione' }" -->
            <!--     title="Importazione aziende, stabilimenti e operatori" -->
            <!--     >Importazione</b-nav-item -->
            <!-- > -->

        </template>

        <b-nav-text
            style="cursor: pointer"
            :title="'Click per informazioni (' + utente.email + ')'"
            @click="useInfoAmministrazione"
        >
            <b-avatar
                size="sm"
                class="mr-0 ml-2"
                :variant="
                    checkUserAccess(utente, {
                        categoria: 'CONCESSIONARIO',
                    })
                        ? 'light'
                        : checkUserAccess(utente, {
                              categoria: 'CLIENTE',
                          })
                        ? 'light'
                        : ''
                "
            ></b-avatar>
        </b-nav-text>

        <b-nav-item
            :to="{
                name: 'logout-administration',
                params: { fromNavBar: true },
            }"
            :title="$t('exit')"
        >
            <font-awesome-icon class="ml-2" icon="sign-out-alt" />
        </b-nav-item>
    </b-navbar-nav>
</div>
