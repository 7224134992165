const VasoList = () => import('../components/vaso/VasoList.vue');
const VasoUpdate = () => import('../components/vaso/VasoUpdate.vue');
const VasoCreate = () => import('../components/vaso/VasoCreate.vue');

export const vaso_routes = [
    {
      // path: "/vaso-list/:cuaa/:icqrf",
      path: "/vaso-list",
      name: "vaso-list",
      component: VasoList,
      //props: true,
      meta: { title: 'Elenco Vasi - e-Sian', requiresAuth: true, },
      //beforeEnter: (to, from, next) => {
      //  if (!) {  
      //    next({ name: 'home' });
      //  }
      //},
    },
    // {
    //   path: "/elenco-vaso-sian",
    //   name: "elenco-vaso-sian",
    //   component: SianElenco,
    //   meta: { title: 'Elenco vasi SIAN' + '- e-Sian', requiresAuth: true, },
    //   // meta: { title: 'Elenco vasi SIAN' + '- e-Sian', layout: "elenchi-sian", requiresAuth: true, },
    // },
    {
      path: "/vaso-create",
      name: "vaso-create",
      component: VasoCreate,
      meta: { title: 'Aggiunta vaso' + '- e-Sian', requiresAuth: true, },
      // meta: { title: app.$i18n.t('aggiungi-vaso') + '- e-Sian', }
    },
    {
      path: "/vaso-update/:id",
      name: "vaso-update",
      component: VasoUpdate,
      meta: { title: 'Modifica vaso' + '- e-Sian', requiresAuth: true, },
    },
]
