
<b-container class="border h-100">
    <p class="mt-1 mb-3">&copy;MMXVIII-present Atlantic Sky Corporation</p>
    <h5>Build</h5>
    <ul>
        <li>
            Front-end:
            <b>{{
                build_frontend ? build_frontend.substr(0, 7) : "n.d."
            }}</b>
            ({{ build_frontend ? branch_frontend : "n.d." }})
        </li>
        <li>
            Back-end:
            <b>{{ build_backend ? build_backend.substr(0, 7) : "n.d." }}</b>
            ({{ build_frontend ? branch_backend : "n.d." }})
        </li>
        <li>
            Time: <b>{{ build_time }}</b>
        </li>
    </ul>
</b-container>
