import axios from "axios";
import { mapState, mapMutations } from "vuex";
import {
    ValidationProvider,
    ValidationObserver,
    Validator,
} from "vee-validate";
import {useMutations} from "@u3u/vue-hooks";
import {onMounted, onUnmounted} from "@vue/composition-api";

import SintesiSoggetto from "./SintesiSoggetto.vue";
import SoggettoIndirizzo from "./SoggettoIndirizzo.vue";
import { SidebarMenu } from "./SoggettoMixins.js";
import {
    isCodiceSoggettoUnico,
    isIdFiscaleSoggettoUnico,
    isNotItalia,
} from "./Validation.js";
import { showAlert } from "../../utils/eSianUtils.js";
import { initSoggettoOptions } from "./functions.js"; // named import
import { disabledState } from "./Disabilitazioni.js";

const fieldsRequiredStatus = {
    codice: true,
    tipo_soggetto: true,
};

export default {
    name: "soggetto",

    props: {
        // inModal: {
        //     type: Boolean,
        //     default: false,
        //     required: false,
        // },
        //inMvv: {
        //  type: Boolean,
        //  default: false,
        //  required: false
        //},
        azienda_id: {
            type: Number,
            required: true,
        },
        action: {
            type: String,
            required: true,
            default: "add",
        },
        id: {
            type: [Number, String],
            required: false,
        },
        trasportatore: {
            type: Boolean,
            default: false,
            required: true,
        },
    },

    mixins: [SidebarMenu],

    components: {
        ValidationProvider,
        ValidationObserver,
        SintesiSoggetto,
        SoggettoIndirizzo,
    },

    setup(props, context) {
        const {setTarget, setSourceArchivio} = useMutations("archivioModule", ["setTarget", "setSourceArchivio"])

        extendValidator();

        onMounted(() => {
            setSourceArchivio("soggetto")
        })
        
        onUnmounted(() => {
            setTarget({soggetto: null})
            setSourceArchivio("")
        })

        function formatter(value) {
            return value.toUpperCase();
        }

        return {
            formatter,
            // chiudi() {
            //     props.trasportatore
            //         ? context.root.$bvModal.hide(
            //               "mvvSoggettoTrasportatoreModal"
            //           )
            //         : context.root.$bvModal.hide(
            //               "mvvSoggettoDestinatarioModal"
            //           );
            // },
            requiredState,
            disabledState,
        };
    },

    data() {
        return {
            optionsStato: [],
            valore_iniziale_codice: null,
            valore_iniziale_piva: null,
            valore_iniziale_cf: null,
            form: {
                codice: null,
                categoria_soggetto: null,
                tipo_soggetto: null,
                email: null,
                seedDest: null,
                Cellulare: null,
                PrefissoInt: null,
                persona: false,
                ragione_sociale: null,
                cf: null,
                piva: null,
                icqrf: null,
                destinatario_prodotti_vinicoli: true,
                nome_persona_fisica: null,
                cognome_p_fisica: null,
                indirizzo: null,
                cap: null,
                comune: null,
                stato: null,
                saveToSIAN: false,
            },
        };
    },

    computed: {

        addendum: function() {
            const addendum = this.trasportatore
                ? "&trasportatore=2"
                : "&trasportatore=3";
            return addendum;
        },

        ...mapState("soggettoModule", [
            "optionsComune",
            "optionsCategoriaSoggetto",
            "optionsTipoSoggetto",
        ]), // Oct 04, 2019-Dec 09, 2019
        ...mapState("loginModule", ["currentAzienda"]),
        ...mapState("mvvModule", ["mvvDettaglioDest"]), // Dec 30, 2019-Feb 15, 2020
    },

    // Dec 19, 2019.
    // impostazione automatica stato=italia
    watch: {
        form: {
            deep: true,
            handler: function(val) {
                if (
                    val.tipo_soggetto != undefined &&
                    val.tipo_soggetto.codice == "IT"
                ) {
                    val.stato = this.optionsStato.find(
                        (x) => x.codice === "380"
                    );
                }
                if (!val.persona) {
                    val.nome_persona_fisica = null;
                    val.cognome_p_fisica = null;
                }
            },
        },
    },

    created() {
        if (localStorage.getItem("optionsPaesi")) {
            try {
                this.optionsStato = JSON.parse(
                    localStorage.getItem("optionsPaesi")
                );
            } catch (e) {
                localStorage.removeItem("optionsPaesi");
            }
        }

        if (this.action == "clone" || this.action == "edit") {
            this.retrieveSoggetto(this.id);
        } else {
            this.initSoggettoForm();
        }
    },

    methods: {
        ...mapMutations("archivioModule", ["setTarget"]),
        ...mapMutations("mvvModule", [
            "setMvvDettaglioDest",
            "setIdSoggettoCreated",
            "setSoggettoUpdated",
            "setIdSoggettoTrasportatoreCreated",
            "setSoggettoTrasportatoreUpdated",
        ]),

        cleanFields(evt) {
            // al cambio del tipo_soggetto
            // Dec 08, 2019
            this.form.cf = null;
            this.form.piva = null;
            this.form.cap = null;
            this.form.comune = null;
            this.form.provincia = null;
            this.form.stato = null;
            this.form.destinatario_prodotti_vinicoli = true;
            this.form.saveToSIAN = false;
        },

        initSoggettoForm() {
            this.form.tipo_soggetto = null;
            this.form.categoria_soggetto = null;
            this.form.email = null;
            this.form.seedDest = null;
            this.form.Cellulare = null;
            this.form.PrefissoInt = null;
            this.form.ragione_sociale = null;
            this.form.persona = false;
            this.form.piva = null;
            this.form.cf = null;
            this.form.nome_persona_fisica = null;
            this.form.cognome_p_fisica = null;
            this.form.icqrf = null;
            this.form.indirizzo = null;
            this.form.cap = null;
            this.form.comune = null;
            this.form.stato = {};
            this.form.destinatario_prodotti_vinicoli = true;
            this.form.saveToSIAN = false;
        },

        onSoggettoReset(evt) {
            evt.preventDefault();
            this.initSoggettoForm();
        },

        retrieveSoggetto(Id) {
            const options = {
                method: "get",
                url: `/api/soggetto/${this.azienda_id}/${Id}?${this.addendum}`,
            };
            axios
                .request(options)
                .then((response) => {
                    if (this.action == "clone" || this.action == "add") {
                        this.form.codice = null;
                    } else {
                        this.form.codice = response.data.codice;
                    }
                    this.valore_iniziale_codice = this.form.codice; // Mar 19, 2020
                    // categoria: destinatario, trasportatore
                    this.form.categoria_soggetto = this.optionsCategoriaSoggetto.find(
                        (x) => x.codice === response.data.categoria_soggetto
                    );
                    this.form.tipo_soggetto = this.optionsTipoSoggetto.find(
                        (x) => x.codice == response.data.tipo_soggetto
                    );
                    this.form.destinatario_prodotti_vinicoli =
                        response.data.destinatario_prodotti_vinicoli == 1
                            ? true
                            : false;
                    this.form.FlagDest =
                        response.data.destinatario_prodotti_vinicoli == 0
                            ? false
                            : true;
                    if (response.data.informazioni_estese != null) {
                        this.form.email =
                            response.data.informazioni_estese.email;
                        this.form.seedDest =
                            response.data.informazioni_estese.seedDest;
                        this.form.Cellulare =
                            response.data.informazioni_estese.Cellulare;
                        this.form.PrefissoInt =
                            response.data.informazioni_estese.PrefissoInt;
                    } else {
                        this.form.email = null;
                        this.form.seedDest = null;
                        this.form.Cellulare = null;
                        this.form.PrefissoInt = null;
                    }
                    this.form.ragione_sociale = response.data.ragione_sociale;
                    this.form.piva =
                        response.data.identificativo_fiscale != null
                            ? response.data.identificativo_fiscale.length == 11
                                ? response.data.identificativo_fiscale
                                : null
                            : null;
                    this.form.cf =
                        response.data.identificativo_fiscale != null
                            ? response.data.identificativo_fiscale.length == 16
                                ? response.data.identificativo_fiscale
                                : null
                            : null;
                    this.valore_iniziale_piva = this.form.piva;
                    this.valore_iniziale_cf = this.form.cf;
                    this.form.icqrf = response.data.icqrf;
                    // persona fisica?
                    this.form.persona =
                        response.data.piva == null &&
                        response.data.ragione_sociale == null
                            ? true
                            : false;
                    this.form.nome_persona_fisica =
                        response.data.nome_persona_fisica;
                    this.form.cognome_p_fisica = response.data.cognome_p_fisica;
                    //
                    this.form.indirizzo = response.data.indirizzo;
                    this.form.cap = response.data.cap;
                    this.form.comune = response.data.comune;

                    if (response.data.stato != null) {
                        var stato = {};
                        stato = this.optionsStato.find(function(x) {
                            x.codice === response.data.stato.codice;
                            if (x.codice === response.data.stato.codice) {
                                return x;
                            }
                        });
                        this.form.stato = stato;
                    } else {
                        this.form.stato = null;
                    }

                    this.setTarget({
                        soggetto: this.form,
                    })

                })
                .catch((error) => {
                    showAlert({ error: error }, this);
                });
        },

        /***********************************************************************
         * submit
         ***********************************************************************/
        async onSubmitSoggetto(evt) {
            evt.preventDefault();
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                return;
            }
            this.inviaSoggetto();
        },

        inviaSoggetto() {
            const payload = preparePayload(
                this.azienda_id,
                this.form,
                this.trasportatore,
                this.currentAzienda
            );
            // if (this.inModal) {
            //     this.trasportatore
            //         ? this.$bvModal.hide("mvvSoggettoTrasportatoreModal")
            //         : this.$bvModal.hide("mvvSoggettoDestinatarioModal");
            // }
            // clona o aggiunge
            if (this.action == "clone" || this.action == "add") {
                let options = {
                    method: "post",
                    url: `/api/soggetto/${this.azienda_id}?${this.addendum}`,
                    data: payload,
                };
                axios
                    .request(options)
                    .then((response) => {
                        if ("content" in response.data) {
                            showAlert(
                                {
                                    message: response.data.content.messaggio,
                                    variant: "error",
                                },
                                this
                            );
                        } else {

                            // aggiorna la cache in vuex.
                            initSoggettoOptions(this.azienda_id, this);

                            // if (!this.inModal) {
                                this.$router.push({
                                    name: this.trasportatore
                                        ? "elenco-trasportatori"
                                        : "elenco-soggetti",
                                    params: {
                                        trasportatore: this.trasportatore,
                                        solo_mvv: this.currentAzienda.solo_mvv,
                                    },
                                });
                            // } else {
                                // this.trasportatore
                                //     ? this.setIdSoggettoTrasportatoreCreated(
                                //           this.form.codice
                                //       )
                                //     : this.setIdSoggettoCreated(
                                //           this.form.codice
                                //       ); // Feb 15, 2020
                            // }
                        }
                    })
                    .catch((error) => {
                        showAlert({ error: error }, this);
                    });
                // modifica
            } else if (this.action == "edit") {
                let options = {
                    method: "patch",
                    url: `/api/soggetto/${this.azienda_id}/${this.id}?${this.addendum}`,
                    data: payload,
                };
                axios
                    .request(options)
                    .then(() => {
                        
                        // aggiorna la cache in vuex.
                        initSoggettoOptions(this.azienda_id, this);

                        // if (!this.inModal) {
                            this.$router.push({
                                name: this.trasportatore
                                    ? "elenco-trasportatori"
                                    : "elenco-soggetti",
                                params: {
                                    trasportatore: this.trasportatore,
                                    solo_mvv: this.currentAzienda.solo_mvv,
                                },
                            });
                        // } else {
                            // this.trasportatore
                            //     ? this.setSoggettoTrasportatoreUpdated(true)
                            //     : this.setSoggettoUpdated(true); // Feb 15, 2020
                            // this.trasportatore
                            //     ? this.setIdSoggettoTrasportatoreCreated(
                            //           this.form.codice
                            //       )
                            //     : this.setIdSoggettoCreated(this.form.codice); // Feb 15, 2020
                        // }
                    })
                    .catch((error) => {
                        showAlert({ error: error }, this);
                    });
            }
        },
    },

    beforeDestroy() {
        this.form = null;
    },
};

export function customComuneLabel({ codice, descrizione, provincia }) {
    return `${descrizione} (${codice})`; // - ${codice}`
    // return `${descrizione} (${provincia.sigla})`; // - ${codice}`
}

export function requiredState(field, form) {
    switch (field) {
        case "codice":
            return form != undefined ? !form.saveToSIAN : true;
        case "persona":
            return true;
        case "piva":
        case "cf":
        case "nome_persona_fisica":
        case "cognome_p_fisica":
        case "ragione_sociale":
            return !disabledState(field, form);
        default:
            return fieldsRequiredStatus[field] != null
                ? fieldsRequiredStatus[field]
                : false;
    }
}

export function extendValidator() {
    var paramNames = ["azienda", "trasportatore", "action", "valore_iniziale"];
    Validator.extend(
        "isCodiceSoggettoUnico",
        {
            validate: isCodiceSoggettoUnico,
            getMessage: (field, params, data) => {
                return data != undefined ? data.message : ""; // Aug 13, 2019
            },
        },
        {
            immediate: false,
            paramNames,
        }
    );

    var paramNames = ["valore_iniziale", "action", "azienda"];
    Validator.extend(
        "isIdFiscaleSoggettoUnico",
        {
            validate: isIdFiscaleSoggettoUnico,
            getMessage: (field, params, data) => {
                return data != undefined ? data.message : ""; // Aug 13, 2019
            },
        },
        {
            immediate: false,
            paramNames,
        }
    );

    var paramNames = ["tipo_soggetto", "codice_italia"];
    Validator.extend(
        "isNotItalia",
        {
            validate: isNotItalia,
            getMessage: (field, params, data) => {
                return data != undefined ? data.message : ""; // Aug 13, 2019
            },
        },
        {
            immediate: false,
            paramNames,
        }
    );
}

function preparePayload(
    azienda_id,
    form,
    trasportatore /*, inMvv*/,
    currentAzienda
) {
    let servizio = ""; // servizio SIAN richiesto
    let categoria_soggetto =
        form.categoria_soggetto != null ? form.categoria_soggetto.codice : null;
    const payload = {
        solo_mvv: currentAzienda.solo_mvv,
        trasportatore: trasportatore, // Jan 01, 2020
        servizio: servizio,
        azienda: azienda_id,
        codice: form.codice, // codice soggetto
        tipo_soggetto: form.tipo_soggetto.codice,
        categoria_soggetto: categoria_soggetto,
        destinatario_prodotti_vinicoli: form.destinatario_prodotti_vinicoli
            ? 1
            : 0,
        informazioni_estese: {
            email: form.email,
            seedDest: form.seedDest,
            Cellulare: form.Cellulare,
            PrefissoInt: form.PrefissoInt,
        },
        ragione_sociale: form.ragione_sociale,
        identificativo_fiscale:
            form.piva != null ? form.piva : form.cf == null ? form.cf : form.cf,
        icqrf: form.icqrf == null ? form.icqrf : form.icqrf,
        nome_persona_fisica: form.nome_persona_fisica,
        cognome_p_fisica: form.cognome_p_fisica,
        indirizzo: form.indirizzo,
        cap: form.cap,
        comune: form.comune ? form.comune.id : null,
        provincia: form.comune ? form.comune.provincia.codice : null,
        stato: form.stato ? form.stato.id : null,
        //mvv: inMvv ? true : false
    };
    // console.log(JSON.stringify(payload))
    return payload;
}
