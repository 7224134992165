export const amministrazioneModule = {
    namespaced: true,
    state: {
        // Jun 01, 2021
        avvisoAziendeOptions: [],

        // gestione dei gruppi aziendali - December 29, 2020
        // vttore aziende facenti parte di un gruppo aziendale (capogruppo e controllate)
        aziendeGruppo: [],
        // vettore operatori aziendali facenti parte del gruppo aziendale
        operatoriAziendaliGruppo: [],
        //
        creazioneOperatore: false,
        addAziendaSpinner: false,
        showOverlayComuni: false,
        showOverlayAziende: false,
        showOverlayClienti: false,
        optionsTipologia: [
            { id: "PROFESSIONISTA", tipologia: "PROFESSIONISTA" },
            { id: "GRUPPO", tipologia: "GRUPPO" },
        ],
        optionsPosizioneGruppo: [
            { id: "HOLDING", posizione: "HOLDING" },
            { id: "PARTECIPATA", posizione: "PARTECIPATA" },
            { id: "CONTROLLATA", posizione: "CONTROLLATA" },
        ],
        // categorie degli OPERATORI
        optionsCategorie: [
            {
                id: "MVVPLUS",
                categoria: "MVVPLUS",
                descrizione:
                    "L'operatore può compilare MVV-E e gestire soggetti, vede giacenze e non può modificare o cancellare prodotti",
            },
            {
                id: "MVVONLY",
                categoria: "MVVONLY",
                descrizione:
                    "L'operatore può solo compilare un MVV-E e gestire i soggetti",
            },
            {
                id: "READONLY",
                categoria: "READONLY",
                descrizione:
                    "L'operatore può solo consultare i dati e non modificarli",
            },
            { id: "API", categoria: "API", descrizione: "Utente API" },
            {
                id: "AZIENDA",
                categoria: "AZIENDA",
                descrizione:
                    "L'operatore può operare su tutti i dati aziendali",
            },
            {
                id: "GRUPPO",
                categoria: "GRUPPO",
                descrizione:
                    "L'operatore può operare su tutti i dati delle aziende del gruppo",
            },
            {
                id: "CLIENTE",
                categoria: "CLIENTE",
                descrizione: "Operatore del cliente",
            },
            {
                id: "CONCESSIONARIO",
                categoria: "CONCESSIONARIO",
                descrizione: "Concessionario",
            },
            { id: "STAFF", categoria: "STAFF", descrizione: "Staff" },
            { id: "ADMIN", categoria: "ADMIN", descrizione: "Amministratore" },
        ],
        // optionsCategorieAzienda: [
        //   { id: "GRUPPO", categoria: "GRUPPO", descrizione: "Azienda parte di un gruppo" },
        // ],
        action: "add",
        amministrazioneCliente: false,
        amministrazioneConcessionario: false,
        avviso: {},
        avvisoAction: "add",
        avvisoElencoQuery: "",
        avvisoForm: {},
        aziendeElencoQuery: "",
        caricoCliente: null,
        caricoConcessionario: null,
        clienteAction: "add",
        gruppoAction: "add",
        clienteElencoQuery: "",
        clienteForm: null,
        gruppoForm: null,
        currentAzienda: {},
        currentCliente: {},
        currentConcessionario: {},
        currentGruppo: {},
        currentOperatore: {},
        currentInfoAzienda: {},
        form: {},
        formData: {},
        loading: false,
        numberOfAvviso: null,
        numberOfAzienda: null,
        numberOfCliente: null,
        numberOfGruppo: null,
        numberOfOperatore: null,
        operatoreAction: "add",
        operatoreElencoQuery: "",
        operatoreForm: null,
        optionsAvviso: [],
        optionsAzienda: [],
        optionsGruppo: [],
        optionsCliente: [],
        optionsOperatore: [],
        /* le optionsServizio sono recuperate dal database */
        optionsServizio: [],
        optionsStabilimento: [],
        stabilimentoAction: "add",
        stabilimentoForm: {},
        // isCliente: false,
        // isConcessionario: false,
    },
    mutations: {
        setAvvisoAziendeOptions(state, payload) {
            state.avvisoAziendeOptions = payload;
        },

        setAziendeGruppo(state, payload) {
            state.aziendeGruppo = payload;
        },
        setOperatoriAziendaliGruppo(state, payload) {
            state.operatoriAziendaliGruppo = payload;
        },
        setCreazioneOperatore(state, payload) {
            state.creazioneOperatore = payload;
        },
        setAddAziendaSpinner(state, payload) {
            state.addAziendaSpinner = payload;
        },
        setShowOverlayComuni(state, payload) {
            state.showOverlayComuni = payload;
        },
        setShowOverlayAziende(state, payload) {
            state.showOverlayAziende = payload;
        },
        setShowOverlayClienti(state, payload) {
            state.showOverlayClienti = payload;
        },
        setOptionsAvviso(state, payload) {
            state.optionsAvviso = payload;
        },
        setAvvisoElencoQuery(state, payload) {
            state.avvisoElencoQuery = payload;
        },
        setAvviso(state, payload) {
            state.avviso = payload;
        },
        setAvvisoAction(state, payload) {
            state.avvisoAction = payload;
        },
        setAvvisoForm(state, payload) {
            state.avvisoForm = payload;
        },
        setOptionsAvviso(state, payload) {
            state.optionsAvviso = payload;
        },
        setNumberOfAvviso(state, payload) {
            state.numberOfAvviso = payload;
        },
        setAvvisoElencoQuery(state, payload) {
            state.avvisoElencoQuery = payload;
        },
        //
        setAziendeElencoQuery(state, payload) {
            state.aziendeElencoQuery = payload;
        },
        setOperatoreElencoQuery(state, payload) {
            state.operatoreElencoQuery = payload;
        },
        setClienteElencoQuery(state, payload) {
            state.clienteElencoQuery = payload;
        },
        setNumberOfAzienda(state, payload) {
            state.numberOfAzienda = payload;
        },
        setNumberOfOperatore(state, payload) {
            state.numberOfOperatore = payload;
        },
        setNumberOfCliente(state, payload) {
            state.numberOfCliente = payload;
        },
        setNumberOfGruppo(state, payload) {
            state.numberOfGruppo = payload;
        },
        setCurrentOperatore(state, payload) {
            state.currentOperatore = payload;
        },
        // setIsCliente(state, payload) {
        //   state.isCliente = payload
        // },
        // setIsConcessionario(state, payload) {
        //   state.isConcessionario = payload
        // },
        setCaricoCliente(state, payload) {
            state.caricoCliente = payload;
        },
        setCaricoConcessionario(state, payload) {
            state.caricoConcessionario = payload;
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        // setOptionsTipologia(state, payload) {
        //   state.optionsTipologia = payload;
        // },
        setAmministrazioneConcessionario(state, payload) {
            state.amministrazioneConcessionario = payload;
        },
        setAmministrazioneCliente(state, payload) {
            state.amministrazioneCliente = payload;
        },
        setCurrentAzienda(state, payload) {
            state.currentAzienda = payload;
        },
        setCurrentInfoAzienda(state, payload) {
            state.currentInfoAzienda = payload;
        },
        setCurrentCliente(state, payload) {
            state.currentCliente = payload;
        },
        setCurrentConcessionario(state, payload) {
            state.currentConcessionario = payload;
        },
        setCategorie(state, payload) {
            state.categorie = payload;
        },
        setAction(state, payload) {
            state.action = payload;
        },
        setClienteAction(state, payload) {
            state.clienteAction = payload;
        },
        setGruppoAction(state, payload) {
            state.gruppoAction = payload;
        },
        setOperatoreAction(state, payload) {
            state.operatoreAction = payload;
        },
        setStabilimentoAction(state, payload) {
            state.stabilimentoAction = payload;
        },
        setForm(state, payload) {
            state.form = payload;
        },
        setOperatoreForm(state, payload) {
            state.operatoreForm = payload;
        },
        setClienteForm(state, payload) {
            state.clienteForm = payload;
        },
        setGruppoForm(state, payload) {
            state.gruppoForm = payload;
        },
        setStabilimentoForm(state, payload) {
            state.stabilimentoForm = payload;
        },
        setFormData(state, payload) {
            state.formData = payload;
        },
        setOptionsAzienda(state, payload) {
            state.optionsAzienda = payload;
        },
        setOptionsCliente(state, payload) {
            state.optionsCliente = payload;
        },
        setOptionsGruppo(state, payload) {
            state.optionsGruppo = payload;
        },
        setOptionsOperatore(state, payload) {
            state.optionsOperatore = payload;
        },
        setOptionsStabilimento(state, payload) {
            state.optionsStabilimento = payload;
        },
        setOptionsServizio(state, payload) {
            state.optionsServizio = payload;
        },
    },
};
