
  <div>
    <b-container>
      <h4 class="mt-3">{{ $t('cancella_azienda') }}</h4>

      <p class="lead">{{ $t('testo_cancella_azienda') }}</p>

      <b-form-row class="mt-4">
        <b-col>
          <b-form-group>
            <b-btn
              variant="primary"
              @click.prevent="handleCancellaAzienda('cancella')"
            >Cancella dati di prova</b-btn>
          <b-form-text>
            {{ idTrasmissione != "" ? 'Id trasmissione: ' + idTrasmissione : "" }}
          </b-form-text>
          </b-form-group>
        </b-col>
      </b-form-row>

      <h4 class="mt-3">{{ $t('verifica_cancellazione') }}</h4>

      <p class="lead">{{ $t('testo_verifica_cancellazione') }}</p>

      <b-form-row class="mt-4">
        <b-col>
          <b-form-group>
            <b-btn
              :disabled="idTrasmissione==''"
              variant="primary"
              @click.prevent="handleCancellaAzienda('cancella_verifica')"
            >Verifica cancellazione</b-btn>
          <b-form-text>
            {{ idTrasmissione == "" ? 'Id trasmissione non disponibile. Richiedere eventualmente la cancellazione dei dati di prova.' : "" }}
{{ idTrasmissione != "" ? 'Id trasmissione: ' + idTrasmissione : "" }}
          </b-form-text>
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr>
    </b-container>
  </div>
