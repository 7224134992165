
<div class="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
    <div
        class="ml-3 mr-3 mt-3 ml-md-0 mr-md-0 mt-md-0 mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden"
        style="background: rgba(0,0,0,0.35);border-radius: 12px;"
    >
        <logo class="logo" />
        <p
            class="lead text-white"
        >Servizio cloud per la tenuta del registro viti-vinicolo, documenti MVV-E e comunicazioni degli operatori al SIAN</p>
        <b-row class="mt-5">
            <b-col class="text-center">
                <b-btn variant="primary" :to="{name: 'login'}">Accedi al servizio</b-btn>
            </b-col>
        </b-row>
        <p
            class="small text-left"
            style="margin-top: 10em; color: rgba(255,255,255,0.9);"
        >e-Sian è un servizio di proprietà di Atlantic Sky Corporation e non è affiliato al SIAN</p>
    </div>

    <div
        class="
            ml-3 mr-3 mb-md-0 ml-md-0 mr-md-0 mb-3 
            mt-lg-0 mt-md-0 mt-sm-3 mt-3
            mr-md-3 
            pt-3 px-3 pt-md-5 px-md-5 
            overflow-hidden
        "
        style="background: rgba(0,0,0,0.55); border-radius: 12px; color: whitesmoke;"
    >
        <div class="row">
            <div class="col-md-6">
                <h3 class="mt-2">Registro</h3>
                <p>Registro di carico e scarico viti-vinicolo ufficiale.</p>
                <p>Uso professionale multi-aziendale.</p>
                <p>Collegamento sicuro e affidabile con il SIAN.</p>
                <p>Aumento della produttività.</p>
                <p>Calcolo e visualizzazione istantanea delle giacenze dei prodotti movimentati.</p>

                <h3 class="mt-2">Vinificazione</h3>
                <p>
                    Scritture delle operazioni di vinificazione completamente
                    <b>automatizzate</b> e flessibili.
                </p>

                <h3 class="mt-2">Integrazione</h3>
                <p>Importazione di fatture elettroniche, XML o Excel, emesse da programmi di contabilità aziendale.</p>
                <!-- <p>Importazione di file Excel, CSV ed API per l'integrazione con sistemi di gestione aziendale.</p> -->
            </div>
            <div class="col-md-6">
                <h3 class="mt-2">MVV-E</h3>
                <p
                    class
                >Documenti MVV-E integrati con le registrazioni SIAN e il magazzino prodotti.</p>
                <p class>Interfaccia semplice e flessibile.</p>
                <p class>Controlli preventivi sui dati immessi.</p>

                <h3 class="mt-2">Comunicazioni</h3>
                <p class>Comunicazioni degli operatori vitivinicoli art. 59 comma 2 L. 238/2016</p>
                <p class>Integrazione completa con il SIAN e documenti ufficiali in formato PDF.</p>
                <p
                    class="small"
                    style="color: rgba(255,255,255,0.6);"
                >Disponibile comunicazione DSTT</p>

                <h3 class="mt-2">Archiviazione</h3>
                <p
                    class
                >Archiviazione integrata con registrazioni, vasi, soggetti, ecc. di documenti elettronici (file PDF, immagini, fogli elettronici)</p>
                <p
                    class
                >Archiviazione sicura e veloce, con ricerca ed organizzazione intuitiva dei documenti arichiviati</p>
            </div>
        </div>
        <b-row class="mt-3 mb-5">
            <b-col class="text-center">
                <b-btn variant="primary" :to="{name: 'about'}">Maggiori informazioni</b-btn>
            </b-col>
        </b-row>
    </div>
</div>
