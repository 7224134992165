
<b-container>
  <b-form-row class="mt-3">
    <b-col>
      <ValidationProvider
        :name="$t('indirizzo')"
        :rules="{ 
          required: true, 
          regex: /^[a-zA-Z0-9.',()\r\n _\/-]*$/,
          max:'250',
        }"
        v-slot="{ valid, errors }"
      >
        <b-form-group
          :label="$t('indirizzo')"
          :label-class="{ 'font-weight-bold': true }"
          :state="errors[0] ? false : null"
          :invalid-feedback="errors[0]"
        >
          <b-form-textarea
            v-focus
            :rows="4"
            :max-rows="4"
            v-model="form.indirizzo"
            :placeholder="$t('indirizzo_placeholder')"
            :state="errors[0] ? false : null"
            :disabled="form.tipo_soggetto == null ? true : false"
          />
            <b-form-text>
            Non includere caratteri accentati o simboli (ad esempio: 'è', '@', ecc.).
            <span v-show="form.tipo_soggetto != null ? (form.tipo_soggetto.codice == 'IT' ? true : false) : true">
              NON includere il CAP, ma inserirlo eventualmente di seguito.
            </span>
          </b-form-text>
        </b-form-group>
      </ValidationProvider>
    </b-col>
  </b-form-row>

  <b-form-row>
    <b-col lg="2" sm="12">
      <ValidationProvider
        :name="$t('cap')"
        :rules="{ 
          required: (form.tipo_soggetto != null && form.tipo_soggetto.codice == 'IT' ? true : false), 
          digits: '5', 
        }"
        v-slot="{ valid, errors }"
      >
      <b-form-group
        :label="$t('cap')"
        :label-class="{ 'font-weight-bold': form.tipo_soggetto.codice == 'IT' ? true : false }"
        :state="errors[0] ? false : null"
        :invalid-feedback="errors[0]"
      >
        <b-form-input
          type="text"
          v-model="form.cap"
          :placeholder="$t('cap')"
          :state="errors[0] ? false : null"
          :disabled="getDisabledStatus('cap')"
        />
      </b-form-group>
      </ValidationProvider>
    </b-col>

    <b-col lg="4" sm="12">
      <ValidationProvider
        :name="$t('comune')"
        :rules="{ 
          required: form.tipo_soggetto != null ? (form.tipo_soggetto.codice == 'IT' ? true : false) : false 
        }"
        v-slot="{ valid, errors }"
      >
      <b-form-group
        :label="$t('comune')"
        :label-class="{ 'font-weight-bold': form.tipo_soggetto != null ? (form.tipo_soggetto.codice == 'IT' ? true : false) : false }"
        :state="errors[0] ? false : null"
        :invalid-feedback="errors[0]"
        description="Tra parentesi il codice ISTAT"
      >
        <multiselect
          :custom-label="customComuneLabel"
          :multiple="false"
          v-model="form.comune"
          track-by="id"
          label="descrizione"
          :options="optionsComune"
          :placeholder="$t('comune_placeholder')"
          :show-labels="false"
          :class="{ 'errore': errors[0] }"
          :disabled="form.tipo_soggetto == null || form.tipo_soggetto ? (form.tipo_soggetto.codice == 'IT' ? false : true) : false"
        >
          <template slot="noResult">{{ $t("message.nessun_risultato") }}</template>
          <template slot="noOptions">{{ $t("message.nessun_risultato") }}</template>
          <template slot="singleLabel" slot-scope="props">{{ props.option.descrizione }} ({{ props.option.codice }})</template>
          <template slot="option" slot-scope="props">{{ props.option.descrizione }} ({{ props.option.provincia.sigla }})</template>
        </multiselect>
      </b-form-group>
      </ValidationProvider>
    </b-col>

    <b-col sm=12 lg=2>
      <b-form-group
        :label="$t('Provincia')"
        :label-class="{ 'font-weight-bold': true }"
      >
      <span style="vertical-align: -webkit-baseline-middle;">
        {{ form.comune ? form.comune.provincia.descrizione : '' }} 
        {{ form.comune ? '(' +form.comune.provincia.codice + ')' : '' }}
      </span>
      <b-form-text class="label-provincia" v-show="form.comune">
        Tra parentesi il codice ISTAT
      </b-form-text>
      </b-form-group>
    </b-col>

    <b-col lg=4 sm="12">
      <ValidationProvider
        :name="$t('stato')"
        :rules="{ 
          required: true, 
          isNotItalia: [form.tipo_soggetto != null ? form.tipo_soggetto.codice : null, '380'],
        }"
        v-slot="{ valid, errors }"
      >
      <b-form-group
        :label="$t('stato')"
        :label-class="{ 'font-weight-bold': form.tipo_soggetto != null ? (form.tipo_soggetto.codice == 'IT' ? true : false) : false }"
        :state="errors[0] ? false : null"
        :invalid-feedback="errors[0]"
      >
        <multiselect
          :multiple="false"
          v-model="form.stato"
          track-by="codice"
          label="descrizione"
          :options="optionsStato"
          :placeholder="$t('stato_placeholder')"
          :show-labels="false"
          :class="{ 'errore': errors[0] }"
          :disabled="disabledState('stato', form)"
        >
          <template slot="noResult">{{ $t("message.nessun_risultato") }}</template>
        </multiselect>
      </b-form-group>
      </ValidationProvider>
    </b-col>
  </b-form-row>
</b-container>
