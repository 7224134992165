// February 02, 2022 
import {useMutations} from "@u3u/vue-hooks";

export const handleDocumentiProdotto = (props, root) => {
    const {setTarget, setSourceArchivio} = useMutations("archivioModule", ["setTarget", "setSourceArchivio"])

    const prodotto = props.prodotto

    // set del target
    setTarget({prodotto: prodotto})

    // set del source (applicazione/oggetto: vaso, vigna, prodotto, ecc.)
    setSourceArchivio('prodotto')

    // apertura del modale
    root.$bvModal.show("documentiProdottoModal");
}
