import Vue from 'vue';
import Router from 'vue-router';

import HomeUnauthenticated from './components/HomeUnauthenticated.vue';
import HomeAdministration from './components/HomeAdministration.vue';

const Home = () => import("./components/Home.vue");
const About = () => import("./components/About.vue");
const Login = () => import("./components/login/Login.vue");
const Logout = () => import("./components/login/Logout.vue");
const Guida = () => import("./components/Guida.vue");

// import About from './components/About.vue';
import Page_404 from './components/404.vue';

import {comunicazioni_routes} from './routes/comunicazioni_routes.js';
import {vinificazione_routes} from './routes/vinificazione_routes.js';

import {password_routes} from './routes/password_routes.js';
import {soggetto_routes} from './routes/soggetto_routes.js';
import {prodotto_routes} from './routes/prodotto_routes.js';
import {vaso_routes} from './routes/vaso_routes.js';
import {vigna_routes} from './routes/vigna_routes.js';
import {registrazione_routes} from './routes/registrazione_routes.js';
import {print_routes} from './routes/print_routes.js';
import {utente_routes} from './routes/utente_routes.js';
import {strumenti_routes} from './routes/strumenti_routes.js';
import {archivio_routes} from './routes/archivio_routes.js';
import {mvv_routes} from './routes/mvv_routes.js';
import {amministrazione_routes} from './routes/amministrazione_routes.js';

var _routes = [
    {
        path: '/',
        name: 'home-unauthenticated',
        component: HomeUnauthenticated,
        meta: { title: 'Benvenuti! - e-Sian', layout: "home", },
        props: true,
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: { title: 'e-Sian', layout: "home", requiresAuth: true, },
        // meta: { title: homeTitle, layout: "home", requiresAuth: true, },
        props: true
    },    
    {
        path: '/home-administration',
        name: 'home-administration',
        component: HomeAdministration,
        meta: { title: 'e-Sian', layout: "home", requiresAuth: true, },
        // meta: { title: homeTitle, layout: "home", requiresAuth: true, },
        props: true
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { title: 'Login - e-Sian', layout: "home", },
        props: true,
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: { title: 'Arrivederci! - e-Sian', layout: "home", requiresAuth: true, },
        props: true,
    },
    {
        path: '/logout-administration',
        name: 'logout-administration',
        component: Logout,
        meta: { title: 'Arrivederci! - e-Sian Amministrazione', layout: "home-administration", requiresAuth: true, },
    },
    {
        path: '/guida',
        name: 'guida',
        component: Guida,
        meta: { title: 'Guida - e-Sian', layout: "guida", requiresAuth: true }
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: { title: 'Maggiori informazioni - e-Sian', layout: "home" }
    },
    {
        path: "*",
        name: "404",
        component: Page_404, 
        meta: { title: 'Pagina non trovata - e-Sian', layout: 'home' },
    }
]

_routes = _routes.concat(comunicazioni_routes);
_routes = _routes.concat(vinificazione_routes);
_routes = _routes.concat(password_routes);
_routes = _routes.concat(vaso_routes);
_routes = _routes.concat(vigna_routes);
_routes = _routes.concat(registrazione_routes);
_routes = _routes.concat(soggetto_routes);
_routes = _routes.concat(prodotto_routes);
_routes = _routes.concat(utente_routes);
_routes = _routes.concat(print_routes);
_routes = _routes.concat(strumenti_routes);
_routes = _routes.concat(archivio_routes);
_routes = _routes.concat(mvv_routes);
_routes = _routes.concat(amministrazione_routes);

Vue.use(Router);

/*
 * https://stackoverflow.com/questions/40015037/can-vue-router-open-a-link-in-a-new-tab/55237422#55237422
 */
export default new Router({
    mode: "history", // Oct 07, 2019
    // base,
    routes: _routes,
    // va ad una anchor hash nel file html
    scrollBehavior: function (to, from, savedPosition) { // Jun 19, 2021
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        } else if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})


// Router.prototype.open = function (routeObject) {
//   const {href} = this.resolve(routeObject)
//   window.open(href, '_blank')
// }
