import axios from "axios";
import { useState, useMutations } from "@u3u/vue-hooks";
import { set } from "@vue/composition-api";

import { getProgress, loading } from "../../utils/getProgress.js";
import { showAlert } from "../../utils/eSianUtils.js";
import { UTENTE, AZIENDA, GENERALE } from "./getAvvisi.js";
import { checkUserAccess } from "../../utils/access.js";

/*********************************************************************************
 * avvisi archiviazione
 * January 13, 2023
 *********************************************************************************/

// chiamata di un task celery (usa getProgress)
export const getAvvisiArchiviazione = async (root) => {
    const { setAvvisiDiSistemaArchiviazione } = useMutations("navbarModule", [
        "setAvvisiDiSistemaArchiviazione",
    ]);
    setAvvisiDiSistemaArchiviazione([]);
    const options = {
        method: "GET",
        url: "/api/avviso/archiviazione",
    };
    try {
        const response = await axios.request(options);
        try {
            getProgress(
                response.data.task_id,
                root,
                "", // id modale
                "avvisiArchiviazione", // identificativo
                null, // `Controllo avvisi MVV completato.`, // messaggio finale mostrato in showAlert
                avvisiArchiviazioneCallback, // callback
                null, // failure callback
                null, // error callback
                { root: root } // callback params
            );
        } catch (error) {
            set(loading.value, "avvisiArchiviazione", false);
            showAlert({ error: error }, root);
        }
    } catch (error) {
        set(loading.value, "avvisiArchiviazione", false);
        showAlert({ error: error }, root);
    }
};

/*
 * usata come callback in getProgress.js per impostare gli avvisi ricevuti dal
 * server al termine del processo asincrono
 */
export const avvisiArchiviazioneCallback = (params, response) => {
    const { root } = { ...params };
    const { currentAzienda, utente } = useState("loginModule", [
        "currentAzienda",
        "utente",
    ]);
    const { setAvvisiDiSistemaArchiviazione } = useMutations("navbarModule", [
        "setAvvisiDiSistemaArchiviazione",
    ]);
    let elencoAvvisi = response.data.details.filter((x) => {
        // non è un avviso di aziendale (tipo 1)
        if (x.tipo != AZIENDA && x.show) {
            return true;
        } else if (x.tipo != AZIENDA && !x.show) {
            return false;
        } else {
            return x.azienda.cuaa == currentAzienda.value.cuaa;
        }
    });
    // category avviso = 20  (ARC)
    setAvvisiDiSistemaArchiviazione(
        elencoAvvisi.filter((x) => x.system && x.category == 20)
    );
    if (
        checkUserAccess(utente.value, {
            servizio: "ARC",
            azienda: currentAzienda.value,
        })
    ) {
        showAlert(
            {
                message: `Controllo avvisi documenti azienda ${currentAzienda.value.ragione_sociale} completato`,
            },
            root
        );
    }
};
