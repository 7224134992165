export const commonModule = {
  namespaced: true,
  state: {
    showOverlay: false,
    preferences: {
      debug: false,
    },
  },
  getters: {},
  mutations: {
    setShowOverlay(state, payload) { 
      state.showOverlay = payload;
    },
    setPreferences(state, payload) { 
      state.preferences = payload.preferences;
    },
  },
  actions: {
    setPreferences(context, payload) {
      context.commit("setPreferences", payload);
    },    
  }
};
