<template>

  <!-- In pratica "AperturaCampagna" chiama la componente ripristina con il
  parametro "apertura" e le passa uno "slot" (qui sotto).  -->
  <ripristina :apertura="true" >

      <!-- <pre>1. isCampagnaAziendaleRiaperta {{ isCampagnaAziendaleRiaperta }}</pre> -->
      <!-- <pre>statoStabilimenti.stabilimenti_aperti {{ statoStabilimenti.stabilimenti_aperti }}</pre> -->

      <!-- slot content -->
      <h4>Apertura campagna aziendale e-Sian</h4>

      <p class="font-weight-light">
          {{ aziendaDaRipristinare != null ? aziendaDaRipristinare.ragione_sociale + ' (' + aziendaDaRipristinare.cuaa + '). ' : ''  }} <b>Tutti gli stabilimenti</b>.
      </p>

      <p class="lead">L'apertura della campagna <b>aziendale</b> trasferisce dal SIAN agli archivi <b>e-Sian</b> le operazioni di giacenza iniziale effettuate in automatico alla chiusura della campagna di ciascun stabilimento aziendale.</p>

      <p v-if="!isCampagnaAziendaleRiaperta">
        Stato della campagna per ciascuno <b>stabilimento</b>:
        <ul>
            <li v-for="stabilimento in statoStabilimenti.stato_stabilimenti" :key="stabilimento.icqrf">
                  {{ statoStabilimento(stabilimento) }}
            </li>
        </ul>
      </p>

      <div v-if="!isCampagnaAziendaleRiaperta && statoStabilimenti.stabilimenti_aperti != 0">
        <p>
          L'apertura della campagna aziendale può essere effettuata <b>solo</b> quando la campagna, per <b>tutti</b> gli stabilimenti aziendali, è stata chiusa (cioè la richiesta di chiusura, per ciascuno stabilimento aziendale, è stata trasmessa al SIAN e verificata).
        </p>
      </div>

      <b-alert show v-if="isCampagnaAziendaleRiaperta" class="mb-3">
        <p>
          La campagna aziendale e-Sian è stata aperta il giorno {{ dataCampagnaAziendaleRiaperta }}.
        </p>
        <p show v-if="nuovaCampagnaForzata">
          <b>NOTA</b>. La campagna aziendale è stata riaperta forzatamente.
        </p>
      </b-alert>

      <b-alert show variant="warning" class="mt-3" v-if="!isCampagnaAziendaleRiaperta && statoStabilimenti.stabilimenti_aperti == 0">
        <p>Campagna <b>aziendale</b> corrente:
          {{
            dataInizioCampagna.toLocaleDateString("it-IT")
          }}-{{
            dataFineCampagna.toLocaleDateString("it-IT")
          }}.
        </p> 

        <p>Procedere all'apertura della campagna aziendale per allineare i dati e-Sian con quelli generati automaticamente dal SIAN alla chiusura della campagna precedente di ciascun stabilimento.</p> 
      </b-alert>

  </ripristina>
</template>

<script>
import { useState} from "@u3u/vue-hooks";
import { computed } from "@vue/composition-api";

import Ripristina, {aziendaDaRipristinare} from "./Ripristina.vue";
import {
  calcolaDataInizioCampagnaInformale,
  calcolaDataFineCampagna,
  calcolaDataInizioCampagna,
  calcolaDataInizioCampagnaPrecedente,
  calcolaDataFineCampagnaPrecedente,
} from "./campagnaUtils.js";


export default {
  name: "apertura-campagna",

  components: {
    Ripristina,
  },

  setup() {
    const {
      nuovaCampagnaForzata,
      isCampagnaAziendaleRiaperta,
      dataCampagnaAziendaleRiaperta,
      numeroStabilimenti,
      statoStabilimenti,
    } = useState("loginModule", [
      "nuovaCampagnaForzata",
      "isCampagnaAziendaleRiaperta",
      "dataCampagnaAziendaleRiaperta",
      "numeroStabilimenti",
      "statoStabilimenti",
    ]);

    // const situazioneCurrentStabilimento = computed(() => _situazioneCurrentStabilimento())

    const data_inizio_apertura = computed(() => {
      if (isCampagnaAziendaleRiaperta.value) {
        return calcolaDataInizioCampagna()
      } else {
        return calcolaDataInizioCampagna({ format: "DT" }).plus({year: 1}).toJSDate()
      }
    })
    
    const data_fine_apertura = computed(() => {
      if (isCampagnaAziendaleRiaperta.value) {
        return new Date()
      } else {
        return calcolaDataFineCampagna({ format: "DT" }).plus({year: 1}).toJSDate()
      }
    })

    const statoStabilimento = (stabilimento) => {
      if (stabilimento.stato == 'aperto') {
        return `la campagna dello stabilimento "${stabilimento.descrizione}" (${stabilimento.icqrf}) è aperta`  
      } else {
        return `la campagna dello stabilimento "${stabilimento.descrizione}" (${stabilimento.icqrf}) è chiusa`
      }
    }
    
    return {
      statoStabilimento,
      dataFineCampagnaPrecedente: calcolaDataFineCampagnaPrecedente(),
      dataInizioCampagnaPrecedente: calcolaDataInizioCampagnaPrecedente(),
      dataFineCampagna: calcolaDataFineCampagna(),
      dataInizioCampagna: calcolaDataInizioCampagna(),
      data_inizio_apertura,
      data_fine_apertura,
      nuovaCampagnaForzata,
      // situazioneCurrentStabilimento,
      aziendaDaRipristinare,
      statoStabilimenti,
      numeroStabilimenti,
      isCampagnaAziendaleRiaperta,
      dataCampagnaAziendaleRiaperta,
    }
  }
}

</script>

<i18n>
{
  "en": {
  },
  "it": {
    "conferma": "Conferma",
    "testo_chiusura_campagna": "La richiesta di chiusura della campagna in corso può essere trasmessa esclusivamente dal 1 agosto al 10 settembre di ogni anno.",
    "chiusura_campagna": "Richiesta chiusura",
    "testo_verifica_campagna": "Verifica lo stato della campagna in corso.",
    "verifica": "Verifica chiusura",
    "verifica_campagna": "Verifica della chiusura"
  }
}
</i18n>
