// February 10, 2022 
import axios from 'axios'
import {ref} from "@vue/composition-api";
import {useState} from "@u3u/vue-hooks";

import {showAlert} from "../../utils/eSianUtils.js";

export const vasi = ref(null)
export const vasiSIAN = ref(null)
export const gettingVasiSIAN = ref(false)

export const getVasi = async () => {
    const {
        currentAzienda,
        currentStabilimento,
        // accessToken,
    } = useState("loginModule", [
        // "accessToken",
        "currentAzienda",
        "currentStabilimento",
    ]);

    // IL CLIENT SI DEVE COLLEGARE AL MICROSERVICE
    // 1) alla root delle api (VITE_APP_ROOT_API) si aggiunge la url di base
    // che rimanda al microservizio (/starlette)
    // 2) si deve presentare il token ch viene verificato con lo stesso secret kwy 
    // (si usa il token nei cookie)
    const baseUrl = import.meta.env.VITE_APP_MICROSERVICE 
    const options = {
        method: 'get',
        url: `${baseUrl}/vasi/${currentAzienda.value.azienda_id}/${currentStabilimento.value.id}`,
    }
    try {
        const response = await axios.request(options)
        // console.log(response.headers) 
        vasi.value = response.data
    } catch (error) {
        console.log(error) 
    }
}

export const getVasiSIAN = async (root) => {
    gettingVasiSIAN.value = true
    const {
        currentAzienda,
        currentStabilimento,
    } = useState("loginModule", [
        "currentAzienda",
        "currentStabilimento",
    ]);
    const baseUrl = import.meta.env.VITE_APP_MICROSERVICE 
    const options = {
        method: 'get',
        url: `${baseUrl}/vasi/sian/${currentAzienda.value.azienda_id}/${currentStabilimento.value.id}`,
    }
    try {
        const response = await axios.request(options)
        gettingVasiSIAN.value = false
        vasiSIAN.value = processSIANResponse(response, root)
    } catch (error) {
        gettingVasiSIAN.value = false
        console.log(error) 
    }
}

export const processSIANResponse = (response, root) => {
    let variant = 'warning'
    if (response.data.esito) {
        showAlert({message: `${response.data.esito.messaggio} (${response.data.esito.codice})`, variant: variant}, root)
    }
    return response.data.result
}
