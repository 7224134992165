
<table class="table">
  <tr>
    <th>Ragione sociale</th>
    <th>CUAA</th>
  </tr>
  <tr v-for="(azienda, i) in aziende" :key="i">
    <td> {{ azienda.ragione_sociale }} </td>
    <td> {{ azienda.cuaa }} </td>
  </tr>
</table>
