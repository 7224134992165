import * as validators from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";

import {
    calcolaDataFineCampagna,
    calcolaDataInizioCampagna,
} from "../../components/strumenti/campagnaUtils.js";

// import { withI18nMessage } from '@/utils/withI18nMessage'
// export const required = withI18nMessage(validators.required)
// export const numeric = withI18nMessage(validators.numeric)
// export const maxLength = withI18nMessage(validators.maxLength)

/*
    usage:
    urlValidator: helpers.withMessage(
        "L'indirizzo web immesso non è corretto", urlValidator
    ),
*/
export const urlValidator = (value) => {
    const re =
        /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    // https://vuelidate-next.netlify.app/custom_validators.html#optional-validator
    return !helpers.req(value) || re.test(value);
};

export const maxDecimals = (length) =>
    helpers.withParams(
        { type: "maxDecimals", max: length },
        (value) =>
            !helpers.req(value) ||
            new RegExp(
                `^\\s*-?(\\d+(\\.\\d{1,${length}})?|\\.\\d{1,${length}})\\s*$`
            ).test(value)
    );

export const errorInTab = (campiInTab, v$) =>
    v$.$errors.some((x) => campiInTab.includes(x.$property)) ||
    v$.$silentErrors.some((x) => campiInTab.includes(x.$property));

const _numerico = (value) => {
    const re = /^[0-9\.\-]*$/;
    return value ? re.test(value) : true;
};

export const numerico = helpers.withMessage(
    "il valore deve essere un numero (con eventuali decimali separati dal punto)",
    _numerico
);

const _positivo = (value) => {
    return value ? value > 0 : true;
    // return value > 0 || value == null
};

export const positivo = helpers.withMessage(
    "il valore deve essere un numero positivo",
    _positivo
);

export const richiesto = helpers.withMessage(
    "Deve essere immesso un valore",
    validators.required
);

// https://medium.com/js-dojo/learn-form-validation-in-vue-3-in-10-minutes-with-vuelidate-8929c5059e66
export const CodComunicazioneValidator = (value) => {
    const re = /^[a-zA-Z0-9'\-._%\/]*$/;
    return re.test(value);
};

// export const CUAAValidator = (value) => {
//     const re = /^([A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z0-9]{5}|[0-9]{11})$/;
//     return re.test(value);
// };

export const PersonaFisicaValidator = (value, campi, vm) => {
    if (
        value != null &&
        vm.form.impianti == "altri" &&
        vm.form.cuaa == "fisica"
    ) {
        const re =
            /^(\s*|[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z0-9]{5})$/;
        if (vm.form.CuaaImpiantiDitta.PersonaGiuridica) {
            return true;
        } else {
            return re.test(value);
        }
    }
    return true;
};

export const PersonaGiuridicaValidator = (value, campi, vm) => {
    if (
        value != null &&
        vm.form.impianti == "altri" &&
        vm.form.cuaa == "giuridica"
    ) {
        // \s = any whitespace charater...
        const re = /^(\s*|[0-9]{11})$/;
        if (vm.form.CuaaImpiantiDitta.PersonaFisica) {
            return true;
        } else {
            return re.test(value);
        }
    }
    return true;
};

// March 10, 2022
export const isASCII = (value) => {
    var nessun_magg_min = !/[<>&]/.test(value); // non contiene <> (dall'inizio riga alla fine) e &
    var solo_ascii = /^[\na-zA-Z0-9'().+\:, \/\-_]*$/.test(value); // \n consente anche il newline Feb 10, 2020
    return nessun_magg_min && solo_ascii;
};

// October 31, 2021
// tutti i caratteri devono essere latin-1 (compresi nel range)
export const soloLatin1 = (value) => {
    // "true" se tutti i caratteri, dall'inizio alla fine della stringa, sono
    // compresi nel range

    const re = /^[\x00-\x7F_.\-]*$/; // January 18, 2022 - espanso con "_", "." e "-"

    // il test risulta "false" se non è vera la condizione
    // il validatore, se ritorna 'true' è tutto a posto...
    return re.test(value);
};

export const lunghezzaMassima = (value) =>
    helpers.withMessage(
        ({ $pending, $invalid, $params, $model }) =>
            `Il valore deve avere una lunghezza massima di ${$params.max} caratteri`,
        // } caratteri e il valore immesso (${$model}) perciò ${
        // } caratteri e il valore immesso ${
        //     $invalid ? "non è quindi valido" : "è valido"
        // }`,
        validators.maxLength(value)
    );

export const greaterThanZero = (value) => (value != null ? value > 0 : true);

// February 10, 2023

export const controlloData = (value) => {
    const dataInizioCampagnaInCorso = calcolaDataInizioCampagna({
        format: "JS",
    });
    const dataFineCampagnaInCorso = calcolaDataFineCampagna({ format: "JS" });
    if (value > dataFineCampagnaInCorso || value < dataInizioCampagnaInCorso)
        return false;
    return true;
};

// fix October 31, 2023
export const controlloAnnataVinificazione = (value) => {
    const dataInizioCampagna = calcolaDataInizioCampagna({ format: "DT" });
    const annoInizioCampagna = dataInizioCampagna.year;
    if (value > 0 && value < annoInizioCampagna) {
        return false; // errore
    }
    return true;
};

// February 11, 2023

export const controlloDataIniziale = (value, form, vm) => {
    if (value < form.data_registrazione) return false;
    return true;
};
