import axios from "axios";
import { Workbook } from "exceljs";
import { showAlert } from "@/utils/eSianUtils.js";

export const esporta = async (carico, context) => {
    const fileName = `Elenco_aziende_${new Date().toLocaleTimeString()}`;
    const workbook = new Workbook();

    workbook.creator = "e-Sian " + import.meta.env.VITE_APP_VERSIONE;
    workbook.created = new Date();

    const worksheet = workbook.addWorksheet("Aziende");

    worksheet.columns = [
        { header: "Id", key: "id" },
        { header: "CUAA", key: "cuaa" },
        { header: "Ragione sociale", key: "ragione_sociale" },
        { header: "Data attivazione", key: "data_attivazione" },
        { header: "Prodotti", key: "prodotti"},
        { header: "Registrazioni", key: "registrazioni"},
        { header: "Registrazioni locked", key: "registrazioni_locked"},
        { header: "Registrazioni storiche", key: "registrazioni_storiche"},
        { header: "Soggetti", key: "soggetti"},
    ];

    const results = await elencoTotale(context)
    if (!results) return 
    results.data.forEach((result) => {
        let caricoAzienda = carico.value.find(x => x.azienda_id == result.id)
        if (caricoAzienda) {
            result.registrazioni = caricoAzienda.registrazioni
            result.registrazioni_locked = caricoAzienda.registrazioni_locked
            result.registrazioni_storiche = caricoAzienda.registrazioni_storiche
            result.soggetti = caricoAzienda.soggetti
            result.prodotti = caricoAzienda.prodotti
        }
        var row = Object.assign(result);
        worksheet.addRow(row);
    });

    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `${fileName}.xlsx`);
    });

    return fileName;
}

export const elencoTotale = async (context) => {
    const options = {
        method: "get",
        url: `/api/azienda/esporta/`,
    };
    try {
        return await axios.request(options);
    } catch (error) {
        showAlert({ error: error }, context.root);
    }
};
