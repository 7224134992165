import axios from "axios";
import { DateTime } from "luxon";
import { useState, useMutations, useRouter } from "@u3u/vue-hooks";

import { showAlert } from "../../utils/eSianUtils.js";
import {
    isInCampagnaEstesa,
    calcolaDataFineCampagnaPrecedente,
    calcolaDataFineCampagna,
    calcolaDataInizioCampagna,
} from "./campagnaUtils.js";

/*
 * Chiamata dalla "NavBar.vue" alla login (che a sua volta riceve l'evento da
 * "Login.vue") e al cambio del cuaa e stabilimento
 *
 * 1) verifica se la campagna aziendale corrente è stata già aperta (e-Sian)
 * 2) verifica lo stato di chiusura sul SIAN e recupera lo stato per tutti gli stabilimenti
 * 3) in caso di timeout di rete consente di operare ma blocca ... TODO
 */
export function verificaChiusuraCampagna(
    azienda_id,
    stabilimento_id,
    root,
    fromButton = false
) {
    const {
        isCampagnaAziendaleRiaperta,
        dataCampagnaAziendaleRiaperta,
        currentStabilimento,
        mostraPopupCampagnaAziendaleAperta,
        currentAzienda,
        statoStabilimenti,
    } = useState("loginModule", [
        "isCampagnaAziendaleRiaperta",
        "dataCampagnaAziendaleRiaperta",
        "currentStabilimento",
        "mostraPopupCampagnaAziendaleAperta",
        "currentAzienda",
        "statoStabilimenti",
    ]);
    const {
        setNuovaCampagnaForzata,
        setStatoCampagnaNonAcquisito,
        setMostraPopupCampagnaAziendaleAperta,
        setIsCampagnaChiusa,
        setIsCampagnaStabilimentoChiusa,
        setIsCampagnaAziendaleRiaperta,
        setStatoStabilimenti,
        setVerificaCampagnaInCorso,
        setDataCampagnaAziendaleRiaperta,
    } = useMutations("loginModule", [
        "setNuovaCampagnaForzata",
        "setStatoCampagnaNonAcquisito",
        "setMostraPopupCampagnaAziendaleAperta",
        "setIsCampagnaChiusa",
        "setIsCampagnaStabilimentoChiusa",
        "setIsCampagnaAziendaleRiaperta",
        "setStatoStabilimenti",
        "setVerificaCampagnaInCorso",
        "setDataCampagnaAziendaleRiaperta",
    ]);

    setVerificaCampagnaInCorso(true);
    setStatoCampagnaNonAcquisito(true);

    const options = {
        methods: "GET",
        url: `/api/sian/verifica_chiusura/${azienda_id}/${stabilimento_id}`,
    };
    return axios
        .request(options)
        .then((response) => {
            // il sian non è raggiungibile e avviso l'utente -- Sep 08, 2021
            if (response.data.message) {
                showAlert(
                    {
                        message: `${response.data.message}`,
                        variant: response.data.variant,
                    },
                    root
                );
                // non riesce a stabilire lo stato della campagna precedente
                setStatoCampagnaNonAcquisito(true);
                // root.loadingVerificaChiusura = false;
                setVerificaCampagnaInCorso(false);
                setStatoStabilimenti({
                    campagna_chiusa: null,
                    stabilimenti_chiusi: null,
                    stabilimenti_aperti: null,
                    stato_stabilimenti: [
                        {
                            cuaa: null,
                            icqrf: null,
                            id: null,
                            stato: null,
                            data_campagna_chiusa: null,
                            codice_campagna_chiusa: null,
                        },
                    ],
                });
            } else {
                const { messaggio, variant, position, secs, title, codice } =
                    parseCodice(
                        response.data.situazione_stabilimenti
                            .stato_stabilimenti,
                        stabilimento_id,
                        currentStabilimento
                    );

                setMostraPopupCampagnaAziendaleAperta(
                    response.data.mostra_popup_apertura_nuova_campagna
                );

                setIsCampagnaAziendaleRiaperta(
                    response.data.nuova_campagna_aperta
                );

                setNuovaCampagnaForzata(response.data.nuova_campagna_forzata);
                setDataCampagnaAziendaleRiaperta(
                    DateTime.fromISO(response.data.data_nuova_campagna_aperta)
                        .toJSDate()
                        .toLocaleString()
                );

                // imposta la situazione di tutti gli stabilimenti sulla base dei
                // dati ricevuti dal sian
                setStatoStabilimenti(response.data.situazione_stabilimenti);
                // console.log(response.data.situazione_stabilimenti)

                /*
                 * mostra la richiesta di riapertura.
                 */
                if (mostraPopupCampagnaAziendaleAperta.value) {
                    showMsgBoxApertura(root);
                }

                if (codice == "H00") {
                    // Stato della campagna non acquisito
                    setIsCampagnaChiusa(true);
                    setStatoCampagnaNonAcquisito(true);
                    showAlert(
                        {
                            message: `${messaggio} (codice: ${codice}).`,
                            variant: variant,
                            position: position,
                            secs: 12,
                            title: title,
                        },
                        root
                    );
                } else if (codice == "H63") {
                    // Campagna in corso di chiusura
                    setIsCampagnaChiusa(false);
                    setStatoCampagnaNonAcquisito(false);
                    showAlert(
                        {
                            message: `${messaggio} (codice: ${codice}).`,
                            variant: variant,
                            position: position,
                            secs: 12,
                            title: title,
                        },
                        root
                    );
                } else if (codice != "H65") {
                    // Campagna NON CHIUSA
                    setIsCampagnaChiusa(false);
                    setStatoCampagnaNonAcquisito(false);
                    // , ${currentAzienda.value.ragione_sociale} (CUAA: ${currentAzienda.value.cuaa})
                    showAlert(
                        {
                            message: `${messaggio} (codice: ${codice}).`,
                            variant: setVariant(codice),
                            position: position,
                            secs: 12,
                            title: title,
                        },
                        root
                    );
                } else {
                    // Campagna dello stabilimento CHIUSA (H65)

                    // console.log(messaggio, codice)

                    if (!isCampagnaAziendaleRiaperta.value) {
                        showAlert({ message: `${messaggio}.` }, root);
                    }

                    if (fromButton) {
                        showAlert(
                            {
                                message: `${messaggio} (codice: ${codice}).`,
                                variant: setVariant(codice),
                                position: position,
                                secs: 12,
                                title: title,
                            },
                            root
                        );
                    }

                    // imposta a chiusa il flag della campagna usato per la gestione del
                    // locked nell'intertempo tra le campagne
                    setIsCampagnaChiusa(true);
                    setStatoCampagnaNonAcquisito(false);
                }

                // root.loadingVerificaChiusura = false;
                setVerificaCampagnaInCorso(false);
            }
        })
        .catch((error) => {
            showAlert({ error: error }, root);
            // non riesce a stabilire lo stato della campagna precedente
            setStatoCampagnaNonAcquisito(true);
            // root.loadingVerificaChiusura = false;
            setVerificaCampagnaInCorso(false);
            setStatoStabilimenti({
                campagna_chiusa: null,
                stabilimenti_chiusi: null,
                stabilimenti_aperti: null,
                stato_stabilimenti: [
                    {
                        cuaa: null,
                        icqrf: null,
                        id: null,
                        stato: null,
                        data_campagna_chiusa: null,
                        codice_campagna_chiusa: null,
                    },
                ],
            });
        });
}

const setVariant = (codice) => {
    if (codice == "H65") {
        return "info";
    } else if (codice == "H64") {
        return "warning";
    }
    return "info";
};

export function parseCodice(
    stato_stabilimenti,
    stabilimento_id,
    currentStabilimento
) {
    const { setStatoCampagnaNonAcquisito } = useMutations("loginModule", [
        "setStatoCampagnaNonAcquisito",
    ]);
    var messaggio = null;
    var variant = null;
    var position = null;
    var secs = null;
    var title = null;
    var codice = stato_stabilimenti.find(
        (x) => x.id == stabilimento_id
    ).codice_campagna_chiusa;
    switch (codice) {
        // verifiche
        case "H63":
            messaggio = `Campagna dello stabilimento ${currentStabilimento.value.descrizione} (${currentStabilimento.value.icqrf}) in corso di chiusura`;
            variant = "info";
            codice = codice;
            break;
        // dopo 1 agosto blocco operazioni
        case "H64":
            messaggio = `Campagna dello stabilimento ${currentStabilimento.value.descrizione} (${currentStabilimento.value.icqrf}) ancora aperta`;
            variant = "warning";
            // position = "b-toaster-bottom-right";
            codice = codice;
            title = "Attenzione";
            //secs = 0;
            secs = 8;
            break;
        case "H65":
            messaggio = `Campagna dello stabilimento ${currentStabilimento.value.descrizione} (${currentStabilimento.value.icqrf}) chiusa`;
            variant = "info";
            title = "Informazione";
            secs = 6;
            codice = codice;
            position = "b-toaster-bottom-right";
            break;
        // invii successivi della richiesta di chiusura
        case "H66":
            messaggio = `Richiesta di chiusura della campagna dello stabilimento ${currentStabilimento.value.descrizione} (${currentStabilimento.value.icqrf}) già pervenuta`; // Ripristinare gli archivi (menu 'Strumenti > Ripristina')."
            variant = "info";
            codice = codice;
            break;
        // primo invio (1/8 al 10/9)
        case "H67":
            messaggio = `Chiusura della campagna dello stabilimento ${currentStabilimento.value.descrizione} (${currentStabilimento.value.icqrf}) non consentita alla data`;
            variant = "error";
            codice = codice;
            break;
        // primo invio
        case "H68":
            messaggio = `Richiesta di chiusura della campagna dello stabilimento ${currentStabilimento.value.descrizione} (${currentStabilimento.value.icqrf}) avvenuta con successo`;
            variant = "success";
            codice = codice;
            break;
        case "H00":
            messaggio = `Non è stato possibile determinare lo stato della campagna precedente per lo stabilimento ${currentStabilimento.value.descrizione} (${currentStabilimento.value.icqrf})`;
            setStatoCampagnaNonAcquisito(true);
            variant = "info";
            codice = codice;
            break;
        default:
            setStatoCampagnaNonAcquisito(true);
            messaggio = "Errore nella risposta ricevuta dal SIAN";
            codice = codice;
            variant = "error";
    }
    return { messaggio, variant, position, secs, title, codice };
}

export const showMsgBoxApertura = (root) => {
    // const { router, route } = useRouter();
    const { currentAzienda } = useState("loginModule", ["currentAzienda"]);
    root.$bvModal
        .msgBoxOk(
            `La nuova campagna aziendale (${calcolaDataInizioCampagna().toLocaleDateString(
                "it-IT"
            )}-${calcolaDataFineCampagna().toLocaleDateString(
                "it-IT"
            )}) per l'azienda ${currentAzienda.value.ragione_sociale} (${
                currentAzienda.value.cuaa
            }) deve essere aperta.`,
            {
                title: "Apertura nuova campagna aziendale",
                okVariant: "light",
                headerClass: "p-2 border-bottom-0",
                footerClass: "p-2 border-top-0",
                okTitle: "Chiudi",
                centered: true,
            }
        )
        .then((value) => {
            // if (value) {
            //     if (route.value.name != 'strumenti') {
            //         router.push({ name: "strumenti", params: { tabIndex: 4 } });
            //     }
            // } else {
            //     return;
            // }
        })
        .catch((err) => {
            console.log(err);
        });
};
