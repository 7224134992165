// import InfoUtente from '../../components/utente/InfoUtente.vue';
const InfoUtente = () => import("../components/utente/InfoUtente.vue");

export const utente_routes = [
  {
    path: "/utente",
    name: "utente",
    component: InfoUtente,
    meta: { title: 'Informazioni sull\'utente - e-Sian', requiresAuth: true, },
  },
]
