import { useState, useMutations } from "@u3u/vue-hooks";

import { onChangeCuaa, onChangeIcqrf } from "../navigation/NavBar.js";

export const goToAzienda = (
    context,
    cuaa,
    icqrf,
    route = "home",
    hash = ""
) => {
    const { optionsCuaa, optionsIcqrf } = useState("navbarModule", [
        "optionsCuaa",
        "optionsIcqrf",
    ]);
    const { setAvvisiModalIsShown } = useMutations("navbarModule", [
        "setAvvisiModalIsShown",
    ]);

    context.root.$bvModal.hide("modaleAvvisi");
    setAvvisiModalIsShown(false)

    context.root.$bvModal.hide("modaleAvvisiHome");
    onChangeCuaa(
        optionsCuaa.value.find((x) => x.cuaa == cuaa),
        context.root,
        true /* do not show the alert requesting the selection of icqrf */,
        route,
        hash,
        true /* doNotVerificaChiusuraCampagna  */
    );
    onChangeIcqrf(
        optionsIcqrf.value.find((x) => x.icqrf == icqrf),
        context.root,
        route /* route */,
        hash,
        true /* doNotVerificaChiusuraCampagna  */
    );
};
