import axios from "axios";
import router from "./router";
import store from "./store/";
import { useMutations } from "@u3u/vue-hooks";

import { EventBus } from "./EventBus.js";


export const interceptorsSetup = () => {
    // const {refreshTokenExpired} = useMutations("loginModule", ["refreshTokenExpired"])
    // const {router} = useRouter()
    
    // Add a request interceptor
    /*
    axios.interceptors.request.use(
        (config) => {
            
            console.log(`%c ${config.method.toUpperCase()} - ${getUrl(config)}:`, 'color: #0086b3; font-weight: bold', config);

            // Do something before request is sent
            // aggiunge il token di accesso agli header di ogni richiesta
            // commenta +4 se cookie
            // const token = store.state.loginModule.accessToken;
            // if (token) {
            //     config.headers.Authorization = `Bearer ${token}`;
            // }
            //
            return config;
        },
        (error) => {
            // Do something with request error
            return Promise.reject(error)
        }, 
        // { synchronous: true }
    );
    */

    axios.interceptors.response.use(
        (response) => {

            // Any status code that lie within the range of 2xx cause this function to trigger
            // console.log(`%c ${response.status} - ${getUrl(response.config)}:`, 'color: #008000; font-weight: bold', response);
            
            // Do something with response data
            return response
        },
        async (error) => {

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (!error.response) {

                console.log(`%c ${error}:`, "color: #a71d5d; font-weight: bold");

                let message = null
                if (axios.isCancel(error)) {
                    message = error.message
                } else {
                    message = "Non è possibile connettersi al server. Verificare la connessione di rete oppure il server potrebbe non rispondere."
                }

                return Promise.reject(new Error(message));
            }

            if (error.response.data) {
                console.log(`%c ${error.response.status} - ${getUrl(error.response.config)}:`, "color: #a71d5d; font-weight: bold", error.response);
            } else {
                console.log(error)
                return Promise.reject(error);
            }
            const originalRequest = error.config;

            // se è un errore di autenticazione ed è una chiamata a refresh
            // vuol dire che il token di refresh è scaduto e va rifatta la login...
            if (
                error.response.status === 401 &&
                originalRequest.url.includes("/api/token/refresh/")
            ) {
                console.log("Refresh token expired")
                clearUserData()
                if (router.currentRoute.name != 'login') {
                    router.push({ 
                        name: "login", 
                        params: { 
                            fromInterceptor: true 
                        }
                    });
                }
                return Promise.reject(error);

            // se è un errore di autenticazione ma non è un refresh
            } else if (
                error.response.status === 401 &&
                !originalRequest._retry 
            ) {
                originalRequest._retry = true;
                await refreshToken(originalRequest)
                // solo dopo che il token è stato rinfrescato ed assegnato...
                return axios(originalRequest);

            // è un LOGOUT: non riesce a scrivere l'audit (errore 503) perchè non 
            // è più autenticato
            } else if (
                error.response.status === 503  &&
                originalRequest.url.includes("/api/logout/") 
            ) {
                originalRequest._retry = true;
                await refreshToken(originalRequest)
                return axios(originalRequest);
            } 

            return Promise.reject(error);
        }, 
    );
};


const refreshToken = async () => {
    const options = {
        method: "post",
        url: "/api/token/refresh/",
        // commenta +3 se cookie
        // data: {
        //     refresh: store.state.loginModule.refreshToken,
        // },
    };
    try {
        await axios
            .request(options)
            .then(response => {
                // commenta +4 se cookie
                // if (response && response.status === 200) {
                //     store.state.loginModule.accessToken =
                //         response.data.access;
                // }
                console.log(`Access token refreshed (expires at ${response.data.access_token_expiration})`)
            })
            .catch((error) => {
                console.log(error)
            })
                
    } catch (error) {
        console.log(error)
    }
}


export const clearUserData = () => {
    const {setRefreshTokenExpired, setIsAuthenticated} = useMutations("loginModule", ["setRefreshTokenExpired", "setIsAuthenticated"])

    EventBus.$emit("Logout:App", {
        is_authenticated: false,
        cuaa: null,
        icqrf: null,
        azienda_id: 0,
        stabilimento_id: 0
    });
      EventBus.$emit("Logout:NavBar", {
        optionsCuaa: [],
        optionsIcqrf: []
      });
    setRefreshTokenExpired(true)
    setIsAuthenticated(false)
}


/**
 * Strip baseURL from URL
 *
 * @param config Object
 * @returns String
 */
const getUrl = (config) => {
  if (config.baseURL) {
    return config.url.replace(config.baseURL, "");
  }
  return config.url;
}
