import axios from "axios";

// JWT (June 11, 2019 )
// Helper function to extract claims from a JWT. Does *not* verify the
// validity of the token.
// credits: https://github.com/firebase/angularFire/blob/master/angularFire.js#L370
// polyfill window.atob() for IE8: https://github.com/davidchambers/Base64.js
// or really fast Base64 by Fred Palmer: https://code.google.com/p/javascriptbase64/
export function deconstructJWT(token) {
    var segments = token.split(".");
    if (!segments instanceof Array || segments.length !== 3) {
        throw new Error("Invalid JWT");
    }
    var claims = segments[1];
    return JSON.parse(decodeURIComponent(escape(window.atob(claims))));
}

// Moment: http://momentjs.com/
/* 
function tokHasTimeLeft(tok) {
  if( !tok ) {return false;}
  try {
     var parts = deconstructJWT(tok);
      // default is 24 hrs
     var exp = parts.exp? moment.unix(parts.exp) : moment.unix(parts.iat).add('hours', 24);
// returns true if token has at least 12 hours left
     return exp.diff(moment(), 'hours') > 12;
  }
  catch(e) {
     console.warn(e);
     return false;
  }
}
*/

export const loginModule = {
    namespaced: true,
    state: {
        nuovaCampagnaForzata: false,
        statoCampagnaNonAcquisito: true,

        // messaggio di sistema settato dall'amministratore
        // in un file sul server (spaces--guarda implementazione release notes)
        // May 30, 2021
        systemMessage: null,
        systemMessageHeight: 0,
        navBarStyleObject: null,
        systemMessageStyleObject: null,
        systemMessageSidebarStyleObject: null,

        refreshToken: null,
        refreshTokenExpired: null,
        accessToken: null,
        accessTokenExpiration: null,
        refreshTokenExpiration: null,

        windowWidth: window.innerWidth,

        termineInitOptionsComune: false,
        dataCampagnaAziendaleRiaperta: null,
        // isCampagnaAziendaleChiusa: false,   // da e-sian (model azienda)
        isCampagnaAziendaleRiaperta: false, // da e-sian (model azienda)
        mostraPopupCampagnaAziendaleAperta: false, // da e-sian (model azienda)
        loadingStatoStabilimenti: false,
        statoStabilimenti: [],
        numeroStabilimenti: { aperti: null, chiusi: null },
        currentStabilimento: null,
        verificaCampagnaInCorso: false,
        controlloScritturaConsentita: true,
        isCampagnaStabilimentoChiusa: { stato: false, stabilimento: null }, // Aug 19, 2021
        isCampagnaChiusa: false, // August 03, 2020

        inManutenzione: false, // Jul 04, 2020
        resetLoading: false, // Jul 02, 2020
        utente: null, // Apr 18, 2020

        optionsCodiceRegistrazione: [], // Jan 14, 2020

        caricoTaskId: null,
        carico: [],
        storicheAzienda: false,

        // default preferenze
        preferenze: {
            checkAvvisiMvv: true,
            mostraAvvisiInHome: true, // November 24, 2021
            mostraSaldi: true, // Nov 19, 2020
            creaRegistrazioneDaMvv: false, // Dec 05, 2019
            messaggiElenchi: true, //Sep 24, 2019
            confermaTrasmissione: true, // Sep 05, 2019
            debug: false,
            beta: false, // Nov 07, 2019
            last_cuaa: "",
            last_icqrf: "",
        },

        termineInitOptions: false,

        // selettore metodo di autenticazione (vanno aggiustati anche i setting di django)
        // JWT: true,
        JWT: false,
        isAuthenticated: false,

        currentAzienda: {
            // cuaa: "",
            // icqrf: "",
            // azienda_id: null,
            // stabilimento_id: null,
            // ragione_sociale: null,
            // ambiente_operativo_esercizio: null,
            // data_attivazione: null,
        },
        uuid_token: "", // per il lock (July 11, 2019 )
        deadlock: 0, // false - per il lock (July 13, 2019 )
        token: "",
        access: "", // JWT
        refresh: "", // JWT
        endpoints: {
            obtainTokenURL: "/api/login/",
            // obtainTokenURL: "/api/users/token-auth/",
            obtainJWTTokenURL: "/api/token/", // JWT
            refreshJWTTokenURL: "/api/token/refresh/", // JWT
        },
        headers: {
            authentication: "",
        },
    },

    // getters: {

    //     getToken: function(state, getters) {
    //         if (state.JWT) {
    //             return state.access;
    //         } else {
    //             return state.token;
    //         }
    //     },

    //     getAuthHeader: function(state, getters) {
    //         if (state.JWT) {
    //             return `Bearer ${ state.access }`;
    //         } else {
    //             return `Token ${ state.token }`;
    //         }
    //     },

    //     getUtente: function(state, getters, response) {
    //         return function(response) {
    //             if (state.JWT) {
    //                 const token_claims = deconstructJWT(state.token);
    //                 return token_claims.utente;
    //             } else {
    //                 return obtainUtente()
    //                 // return response.data.utente;
    //             }
    //         };
    //     }
    //     // getAuthenticationHeader: state => state.headers.authentication
    // },

    // https://forum.vuejs.org/t/vuex-best-practices-for-complex-objects/10143/18
    mutations: {
        setNuovaCampagnaForzata(state, payload) {
            state.nuovaCampagnaForzata = payload;
        },
        setStatoCampagnaNonAcquisito(state, payload) {
            state.statoCampagnaNonAcquisito = payload;
        },

        setSystemMessage(state, payload) {
            state.systemMessage = payload;
        },
        setNavBarStyleObject(state, payload) {
            state.navBarStyleObject = payload;
        },
        setSystemMessageHeight(state, payload) {
            state.systemMessageHeight = payload;
        },
        setSystemMessageStyleObject(state, payload) {
            state.systemMessageStyleObject = payload;
        },
        setSystemMessageSidebarStyleObject(state, payload) {
            state.systemMessageSidebarStyleObject = payload;
        },

        setAccessToken(state, payload) {
            state.accessToken = payload;
        },
        setAccessTokenExpiration(state, payload) {
            state.accessTokenExpiration = payload;
        },
        setRefreshTokenExpiration(state, payload) {
            state.refreshTokenExpiration = payload;
        },
        setRefreshToken(state, payload) {
            state.refreshToken = payload;
        },
        setRefreshTokenExpired(state, payload) {
            state.refreshTokenExpired = payload;
        },

        setWindowWidth(state, payload) {
            state.windowWidth = payload;
        },

        // setIsCampagnaAziendaleChiusa(state, payload) {
        //   state.isCampagnaAziendaleChiusa = payload
        // },
        setIsCampagnaAziendaleRiaperta(state, payload) {
            state.isCampagnaAziendaleRiaperta = payload;
        },
        setDataCampagnaAziendaleRiaperta(state, payload) {
            state.dataCampagnaAziendaleRiaperta = payload;
        },
        setMostraPopupCampagnaAziendaleAperta(state, payload) {
            state.mostraPopupCampagnaAziendaleAperta = payload;
        },
        setLoadingStatoStabilimenti(state, payload) {
            state.loadingStatoStabilimenti = payload;
        },
        setStatoStabilimenti(state, payload) {
            state.statoStabilimenti = payload;
        },
        setNumeroStabilimenti(state, payload) {
            state.numeroStabilimenti = payload;
        },
        setVerificaCampagnaInCorso(state, payload) {
            state.verificaCampagnaInCorso = payload;
        },
        setCurrentStabilimento(state, payload) {
            state.currentStabilimento = payload;
        },
        setControlloScritturaConsentita(state, payload) {
            state.controlloScritturaConsentita = payload;
        },
        setIsAuthenticated(state, payload) {
            state.isAuthenticated = payload;
        },
        // si intende, campagna precedente
        setIsCampagnaChiusa(state, payload) {
            state.isCampagnaChiusa = payload;
        },
        // si intende, campagna precedente
        setIsCampagnaStabilimentoChiusa(state, payload) {
            state.isCampagnaStabilimentoChiusa = payload;
        },

        setInManutenzione(state, payload) {
            state.inManutenzione = payload;
        },
        setResetLoading(state, payload) {
            state.resetLoading = payload;
        },
        setUtente(state, payload) {
            state.utente = payload;
        },
        setToken(state, payload) {
            state.token = payload;
        },
        setOptionsCodiceRegistrazione(state, payload) {
            state.optionsCodiceRegistrazione = payload;
        },
        setStoricheAzienda(state, payload) {
            state.storicheAzienda = payload;
        },
        setCarico(state, payload) {
            state.carico = payload;
        },
        setCaricoTaskId(state, payload) {
            state.caricoTaskId = payload;
        },

        updateToken(state, newToken) {
            // Dec 15, 2019
            // sessionStorage.setItem("token", newToken);
            state.token = newToken;
        },
        removeToken(state) {
            // Dec 15, 2019
            // sessionStorage.removeItem("token");
            state.token = null;
        },
        //updateJWTToken(state, newToken) {
        //  // var parsed = JSON.stringify(newToken);
        //  // sessionStorage.setItem("access", parsed);
        //  state.access = newToken;
        //},
        //updateJWTRefreshToken(state, newToken) {
        //  // var parsed = JSON.stringify(newToken);
        //  // sessionStorage.setItem("refresh", parsed);
        //  state.refresh = newToken;
        //},
        //removeJWTToken(state) {
        //  // sessionStorage.removeItem("access");
        //  state.access = null;
        //},
        //removeJWTRefreshToken(state) {
        //  // sessionStorage.removeItem("refresh");
        //  state.refresh = null;
        //},

        setTermineInitOptionsComune(state, payload) {
            state.termineInitOptionsComune = payload;
        },
        setTermineInitOptions(state, payload) {
            state.termineInitOptions = payload.termineInitOptions;
        },

        //setUuidToken(state, payload) {
        //  state.uuid_token = payload.uuid_token;
        //},

        //setDeadlock(state, payload) {
        //  state.deadlock = payload.deadlock;
        //},

        //setAuthenticated(state, payload) {
        //  // il payload è un oggetto
        //  state.isAuthenticated = payload.isAuthenticated;
        //},
        setPreferences(state, payload) {
            state.preferenze = payload;
        },
        // basta passare un oggetto come payload...
        setCurrentAzienda(state, payload) {
            state.currentAzienda = payload;
        },
    },

    actions: {
        // https://www.reddit.com/r/vuejs/comments/84wufw/vuejs_and_authentication_with_jwt_and_refresh/
        // setAuthenticationHeader(context, payload) {
        //   axios.defaults.headers.common["Authorization"] = payload
        // },
        obtainToken(context, payload) {
            return new Promise((resolve, reject) => {
                if (this.state.loginModule.JWT) {
                    var url =
                        this.state.loginModule.endpoints.obtainJWTTokenURL;
                } else {
                    var url = this.state.loginModule.endpoints.obtainTokenURL;
                }
                //return axios
                axios
                    .post(url, payload)
                    .then((response) => {
                        if (this.state.loginModule.JWT) {
                            context.commit(
                                "updateJWTToken",
                                response.data.access
                            );
                            context.commit(
                                "updateJWTRefreshToken",
                                response.data.refresh
                            );
                        } else {
                            context.commit("updateToken", response.data.key); // Mar 31, 2020
                            // context.commit("updateToken", response.data.token);
                        }
                        resolve(response); // risolve la promessa "Promise" con la response da axios
                        //return response
                    })
                    .catch((error) => {
                        if (this.state.loginModule.JWT) {
                            context.commit("removeJWTToken");
                            context.commit("removeJWTRefreshToken");
                        } else {
                            context.commit("removeToken");
                        }
                        reject(error);
                        //return error
                    });
            });
        },

        // DRF token
        removeToken(context) {
            context.commit("removeToken");
        },

        // JWT token
        //removeJWTToken(context) {
        //  context.commit("removeJWTToken");
        //},
        //removeJWTRefreshToken(context) {
        //  context.commit("removeJWTRefreshToken");
        //},
        //refreshJWTToken(context) {
        //  const payload = {
        //    // manda il token "refresh" al server per chiedere il refresh
        //    refresh: this.state.loginModule.refresh
        //  };
        //  axios
        //    .post(this.state.loginModule.endpoints.refreshJWTTokenURL, payload)
        //    .then(response => {
        //      context.commit("updateJWTToken", response.data.access);
        //      axios.defaults.headers.common["Authorization"] = `Bearer ${
        //        this.state.loginModule.access
        //      }`;
        //    })
        //    .catch(error => {
        //      console.log(error);
        //    });
        //},

        // inspectJWTToken(context) {
        //   const access = context.state.access;
        //   const refresh = context.state.refresh;
        //   if (access) {
        //     const decoded = deconstructJWT(access);
        //     const refresh_decoded = deconstructJWT(refresh);
        //     const exp = decoded.exp;
        //     const refresh_exp = refresh_decoded.exp;

        //     if (
        //       exp - Date.now() / 1000 < 1800 &&
        //       Date.now() / 1000 - refresh_exp < 628200
        //     ) {
        //       context.dispatch("refreshJWTToken");
        //     } else if (exp - Date.now() / 1000 < 1800) {
        //       // DO NOTHING, DO NOT REFRESH
        //     } else {
        //       // PROMPT USER TO RE-LOGIN
        //       // THIS ELSE CLAUSE COVERS THEN CONDITION WHERE A TOKEN IS EXPIRED
        //     }
        //   }
        // },

        //setDeadlock(context, payload) {
        //  context.commit("setDeadlock", payload);
        //},

        //setUuidToken(context, payload) {
        //  context.commit("setUuidToken", payload);
        //},

        // Autenticazione utente
        setAuthenticated(context, payload) {
            context.commit("setAuthenticated", payload);
        },

        //setPreferences(context, payload) {
        //  context.commit("setPreferences", payload);
        //},

        //setCurrentAzienda(context, payload) {
        //  context.commit("setCurrentAzienda", payload);
        //}
    },
};
