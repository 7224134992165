export const navbarModule = {
    
    namespaced: true,

    state: {

        // January 13, 2023 
        avvisiDiSistemaArchiviazione: [],

        // December 22, 2022 
        avvisiModalIsShown: false,
        
        avvisi: [],
        avvisiDiSistemaTrasmissioni: [],
        avvisiDiSistemaMVV: [],

        loadingAvvisi: false,
        optionsIcqrf: [],
        optionsCuaa: [],
        selectedCuaaObj: null,
        selectedIcqrf: null,
        preferred_cuaa: null,
        preferred_icqrf: null,
    },

    mutations: {

        // January 13, 2023 
        setAvvisiDiSistemaArchiviazione(state, payload) { state.avvisiDiSistemaArchiviazione = payload },

        // December 22, 2022 
        setAvvisiModalIsShown(state, payload) { state.avvisiModalIsShown = payload },

        setAvvisi(state, payload) { state.avvisi = payload },
        setAvvisiDiSistemaTrasmissioni(state, payload) { state.avvisiDiSistemaTrasmissioni = payload },
        setAvvisiDiSistemaMVV(state, payload) { state.avvisiDiSistemaMVV = payload },

        setOptionsIcqrf(state, payload) { state.optionsIcqrf  = payload },
        setOptionsCuaa(state, payload) { state.optionsCuaa  = payload },
        setSelectedCuaaObj(state, payload) { state.selectedCuaaObj  = payload },
        setSelectedIcqrf(state, payload) { state.selectedIcqrf  = payload },
        setPreferred_cuaa(state, payload) { state.preferred_cuaa  = payload },
        setPreferred_icqrf(state, payload) { state.preferred_icqrf  = payload },
        setLoadingAvvisi(state, payload) {
            state.loadingAvvisi = payload
        },
    },
};
