<template>
  <span class="text-nowrap" style="font-family: 'Orbitron', sans-serif;">
    <span style="color: rgb(66,148,246);">{{part1}}</span>
    <span style="color: rgb(136,45,142);">{{part2}}</span>
  </span>
</template>

<script>
export default {
  name: "Logo",
  setup() {
    return {
      part1: import.meta.env.VITE_APP_NAME_PART1,
      part2: import.meta.env.VITE_APP_NAME_PART2,
    }
  }
};
</script>
