// Apr 20, 2020
import axios from "axios";
import { useState, useMutations } from "@u3u/vue-hooks";
import { ref } from "@vue/composition-api";

import { showAlert } from "@/utils/eSianUtils.js";

export const alertText = ref(null)

export function editCliente(cliente, context) {
    const { setClienteAction } = useMutations("amministrazioneModule", [
        "setClienteAction",
    ]);
    retrieveCliente(cliente.id, context);
    setClienteAction("edit");
}

export function retrieveCliente(id, context) {
    // const { optionsTipologia } = useState("amministrazioneModule", ["optionsTipologia",]);
    const { setClienteForm } = useMutations("amministrazioneModule", [
        "setClienteForm",
    ]);
    axios
        .get("/api/clienti/" + id)
        .then((response) => {
            // response.data.tipologia = optionsTipologia.value.find(x=>x.id==response.data.tipologia)
            setClienteForm(response.data);
            context.root.$bvModal.show("clienteModal");
        })
        .catch((error) => {
            showAlert({ error: error }, context.root);
        });
}

export function deleteCliente(cliente, context) {
    let messaggio =
        'Conferma cancellazione cliente "' +
        cliente.ragione_sociale +
        '" (' +
        cliente.codice +
        ")?";
    context.root.$bvModal
        .msgBoxConfirm(messaggio, {
            okVariant: "danger",
            cancelVariant: "light",
            okTitle: "Cancella",
            cancelTitle: "Annulla",
            footerClass: "p-2",
            centered: true,
        })
        .then((value) => {
            if (value) {
                axios
                    .delete("/api/clienti/" + cliente.id)
                    .then(() => {
                        loadOptionsCliente(context.root);
                    })
                    .catch((error) => {
                        showAlert({ error: error }, context.root);
                    });
            }
        })
        .catch((error) => {
            showAlert({ error: error }, context.root);
        });
}

export function addCliente() {
    const { utente } = useState("loginModule", ["utente"]);
    const { setClienteAction, setClienteForm } = useMutations(
        "amministrazioneModule",
        ["setClienteAction", "setClienteForm"]
    );
    setClienteAction("add");
    setClienteForm({
        codice: "",
        is_active: true,
        ragione_sociale: null,
        identificativo_fiscale: null,
        tipologia: null,
        servizi_ammessi: null,
        numero_utenti_max: 0,
        numero_aziende_max: 0,
        nome: null,
        cognome: null,
        email: null,
        telefono: null,
        nome_ammvo: null,
        cognome_ammvo: null,
        email_ammvo: null,
        telefono_ammvo: null,
        indirizzo: null,
        cap: null,
        comune: null,
        note: null,
        disattivato: false,
        data_disattivazione: null,
        concessionario: utente.value.concessionario.id,
    });
}

export async function onSubmit(context) {
    const { clienteForm, clienteAction } = useState("amministrazioneModule", [
        "clienteForm",
        "clienteAction",
    ]);
    const isValid = await context.refs.observer.validate();
    if (!isValid) {
        return;
    }
    if (clienteForm.value.is_active == false) {
        let messaggio =
            'Conferma disattivazione cliente "' +
            clienteForm.value.email +
            '" (' +
            clienteForm.value.first_name +
            " " +
            clienteForm.value.last_name +
            ")?";
        context.root.$bvModal
            .msgBoxConfirm(messaggio, {
                okVariant: "warning",
                cancelVariant: "light",
                okTitle: "Disattiva",
                cancelTitle: "Annulla",
                footerClass: "p-2",
                centered: true,
            })
            .then((value) => {
                if (!value) {
                    clienteForm.value.is_active = true;
                    return;
                }
                sendCliente(context).then(() => {
                    loadOptionsCliente(context.root);
                    context.root.$bvModal.hide("clienteModal");
                    showAlert(
                        {
                            message:
                                clienteAction.value == "add"
                                    ? "Cliente creato"
                                    : "Cliente aggiornato",
                            variant: "success",
                        },
                        context.root
                    );
                });
            })
            .catch((error) => {
                showAlert({ error: error }, context.root);
            });
    } else {
        sendCliente(context).then(() => {
            loadOptionsCliente(context.root);
            context.root.$bvModal.hide("clienteModal");
            showAlert(
                {
                    message:
                        clienteAction.value == "add"
                            ? "Cliente creato"
                            : "Cliente aggiornato",
                    variant: "success",
                },
                context.root
            );
        });
    }
}

export async function loadOptionsCliente(root, pageNum = 1) {
    const { clienteElencoQuery } = useState("amministrazioneModule", [
        "clienteElencoQuery",
    ]);
    const { setOptionsCliente, setNumberOfCliente } = useMutations(
        "amministrazioneModule",
        ["setOptionsCliente", "setNumberOfCliente"]
    );
    // var t0 = performance.now();
    try {
        const response = await axios.get(
            `/api/clienti?page=${pageNum}&search=${clienteElencoQuery.value}`
        );
        setNumberOfCliente(response.data.count);
        setOptionsCliente(response.data.results);
        // var t1 = performance.now();
        // console.log("loadOptionsCliente (completed): ", (t1 - t0) / 1000)
        return response;
    } catch (error) {
        showAlert({ error: error }, root);
    }
}

/*
 * December 31, 2020-January 09, 2022
 *
 * Ereditarietà dei servzi:
 *
 * SERVIZIO -> (solo quelli in BETA e PROD) CONCESSIONARIO -> CLIENTE -> AZIENDA -> OPERATORE
 *
 * l'operatore eredita i servizi dell'azienda (attribuiti alla sua creazione
 * dal cliente) a cui viene aggiunto (l'azienda eredita i servizi del cliente
 * cui sono stati assegnati dal concessionario)
 *
 * I servizii sono quelli contenuti nella tabella "clienti_servzio" (modello "Servizio")
 */

export async function loadOptionsServizio(context) {
    const { setOptionsServizio } = useMutations("amministrazioneModule", [
        "setOptionsServizio",
    ]);
    try {
        const response = await axios.get("/api/servizi");

        // SOLO STATO BETA E PROD
        setOptionsServizio(
            response.data.results.filter((x) => x.stato >= "BETA")
        );
    } catch (error) {
        showAlert({ error: error }, context.root);
    }
}

export function onCloseAddCliente(context) {
    context.root.$bvModal.hide("clienteModal");
}

export async function sendCliente(context) {
    const { clienteAction, clienteForm } = useState("amministrazioneModule", [
        "clienteAction",
        "clienteForm",
    ]);
    let payload = JSON.parse(JSON.stringify(clienteForm.value)); // December 28, 2020
    if (payload.servizi_ammessi == null) {
        delete payload.servizi_ammessi;
    } else {
        let servizi_ammessi = [];
        payload.servizi_ammessi.forEach((servizio) => {
            servizi_ammessi.push(servizio.id);
        });
        payload.servizi_ammessi = servizi_ammessi;
    }
    if (payload.comune != null) {
        payload.provincia = payload.comune.provincia.codice;
        payload.comune = payload.comune.id;
    }
    // payload.tipologia = clienteForm.value.tipologia.id
    try {
        if (clienteAction.value == "add") {
            return await axios.post("/api/clienti", payload);
        } else if (clienteAction.value == "edit") {
            return await axios.patch("/api/clienti/" + payload.id, payload);
        }
    } catch (error) {
        showAlert({ error: error }, context.root);
    }
}

export function formatter(value) {
    return value.toUpperCase();
}

export async function onSubmitClienteSearch(query, context) {
    const { setClienteElencoQuery } = useMutations("amministrazioneModule", [
        "setClienteElencoQuery",
    ]);
    setClienteElencoQuery(query);
    loadOptionsCliente(context.root);
}

export function onResetClienteSearch(search_form, context) {
    const { setClienteElencoQuery } = useMutations("amministrazioneModule", [
        "setClienteElencoQuery",
    ]);
    search_form.value.query = "";
    setClienteElencoQuery("");
    loadOptionsCliente(context.root);
}

export async function getCaricoConcessionario(context) {
    const { utente } = useState("loginModule", ["utente"]);
    const { setCaricoConcessionario } = useMutations("amministrazioneModule", [
        "setCaricoConcessionario",
    ]);
    try {
        const response = await axios.get(
            `/api/users/get_carico_concessionario/${utente.value.id}`
        );
        setCaricoConcessionario(response.data);
    } catch (error) {
        showAlert({ error: error }, context.root);
    }
}
