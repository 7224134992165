import axios from "axios";
import { ref, set } from "@vue/composition-api";

import { showAlert } from "./eSianUtils.js";

export const loaded = ref({});
export const loading = ref({});
export const loadingError = ref({});
export const loadingCurrent = ref({});
export const loadingTotal = ref({});
export const loadingMessage = ref({});
export const responseData = ref({});
export const tempo = ref({});
export const finish = ref(new Date());
export const showWarnings = ref({});

// const DEFAULT_POLLING_INTERVAL = 300; // secs
const DEFAULT_POLLING_INTERVAL = 3000; // secs

export const getProgress = (
    task_id,
    root,
    modal,
    identificativo,
    messaggio = "",
    callback,
    failureCallback,
    errorCallback,
    callBackParams,
    failureCallbackParams,
    errorCallbackParams,
    pollingInterval
) => {
    let t0 = performance.now();

    set(responseData.value, identificativo, null);
    set(loaded.value, identificativo, false);
    set(loading.value, identificativo, true);
    set(finish.value, identificativo, null);
    set(tempo.value, identificativo, 0);
    set(loadingError.value, identificativo, {
        details: null,
        is_error: false,
    });
    set(loadingMessage.value, identificativo, null);
    set(loadingCurrent.value, identificativo, null);
    set(loadingTotal.value, identificativo, null);

    const options = {
        method: "GET",
        url: `/api/get_progress/${task_id}`,
    };

    let pollingId = setInterval(
        () => {
            axios
                .request(options)
                .then((response) => {
                    if (response.data.state == "FAILURE") {
                        clearInterval(pollingId);
                        // manda a video il failure in uno snackbar
                        showAlert(
                            {
                                message: response.data.details,
                                variant: "error",
                            },
                            root
                        );
                        set(loadingError.value, identificativo, {
                            details: response.data.details,
                            is_error: true,
                        });
                        set(loaded.value, identificativo, true);
                        set(loading.value, identificativo, false);
                        set(finish.value, identificativo, new Date());

                        // se è stato passato un identificativo del modale, lo chiude
                        modal != null ? root.$bvModal.hide(modal) : null;

                        // se c'e' un callback che gestisce il failure, lo chiama...
                        if (failureCallback) {
                            failureCallback(failureCallbackParams, response.data.details);
                        }

                        resetStatus(identificativo, t0);

                    } else if (response.data.state == "SUCCESS") {

                        let t1 = performance.now();
                        let variant = "info";

                        // assegna il valore ritornato dalla routine eseguita da
                        // celery, che è un vettore di risultati ("responses").  il
                        // valore viene messo in "data.details" dalla routine da celery
                        // sul server
                        set(
                            responseData.value,
                            identificativo,
                            response.data.details
                        );

                        resetStatus(identificativo, t0);

                        modal != null ? root.$bvModal.hide(modal) : null;

                        if (messaggio) {
                            set(
                                loadingMessage.value,
                                identificativo,
                                messaggio
                            );
                            showAlert(
                                { message: messaggio, variant: variant },
                                root
                            );
                        }

                        // funzione eseguita al termine dell'operazione asincrona
                        if (callback) {
                            callback(callBackParams, response);
                        }

                        // termina il polling
                        clearInterval(pollingId);

                    } else {
                        // else PENDING...
                        
                        // throw 'Errore mock'

                        // assegna i dettagli per la progress bar 
                        assignDetails(response, identificativo);

                    }
                })
                .catch((error) => {
                    modal != null ? root.$bvModal.hide(modal) : null;
                    // termina il polling
                    clearInterval(pollingId);
                    console.log(error);
                    // funzione eseguita al termine dell'operazione asincrona in caso di errore
                    if (errorCallback) {
                        errorCallback(errorCallbackParams, error);
                    }

                    resetStatus(identificativo, t0);
                });
        },
        pollingInterval ? pollingInterval : DEFAULT_POLLING_INTERVAL
    );
};

const resetStatus = (identificativo, t0, t1 = performance.now()) => {
    set(loading.value, identificativo, false);
    set(loaded.value, identificativo, true);
    set(finish.value, identificativo, new Date());
    set(tempo.value, identificativo, Math.round((t1 - t0) / 1000));
    set(loadingError.value, identificativo, { details: "", is_error: false });
    set(loadingCurrent.value, identificativo, responseData.value.total);
    set(loadingTotal.value, identificativo, responseData.value.total);
};

// per il progress bar 
const assignDetails = (response, identificativo) => {
    let message = "";
    if (response.data.details) {
        if (response.data.details["message"]) {
            message =
                `${response.data.details["message"]} ` +
                (response.data.details["total"] != 0
                    ? `${response.data.details["current"]}/${response.data.details["total"]}`
                    : "");
        } else {
            message =
                `${response.data.state} ` +
                (response.data.details["total"] != 0
                    ? `${response.data.details["current"]}/${response.data.details["total"]}`
                    : "");
        }
        set(loadingMessage.value, identificativo, message);
        set(
            loadingCurrent.value,
            identificativo,
            response.data.details["current"]
        );
        set(loadingTotal.value, identificativo, response.data.details["total"]);
    }
};
