
    <div>
        <b-container>
            <h3>
                <span style="font-size: 1.25rem">
                    <b-avatar
                        badge
                        badge-variant="light"
                        badge-offset="-0.25rem"
                        v-b-tooltip.hover
                        :title="aziendeRef.length + ' aziende in gestione'"
                    >
                        <template v-slot:badge>{{
                            aziendeRef.length
                        }}</template>
                    </b-avatar>
                </span>
                <span class="ml-2" style="vertical-align: baseline">
                    {{ utente.first_name }}&nbsp;{{ utente.last_name }}
                    <span class="small ml-1">({{ utente.email }})</span>
                </span>
            </h3>

            <p>
                <!-- <span class="text-muted">Ultimo accesso</span> -->
                <!-- {{ utente.last_login | DateToLocaleString }}. -->
                <span class="text-muted">Scadenza sessione</span>
                <!-- tra {{manca}} <span class="text-muted">(al {{DateToLocaleStringLong(refreshTokenExpiration) }})</span> -->
                {{ DateToLocaleStringLong(refreshTokenExpiration) }}
            </p>

            <!-- ++{{ showOverlay}} -->

            <b-tabs class="mt-3">
                <b-tab
                    active
                    title="Preferenze"
                    :disabled="
                        checkUserAccess(utente, { categoria: 'READONLY' })
                    "
                >
                    <preferenze
                        :azienda_id="azienda_id"
                        :stabilimento_id="stabilimento_id"
                        :userId="userId"
                        :utente="utente"
                    />
                </b-tab>

                <b-tab lazy>
                    <template v-slot:title>
                        Aziende
                        <!-- <b-badge class="ml-1">{{ aziendeRef.length }}</b-badge> -->
                    </template>
                    <b-container class="mb-0 mt-3">

                        <h4 class="mt-3">
                            {{ $t("Aziende") }}
                            <b-btn @click="useEsporta()" variant="primary" class="float-right mb-3">Esporta elenco aziende</b-btn>
                        </h4>


                        <!-- <b-row class="mt-5 mb-0"> -->
                        <!--   <b-col> -->
                        <!--   <b-table-simple responsive small class="mt-0 mb-0" v-if="aziendeRef.length > 1" > -->
                        <!--     <tr class="text-right"> -->
                        <!--       <th>Aziende</th> -->
                        <!--       <th>Campagna aperta</th> -->
                        <!--       <th>Campagna chiusa</th> -->
                        <!--     </tr> -->
                        <!--     <tbody> -->
                        <!--       <tr> -->
                        <!--         <td class="text-right font-weight-normal"> {{ carico != undefined ? carico.length - 1 : "" | IntNumberToLocaleString }} </td> -->
                        <!--         <td class="text-right font-weight-normal">{{ carico != undefined ? carico[carico.length - 1].aziende_campagna_aperta : "" | IntNumberToLocaleString }}</td> -->
                        <!--         <td class="text-right font-weight-normal">{{ carico != undefined ? carico[carico.length - 1].aziende_campagna_chiusa : "" | IntNumberToLocaleString }}</td> -->
                        <!--       </tr> -->
                        <!--     </tbody> -->
                        <!--   </b-table-simple> -->
                        <!-- </b-col> -->

                        <!------------------------------- Statistiche ------------------------------->
                        <!-- <h5 class="mt-3">{{ $t('Statistiche (totale aziende in gestione)') }}</h5> -->
                        <!-- <b-col> -->
                        <b-table-simple
                            responsive
                            small
                            class="mt-3 mb-0"
                            v-if="aziendeRef.length > 1"
                        >
                            <tr class="text-right">
                                <th>Aziende</th>
                                <th>Camp. aperta</th>
                                <th>Camp. da aprire</th>
                                <th>Registrazioni</th>
                                <th>Prodotti</th>
                                <th>Soggetti</th>
                                <th>Reg. storiche</th>
                                <th>Reg. locked</th>
                            </tr>
                            <tbody>
                                <tr>
                                    <td class="text-right font-weight-normal">
                                        {{
                                            carico != undefined
                                                ? carico.length - 1
                                                : "" | IntNumberToLocaleString
                                        }}
                                    </td>
                                    <td
                                        class="text-success text-right font-weight-normal"
                                    >
                                        {{
                                            carico && carico[carico.length - 1]
                                                ? carico[carico.length - 1]
                                                      .aziende_campagna_aperta
                                                : "" | IntNumberToLocaleString
                                        }}
                                    </td>
                                    <td
                                        class="text-danger text-right font-weight-normal"
                                    >
                                        {{
                                            carico && carico[carico.length - 1]
                                                ? carico[carico.length - 1]
                                                      .aziende_campagna_chiusa
                                                : "" | IntNumberToLocaleString
                                        }}
                                    </td>
                                    <td class="text-right font-weight-normal">
                                        {{
                                            carico && carico[carico.length - 1]
                                                ? carico[carico.length - 1]
                                                      .registrazioni_tot
                                                : "" | IntNumberToLocaleString
                                        }}
                                    </td>
                                    <td class="text-right font-weight-normal">
                                        {{
                                            carico && carico[carico.length - 1]
                                                ? carico[carico.length - 1]
                                                      .prodotti_tot
                                                : "" | IntNumberToLocaleString
                                        }}
                                    </td>
                                    <td class="text-right font-weight-normal">
                                        {{
                                            carico && carico[carico.length - 1]
                                                ? carico[carico.length - 1]
                                                      .soggetti_tot
                                                : "" | IntNumberToLocaleString
                                        }}
                                    </td>
                                    <td class="text-right font-weight-normal">
                                        {{
                                            carico && carico[carico.length - 1]
                                                ? carico[carico.length - 1]
                                                      .registrazioni_storiche_tot
                                                : "" | IntNumberToLocaleString
                                        }}
                                    </td>
                                    <td class="text-right font-weight-normal">
                                        {{
                                            carico && carico[carico.length - 1]
                                                ? carico[carico.length - 1]
                                                      .registrazioni_locked_tot
                                                : "" | IntNumberToLocaleString
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </b-table-simple>
                        <!-- </b-col> -->
                        <!-- </b-row> -->

                        <!----------------------------------------- azienda e stabilimento ------------------------------------->
                        <b-table-simple responsive class="mt-3">
                            <tr>
                                <th class="tableKey"></th>
                                <th>Ragione sociale (CUAA)</th>
                                <th>Stabilimenti</th>
                            </tr>
                            <tbody>
                                <tr
                                    v-for="(azienda, index) in aziendeRef"
                                    :key="index"
                                    :class="{
                                        'bg-light': azienda.cuaa === cuaa,
                                    }"
                                >
                                    <!------------------------ menu contestuale ----------------------->
                                    <td class="tableKey">
                                        <b-dropdown
                                            variant="link"
                                            no-caret
                                            toggle-class="text-decoration-none pt-0 pb-0 pr-0"
                                            v-b-tooltip.hover
                                            title="Azioni"
                                        >
                                            <template slot="button-content">
                                                <font-awesome-layers
                                                    class="fa-lg"
                                                >
                                                    <font-awesome-icon
                                                        icon="circle"
                                                    />
                                                    <font-awesome-icon
                                                        icon="ellipsis-h"
                                                        transform="shrink-6"
                                                        :style="{
                                                            color: 'white',
                                                        }"
                                                    />
                                                </font-awesome-layers>
                                                <!-- <font-awesome-icon icon="ellipsis-v"/> -->
                                            </template>
                                            <b-dropdown-item
                                                @click="showModal(azienda.cuaa)"
                                                :disabled="
                                                    checkUserAccess(utente, {
                                                        categoria: 'READONLY',
                                                    })
                                                "
                                            >
                                                <font-awesome-icon
                                                    icon="edit"
                                                    class="text-primary mr-2"
                                                    title="Modifica i dati aziendali (indirizzo, stabilimento, ecc.)"
                                                    :class="{
                                                        'text-muted':
                                                            checkUserAccess(
                                                                utente,
                                                                {
                                                                    categoria:
                                                                        'READONLY',
                                                                }
                                                            ),
                                                    }"
                                                />Modifica
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </td>

                                    <td :title="azienda.id">
                                        <font-awesome-icon
                                            v-if="
                                                azienda.ambiente_operativo_esercizio
                                            "
                                            icon="circle"
                                            :style="{ color: '#0099CC' }"
                                            class="ml-0 mr-1"
                                        />

                                        <span
                                            :class="{
                                                'font-weight-bold':
                                                    azienda.cuaa === cuaa,
                                            }"
                                            >{{ azienda.ragione_sociale }} ({{
                                                azienda.cuaa
                                            }})</span
                                        >

                                        <!--------------------- tabella carico ------------------------------------->
                                        <b-table-simple small class="mt-3 mb-0">
                                            <tr
                                                class="small text-muted text-right"
                                            >
                                                <th class="font-weight-normal">
                                                    Registrazioni
                                                </th>
                                                <th class="font-weight-normal">
                                                    Prodotti
                                                </th>
                                                <th class="font-weight-normal">
                                                    Soggetti
                                                </th>
                                                <th class="font-weight-normal">
                                                    Reg. storiche
                                                </th>
                                                <th class="font-weight-normal">
                                                    Reg. locked
                                                </th>
                                                <!--<th class="font-weight-normal">Prod. locked</th>-->
                                            </tr>
                                            <tr>
                                                <td class="small text-right">
                                                    {{
                                                        carico &&
                                                        carico.length > 0
                                                            ? carico.find(
                                                                  (x) =>
                                                                      x.azienda_id ==
                                                                      azienda.id
                                                              ).registrazioni
                                                            : ""
                                                              | IntNumberToLocaleString
                                                    }}
                                                </td>
                                                <td class="small text-right">
                                                    {{
                                                        carico &&
                                                        carico.length > 0
                                                            ? carico.find(
                                                                  (x) =>
                                                                      x.azienda_id ==
                                                                      azienda.id
                                                              ).prodotti
                                                            : ""
                                                              | IntNumberToLocaleString
                                                    }}
                                                </td>
                                                <td class="small text-right">
                                                    {{
                                                        carico &&
                                                        carico.length > 0
                                                            ? carico.find(
                                                                  (x) =>
                                                                      x.azienda_id ==
                                                                      azienda.id
                                                              ).soggetti
                                                            : ""
                                                              | IntNumberToLocaleString
                                                    }}
                                                </td>
                                                <td class="small text-right">
                                                    {{
                                                        carico &&
                                                        carico.length > 0
                                                            ? carico.find(
                                                                  (x) =>
                                                                      x.azienda_id ==
                                                                      azienda.id
                                                              )
                                                                  .registrazioni_storiche
                                                            : ""
                                                              | IntNumberToLocaleString
                                                    }}
                                                </td>
                                                <td class="small text-right">
                                                    {{
                                                        carico &&
                                                        carico.length > 0
                                                            ? carico.find(
                                                                  (x) =>
                                                                      x.azienda_id ==
                                                                      azienda.id
                                                              )
                                                                  .registrazioni_locked
                                                            : ""
                                                              | IntNumberToLocaleString
                                                    }}
                                                </td>

                                                <!--                
<td class="small text-right">
                          {{
                            carico && carico.length > 0
                              ? carico.find((x) => x.azienda_id == azienda.id)
                                  .prodotti_locked
                              : "" | IntNumberToLocaleString
                          }}
                        </td>
                        -->
                                            </tr>
                                        </b-table-simple>

                                        <!--------------------- tabella date apertura, attivazione, ripristino ------------------------------------->
                                        <hr />
                                        <b-row
                                            class="small"
                                            v-if="
                                                azienda.data_nuova_campagna_aperta !=
                                                null
                                            "
                                        >
                                            <b-col class="text-muted"
                                                >Data apertura campagna
                                                aziendale</b-col
                                            >
                                            <b-col
                                                :class="{
                                                    'text-success':
                                                        azienda.nuova_campagna_aperta,
                                                    'text-danger':
                                                        !azienda.nuova_campagna_aperta,
                                                }"
                                                >{{
                                                    azienda.data_nuova_campagna_aperta
                                                        | DateToLocaleString
                                                }}</b-col
                                            >
                                        </b-row>
                                        <b-row
                                            class="small"
                                            v-if="
                                                azienda.data_ripristino != null
                                            "
                                        >
                                            <b-col class="text-muted"
                                                >Data attivazione</b-col
                                            >
                                            <b-col>
                                                {{
                                                    azienda.data_attivazione
                                                        | DateToLocaleString
                                                }}
                                            </b-col>
                                        </b-row>
                                        <b-row
                                            class="small"
                                            v-if="
                                                azienda.data_ripristino != null
                                            "
                                        >
                                            <b-col class="text-muted"
                                                >Data ripristino archivi</b-col
                                            >
                                            <b-col>
                                                {{
                                                    carico && carico.length > 0
                                                        ? carico.find(
                                                              (x) =>
                                                                  x.azienda_id ==
                                                                  azienda.id
                                                          ).data_ripristino
                                                        : ""
                                                          | DateToLocaleString
                                                }}
                                            </b-col>
                                        </b-row>
                                        <b-row
                                            class="small"
                                            v-if="
                                                azienda.data_importazione_storici !=
                                                null
                                            "
                                        >
                                            <b-col class="text-muted"
                                                >Data importazione
                                                storici</b-col
                                            >
                                            <b-col>
                                                {{
                                                    carico && carico.length > 0
                                                        ? carico.find(
                                                              (x) =>
                                                                  x.azienda_id ==
                                                                  azienda.id
                                                          )
                                                              .data_importazione_storici
                                                        : ""
                                                          | DateToLocaleString
                                                }}
                                            </b-col>
                                        </b-row>
                                    </td>

                                    <!------------------------- stabilimenti ------------------------->
                                    <td width="35%">
                                        <span
                                            :class="{
                                                'font-weight-bold':
                                                    stabilimento.icqrf ===
                                                    icqrf,
                                                'text-success':
                                                    carico && carico.length > 0
                                                        ? carico.find(
                                                              (x) =>
                                                                  x.azienda_id ==
                                                                  azienda.id
                                                          )
                                                              .situazione_stabilimenti
                                                              .stato_stabilimenti[
                                                              index
                                                          ].stato == 'chiuso'
                                                        : false,
                                                'text-danger':
                                                    carico && carico.length > 0
                                                        ? carico.find(
                                                              (x) =>
                                                                  x.azienda_id ==
                                                                  azienda.id
                                                          )
                                                              .situazione_stabilimenti
                                                              .stato_stabilimenti[
                                                              index
                                                          ].stato == 'aperto'
                                                        : false,
                                            }"
                                            v-for="(
                                                stabilimento, index
                                            ) in azienda.stabilimenti_aziendali"
                                            :key="index"
                                            :title="stabilimento.id"
                                        >
                                            <b-link
                                                @click="
                                                    useGoToAzienda(
                                                        azienda.cuaa,
                                                        stabilimento.icqrf,
                                                        'home'
                                                    )
                                                "
                                            >
                                                <font-awesome-icon
                                                    icon="arrow-circle-right"
                                                    class="fa-lg mr-1"
                                                />
                                            </b-link>
                                            {{ stabilimento.descrizione }} ({{
                                                stabilimento.icqrf
                                            }})
                                            <!-- <span class="small">{{ carico && carico.length > 0 ? carico.find((x) => x.azienda_id == azienda.id).situazione_stabilimenti.stato_stabilimenti[index].data_campagna_chiusa : "" | DateToLocaleString }}</span> -->
                                            <hr />
                                        </span>
                                    </td>
                                    <!--
                  <td class="text-center">
                    <b-form-group>
                      <b-form-checkbox  
                        disabled
                        v-model="ambienteRef[index]" 
                        @input="setAmbiente(index)">
                      </b-form-checkbox>

                      <b-form-text>
                        Selezionare per operare in ambiente di esercizio. se non selezionato, si opera in ambiente di collaudo (test).
                      </b-form-text>

                    </b-form-group>

                  </td>
                  -->
                                </tr>
                            </tbody>
                        </b-table-simple>

                        <hr class="mb-3" />
                    </b-container>
                </b-tab>

                <!----------------------------- informazioni ----------------------------------->
                <b-tab lazy>
                    <template v-slot:title>Informazioni</template>
                    <b-container class="mb-0 mt-3">

                        <b-row class="mt-3 mb-0">
                            <b-col>
                                <h4>Informazioni sull'utente</h4>
                                <b-row>
                                    <b-col>
                                        <b>Cliente professionale</b>
                                        <p>
                                            Chi è il cliente professionale (non azienda
                                            finale) da cui dipende l'utente
                                        </p>
                                        <ul>
                                            <li>{{ utente.cliente.codice }}</li>
                                        </ul>
                                    </b-col>
                                </b-row>
                                <b-row class="">
                                    <b-col>
                                        <b>Categorie di appartenenza</b>
                                        <ul>
                                            <li
                                                v-for="(
                                                    categoria, i
                                                ) in utente.categorie"
                                                :key="i"
                                            >
                                                {{ getCategoria(categoria) }}
                                            </li>
                                        </ul>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b>Servizi abilitati</b>
                                        <ul>
                                            <li
                                                v-for="(servizio, i) in utente.servizi"
                                                :key="i"
                                            >
                                                {{ getServizio(servizio) }}
                                            </li>
                                        </ul>
                                    </b-col>
                                </b-row>
                                <b-row class="mb-0">
                                    <b-col>
                                        <b>Stato dell'utente</b>
                                        <p>
                                            A quale livello di stato dei servizi può
                                            accedere l'utente. Ad esempio, se ha lo
                                            stato 'ALFA', l'utente può accedere ai
                                            servizi in stato 'ALFA' e stato superiore
                                            ('BETA', 'PROD').
                                        </p>
                                        <ul>
                                            <li>{{ utente.stato }}</li>
                                        </ul>
                                    </b-col>
                                </b-row>

                            </b-col>

                            <!-- <b-col> -->
                            <!--     <h4>Informazioni sull'azienda corrente</h4> -->

                            <!--     <b-row class="mt-3 mb-0"> -->
                            <!--         <b-col> -->
                            <!--             <b>Ragione sociale</b> -->
                            <!--             <p>{{ currentAzienda.ragione_sociale }}</p> -->
                            <!--             <!-1- <pre>{{ currentAzienda }}</pre> -1-> -->
                            <!--             <b-row class="mb-0"> -->
                            <!--                 <b-col> -->
                            <!--                     <b>Servizi abilitati</b> -->
                            <!--                     <ul> -->
                            <!--                             <li v-for="servizio in currentAzienda.servizi_ammessi"> -->
                            <!--                                 {{ servizio.descrizione }} {{ servizio.codice }} <span class="text-muted">({{ servizio.stato }})</span> -->
                            <!--                             </li> -->
                            <!--                     </ul> -->
                            <!--                 </b-col> -->
                            <!--             </b-row> -->
                            <!--         </b-col> -->
                            <!--     </b-row> -->
                            <!-- </b-col> -->
                        </b-row>
                    </b-container>
                </b-tab>

                <!-- <b-tab title="Token" v-if="checkUserAccess(utente)" > -->
                <!--   <b-container class="mt-4 border bg-light"> -->
                <!--     <p class="text-muted text-center mt-3">{{ getToken }}</p> -->
                <!--   </b-container> -->
                <!-- </b-tab> -->

            </b-tabs>
        </b-container>
        <b-overlay :show="showOverlay" fixed no-wrap></b-overlay>


        <!-----------------------------------------------------------------------------
        modale STABILIMENTO
        -------------------------------------------------------------------------------->
        <b-modal
            lazy
            size="lg"
            id="stabilimentoModal"
            ref="stabilimentoModal"
            centered
        >
            <div slot="modal-title" class="w-100">
                {{ aziendaRef != null ? aziendaRef.ragione_sociale : "" }} ({{
                    aziendaRef != null ? aziendaRef.cuaa : ""
                }})
            </div>

            <azienda 
                :cuaa="cuaa" 
                :azienda_id="azienda_id" 
                :stabilimenti="stabilimenti" 
            />

            <div slot="modal-footer" class="w-100">
                <b-btn
                    class="float-right"
                    variant="primary"
                    @click="onSubmit"
                    :disabled="errors.items.length > 0"
                    >{{ $t("conferma") }}</b-btn
                >
                <b-btn
                    class="float-right mr-2"
                    variant="light"
                    @click="closeModal"
                >
                    {{ $t("chiudi") }}
                </b-btn>
            </div>
        </b-modal>


    </div>
