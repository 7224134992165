
<b-jumbotron  class="text-white" style="background: rgba(0,0,0,0.45); margin: 15px;">
  <template slot="header">
   {{ $t('404')}}
  </template>
  <template slot="lead">
    {{ $t('messaggio_404')}}
  </template>
  <hr style="color: rgba(255,255,255,0.1); border-color: rgba(255,255,255,0.1); background-color: rgba(255,255,255,0.1)">
  <!-- <hr class="my-4 hr-white" style="color: white;"> -->
  <p>
    {{ $t('messaggio_404_1')}}
  </p>
  <!-- <p> -->
  <!--   <b-link to="/" class="p404">Andare alla pagina iniziale...</b-link> -->
  <!-- </p> -->
</b-jumbotron>
