<template>
  <b-container class="mt-3">
    <b-tabs>
        
      <!-- <b-tab active title="Indirizzo"> -->
      <!--   <azienda-indirizzo -->
      <!--     :azienda_id="azienda_id" -->
      <!--     :form="form" -->
      <!--   /> -->
      <!-- </b-tab> -->

      <b-tab title="Stabilimenti">
        <azienda-stabilimento :stabilimenti="stabilimenti" />
      </b-tab>

      <b-tab title="Parametri codici aziendali" 
            v-if="checkUserAccess(utente, {servizio: 'XML', azienda: currentAzienda})"
      >
          <parametri-codici-aziendali 
              :cuaa="cuaa" 
              :azienda_id="azienda_id" 
          />
      </b-tab>

      <b-tab title="Servizi ammessi" 
      >
        <h5 class="mt-3">Servizi ammessi per l'azienda</h5>
        <ul>
          <li v-for="servizio in currentAzienda.servizi_ammessi">
            {{ servizio. codice }} <span class="text-muted">({{ servizio.stato }})</span>
          </li>
        </ul>
      </b-tab>

    </b-tabs>
  </b-container>
</template>

<script>
import { ref, watch, computed } from "@vue/composition-api";
import { useState } from "@u3u/vue-hooks";

import AziendaIndirizzo from "./AziendaIndirizzo.vue";
import AziendaStabilimento from "./AziendaStabilimento.vue";
import ParametriCodiciAziendali from "./ParametriCodiciAziendali.vue";

import {checkUserAccess} from "../../utils/access.js";

export default {
  name: "azienda",

  props: {
    cuaa: String,
    azienda_id: Number,
    stabilimenti: Array,
  },

  components: { AziendaIndirizzo, AziendaStabilimento, ParametriCodiciAziendali },

  setup(props, context) {
    const state = ref({
      highlighted: {
        dates: [new Date()]
      }
    });
    // const it = ref(it);
    // const en = ref(en);
    const { currentAzienda, utente } = useState("loginModule", ["currentAzienda", "utente"]);

    return {
        utente,
        currentAzienda,
        checkUserAccess,
    };
  }
};

// function preparePayload(form, props) {

//   return 
// }


</script>

<i18n>
{
  "en": {
  },
  "it": {
    "text_FlagArt33": "Documento ricadente ai sensi del ex art. 33, del Reg. (CE) (art. 17 del Reg. (UE) n. 2018/273",
    "text_FlagArt29": "Documento ricadente nell’art. 29 del Reg. (CE) n. 436/09, per il trasporto di alcuni prodotti vitivinicoli sfusi destinati all’estero.",
    "archiviazione": "Archiviazione",
    "testo_archiviazione": "Archiviazione documenti aziendali"
  }
}
</i18n>
