export const strumentiModule = {
  namespaced: true,
  state: {
      apertura: false, // Aug 01, 2021
    bloccoRiapertura: false, // Jun 09, 2021
    bloccoRipristino: false,
    showForzaApertura: false,

    importazioneStorici: false, // Nov 27, 2019
    ripristinoArchivi: false, // Nov 27, 2019
    unMountScheduled: false, // Oct 05, 2019
    storiciTabClicked: false, // il bottone col n. record in registrazione
    idTrasmissione: "",
    storici: {
      data_inizio: new Date,
      data_fine: new Date
    },
    componente: "",
    temporaneo: "3",
    importato: false,
  },
  mutations: {
      setApertura(state, payload) { state.apertura = payload; },
    // Jun 09, 2021
    setBloccoRiapertura(state, payload) { state.bloccoRiapertura = payload; },        
    setBloccoRipristino(state, payload) { state.bloccoRipristino = payload; },        
    setShowForzaApertura(state, payload) { state.showForzaApertura = payload; },        


    setImportazioneStorici(state, payload) {
      state.importazioneStorici = payload;
    },        
    setRipristinoArchivi(state, payload) {
      state.ripristinoArchivi = payload;
    },         

    scheduleUnMount(state, payload) {
      state.unMountScheduled = payload.unMountScheduled;
    },
    setStoriciTabClicked(state, payload) {
      state.storiciTabClicked = payload;
    },
    setStorici(state, payload) {
      state.storici = payload;
    },        
 
    setComponente(state, payload) {
      state.componente = payload.componente;
    },    
    setImportato(state, payload) {
      state.importato = payload.importato;
    },
    setTemporaneo(state, payload) {
      state.temporaneo = payload.temporaneo;
    },
    setIdTrasmissione(state, payload) { 
      state.idTrasmissione = payload;
    },
  },
};
