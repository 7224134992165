
<table class="table table-sm">
  <thead>
    <tr>
        <th width="30%" class="border-top-0">
          Azienda e stabilimento
        </th>
      <!-- <th width="1%" class="border-top-0"></th> -->
      <!-- <th width="15%" class="border-top-0"></th> -->
      <th class="border-top-0">Documento</th>
      <!-- <th class="border-top-0">Caricato da</th> -->
      <!-- <th class="border-top-0">In data</th> -->
    </tr>
  </thead>
  <tbody>
    <template v-for="avviso in avvisiDiSistemaArchiviazione">
      <tr :key="avviso.id">
        <td>
          {{ avviso.azienda.ragione_sociale }} <span class="text-muted">({{ avviso.azienda.cuaa }})</span>
            <div v-if="avviso.stabilimento">
                {{ avviso.stabilimento?.descrizione }} 
                <span class="text-muted">({{ avviso.stabilimento?.icqrf }})
                </span>
            </div>
        </td>
        <!-- <td> -->
        <!--   <b-link @click="useGoToAzienda(avviso.azienda.cuaa, avviso.stabilimento.icqrf, 'mvv-list')"> -->
        <!--     <font-awesome-icon icon="arrow-circle-right" /> -->
        <!--   </b-link> -->
        <!-- </td> -->                           
        <td>
          <span class="text-nowrap">{{avviso.result.file_name}}</span>
          <br/><span class="small text-muted">Caricato da </span> 
          <span class="small"><a :href="'mailto:' + avviso.result.uploaded_by.email">{{ avviso.result.uploaded_by.email }}</a></span>
          <span class="small text-muted"> il </span> 
          <span class="small">{{ DateToLocaleString(avviso.result.uploaded_at) }}</span>
          <div class="small mt-0">{{ avviso.result.note }}</div>
          <!-- <b-badge --> 
          <!--     :variant="getCategoria(avviso.result.uploaded_by).variant" --> 
          <!--     class="ml-1" --> 
          <!--     :style="getCategoria(avviso.result.uploaded_by).style" -->
          <!--     :title="getCategoria(avviso.result.uploaded_by).title" -->
          <!--     v-b-tooltip.hover -->
          <!-- > -->
          <!--     {{getCategoria(avviso.result.uploaded_by).text}} -->
          <!-- </b-badge> -->
        </td>
      </tr>              
    </template>
  </tbody>
</table>
