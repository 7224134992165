// August 04, 2019 
import Vue from "vue";

import { DateTime } from "luxon"; // https://moment.github.io/luxon/index.html

Vue.filter("DateToLocaleString", function(value) {
  if (!value) {
    return "";
  } else {
    // var f = {month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minutes: '2-digit', second: 'numeric'}
    var dt = DateTime.fromISO(value);
    return dt.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
  }
});

Vue.filter("DateToLocaleStringShort", function(value) {
  if (!value) {
    return "";
  } else {
    // var f = {month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minutes: '2-digit', second: 'numeric'}
    var dt = DateTime.fromISO(value);
    return dt.toLocaleString(DateTime.DATE_FULL);
  }
});

Vue.filter("DateJSToLocaleString", function(value) {
  if (!value) {
    return "";
  } else {
    var dt = DateTime.fromJSDate(value);
    return dt.toLocaleString(DateTime.DATE_SHORT);
  }
});

Vue.filter("DateJSToLocaleStringLong", function(value) {
  if (!value) {
    return "";
  } else {
    var dt = DateTime.fromJSDate(value);
    return dt.toLocaleString(DateTime.DATE_FULL);
  }
});

// il locale è fisso! da cambiare in caso di multilingua
Vue.filter("NumberToLocaleString", function(value) {
  if (value == null) {
    return 0;
  } else if (value == 0) {
    return 0;
  } else {
    var n = parseFloat(value) //.toFixed(3);
    return n.toLocaleString("it-IT", {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3,
    });
  }
});

Vue.filter("IntNumberToLocaleString", function(value) {
  if (value == null) {
    return 0;
    // return null;
  } else if (value == 0) {
    return 0;
  } else {
    var n = Number(value);
    return n.toLocaleString("it-IT");
  }
});

/**
 * https://github.com/freearhey/vue2-filters/blob/master/src/string/uppercase.js
 *
 * Converts a string to UPPERCASE
 *
 * 'abc' => 'ABC'
 */

Vue.filter("uppercase", function(value) {
  return value || value === 0 ? value.toString().toUpperCase() : "";
});

// https://github.com/imcvampire/vue-truncate-filter/blob/master/vue-truncate.js
Vue.filter("truncate", function(text, length, clamp) {
  text = text || "";
  clamp = clamp || "...";
  length = length || 30;
  if (text.length <= length) return text;
  var tcText = text.slice(0, length - clamp.length);
  var last = tcText.length - 1;
  while (last > 0 && tcText[last] !== " " && tcText[last] !== clamp[0])
    last -= 1;
  // Fix for case when text dont have any `space`
  last = last || length - clamp.length;
  tcText = tcText.slice(0, last);
  return tcText + clamp;
});
