import axios from "axios";

export const isASCII = (value, args) => {
    // return (extended ? /^[\x00-\xFF]*$/ : /^[\x00-\x7F]*$/).test(value);
    var nessun_magg_min = !/[<>&]/.test(value); // non contiene <> (dall'inizio riga alla fine) e &
    var solo_ascii = /^[\na-zA-Z0-9'().+\:, \/\-_]*$/.test(value); // \n consente anche il newline Feb 10, 2020
    return {
        valid: nessun_magg_min && solo_ascii,
        data: {
            message:
            "Non sono ammessi caratteri accentati (è, à, ecc.) e altri caratteri di controllo"
            // + 
            // (args[0] != null ? ' (campo: ' + args[0] + ')' : '')
        }
    };
};

/* 

Jan 20, 2020

Chiamata del controllo:

Gestisce il separatore dei decimali come ","

1 SE è obbligatorio verifica che sia > 0
greaterThanZero: [getRequired('vol_conf', i)]

2. è obbligatorio, verifica che sia > 0 (equivale a "greaterThanZero: []")
greaterThanZero: [true]

3. Verifica comunque che sia > 0
greaterThanZero: []

4. NON Verifica
greaterThanZero: [false]

*/
export const greaterThanZero = (value, args) => {
    let valid = false
    let message = ""
    let val = typeof value == "string" ? parseFloat(value.replace(",",".")) : value
    if (args[0] != null && args[0]) { 
        // controlla solo se required==true
        valid = val > 0
        message = valid ? "" : "Il valore è richiesto e deve essere maggiore di zero."
    } else if (args.length == 0 || args[0]==null) {
        valid = val > 0
        if (!valid) {
            message = "Il valore deve essere maggiore di zero."
        }
    } else if (!args[0]) {
        valid = true
    }
    return {
        valid: valid,
        data: { message: message }
    };
};


export const lessThan = (value, args) => {
    let valid = false
    let message = ""
    let val = typeof value == "string" ? parseFloat(value.replace(",", ".")) : value
    if (args[0] != null && args[0]) { 
        valid = val < args[0]
        message = valid ? "" : "Il valore deve essere minore di " + args[0].toLocaleString('it-IT')
    } else if (!args[0]) {
        valid = true
    }
    return {
        valid: valid,
        data: { message: message }
    };
};

/*
 * vaso
 */
export const isCodiceVasoUnico = (value, args) => {
    if (value != args.valore_iniziale) {
        const options = {
            method: 'get',
            url: `/api/validate/codice_vaso_unico/${value.toUpperCase()}/${args.azienda}/${
      args.stabilimento}`
        }
        return axios.request(options)
            .then((response) => {
                return {
                    valid: response.data.valid,
                    data: {
                        message: response.data.message
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    } else {
        return {
            valid: true,
            data: {
                message: '' 
            }
        }
    }
};

/*
 * vigna
 */
export const isCodiceVignaUnico = (value, args) => {
    if (value != args.valore_iniziale) {
        const path = `/api/validate/codice_vigna_unico/${value.toUpperCase()}/${args.azienda}/${
      args.stabilimento
    }`;
        return axios
            .get(path)
            .then(response => ({
                valid: response.data.valid,
                data: {
                    message: response.data.message
                }
            }))
            .catch(error => {
                console.log(error);
            });
    } else {
        return {
            valid: true,
            data: {
                message: '' 
            }
        }
    }
};
