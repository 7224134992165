import { isEmpty } from "../../utils/eSianUtils.js";


export function disabledState(field, form) {
  switch (field) {
    case 'seedDest':
        if (form.codice == '' || form.codice == null) {
            return true
        }
        // if (form.tipo_soggetto !=null && form.tipo_soggetto.codice == 'EX') {
        //     // lo pulisce prima di bloccarlo
        //     form.seedDest = null
        //     return true
        // }
        return false
    case "tipo_soggetto":
      if (isEmpty(form.codice)) {
        return true;
      }
      break;
    case "persona":
      if (
        isEmpty(form.tipo_soggetto) 
        // || form.tipo_soggetto.codice == "EX" 
        // || form.tipo_soggetto.codice == "UE"
      ) {
        return true;
      }
      break;    
    case "piva":
      if (
        isEmpty(form.tipo_soggetto) 
        || form.persona // è persona fisica
        || form.tipo_soggetto.codice == "EX" 
        || form.tipo_soggetto.codice == "UE"
        || !isEmpty(form.cf) 
      ) {
        form.piva = null;
        return true;
      }
      break;
    case "cf":
      if (
        isEmpty(form.tipo_soggetto) 
        || !isEmpty(form.piva) 
        || form.tipo_soggetto.codice == "EX" 
        || form.tipo_soggetto.codice == "UE"
      ) {
        form.cf = null;
        return true;
      }
      break;
    case "ragione_sociale":
      if (
        isEmpty(form.tipo_soggetto) 
        || form.persona // è persona fisica
      ) {
        form.ragione_sociale = null;
        return true;
      }
      break;
    case "nome_persona_fisica":
    case "cognome_p_fisica":
      if ( 
        isEmpty(form.tipo_soggetto) 
        || !form.persona // non è persona fisica
        // || form.tipo_soggetto.codice == "EX" 
        // || form.tipo_soggetto.codice == "UE"
      ) {
        return true;
      }
      break;
    case "icqrf":
      if (
        isEmpty(form.tipo_soggetto) 
        || isEmpty(form.persona) 
        || form.tipo_soggetto.codice == "EX"
        || form.tipo_soggetto.codice == "UE"
      ) {
        form.destinatario_prodotti_vinicoli = null;
        form.icqrf = null;
        return true;
      }
      break;
    case "stato":
      if (form.tipo_soggetto != null && form.tipo_soggetto.codice == "IT") {
        return true
      }
      if (isEmpty(form.codice)) {
        form.destinatario_prodotti_vinicoli = false;
        form.icqrf = null;
        form.cf = null;
        form.piva = null;
        form.cap = null;
        form.comune = null;
        form.provincia = null;
        return true;
      }
      break;
    default:
      return false;
  }
  return false;
}
