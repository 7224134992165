
<!-- <div class="container mt-5"> -->
<b-container fluid class="h-100 v-100">
  <ValidationObserver ref="observer" v-slot="{ valid, invalid, dirty }">
  <b-row class="h-100" align-h="center">
    <b-col align-self="center" md="8" lg="4">

  <!-- <b-row align-h="center"> -->
  <!--   <b-col sm="7" md="6" lg="5" > -->
      <b-card :title="$t('titolo')" class="mt-3 text-white" style="background: rgba(0,0,0,0.3);">
        <b-card-body>

        <p>{{ $t('forgot') }}</p>

        <ValidationProvider
          :name="$t('email')"
          :rules="{ 
            required: true,
            email: true,
          }"
          v-slot="{ valid, errors }"
          mode="lazy"
        >          
            <!-- class="login-invalid-feedback" -->
          <b-form-group 
            id="form-email-group" 
            :label="$t('email')" 
            label-for="form-email-input" 
            label-class="font-weight-bold pt-0"
            :state="errors[0] ? false : null"
            :invalid-feedback="errors[0]"
          >
            <b-form-input
              ref="refEmail"
              id="refEmail" 
              type="text" 
              v-model="form.email" 
              :placeholder="$t('email_placeholder')"
              :state="errors[0] ? false : null"
              />
          </b-form-group>
        </ValidationProvider>
        </b-card-body>

        <b-card-footer class="text-right">
          <b-button @click="onReset" class="mr-2" type="reset" variant="secondary">{{ $t('annulla') }}</b-button>
          <b-button @click="onSubmit" 
            type="submit" 
            :variant="invalid ? 'danger' : 'primary'"
            :disabled="invalid" 
          >
            {{ $t('conferma') }}
            <b-spinner small class="ml-1" v-show="resetLoading" />
          </b-button>
        </b-card-footer>

      </b-card>
    </b-col>
  </b-row>
  </ValidationObserver>
  <b-alert
    v-model="show"
    class="position-fixed fixed-top m-0 rounded-0"
    style="z-index: 2000;"
    variant="warning"
    dismissible
  >
    {{ loginMessage }}
  </b-alert>
</b-container>
