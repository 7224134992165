// Apr 30, 2020
import axios from "axios";
import { showAlert } from "../../utils/eSianUtils.js";


export function IntNumberToLocaleString(value) {
  if (value == null) {
    return 0;
  } else if (value == 0) {
    return 0;
  } else {
    var n = Number(value);
    return n.toLocaleString("it-IT");
  }
}


export function truncate(text, length, clamp) {
  text = text || "";
  clamp = clamp || "...";
  length = length || 30;
  if (text.length <= length) return text;
  return text.substr(0, length - clamp.length).trim() + clamp

  // var tcText = text.slice(0, length - clamp.length);
  // var last = tcText.length - 1;
  // while (last > 0 && tcText[last] !== " " && tcText[last] !== clamp[0])
  //   last -= 1;
  // // Fix for case when text dont have any `space`
  // last = last || length - clamp.length;
  // tcText = tcText.slice(0, last);
  // return tcText + clamp;
}


export async function checkAddAzienda(context) {
  try {
    return await axios.get('/api/azienda/get_numero_aziende/').then(response => response.data.numero_aziende < response.data.numero_aziende_max) 
  } catch (error) {
    showAlert({ error: error }, context.root);
  }
}


export async function checkAddOperatore(context) {
  try {
    return await axios.get('/api/azienda/get_numero_operatori/').then(response => response.data.numero_utenti < response.data.numero_utenti_max) 
  } catch (error) {
    showAlert({ error: error }, context.root);
  }
}


export function random_password(max,min)
{
    var passwordChars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz#@!%&()/";
    var randPwLen = Math.floor(Math.random() * (max - min + 1)) + min;
    var randPassword = Array(randPwLen).fill(passwordChars).map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
    return randPassword;
}
