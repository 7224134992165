import axios from 'axios'
import { mapState, mapMutations } from "vuex";

import { showAlert } from "../../utils/eSianUtils.js";

export const GetCarico = {

  computed: {

    ...mapState("loginModule", [
      "currentAzienda",
      "carico",
    ])
  },

  methods: {
    
    ...mapMutations("loginModule", ["setCarico", "setCurrentAzienda"]),
    
    async getCarico() {
      try {
        let options = {
          method: 'get',
          url: "/api/users/get_carico/"
        }
        let response =  await axios.request(options)
        this.setCarico(response.data.carico)
        let id = this.currentAzienda.azienda_id
        this.setCurrentAzienda({
          ...this.$store.state.loginModule.currentAzienda,
           // Jan 03, 2020
          data_ripristino: response.data.carico.find(x => x.azienda_id==id).data_ripristino, 
          data_importazione_storici: response.data.carico.find(x=>x.azienda_id==id).data_importazione_storici,
          data_attivazione: response.data.carico.find(x=>x.azienda_id==id).data_attivazione,
        });
      } catch (error) {
        showAlert({ error: error }, this);
      }
    }
  }
}



