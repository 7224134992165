
<span>
    <font-awesome-icon
        icon="circle"
        :style="{color: '#009688'}"
        class="mr-1 small"
        v-show="prodotto.da_mvv"
        title="Prodotto creato automaticamente (MVV-E)"
    />

    <span :class="{'temporaneo': inRegistrazione}" v-html="sintesiProdotto"></span>
    
    <span class="text-muted">{{prodotto.da_designazione ? '(' + prodotto.id + ')' : ''}}</span>
    <!-- <span class="text-muted small ml-1">{{ prodotto.id }}</span> -->

    <!-------------------- DOCUMENTI ---------------------->
    <span
        v-b-tooltip.hover
        :title="documenti(prodotto)"
        v-if="
            checkUserAccess(utente, {servizio: 'ARC', azienda: currentAzienda}) 
            && prodotto.documenti 
            && prodotto.documenti.length > 0 
        "
        @click="useHandleDocumentiProdotto()"
        style="cursor: pointer;"
    >
        <!-- @click="handleArchiviaDocumento(prodotto, inRegistrazione)" -->
        <font-awesome-icon icon="file-alt" class="text-success ml-1" />
    </span>

    <!-------------------- LOCK ---------------------->
    <font-awesome-icon
        icon="lock"
        class="text-warning ml-1"
        v-show="prodotto.toBeLocked && inElencoProdotti"
    />
</span>
