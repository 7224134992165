<script>
import axios from "axios";
import {showAlert} from "../../utils/eSianUtils.js";

export default {
    name: 'confirm-email',

    created() {
        const root_api = import.meta.env.VITE_APP_ROOT_API
        const path = `${root_api}/dj-rest-auth/registration/verify-email/`;
        axios
            .post(path, {key: this.$route.params.key})
            .then(response => {
                this.$router.push({name: "confirm-email-done"});
            })
            .catch(error => {
                showAlert({error: error}, this);
            });
        return;
    },
};
</script>
