<template>


</template>

<script>
import axios from "axios";
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs'

import { showAlert } from "../../../utils/eSianUtils.js";

export default {
  name: "clienti-esportazione",

  setup(props, context) {

    clientiEsporta(context)

    return {
      //
    };
  }
}

function clientiEsporta(context) {
  let messaggio = 'Conferma esportazione dati clienti?';
  let path = '/api/clienti/esportazione/'
  context.root.$bvModal
    .msgBoxConfirm(messaggio, {
      okVariant: "primary",
      cancelVariant: "light",
      okTitle: "Esporta",
      cancelTitle: "Annulla",
      footerClass: "p-2",
      centered: true,
    })
    .then((value) => {
      if (value) {
        axios
          .get(path)
          .then(response => {
            console.log(response.data) 
            esporta(response.data)

            //var t1 = performance.now();
            //var tempo = Math.round((t1 - t0) / 1000);
            //showAlert({
            //  message:
            //    // "Stampa del file: \"" + fileName  + "\" completata in " +
            //    "Esportazione completata in " +
            //    tempo +
            //    (tempo == 1 ? " secondo (" : " secondi (") +
            //    data_results.length +
            //    " registrazioni elaborate).",
            //  variant: "success"
            //}, context.root)
          })
          .catch(error => {
            showAlert({ error: error }, context.root);
          })
          .finally(() => {
            // loading = false;
          })
      }
    })
    .catch((error) => {
      showAlert({ error: error }, context.root);
    });
}

function esporta(data_results) {
  const fileName = `Clienti_${new Date()}`
  const workbook = new Workbook()
  workbook.creator = 'e-Sian (Amministrazione) 1.0';
  workbook.created = new Date()

  const worksheetAziende = workbook.addWorksheet("Clienti")

  worksheetAziende.columns = [
    //{header: 'Id', key: 'azienda_id'},
    {header: 'CUAA', key: 'cuaa'},
    {header: 'Ragione sociale', key: 'ragione_sociale'},
    {header: 'Comune', key: 'comune_descrizione'},
    {header: 'Provincia', key: 'provincia_sigla'},
    {header: 'Cliente', key: 'cliente_codice'},
    //
    //{header: 'Id operatore', key: 'operatore_id'},
    {header: 'Operatori', key: 'email'},
    //
    //{header: 'ICQRF', key: 'icqrf'},
    //{header: 'Descrizione', key: 'descrizione'},
  ];

  data_results.forEach((result) => {
    result.azienda_id = result.id
    result.cliente_codice = result.cliente.codice
    result.comune_descrizione = result.comune != null ? result.comune.descrizione : ''
    result.provincia_sigla = result.provincia != null ? result.provincia.sigla : ''
    result.operatori.forEach((operatore)=>{
      result.operatore_id = operatore.id
      var row = Object.assign({}, result, operatore)
      worksheetAziende.addRow(row)
    })
  })

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${fileName}.xlsx`)
  });

  return fileName

}
</script>
