import { DateTime, Interval } from "luxon";
import { useState } from "@u3u/vue-hooks";

const { isCampagnaAziendaleRiaperta } = useState("loginModule", [
    "isCampagnaAziendaleRiaperta",
]);

export const isInCampagnaEstesa = (now = DateTime.local()) => {
    const thisYear = now.year;
    const inizioCampagnaInformale = DateTime.fromObject({
        year: thisYear,
        month: 8,
        day: 1,
    });
    const fineCampagnaInformale = DateTime.fromObject({
        year: thisYear,
        month: 9,
        day: 11,
    });
    const periodoCampagnaInformale = Interval.fromDateTimes(
        inizioCampagnaInformale,
        fineCampagnaInformale
    );
    if (periodoCampagnaInformale.contains(now)) {
        return true;
    }
    return false;
};

function calcolaDataInizioCampagnaInformale(now = DateTime.local()) {
    var inizioCampagna = null;
    //const now = DateTime.local();
    const thisYear = now.year;
    const lastYear = now.year - 1;
    const inizioCampagnaInformale = DateTime.fromObject({
        year: thisYear,
        month: 8,
        day: 1,
    });
    const fineCampagnaInformale = DateTime.fromObject({
        year: thisYear,
        month: 9,
        day: 11,
    });
    const periodoCampagnaInformale = Interval.fromDateTimes(
        inizioCampagnaInformale,
        fineCampagnaInformale
    );
    if (periodoCampagnaInformale.contains(now)) {
        inizioCampagna = DateTime.fromObject({
            year: lastYear,
            month: 8,
            day: 1,
        });
    } else {
        inizioCampagna = DateTime.fromJSDate(calcolaDataInizioCampagna());
    }
    return inizioCampagna.toJSDate();
}

// Sep 12, 2019
// Dec 31, 2019
// September 10, 2020
/*
 * il default è un oggetto vuoto
 * e se è vuoto, o un parametro è vuoto,
 * il default dei parametri è quello specificato
 */
function calcolaDataInizioCampagna({
    now = DateTime.local(),
    format = "JS",
} = {}) {
    let inizioCampagna = null;
    // const now = DateTime.local();
    const thisYear = now.year;
    const lastYear = now.year - 1;
    const nextYear = now.year + 1;
    const primaParteAnnoInCorso = Interval.fromDateTimes(
        DateTime.local(lastYear, 12, 31),
        DateTime.local(thisYear, 8, 1)
    );
    const secondaParteAnnoInCorso = Interval.fromDateTimes(
        DateTime.local(thisYear, 7, 31),
        DateTime.local(nextYear, 1, 1) // Dec 31, 2019
    );
    if (primaParteAnnoInCorso.contains(now)) {
        inizioCampagna = DateTime.fromObject({
            year: lastYear,
            month: 8,
            day: 1,
        });
    } else if (secondaParteAnnoInCorso.contains(now)) {
        inizioCampagna = DateTime.fromObject({
            year: thisYear,
            month: 8,
            day: 1,
        });
    }
    const inCampagnaEstesa = isInCampagnaEstesa()
    if (inCampagnaEstesa && !isCampagnaAziendaleRiaperta.value) {
        inizioCampagna = inizioCampagna.minus({year: 1}) 
    }
    return format == "JS" ? inizioCampagna.toJSDate() : inizioCampagna;
}

// Sep 12, 2019
function calcolaDataFineCampagna({
    now = DateTime.local(),
    format = "JS",
} = {}) {
    let fineCampagna = null;
    //const now = DateTime.local();
    const thisYear = now.year;
    const nextYear = now.year + 1;
    const lastYear = now.year - 1;
    const primaParteAnnoInCorso = Interval.fromDateTimes(
        DateTime.local(lastYear, 12, 31),
        DateTime.local(thisYear, 8, 1)
    );
    const secondaParteAnnoInCorso = Interval.fromDateTimes(
        DateTime.local(thisYear, 7, 31),
        DateTime.local(nextYear, 1, 1) // Dec 31, 2019
    );
    if (primaParteAnnoInCorso.contains(now)) {
        fineCampagna = DateTime.local(thisYear, 7, 31);
    } else if (secondaParteAnnoInCorso.contains(now)) {
        fineCampagna = DateTime.local(nextYear, 7, 31); // August 05, 2020
    }
    if (isInCampagnaEstesa() && !isCampagnaAziendaleRiaperta.value) {
        fineCampagna = fineCampagna.minus({year: 1})
        // fineCampagna = DateTime.local(thisYear, 9, 10)
    }
    return format == "JS" ? fineCampagna.toJSDate() : fineCampagna;
}

// Mar 17, 2020
// September 02, 2020
// https://stackoverflow.com/questions/11796093/is-there-a-way-to-provide-named-parameters-in-a-function-call-in-javascript
function calcolaDataInizioCampagnaPrecedente({
    now = DateTime.local(),
    format = "JS",
} = {}) {
    let dataFineCampagnaPrecedente = calcolaDataFineCampagnaPrecedente({
        now: now,
        format: "DT",
    })
        .minus({ year: 1 })
        .plus({ day: 1 });

    // if (isInCampagnaEstesa() && !isCampagnaAziendaleRiaperta.value) {
    //     dataFineCampagnaPrecedente.minus({year: 1}) 
    // }
    
    if (format == "JS") {
        return dataFineCampagnaPrecedente.toJSDate();
    } else if (format == "DT") {
        return dataFineCampagnaPrecedente;
    }
}

function calcolaDataFineCampagnaPrecedente({
    now = DateTime.local(),
    format = "JS",
} = {}) {
    let fineCampagna = null;
    const thisYear = now.year;
    const nextYear = now.year + 1;
    const lastYear = now.year - 1;
    // intervallo dal 1/1 al 31/7 (inclusi)
    const primaParteAnnoInCorso = Interval.fromDateTimes(
        DateTime.fromObject({ year: lastYear, month: 12, day: 31 }),
        DateTime.fromObject({ year: thisYear, month: 8, day: 1 })
    );
    // intervallo dal 1/8 al 31/12 (inclusi)
    const secondaParteAnnoInCorso = Interval.fromDateTimes(
        DateTime.fromObject({ year: thisYear, month: 7, day: 31 }),
        DateTime.fromObject({ year: nextYear, month: 1, day: 1 }) // Dec 31, 2019
    );
    if (primaParteAnnoInCorso.contains(now)) {
        fineCampagna = DateTime.fromObject({
            year: lastYear,
            month: 7,
            day: 31,
        });
    } else if (secondaParteAnnoInCorso.contains(now)) {
        fineCampagna = DateTime.fromObject({
            year: thisYear,
            month: 7,
            day: 31,
        });
    }
    if (isInCampagnaEstesa() && !isCampagnaAziendaleRiaperta.value) {
        fineCampagna = fineCampagna.minus({year: 1}) 
    }
    return format == "JS" ? fineCampagna.toJSDate() : fineCampagna;
}

export {
    calcolaDataInizioCampagnaInformale,
    calcolaDataInizioCampagna,
    calcolaDataFineCampagna,
    calcolaDataFineCampagnaPrecedente,
    calcolaDataInizioCampagnaPrecedente,
};
