<template>
    <b-row class="border p-1 mb-2 text-muted">
        <b-col md=4 sm=12>
            Categorie operatori
        </b-col>
        <b-col md=2 sm=6 class="text-nowrap" v-b-tooltip.hover title="Operatore amministrativo del cliente: può creare operatori del cliente e operatori aziendali. Non può operare sulle aziende.">
            <font-awesome-icon
                class="mr-1"
                icon="circle"
                style="color: purple;"
            />Amministrativo
        </b-col>
        <b-col md=2 sm=6 class="text-nowrap" v-b-tooltip.hover title="Operatore del cliente: può operare sulle aziende.">
            <font-awesome-icon
                class="mr-1"
                icon="circle"
                style="color: blue;"
            />Cliente
        </b-col>
        <b-col md=2 sm=6 class="text-nowrap" v-b-tooltip.hover title="Operatore aziendale: può operare esclusivamente sulle aziende">
            <font-awesome-icon
                class="mr-1"
                icon="circle"
                style="color: red;"
            />Aziendale
        </b-col>            
        <b-col md=2 sm=6 class="text-nowrap" v-b-tooltip.hover title="Utente API">
            <font-awesome-icon
                class="mr-1"
                icon="circle"
                style="color: orange;"
            />API
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "legenda-operatori",
}
</script>

<style scoped>
.admin {
    color: purple;
}
</style>



