import ConfirmEmail from '../components/login/ConfirmEmail.vue';
import ConfirmEmailDone from '../components/login/ConfirmEmailDone.vue';
import PasswordReset from '../components/login/PasswordReset.vue';
import PasswordResetDone from '../components/login/PasswordResetDone.vue';
import PasswordResetConfirm from '../components/login/PasswordResetConfirm.vue';
import PasswordResetConfirmDone from '../components/login/PasswordResetConfirmDone.vue';

export const password_routes = [
  {
    path: '/dj-rest-auth/registration/account-confirm-email/:key',
    name: 'confirm-email',
    component: ConfirmEmail,
    meta: { title: 'Conferma email - e-Sian', requiresAuth: false, layout: "no-side-bar", },
  },
  {
    path: '/dj-rest-auth/registration/account-confirm-email/done',
    name: 'confirm-email-done',
    component: ConfirmEmailDone,
    meta: { title: 'Conferma email riuscita - e-Sian', requiresAuth: false, layout: "no-side-bar", },
  },
  {
    /*
     * viene chiesta la mail dell'utente per cui si vuole resettare la password
     */
    path: '/password/reset/',
    name: 'password-reset',
    component: PasswordReset,
    meta: { title: 'Reimpostazione password - e-Sian', requiresAuth: false, layout: "no-side-bar", },
  },
  {
    /*
     * si comunica all'utente che il reset della password è riuscito
     */
    path: '/password/reset/done/:from_contract?/:email?',
    name: 'password-reset-done',
    component: PasswordResetDone,
    meta: { title: 'Reimpostazione password riuscita - e-Sian', requiresAuth: false, layout: "no-side-bar", },
  },
  {
   /* 
    * NON RICHIEDE AUTENTICAZIONE
    * questa url viene ricevuta direttamente dalla dalla mail inviata da django. 
    * la url viene modificata appositamente nel serializzatore 
    * "users.api.serializers.CustomPasswordResetSerializer" e messa nella mail inviata all'utente
    * In ricezione del POST viene superato django perché la url non comincia per /api/
    * Apr 04, 2020
    */
    path: '/frontend/password/reset/confirm/:uidb64/:token/',
    name: 'password-reset-confirm',
    component: PasswordResetConfirm,
    meta: { title: 'Reimpostazione password - e-Sian', requiresAuth: false, layout: "no-side-bar", },
  },
  {
    path: '/password/reset/confirm/done',
    name: 'password-reset-confirm-done',
    component: PasswordResetConfirmDone,
    meta: { title: 'Reimpostazione password positiva - e-Sian', requiresAuth: false, layout: "no-side-bar", },
  },
]
