// Apr 20, 2020
import axios from "axios";
import { useMutations, useState } from "@u3u/vue-hooks";
import { Validator } from "vee-validate";

import { showAlert } from "../../../utils/eSianUtils.js";
import { checkAddAzienda } from "../utils.js";


export function editAzienda(azienda, context) {
  const { setAction } = useMutations("amministrazioneModule", ["setAction"]);
  retrieveAzienda(azienda.id, context);
  setAction("edit");
}


export function retrieveAzienda(id, context) {
  const { setForm } = useMutations("amministrazioneModule", ["setForm"]);
  axios
    .get("/api/azienda/" + id)
    .then((response) => {
      if (response.data.categorie.includes("PMI")) {
        response.data.piccola_media = true
      } else {
        response.data.piccola_media = false
      }      
      response.data.operatori_cli = response.data.operatori.filter(x=>x.categorie.includes("CLIENTE"))
      setForm(response.data);
      context.root.$bvModal.show("aziendaModal");
    })
    .catch((error) => {
      showAlert({ error: error }, context.root);
    });
}


export function useLoadOptionsAziende(root, pageNum = 1) {
  const { aziendeElencoQuery } = useState("amministrazioneModule", ["aziendeElencoQuery"]);  
  const { setOptionsAzienda, setNumberOfAzienda } = useMutations("amministrazioneModule", [
    "setOptionsAzienda", "setNumberOfAzienda"
  ]);
  return axios.get(`/api/azienda?page=${pageNum}&search=${aziendeElencoQuery.value}`)
    .then((response) => { 
      setOptionsAzienda(response.data.results);
      setNumberOfAzienda(response.data.count);
      //return response
    })
    .catch((error) => showAlert({ error: error }, root))
}


export function useLoadOptionsComuni(root) {
  const { setShowOverlayComuni} = useMutations("amministrazioneModule", ["setShowOverlayComuni"]);
  const { setTermineInitOptionsComune} = useMutations("loginModule", ["setTermineInitOptionsComune"]);
  // const { optionsComune } = useState("soggettoModule", ["optionsComune"])
  const { setOptionsComune } = useMutations("soggettoModule", [
    "setOptionsComune",
  ]);
  if (!localStorage.getItem("optionsComune")) {
    setShowOverlayComuni(true)
    return axios.get("/api/codifica/comuni").then((response) => {
      localStorage.setItem("optionsComune", JSON.stringify(response.data));
      setOptionsComune(response.data);
      setTermineInitOptionsComune(true);
      setShowOverlayComuni(false)
    }).catch((error) => {
      setShowOverlayComuni(false)
      showAlert({ error: error }, root);
    });

  } else {
  // } else if (optionsComune.value.length == 0) {
    setOptionsComune(JSON.parse(localStorage.getItem("optionsComune")))
    setTermineInitOptionsComune(true);
  }
}


export function editStabilimento(stabilimento, context) {
  const { setStabilimentoAction } = useMutations("amministrazioneModule", [
    "setStabilimentoAction",
  ]);
  retrieveStabilimento(stabilimento.id, context);
  setStabilimentoAction("edit");
}


export function retrieveStabilimento(id, context) {
  const { optionsComune } = useState("soggettoModule", ["optionsComune"]);
  const { setStabilimentoForm } = useMutations("amministrazioneModule", [
    "setStabilimentoForm",
  ]);
  axios
    .get("/api/stabilimenti/" + id)
    .then((response) => {
      response.data.comune = optionsComune.value.find(
        (x) => x.id == response.data.comune
      );
      setStabilimentoForm(response.data);
      context.root.$bvModal.show("stabilimentoModal");
    })
    .catch((error) => {
      showAlert({ error: error }, context.root);
    });
}


export function addStabilimento(azienda_id, context) {
  const {
    setStabilimentoForm,
    setStabilimentoAction,
  } = useMutations("amministrazioneModule", [
    "setStabilimentoForm",
    "setStabilimentoAction",
  ]);
  setStabilimentoAction("add");
  setStabilimentoForm({
    azienda: azienda_id,
    icqrf: null,
    descrizione: "",
    indirizzo: null,
    cap: null,
    comune: null,
    provincia: null,
    note: null,
  });
  context.root.$bvModal.show("stabilimentoModal");
}


export function addAzienda(context) {
  const { setAddAziendaSpinner } = useMutations("amministrazioneModule", ["setAddAziendaSpinner"]);

  // necessario per il data entry
  useLoadOptionsComuni(context.root)

  setAddAziendaSpinner(true)
  checkAddAzienda(context).then((result) => {
    if (result) {
      const { utente } = useState("loginModule", ["utente"]);
      const { setForm, setAction } = useMutations("amministrazioneModule", [
        "setForm",
        "setAction",
      ]);
      setAction("add");
      setForm({
        solo_mvv: false,
        piccola_media: false,
        ragione_sociale: "",
        cuaa: null,
        check_credentials: true,
        ambiente_operativo_esercizio: false,
        sian_username: null,
        sian_password: null,
        sian_username_esercizio: null,
        sian_password_esercizio: null,
        indirizzo: null,
        cap: null,
        comune: null,
        note: null,
        stabilimenti: [],
        gruppo: null,
        // posizione dell'azienda nel gruppo aziendale
        // holding, controllata, partecipata
        posizione: [],
        //
        servizi_ammessi: utente.value.cliente.servizi_ammessi,
        categorie: [],
        operatori: [],
        operatori_cli: [],
        concessionario: utente.value.concessionario.id,
        cliente: utente.value.cliente.id,
      });
      context.root.$bvModal.show("aziendaModal");
    } else {
      showAlert(
        { message: "Numero massimo aziende raggiunto", variant: "warning" },
        context.root
      );
    }
    setAddAziendaSpinner(false)
  });
}


export function deleteAzienda(azienda, context) {
  let messaggio =
    'Conferma cancellazione azienda "' +
    azienda.ragione_sociale +
    '" (' +
    azienda.cuaa +
    ")?";
  context.root.$bvModal
    .msgBoxConfirm(messaggio, {
      okVariant: "danger",
      cancelVariant: "light",
      okTitle: "Cancella",
      cancelTitle: "Annulla",
      footerClass: "p-2",
      centered: true,
    })
    .then((value) => {
      if (value) {
        axios
          .delete("/api/azienda/" + azienda.id)
          .then(() => {
            useLoadOptionsAziende(context);
          })
          .catch((error) => {
            showAlert({ error: error }, context.root);
          });
      }
    })
    .catch((error) => {
      showAlert({ error: error }, context.root);
    });
}


export function deleteStabilimento(stabilimento, context) {
  let messaggio =
    'Conferma cancellazione stabilimento "' +
    stabilimento.descrizione +
    '" (' +
    stabilimento.icqrf +
    ")?";
  context.root.$bvModal
    .msgBoxConfirm(messaggio, {
      okVariant: "danger",
      cancelVariant: "light",
      okTitle: "Cancella",
      cancelTitle: "Annulla",
      footerClass: "p-2",
      centered: true,
    })
    .then((value) => {
      if (value) {
        axios
          .delete("/api/stabilimenti/" + stabilimento.id)
          .then(() => {
            useLoadOptionsAziende(context);
          })
          .catch((error) => {
            showAlert({ error: error }, context.root);
          });
      }
    })
    .catch((error) => {
      showAlert({ error: error }, context.root);
    });
}


export async function onSubmitSearch(query, context) {
  const { setAziendeElencoQuery } = useMutations("amministrazioneModule", [
    "setAziendeElencoQuery",
  ]);
  setAziendeElencoQuery(query)
  useLoadOptionsAziende(context)
}


export function onResetSearch(search_form, context) {
  const { setAziendeElencoQuery } = useMutations("amministrazioneModule", [
    "setAziendeElencoQuery",
  ]);
  search_form.value.query = "";
  setAziendeElencoQuery("");
  useLoadOptionsAziende(context);
}


export function onClose(context) {
  context.root.$bvModal.hide("aziendaModal");
}


export function onCloseStabilimento(context) {
  context.root.$bvModal.hide("stabilimentoModal");
}


export async function onSubmit(context) {
  const { action } = useState("amministrazioneModule", ["action"]);
  const { setLoading } = useMutations("amministrazioneModule", ["setLoading"]);

  const isValid = await context.refs.observer.validate();
  if (!isValid) {
    return;
  }
  setLoading(true)

  verificaCredenzialiSian(context)
    .then((response) => {
      if (response != null && (response == 'OK' || response.status == 200)) {

        sendAzienda(context)
          .then(() => {
            showAlert({ message: action.value == "add" ? "Azienda creata" : "Azienda aggiornata", variant: "success" }, context.root);

            useLoadOptionsAziende(context);

            context.root.$bvModal.hide("aziendaModal");
          })
          .catch((error) => {
            showAlert({ error: error }, context.root)
          })
          .finally(() => {
            setLoading(false)
          });

      } else {
        setLoading(false)
        return;
      }
    })
    .catch((error) => {
      showAlert({ error: error }, context.root)
    })
    .finally(() => {
      setLoading(false)
    });
}


export async function verificaCredenzialiSian(context) {
  const { form } = useState("amministrazioneModule", ["form"]);
  //if (form.value.check_credentials) {
    let payload = "";
    if (form.value.ambiente_operativo_esercizio) {
      payload = {
        solo_mvv: form.value.solo_mvv,
        cuaa: form.value.cuaa,
        username: form.value.sian_username_esercizio,
        password: form.value.sian_password_esercizio,
        ambiente_operativo: "esercizio",
      };
    } else {
      payload = {
        solo_mvv: form.value.solo_mvv,
        cuaa: form.value.cuaa,
        username: form.value.sian_username,
        password: form.value.sian_password,
        ambiente_operativo: "prova",
      };
    }
    const path = `/api/validate/sian_credentials`;
    try {
      return await axios.post(path, payload);
    } catch (error) {
      showAlert({ error: error }, context.root);
    }
  // } else {
  //   return 'OK';
  // }
}


export async function onSubmitStabilimento(context) {
  const isValid = await context.refs.observer.validate();
  if (!isValid) {
    return;
  }
  sendStabilimento(context).then(() => {
    useLoadOptionsAziende(context);
    context.root.$bvModal.hide("stabilimentoModal");
  });
}


export async function sendAzienda(context) {
  const { form, action } = useState("amministrazioneModule", [
    "form",
    "action",
  ]);
  // copia l'oggetto per moficare il nuovo oggetto senza modifcare il vecchio 
  let payload = JSON.parse(JSON.stringify(form.value)) // December 28, 2020 
  if (payload.servizi_ammessi == null) {
    delete payload.servizi_ammessi
  } else {
    let servizi_ammessi = []
    payload.servizi_ammessi.forEach((servizio) => {
      servizi_ammessi.push(servizio.id)
    })
    payload.servizi_ammessi = servizi_ammessi
  }  
  if (payload.piccola_media) {
    if (!payload.categorie.includes("PMI")) {
      payload.categorie.push("PMI")
    }
  } else {
    // remove PMI
    payload.categorie.splice(payload.categorie.indexOf("PMI"), 1);
  }

  // operatore = eventuale operatore del cliente abilitato ad operare sull'azienda
  // aggiunta della categoria "staff" e al set di operatori aziendali SUL SERVER 
  // (serializzatore azienda)
  if (action.value == "add") {
    if (payload.operatori_cli.length > 0) {
      let operatori = payload.operatori.concat(payload.operatori_cli)
      payload.operatori = operatori;
    } 
  } else if (action.value == "edit") {
    // rimuove gli operatori del cliente esistenti dalla lista degli operatori dell'azienda
    let operatori = []
    operatori = payload.operatori.filter(x=>!x.categorie.includes("CLIENTE"))
    payload.operatori = operatori.concat(payload.operatori_cli);
    // } 
  }
  // operatori aziendali
  // (sostituisce l'oggetto con l'id)
  if (payload.operatori != null) {
    if (payload.operatori.length > 0) {
      let operatori = payload.operatori.map((x) => x.id);
      payload.operatori = operatori;
    } else {
      delete payload.operatori;
    }
  }
  if (payload.gruppo != null) {
    payload.gruppo = payload.gruppo.id
  }
  if (payload.comune != null) {
    payload.provincia = payload.comune.provincia.codice;
    payload.comune = payload.comune.id;
  }
  if (action.value == "edit") {
    // non si cambia né cliente, né concessionario
    delete payload.cliente;
    delete payload.concessionario;
  }
  try {
    if (action.value == "add") {
      return await axios.post("/api/azienda", payload);
    } else if (action.value == "edit") {
      const response = await axios.patch("/api/azienda/" + payload.id, payload);
      return response
    }
  } catch (error) {
    throw error
  }
}


export async function sendStabilimento(context) {
  const {
    stabilimentoForm,
    stabilimentoAction,
  } = useState("amministrazioneModule", [
    "stabilimentoForm",
    "stabilimentoAction",
  ]);
  let payload = JSON.parse(JSON.stringify(stabilimentoForm.value)) // December 28, 2020 
  if (payload.comune != null) {
    payload.provincia = payload.comune.provincia.id;
    payload.comune = payload.comune.id;
  }
  try {
    if (stabilimentoAction.value == "add") {
      return await axios.post("/api/stabilimenti", payload);
    } else if (stabilimentoAction.value == "edit") {
      return await axios.patch("/api/stabilimenti/" + payload.id, payload);
    }
  } catch (error) {
    showAlert({ error: error }, context.root);
  }
}


export function _loadOptionsStabilimenti(context) {
  const { setOptionsStabilimento } = useMutations("amministrazioneModule", [
    "setOptionsStabilimento",
  ]);
  loadOptionsStabilimento(context).then((response) => {
    setOptionsStabilimento(response.data.results);
  });
}

export async function loadOptionsStabilimento(context) {
  try {
    return await axios.get("/api/stabilimenti");
  } catch (error) {
    showAlert({ error: error }, context.root);
  }
}

export function formatter(value) {
  return value.toUpperCase();
}

export function customComuneLabel(props) {
  const { descrizione, provincia } = { ...props };
  if (descrizione != null && provincia != null) {
    return `${descrizione} (${provincia.sigla})`;
  }
  return "";
}


export function extendValidator() {
  //var paramNames = ["ambiente_operativo", ];
  //Validator.extend(
  //  "verificaCredenzialiSian",
  //  {
  //    validate: verificaCredenzialiSian,
  //    getMessage: (field, params, data) => {
  //      return data != undefined ? data.message : ""; // Aug 13, 2019
  //    }
  //  },
  //  {
  //    immediate: false,
  //    paramNames
  //  }
  //);

  var paramNames = ["action"];
  Validator.extend(
    "isIcqrfUnico",
    {
      validate: isIcqrfUnico,
      getMessage: (field, params, data) => {
        return data != undefined ? data.message : ""; // Aug 13, 2019
      },
    },
    {
      immediate: false,
      paramNames,
    }
  );

  var paramNames = ["action"];
  Validator.extend(
    "isCuaaUnico",
    {
      validate: isCuaaUnico,
      getMessage: (field, params, data) => {
        return data != undefined ? data.message : ""; // Aug 13, 2019
      },
    },
    {
      immediate: false,
      paramNames,
    }
  );  

  var paramNames = ["action"];
  Validator.extend(
    "isCuaaClienteUnico",
    {
      validate: isCuaaClienteUnico,
      getMessage: (field, params, data) => {
        return data != undefined ? data.message : ""; // Aug 13, 2019
      },
    },
    {
      immediate: false,
      paramNames,
    }
  );  

  var paramNames = ["action"];
  Validator.extend(
    "isClienteUnico",
    {
      validate: isClienteUnico,
      getMessage: (field, params, data) => {
        return data != undefined ? data.message : ""; // Aug 13, 2019
      },
    },
    {
      immediate: false,
      paramNames,
    }
  );
}

//export const verificaCredenzialiSian = (value, args) => {
//  const path = `/api/validate/sian_credentials/${value}`;
//  return axios
//    .get(path)
//    .then((response) => {
//      return {
//        valid: response.data.valid,
//        data: {
//          message: response.data.message
//        }
//      }
//    })
//    .catch(error => {
//      console.log(error);
//    });
//}


export const isCuaaClienteUnico = (value, args) => {
  if (value != null && value != "") {
    if (args.action == "add") {
      const path = `/api/validate/cuaa_cliente_unico/${value}`;
      return axios.get(path).then((response) => {
        return {
          valid: response.data.valid,
          data: {
            message: response.data.message,
          },
        };
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      return {
        valid: true,
        data: {
          message: "",
        },
      };
    }
  } else {
    return {
      valid: true,
      data: {
        message: "",
      },
    };  
  }
};


export const isCuaaUnico = (value, args) => {
  if (value != null && value != "") {
    if (args.action == "add") {
      const path = `/api/validate/cuaa_unico/${value}`;
      return axios
        .get(path)
        .then((response) => {
          return {
            valid: response.data.valid,
            data: {
              message: response.data.message,
            },
          };
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return {
        valid: true,
        data: {
          message: "",
        },
      };
    }
  } else {
    return {
      valid: true,
      data: {
        message: "",
      },
    };  
  }
};


export const isClienteUnico = (value, args) => {
  if (value != null && value != "") {
    if (args.action == "add") {
      const path = `/api/validate/cliente_unico/${value}`;
      return axios
        .get(path)
        .then((response) => {
          return {
            valid: response.data.valid,
            data: {
              message: response.data.message,
            },
          };
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return {
        valid: true,
        data: {
          message: "",
        },
      };
    }
  } else {
    return {
      valid: true,
      data: {
        message: "",
      },
    };  
  }
};


export const isIcqrfUnico = (value, args) => {
  // if (value != args.valore_iniziale) {
  if (value != null && value != "") {
    if (args.action == "add") {
      const path = `/api/validate/icqrf_unico/${value}`;
      return axios
        .get(path)
        .then((response) => {
          return {
            valid: response.data.valid,
            data: {
              message: response.data.message,
            },
          };
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return {
        valid: true,
        data: {
          message: "",
        },
      };
    }
  } else {
    return {
      valid: true,
      data: {
        message: "",
      },
    };  
  }
};


export async function getCaricoCliente(root) {
  const { utente } = useState("loginModule", ["utente"]);
  const { setCaricoCliente } = useMutations("amministrazioneModule", [
    "setCaricoCliente",
  ]);
  try {
    // console.log("getCaricoCliente (started)") 
    // var t0 = performance.now();
    const response = await axios.get(`/api/users/get_carico_cliente/${utente.value.id}`);
    setCaricoCliente(response.data);
    // var t1 = performance.now();
    // console.log("getCaricoCliente (completed): ", (t1 - t0) / 1000)
    return response
  } catch (error) {
    showAlert({ error: error }, root);
  }
}
