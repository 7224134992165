

<!-- In pratica "AperturaCampagna" chiama la componente ripristina con il
parametro "apertura" e le passa uno "slot" (qui sotto).  -->
<ripristina :apertura="true" >

    <!-- <pre>1. isCampagnaAziendaleRiaperta {{ isCampagnaAziendaleRiaperta }}</pre> -->
    <!-- <pre>statoStabilimenti.stabilimenti_aperti {{ statoStabilimenti.stabilimenti_aperti }}</pre> -->

    <!-- slot content -->
    <h4>Apertura campagna aziendale e-Sian</h4>

    <p class="font-weight-light">
        {{ aziendaDaRipristinare != null ? aziendaDaRipristinare.ragione_sociale + ' (' + aziendaDaRipristinare.cuaa + '). ' : ''  }} <b>Tutti gli stabilimenti</b>.
    </p>

    <p class="lead">L'apertura della campagna <b>aziendale</b> trasferisce dal SIAN agli archivi <b>e-Sian</b> le operazioni di giacenza iniziale effettuate in automatico alla chiusura della campagna di ciascun stabilimento aziendale.</p>

    <p v-if="!isCampagnaAziendaleRiaperta">
      Stato della campagna per ciascuno <b>stabilimento</b>:
      <ul>
          <li v-for="stabilimento in statoStabilimenti.stato_stabilimenti" :key="stabilimento.icqrf">
                {{ statoStabilimento(stabilimento) }}
          </li>
      </ul>
    </p>

    <div v-if="!isCampagnaAziendaleRiaperta && statoStabilimenti.stabilimenti_aperti != 0">
      <p>
        L'apertura della campagna aziendale può essere effettuata <b>solo</b> quando la campagna, per <b>tutti</b> gli stabilimenti aziendali, è stata chiusa (cioè la richiesta di chiusura, per ciascuno stabilimento aziendale, è stata trasmessa al SIAN e verificata).
      </p>
    </div>

    <b-alert show v-if="isCampagnaAziendaleRiaperta" class="mb-3">
      <p>
        La campagna aziendale e-Sian è stata aperta il giorno {{ dataCampagnaAziendaleRiaperta }}.
      </p>
      <p show v-if="nuovaCampagnaForzata">
        <b>NOTA</b>. La campagna aziendale è stata riaperta forzatamente.
      </p>
    </b-alert>

    <b-alert show variant="warning" class="mt-3" v-if="!isCampagnaAziendaleRiaperta && statoStabilimenti.stabilimenti_aperti == 0">
      <p>Campagna <b>aziendale</b> corrente:
        {{
          dataInizioCampagna.toLocaleDateString("it-IT")
        }}-{{
          dataFineCampagna.toLocaleDateString("it-IT")
        }}.
      </p> 

      <p>Procedere all'apertura della campagna aziendale per allineare i dati e-Sian con quelli generati automaticamente dal SIAN alla chiusura della campagna precedente di ciascun stabilimento.</p> 
    </b-alert>

</ripristina>
