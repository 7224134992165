
<div>
    <!-- <h4 class="mt-1 mb-3">Avvisi</h4> -->

    <b-alert
        show
        type="info"
        class="mb-4"
        v-if="!preferenze.checkAvvisiMvv"
    >
        Avete scelto nelle preferenze di non verificare automaticamente ad
        ogni cambio di azienda e stabilimento gli avvisi MVV eventualmente
        presenti sul SIAN. Per controllare gli avvisi MVV
        <b>di tutte le aziende in gestione</b> in qualsiasi momento, potete
        usare il bottone in alto a sinistra in questa finestra (refresh).
    </b-alert>

    <!------------------------------- avvisi dell'amministratore ---------------------------->
    <b-tabs style="height: 650px">
        <b-tab :active="avvisi.length > 0" :disabled="avvisi.length == 0">
            <template v-slot:title>
                <b-badge style="vertical-align: text-bottom" class="mr-1">{{
                    avvisi.length
                }}</b-badge
                >Avvisi
            </template>
            <div v-if="avvisi.length > 0" class="container mt-3">
                <template v-for="(avviso, i) in avvisi">
                    <b-card
                        class="mb-3"
                        :key="avviso.id"
                        :id="'avviso-' + i"
                    >
                        <b-card-title
                            :class="avvisoTextClass(avviso.variant)"
                            >{{ avviso.titolo }}</b-card-title
                        >
                        <!-- <b-card-sub-title>{{ avviso.sottotitolo}}</b-card-sub-title> -->

                        <b-card-text>
                            <span
                                style="white-space: pre-wrap"
                                v-html="avviso.testo"
                            ></span>
                        </b-card-text>

                        <!-- <b-card-text> -->
                        <!--     <span style="white-space: pre-wrap;">{{avviso.testo}}</span> -->
                        <!-- </b-card-text> -->

                        <template #footer>
                            <b-link
                                @click="chiudiAvviso(avviso, i)"
                                class="card-link small"
                                v-show="avviso.dismissible"
                                :disabled="!avviso.dismissible"
                            >
                                <font-awesome-icon
                                    icon="times"
                                    class="mr-2"
                                />
                            </b-link>
                            <small class="text-muted">{{
                                avviso.data | DateToLocaleString
                            }}</small>
                            <small class="text-muted ml-1"
                                >({{ avviso.operatori }})</small
                            >
                            <!-- <small class="text-muted ml-5">({{ avviso.tipo }}-{{ avviso.id }}{{ avviso.system ? '-SYS' : ''}})</small> -->
                        </template>
                    </b-card>
                </template>
                <!-- </b-card-group> -->
            </div>
            <div v-else>
                <p class="lead text-center mt-4">Nessun avviso presente</p>
                <p v-for="n in 6" :key="n">&nbsp;</p>
            </div>
        </b-tab>

        <!------------------------------- avvisi di sistema TRASMISSIONI ---------------------------->
        <b-tab>
            <template v-slot:title>
                <b-spinner
                    small
                    class="mr-1"
                    v-show="loading.avvisiTrasmissioni"
                    style="vertical-align: middle"
                />
                <b-badge
                    v-show="!loading.avvisiTrasmissioni"
                    style="vertical-align: text-bottom"
                    class="mr-1"
                    >{{ avvisiDiSistemaTrasmissioni.length }}</b-badge
                >Trasmissioni
            </template>
            <div class="container scrollable">
                <div
                    class="table-responsive mt-3"
                    v-if="avvisiDiSistemaTrasmissioni.length > 0"
                >
                    <TabellaAvvisiTrasmissioni
                        :avvisiDiSistemaTrasmissioni="
                            avvisiDiSistemaTrasmissioni
                        "
                    />
                </div>
                <div
                    v-if="
                        avvisiDiSistemaTrasmissioni.length == 0 &&
                        !loading.avvisiTrasmissioni
                    "
                >
                    <p class="lead text-center mt-4">
                        Nessun avviso trasmissioni presente
                    </p>
                    <!-- <p v-for="n in 6" :key="n">&nbsp;</p> -->
                </div>
                <div
                    v-if="
                        avvisiDiSistemaTrasmissioni.length == 0 &&
                        loading.avvisiTrasmissioni
                    "
                >
                    <p class="lead text-center mt-4">
                        Controllo avvisi trasmissioni in corso...
                    </p>
                    <!-- <p v-for="n in 12" :key="n">&nbsp;</p> -->
                </div>
            </div>
        </b-tab>

        <!------------------------------- avvisi MVV ---------------------------->
        <!-- :disabled="!preferenze.checkAvvisiMvv" -->
        <b-tab
            v-if="
                checkUserAccess(utente, { servizio: 'MVV' }) &&
                categoriaUtente(utente) != 'mvvonly'
            "
        >
            <template v-slot:title>
                <b-spinner
                    small
                    class="mr-1"
                    v-show="loading.avvisiMVV"
                    style="vertical-align: middle"
                />
                <!-- v-show="preferenze.checkAvvisiMvv && !loading.avvisiMVV" -->
                <b-badge
                    v-show="!loading.avvisiMVV"
                    style="vertical-align: text-bottom"
                    class="mr-1"
                    >{{ avvisiDiSistemaMVV.length }}</b-badge
                >MVV da ricevere
            </template>
            <div class="container scrollable">
                <div
                    class="table-responsive mt-3"
                    v-if="avvisiDiSistemaMVV.length > 0"
                >
                    <TabellaAvvisiMvv
                        :avvisiDiSistemaMVV="avvisiDiSistemaMVV"
                    />
                </div>
                <div
                    v-if="
                        avvisiDiSistemaMVV.length == 0 && !loading.avvisiMVV
                    "
                >
                    <p class="lead text-center mt-4">
                        Nessun avviso MVV presente
                    </p>
                    <p v-for="n in 6" :key="n">&nbsp;</p>
                </div>
                <div
                    v-if="
                        avvisiDiSistemaMVV.length == 0 && loading.avvisiMVV
                    "
                >
                    <p class="lead text-center mt-4">
                        Controllo avvisi MVV in corso...
                    </p>
                    <p v-for="n in 6" :key="n">&nbsp;</p>
                </div>
            </div>
        </b-tab>

        <!------------------------------- avvisi DOCUMENTI (ARCHIVIAZIONE) ---------------------------->
        <b-tab
            v-if="
                checkUserAccess(utente, {
                    servizio: 'ARC',
                    azienda: currentAzienda,
                }) && categoriaUtente(utente) != 'mvvonly'
            "
            title="Archiviazione"
        >
            <template v-slot:title>
                <b-spinner
                    small
                    class="mr-1"
                    v-show="loading.avvisiArchiviazione"
                    style="vertical-align: middle"
                />
                <b-badge
                    v-show="!loading.avvisiArchiviazione"
                    style="vertical-align: text-bottom"
                    class="mr-1"
                    >{{ avvisiDiSistemaArchiviazione.length }}</b-badge
                >Documenti
            </template>
            <div class="container scrollable">
                <div class="table-responsive mt-1">
                    <!-- <div class="table-responsive mt-1" v-if="avvisiDiSistemaArchiviazione.length > 0"> -->
                    <b-form-text>
                        Elenco documenti caricati negli ultimi tre giorni
                        dall'utente e dagli utenti aziendali delle aziende
                        di cui l'utente è operatore.
                        <b-link
                            :to="{ name: 'archivio-list' }"
                            @click="closeModaleAvvisi"
                            >Vai all'archivio documenti...</b-link
                        >
                    </b-form-text>
                    <TabellaAvvisiArchiviazione
                        :avvisiDiSistemaArchiviazione="
                            avvisiDiSistemaArchiviazione
                        "
                        class="mt-1"
                    />
                </div>
                <div
                    v-if="
                        avvisiDiSistemaArchiviazione.length == 0 &&
                        !loading.avvisiArchiviazione
                    "
                >
                    <p class="lead text-center mt-4">
                        Nessun avviso archiviazione presente
                    </p>
                    <p v-for="n in 6" :key="n">&nbsp;</p>
                </div>
                <div
                    v-if="
                        avvisiDiSistemaArchiviazione.length == 0 &&
                        loading.avvisiArchiviazione
                    "
                >
                    <p class="lead text-center mt-4">
                        Controllo avvisi archiviazione in corso...
                    </p>
                    <p v-for="n in 6" :key="n">&nbsp;</p>
                </div>
            </div>
        </b-tab>
    </b-tabs>
</div>
