import axios from "axios";
import { useMutations } from "@u3u/vue-hooks";

import { showAlert } from "../../utils/eSianUtils.js";


// rivisto il: November 29, 2021 
export const initSoggettoOptions = async (azienda_id, root) => {
    if (azienda_id != null) {
        const { setOptionsSoggetto, setOptionsTrasportatore } = useMutations("operazioneModule", [
            "setOptionsSoggetto",
            "setOptionsTrasportatore",
        ]);
        const options = {
            method: 'get',
            url: `/api/codifica/soggetto/${azienda_id}`
        }
        try {
            const response = await axios.request(options);
            let optionsSoggetto = Array()
            // let optionsTrasportatore = Array()
            optionsSoggetto = response.data.filter(x => !x.da_trasmettere && !x.da_cancellare && !x.da_completare && !x.da_correggere) 
            // optionsSoggetto = response.data.filter(x => !x.da_trasmettere && !x.da_cancellare && !x.da_completare && !x.da_correggere && x.trasportatore == false) //  .filter(x=>!x.disattivato)
            // optionsTrasportatore = response.data.filter(x => !x.da_trasmettere && !x.da_cancellare && !x.da_completare && !x.da_correggere && x.trasportatore == true) //  .filter(x=>!x.disattivato)
            setOptionsSoggetto({ optionsSoggetto: optionsSoggetto })
            // setOptionsTrasportatore({ optionsTrasportatore: optionsTrasportatore })
        } catch (error) {
            showAlert({ error: error }, root);
        }
    }
}


export const initVignaOptions = async (azienda_id, stabilimento_id, root) => {
    if (azienda_id != null && stabilimento_id != null) {
        const { setOptionsVigna } = useMutations("operazioneModule", [
            "setOptionsVigna"
        ]);
        const path = `/api/codifica/vigna/${azienda_id}/${stabilimento_id}?disattivato=3&da_cancellare=3&da_trasmettere=3&da_completare=3`;
        axios
            .get(path)
            .then(response => {
                setOptionsVigna(response.data.filter(x => !x.disattivato && !x.da_cancellare && !x.da_completare && !x.da_correggere));
            })
            .catch(error => {
                showAlert({ error: error }, root);
            });
    }
}

/*
optionsVaso
informazioni estese contengono la disattivazione persistente al ripristino 
*/
export const initVasoOptions = async (azienda_id, stabilimento_id, root) => {
    if (azienda_id != null && stabilimento_id != null) {
        const { setOptionsVaso } = useMutations("operazioneModule", [
            "setOptionsVaso"
        ]);
        const path = `/api/vaso/codifica/${azienda_id}/${stabilimento_id}?disattivato=3&da_cancellare=3&da_trasmettere=3&da_completare=3`;
        //const path = `/api/vaso/codifica/${azienda_id}/${stabilimento_id}?disattivato=3&da_cancellare=3&da_trasmettere=3&da_completare=3&informazioni_estese__disattivato=3`;
        axios
            .get(path)
            .then(response => {
                let vasi = response.data.filter(x => (x.informazioni_estese != null ? !x.informazioni_estese.disattivato : true) && !x.disattivato && !x.da_cancellare && !x.da_completare && !x.da_trasmettere && !x.da_correggere)
                setOptionsVaso(vasi);
            })
            .catch(error => {
                showAlert({ error: error }, root);
            });
    }
}
