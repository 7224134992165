const SoggettoImportazione = () => import("../components/soggetto/SoggettoImportazione.vue");
const ElencoSoggetti = () => import("../components/soggetto/ElencoSoggetti.vue");
const Soggetto = () => import("../components/soggetto/Soggetto.vue");

export const soggetto_routes = [
  {
    path: "/elenco-soggetti",
    name: "elenco-soggetti",
    component: ElencoSoggetti,
    meta: {
      title: "Elenco soggetti - e-Sian",
      requiresAuth: true
    },
    props: true
  },
  {
    path: "/elenco-trasportatori",
    name: "elenco-trasportatori",
    component: ElencoSoggetti,
    meta: {
      title: "Elenco trasportatori - e-Sian",
      requiresAuth: true
    },
    props: true
  },
  {
    path: "/modifica-soggetto/:id",
    name: "modifica-soggetto",
    component: Soggetto,
    meta: { title: "Modifica soggetto" + "- e-Sian", requiresAuth: true },
    props: true
  },
  {
    path: "/clone-soggetto/:id",
    name: "clone-soggetto",
    component: Soggetto,
    meta: { title: "Duplica soggetto" + "- e-Sian", requiresAuth: true },
    props: true
  },
  {
    path: "/soggetto-importazione",
    name: "soggetto-importazione",
    component: SoggettoImportazione,
    meta: { title: 'Importazione soggetti' + '- e-Sian', requiresAuth: true, },
    props: true,
  },
  {
    path: "/aggiungi-soggetto",
    name: "aggiungi-soggetto",
    component: Soggetto,
    meta: { title: "Aggiunta soggetto" + "- e-Sian", requiresAuth: true },
    props: true
  }
];
