<template>
  <div>
    <b-container class="mt-3">

      <h4>
        Richiesta di dichiarazione di giacenza
      </h4>
      <p class="font-weight-light">
        {{ currentAzienda != null ? currentAzienda.ragione_sociale : ''  }} ({{ currentAzienda != null ? currentAzienda.cuaa : ""  }}), stabilimento {{ currentStabilimento != null ? currentStabilimento.descrizione : '' }} ({{ currentStabilimento != null ? currentStabilimento.icqrf : '' }})
      </p>
      <p class="lead">
        Richiesta al SIAN della dichiarazione di giacenza per lo stabilimento {{ currentStabilimento != null ? currentStabilimento.icqrf : '' }}. Può essere effettuata solo dopo la chiusura della campagna dello stabilimento.
      </p>

      <b-form-row class="mt-3">
        <b-col>

          <b-form-group>
            <b-input-group-append>
              <b-btn
                @click.prevent="useDichiarazioneGiacenza"
                :disabled="loadingDichiarazioneGiacenza || !isCampagnaChiusaCurrentStabilimento"
                :variant="!isCampagnaChiusaCurrentStabilimento ? 'secondary' : 'primary'"
              >
                <template v-if="loadingDichiarazioneGiacenza">
                  Richiesta in corso...
                  <b-spinner small></b-spinner>
                </template>
                <template
                  v-else
                >Dichiarazione di giacenza</template>
              </b-btn>
            </b-input-group-append>
            <b-form-text>Verrà scaricata la dichiarazione (file PDF) e mostrati i dati della dichiarazione.</b-form-text>
          </b-form-group>

        </b-col>
      </b-form-row>

      <p class="lead mt-3">
        Visualizzazione dei dati della dichiarazione di giacenza per lo stabilimento {{ currentStabilimento != null ? currentStabilimento.icqrf : '' }} (Identificativo atto AGEA e Num. ricevuta accettazione).    
      </p>

      <b-form-row class="mt-3">
        <b-col>
          <b-form-group>
            <b-input-group-append>
              <b-btn
                @click.prevent="useGetDatiDichiarazioneGiacenza"
                :disabled="loadingDichiarazioneGiacenza || !isCampagnaChiusaCurrentStabilimento"
                :variant="!isCampagnaChiusaCurrentStabilimento ? 'secondary' : 'primary'"
              >
                Visualizza dati dichiarazione 
              </b-btn>
            </b-input-group-append>
            <b-form-text>Disponibili solo dopo avere richiesto la dichiarazione di giacenza.</b-form-text>
          </b-form-group>

        </b-col>
      </b-form-row>

    </b-container>

    <b-modal id="DatiDichiarazione" centered title="Dati dichiarazione" hide-footer>
      <b-container>
        <b-form-row class="mt-1">
          <b-col>
            <p> Azienda: <b>{{ currentAzienda != null ? currentAzienda.ragione_sociale : '' }}</b> </p>
            <p> Stabilimento: <b>{{ currentStabilimento != null ? currentStabilimento.icqrf : '' }}</b> </p>
            <p> Identificativo atto AGEA: <b>{{ IdentificativoAttoAgea }}</b> </p>
            <p> Num. ricevuta accettazione: <b>{{ numRicevutaAccettazione }}</b> </p>
          </b-col>
        </b-form-row>
      </b-container>
    </b-modal>

  </div>
</template>


<script>
import axios from "axios";
import { useState, useMutations } from "@u3u/vue-hooks";
import { defineComponent, ref, computed, watch } from "@vue/composition-api";

import { showAlert } from "../../utils/eSianUtils.js";

export default {
  name: "DichiarazioneGiacenza",

  setup(props, context) {
    const {
      statoStabilimenti,
      currentAzienda,
      currentStabilimento
    } = useState("loginModule", [
      "statoStabilimenti",
      "currentAzienda",
      "currentStabilimento"
    ]);
    const loadingDichiarazioneGiacenza = ref(false)
    const IdentificativoAttoAgea = ref(null)
    const numRicevutaAccettazione = ref(null)
    
    const useDichiarazioneGiacenza = () => dichiarazioneGiacenza(context.root, loadingDichiarazioneGiacenza, IdentificativoAttoAgea, numRicevutaAccettazione)

    const useGetDatiDichiarazioneGiacenza = () => {
      getDatiDichiarazioneGiacenza(context.root).then((dati)=> {
        IdentificativoAttoAgea.value = dati.IdentificativoAttoAgea
        numRicevutaAccettazione.value = dati.numRicevutaAccettazione
        context.root.$bvModal.show("DatiDichiarazione");
      })
    }

    const isCampagnaChiusaCurrentStabilimento = computed(() => {
      if (statoStabilimenti.value.stato_stabilimenti) {
        let found = statoStabilimenti.value.stato_stabilimenti.find(x => x.icqrf == currentStabilimento.value.icqrf);
        return found ? found.stato == "chiuso" : null;
      }
    });

    return {
      useDichiarazioneGiacenza,
      loadingDichiarazioneGiacenza,
      useGetDatiDichiarazioneGiacenza,
      IdentificativoAttoAgea,
      numRicevutaAccettazione,
      statoStabilimenti,
      currentAzienda,
      currentStabilimento,
      isCampagnaChiusaCurrentStabilimento
    };
  },
}


export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}


export function dichiarazioneGiacenza(root, loadingDichiarazioneGiacenza, IdentificativoAttoAgea, numRicevutaAccettazione) {
  const { currentAzienda, currentStabilimento } = useState("loginModule", ["currentAzienda", "currentStabilimento"]);
  const path = `/api/azienda/dichiarazione_giacenza/${currentStabilimento.value.azienda}/${currentStabilimento.value.id}`;
  const filename = currentAzienda.value.cuaa + '-' + currentStabilimento.value.icqrf + ".pdf";
  loadingDichiarazioneGiacenza.value = true;
  axios.get(path)
    .then(response => {
      console.log(response) 
      if (response.data.content != null) {
        showAlert({ 
          message: `${response.data.content.message} (${response.data.content.codice})`, variant: "info" 
        }, root);
        return 
      }
      let filePdf = b64toBlob(response.data.filePdf, "application/pdf")
      var fileURL = window.URL.createObjectURL(new Blob([filePdf]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', filename);
      document.body.appendChild(fileLink);
      fileLink.click();
      IdentificativoAttoAgea.value = response.data.IdentificativoAttoAgea
      numRicevutaAccettazione.value = response.data.numRicevutaAccettazione
      root.$bvModal.show("DatiDichiarazione");
      showAlert({ 
        message: `Stampa dichiarazione giacenza per lo stabilimento ${currentStabilimento.value.icqrf} (${currentAzienda.value.ragione_sociale}) completata.`, variant: "success" 
      }, root);
      // return { IdentificativoAttoAgea: IdentificativoAttoAgea, numRicevutaAccettazione: numRicevutaAccettazione }
    })
    .catch(error => {
      showAlert({ error: error }, root);
    })
    .finally(() => {
      loadingDichiarazioneGiacenza.value = false
    });
    // })
}


export function getDatiDichiarazioneGiacenza(root) {
  const { currentStabilimento } = useState("loginModule", [ "currentStabilimento" ]);
  const path = `/api/stabilimento/dati_dichiarazione_giacenza/${currentStabilimento.value.id}`;
  return axios.get(path)
    .then(response => {
      return response.data
    })
    .catch(error => {
      showAlert({ error: error }, root);
    })
}

</script>
