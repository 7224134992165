const Strumenti = () => import("../components/strumenti/Strumenti.vue");

export const strumenti_routes = [
  {
    path: "/strumenti",
    name: "strumenti",
    component: Strumenti,
    meta: { title: 'Strumenti - e-Sian', layout: "no-side-bar", requiresAuth: true, },
  },
]
