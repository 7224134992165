<template>
  <b-container class="mt-3">
    <b-form-row v-for="(stabilimento, k) in stabilimenti" :key="k">
      <b-col sm="6">
        <b-form-group :label="k==0 ? $t('descrizione') : ''" :state="!errors.has('descrizione' + k)">
          <b-form-input
            type="text"
            v-model="stabilimento.descrizione"
            :data-vv-as="$t('descrizione')"
            :data-vv-name="'descrizione' + k"
            v-validate="{
                  required: true,
                  isASCII: [],
                }"
            :placeholder="$t('descrizione_placeholder')"
            :name="'descrizione' + k"
            :state="errors.has('descrizione' + k) ? false : null"
          />
          <b-form-invalid-feedback>{{ errors.first('descrizione'+ k) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group :label="k==0 ? $t('icqrf'): ''" :state="!errors.has('icqrf' + k)">
          <b-form-input
            type="text"
            :disabled="true"
            v-model="stabilimento.icqrf"
            :data-vv-as="$t('icqrf')"
            :data-vv-name="'icqrf' + k"
            v-validate="{
                  required: true,
                }"
            :placeholder="$t('icqrf_placeholder')"
            :name="'icqrf' + k"
            :state="errors.has('icqrf' + k) ? false : null"
          />
          <b-form-invalid-feedback>{{ errors.first('icqrf'+ k) }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import { ref, computed, watch } from "@vue/composition-api";

export default {
  name: "azienda-stabilimento",

  props: {
    stabilimenti: {
      type: Array,
      required: true
    }
  },

  setup(props, context) {
    const primoStabilimento = ref(props.stabilimenti[0]);

    const primoStabilimentoIcqrf = computed(
      () => primoStabilimento.value.icqrf
    );

    const data = ref(null);
    watch(
      () => props.stabilimenti,
      async stabilimenti => {
        data.value = await "Stabilimenti cambiati";
      }
    );

    return {
      primoStabilimento,
      primoStabilimentoIcqrf,
      data
    };
  }
};
</script>

<i18n>
{
  "en": {
    "stabilimenti": "Stabilimenti",
    "icqrf": "Codice ICQRF",
    "descrizione": "Descrizione stabilimento",
    "icqrf_placeholder": "Immettere il codice ICQRF...",
    "descrizione_placeholder": "Immettere la descrizione dello stabilimento ..."
  },
  "it": {
    "stabilimenti": "Stabilimenti",
    "icqrf": "Codice ICQRF",
    "descrizione": "Descrizione stabilimento",
    "icqrf_placeholder": "Immettere il codice ICQRF...",
    "descrizione_placeholder": "Immettere la descrizione dello stabilimento ..."
  }
}
</i18n>
