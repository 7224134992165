<template>
    <div>
        <b-container class="mt-3 mb-5">
            <!-- <pre>2. isCampagnaAziendaleRiaperta {{ isCampagnaAziendaleRiaperta }}</pre> -->

            <!------------------- contenuto dello slot da AperturaCampagna.vue -------------------->
            <slot></slot>

            <b-form-row class="mt-3" v-if="!apertura">
                <b-col sm="12" lg="6">
                    <b-form-group
                        description="Data da cui ripristinare gli archivi. Le operazioni verranno ripristinate da questa data (i dati in anagrafica ed i prodotti saranno tutti ripristinati)."
                        :label="$t('data_inizio') + ' (data di inizio)'"
                        :state="v$.form.data_inizio.$error ? false : null"
                        :invalid-feedback="
                            v$.form.data_inizio.$error &&
                            v$.form.data_inizio.$errors.length > 0
                                ? v$.form.data_inizio.$errors[0].$response
                                    ? v$.form.data_inizio.$errors[0].$response
                                          .data.message
                                    : v$.form.data_inizio.$errors[0].$message
                                : null
                        "
                    >
                        <b-form-datepicker
                            :state="v$.form.data_inizio.$error ? false : null"
                            v-model="form.data_inizio"
                            locale="it-IT"
                            label-help
                            start-weekday="1"
                            hide-header
                            value-as-date
                            :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            }"
                            :disabled="verificaCampagnaInCorso"
                            placeholder="Scegliere una data..."
                        />
                    </b-form-group>
                </b-col>

                <b-col sm="12" lg="6">
                    <b-form-group
                        description="Le giacenze verranno calcolate a questa data."
                        :label="$t('data_fine') + ' (data di fine)'"
                        :state="v$.form.data_fine.$error ? false : null"
                        :invalid-feedback="
                            v$.form.data_fine.$error &&
                            v$.form.data_fine.$errors.length > 0
                                ? v$.form.data_fine.$errors[0].$response
                                    ? v$.form.data_fine.$errors[0].$response
                                          .data.message
                                    : v$.form.data_fine.$errors[0].$message
                                : null
                        "
                    >
                        <b-form-datepicker
                            :state="v$.form.data_fine.$error ? false : null"
                            v-model="form.data_fine"
                            locale="it-IT"
                            label-help
                            start-weekday="1"
                            hide-header
                            value-as-date
                            :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            }"
                            :disabled="verificaCampagnaInCorso"
                            placeholder="Scegliere una data..."
                        />
                    </b-form-group>
                </b-col>
            </b-form-row>

            <hr class="mt-0" />

            <div class="text-left">
                <!--------------------------------- bottone apertura/ripristino ---------------------------------->
                <b-button
                    @click="onReset"
                    :disabled="ripristinoArchivi"
                    variant="light"
                    class="mr-2"
                    v-if="!apertura"
                    >Annulla</b-button
                >

                <!------------------ in caso di APERTURA --------------------->
                <b-button
                    @click="onSubmit"
                    v-if="apertura"
                    variant="primary"
                    :disabled="
                        isCampagnaAziendaleRiaperta ||
                        (statoStabilimenti.stabilimenti_aperti != 0 &&
                            !form.forzaApertura)
                    "
                    >Apertura campagna</b-button
                >

                <!------------------ in caso di RIPRISTINO --------------------->
                <b-button
                    @click="onSubmit"
                    v-else
                    :variant="v$.$invalid ? 'danger' : 'primary'"
                    :disabled="v$.$invalid || ripristinoArchivi"
                    >Ripristino archivi dal SIAN</b-button
                >
            </div>

            <b-alert show class="mt-3" variant="warning" v-if="!apertura">
                <b-form-checkbox
                    @change="handleForzaRipristino"
                    v-model="form.forzaRipristino"
                >
                    Ripristino dati dal SIAN
                    <b>
                        anche in presenza di dati trasmessi e non ancora
                        verificati
                    </b>
                </b-form-checkbox>
                <span class="small"
                    >Da utilizzarsi solo in caso in cui non sia stato possibile
                    verificare i dati trasmessi.</span
                >
            </b-alert>

            <!------------------------- checkbox apertura forzata ----------------------------->
            <!-- <b-alert show class="mt-3" variant="warning" v-if="apertura && !isCampagnaAziendaleRiaperta && !nuovaCampagnaForzata"> -->
            <!-- v-if="apertura && !isCampagnaAziendaleRiaperta && statoStabilimenti.stabilimenti_aperti != 0 && !nuovaCampagnaForzata" -->
            <b-alert
                show
                class="mt-5"
                variant="danger"
                v-if="
                    apertura &&
                    !isCampagnaAziendaleRiaperta &&
                    statoStabilimenti.stabilimenti_aperti != 0
                "
            >
                <b-form-checkbox
                    @change="handleForzaApertura"
                    v-if="apertura"
                    v-model="form.forzaApertura"
                >
                    Sblocco bottone apertura della campagna aziendale
                    <b>
                        anche in presenza di stabilimenti con campagna ancora
                        aperta
                    </b>
                </b-form-checkbox>
                <span class="small"
                    >Da utilizzarsi solo in caso di stabilimenti per cui la
                    richiesta di chiusura campagna abbia dato esito negativo (ad
                    esempio, stabilimenti con registro chiuso).</span
                >
            </b-alert>
        </b-container>

        <!------------------------------- modale di conferma ripristino ------------------------------>
        <b-modal
            id="ripristinaModal"
            centered
            :title="
                apertura
                    ? 'Conferma apertura'
                    : 'Ripristino degli archivi dal SIAN'
            "
            @ok="handleRipristinaOk"
            ok-variant="danger"
            :ok-title="apertura ? 'Conferma apertura' : $t('Conferma')"
            :cancel-title="$t('annulla')"
            cancel-variant="primary"
            headerClass="p-2 border-bottom-0"
            footerClass="p-2 border-top-0"
        >
            <span v-if="!apertura" v-html="$t('conferma_ripristina')"></span>
            <span v-else>Conferma apertura</span>
        </b-modal>

        <!------------------------------- modale di ripristino in corso ------------------------------>
        <b-modal
            id="loadingModalRipristina"
            @hide="handleHideRipristina"
            class="text-center"
            centered
            hide-header
            hide-footer
        >
            <b-spinner class="mr-1" small :label="loadingMessage.ripristina" />
            {{ loadingMessage.ripristina }}
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import { DateTime } from "luxon";
import { useState, useMutations } from "@u3u/vue-hooks";
import { ref, computed, set, watch } from "@vue/composition-api";
import { mapState } from "vuex";
import { useVuelidate } from "@vuelidate/core";

import {
    calcolaDataInizioCampagna,
    calcolaDataFineCampagna,
} from "./campagnaUtils.js";
import { showAlert } from "../../utils/eSianUtils.js";
import { strumentiMixin } from "./StrumentiMixins.js";
import { ResetAllSearch } from "../../mixins/ResetAllSearch.js";
import { richiesto } from "@/utils/validators/validators.js";
import {
    controlloDataInizioRipristina,
    controlloDataFineRipristina,
} from "@/utils/validators/ripristina.js";
import {
    getProgress as getProgressNew,
    loading,
    loadingCurrent,
    loadingTotal,
    loadingMessage,
} from "@/utils/getProgress.js";
import { _situazioneCurrentStabilimento } from "@/components/strumenti/Strumenti.vue";
import {
    erroriRegistrazioni,
    risultatiRegistrazioni,
} from "@/components/registrazione/store.js";

// aziendaDaRipristinare mi consente di gestire la riapertura dall'interfaccia
// amministrativa del cliente (menu contestuale dell'azienda)
// NON IMPLEMENTATA VA PENSATA BENE
export const aziendaDaRipristinare = ref(null);

const setAziendaDaRipristinare = () => {
    const { currentInfoAzienda, amministrazioneCliente } = useState(
        "amministrazioneModule",
        ["currentInfoAzienda", "amministrazioneCliente"]
    );
    const { currentAzienda } = useState("loginModule", ["currentAzienda"]);
    if (amministrazioneCliente.value) {
        aziendaDaRipristinare.value = currentInfoAzienda.value;
    } else {
        aziendaDaRipristinare.value = currentAzienda.value;
    }
};

export default {
    name: "ripristina",

    mixins: [strumentiMixin, ResetAllSearch],

    props: {
        // dataInizio: Date,
        // dataFine: Date,
        apertura: Boolean,
    },

    setup(props, context) {
        const {
            nuovaCampagnaForzata,
            currentAzienda,
            currentStabilimento,
            statoStabilimenti,
            isCampagnaAziendaleRiaperta,
            dataCampagnaAziendaleRiaperta,
        } = useState("loginModule", [
            "nuovaCampagnaForzata",
            "currentAzienda",
            "currentStabilimento",
            "statoStabilimenti",
            "isCampagnaAziendaleRiaperta",
            "dataCampagnaAziendaleRiaperta",
        ]);
        const { amministrazioneCliente } = useState("amministrazioneModule", [
            "amministrazioneCliente",
        ]);
        // const { setApertura } = useMutations("strumentiModule", ["setApertura"]);
        // setApertura(props.apertura);

        const situazioneCurrentStabilimento = computed(() =>
            _situazioneCurrentStabilimento()
        );

        setAziendaDaRipristinare();

        // se si passa il formato "JS" viene ritornato una JSDate (anzichè un
        // DateTime)
        const data_inizio = computed(() => {
            if (isCampagnaAziendaleRiaperta.value) {
                return calcolaDataInizioCampagna();
            } else {
                // la campagna non è stata riaperta
                if (props.apertura) {
                    return calcolaDataInizioCampagna({ format: "DT" })
                        .plus({ year: 1 })
                        .toJSDate();
                } else {
                    return calcolaDataInizioCampagna({ format: "JS" });
                }
            }
        });

        const data_fine = computed(() => {
            if (isCampagnaAziendaleRiaperta.value) {
                return new Date();
            } else {
                // la campagna non è stata riaperta
                if (props.apertura) {
                    return calcolaDataFineCampagna({ format: "DT" })
                        .plus({ year: 1 })
                        .toJSDate();
                } else {
                    return calcolaDataFineCampagna({ format: "JS" });
                }
            }
        });

        // watch(data_inizio, (val) => {
        //   set(form.value, "data_inizio", val)
        // })

        // watch(data_fine, (val) => {
        //   set(form.value, "data_fine", val)
        // })

        const form = ref({
            // data_inizio: calcolaDataInizioCampagna(),
            // data_fine: !isCampagnaAziendaleRiaperta.value
            //   ? new Date()
            //   : calcolaDataFineCampagna({ format: "JS" }),
            data_inizio: data_inizio.value,
            data_fine: data_fine.value,
            forzaApertura: false,
            forzaRipristino: false,
        });

        const rules = {
            form: {
                data_inizio: {
                    richiesto,
                    controlloDataInizio: controlloDataInizioRipristina(form),
                },
                data_fine: {
                    richiesto,
                    controlloDataFine: controlloDataFineRipristina(form),
                },
            },
        };

        const handleForzaRipristino = async () => {
            if (form.value.forzaRipristino) {
                const procedere = await context.root.$bvModal.msgBoxConfirm(
                    `ATTENZIONE! Da utilizzare solo in caso in cui non fosse stato possibile verificare i dati trasmessi al SIAN. Conferma il ripristino anche in presenza di dati non verificati?`,
                    {
                        okVariant: "primary",
                        cancelVariant: "light",
                        okTitle: "Conferma",
                        okVariant: "warning",
                        cancelTitle: "Annulla",
                        headerClass: "p-2 border-bottom-0",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    }
                );
                if (!procedere) {
                    form.value.forzaRipristino = false;
                    return;
                }
            }
        };

        const handleForzaApertura = async () => {
            if (form.value.forzaApertura) {
                const procedere = await context.root.$bvModal.msgBoxConfirm(
                    `ATTENZIONE! Da utilizzare solo in caso in cui la richiesta di chiusura campagna di uno stabilimento richiesta al SIAN abbia dato esito negativo. Conferma apertura campagna aziendale (per tutti gli stabilimenti)?`,
                    {
                        okVariant: "primary",
                        cancelVariant: "light",
                        okTitle: "Conferma",
                        okVariant: "danger",
                        cancelTitle: "Annulla",
                        headerClass: "p-2 border-bottom-0",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    }
                );
                if (!procedere) {
                    form.value.forzaApertura = false;
                    return;
                }
            }
        };

        // un "ref" (ad esempio "form", in questo caso) va passato tra "{}".
        // Ovviamente si può passare anche un ref vettore.
        const v$ = useVuelidate(rules, { form }, { $autoDirty: true });

        return {
            // February 14, 2023
            handleForzaRipristino,

            nuovaCampagnaForzata,
            handleForzaApertura,
            situazioneCurrentStabilimento,
            loading,
            loadingMessage,
            loadingCurrent,
            loadingTotal,
            form,
            v$,
            // disableButton,
            currentAzienda,
            amministrazioneCliente,
            calcolaDataInizioCampagna,
            calcolaDataFineCampagna,
            statoStabilimenti,
            dataCampagnaAziendaleRiaperta,
            isCampagnaAziendaleRiaperta,
            currentStabilimento,
        };
    },

    data() {
        return {
            // loading: false,
            // loadingMessage: "",
        };
    },

    computed: {
        ...mapState("loginModule", [
            "isCampagnaAziendaleAperta",
            "verificaCampagnaInCorso",
        ]),
    },

    methods: {
        onSubmit() {
            // in caso di apertura non controlla le date (non c'è interazione con
            // l'utente)
            if (this.apertura) {
                this.$bvModal
                    .msgBoxConfirm(
                        `Si conferma l'apertura della campagna aziendale per l'azienda: ${this.currentAzienda.cuaa} (${this.currentAzienda.icqrf})?`,
                        {
                            title: "Conferma apertura campagna aziendale",
                            okVariant: "primary",
                            cancelVariant: "light",
                            okTitle: "Conferma",
                            cancelTitle: "Annulla",
                            headerClass: "p-2 border-bottom-0",
                            footerClass: "p-2 border-top-0",
                            hideHeaderClose: false,
                            centered: true,
                        }
                    )
                    .then((value) => {
                        if (value == true) {
                            ripristina(
                                this,
                                this.apertura,
                                this.form.data_inizio,
                                this.form.data_fine,
                                this.form
                            );
                        } else {
                            this.form.forzaApertura = false;
                        }
                    })
                    .catch((err) => {
                        showAlert({ error: err }, this);
                    });
                // ripristino
            } else {
                // controllo del form
                this.v$.$touch();
                if (this.v$.$error) {
                    return;
                }
                this.$bvModal.show("ripristinaModal");
            }
        },

        handleRipristinaOk() {
            this.$bvModal.hide("ripristinaModal");
            ripristina(
                this,
                this.apertura,
                this.form.data_inizio,
                this.form.data_fine,
                this.form
            );
        },

        onReset() {
            this.form = {
                data_inizio: calcolaDataInizioCampagna(),
                data_fine: this.isCampagnaAziendaleRiaperta
                    ? new Date()
                    : calcolaDataFineCampagna({ format: "JS" }),
            };
        },

        chiudi() {
            this.$router.push({ name: "home" });
        },

        handleHideRipristina(bvModalEvt) {
            if (bvModalEvt.trigger == null || bvModalEvt.trigger == "event") {
                return;
            } else {
                bvModalEvt.preventDefault();
                this.$bvModal
                    .msgBoxOk(
                        "Per favore, attendere il completamento di questa operazione.",
                        { centered: true }
                    )
                    .then(() => {
                        // next(false);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    },
};

const ripristina = async (root, apertura, dataInizio, dataFine, form) => {
    const { currentAzienda } = useState("loginModule", ["currentAzienda"]);
    const { setIsCampagnaAziendaleRiaperta } = useMutations("loginModule", [
        "setIsCampagnaAziendaleRiaperta",
        "setIsCampagnaAziendaleRiaperta",
    ]);
    const { setRipristinoArchivi } = useMutations("strumentiModule", [
        "setRipristinoArchivi",
    ]);

    const chiedere = await check_da_trasmettere(root);
    if (chiedere) {
        const procedere = await root.$bvModal.msgBoxConfirm(
            `ATTENZIONE! Ci sono delle registrazioni ancora da trasmettere al SIAN che verranno cancellate se si prosegue con il ripristino degli archivi dal SIAN. Si conferma il ripristino?`,
            {
                okVariant: "primary",
                cancelVariant: "light",
                okTitle: "Conferma",
                okVariant: "warning",
                cancelTitle: "Annulla",
                headerClass: "p-2 border-bottom-0",
                footerClass: "p-2 border-top-0",
                centered: true,
            }
        );
        if (!procedere) {
            return;
        }
    }

    setRipristinoArchivi(true);

    if (apertura) {
        set(
            loadingMessage.value,
            "ripristina",
            "Apertura campagna aziendale in corso"
        );
    } else {
        set(
            loadingMessage.value,
            "ripristina",
            "In attesa ricezione dati dal SIAN"
        );
    }
    root.$bvModal.show("loadingModalRipristina");

    let azione = null;
    let data_inizio = null;
    let data_fine = null;
    let azienda_id = currentAzienda.value.azienda_id;
    // è un'apertura della campagna aziendale
    // (implica un ripristino degli archivi dal SIAN e setta il flag sul db)
    if (apertura) {
        azione = "apertura";
        data_inizio = DateTime.fromJSDate(dataInizio).toISODate();
        data_fine = DateTime.fromJSDate(dataFine).toISODate();
    } else {
        azione = "ripristina";
        data_inizio = DateTime.fromJSDate(form.data_inizio).toISODate();
        data_fine = DateTime.fromJSDate(form.data_fine).toISODate();
    }

    const options = {
        method: "get",
        url: `/api/sian/All/${azione}/${azienda_id}`,
        params: {
            data_inizio: data_inizio,
            data_fine: data_fine,
            forza_apertura: form.forzaApertura,
            forza_ripristino: form.forzaRipristino,
        },
    };

    try {
        const response = await axios.request(options);
        try {
            getProgressNew(
                response.data.task_id,
                root,
                "loadingModalRipristina", // id modale
                "ripristina", // identificativo
                `${
                    apertura
                        ? "Apertura campagna aziendale"
                        : "Ripristino archivi dal SIAN"
                } dell'azienda "${
                    currentAzienda.value.ragione_sociale
                }" completata.`,
                callback, // puntatore alla funzione (non chiamo la funzione!...)
                failureCallback,
                errorCallback,
                {
                    apertura: apertura,
                    forzatura: form.forzaApertura,
                    root: root,
                }
            );
        } catch (error) {
            setRipristinoArchivi(false);
            apertura ? setIsCampagnaAziendaleRiaperta(false) : null;
            showAlert({ error: error }, root);
        }
    } catch (error) {
        setRipristinoArchivi(false);
        apertura ? setIsCampagnaAziendaleRiaperta(false) : null;
        showAlert({ error: error }, root);
    }
};

import {
    initSoggettoOptions,
    initVasoOptions,
    initVignaOptions,
} from "@/components/soggetto/functions.js";

const callback = (response, params) => {
    const { apertura, forzatura, root } = params;
    const {
        setDataCampagnaAziendaleRiaperta,
        setIsCampagnaAziendaleRiaperta,
        setMostraPopupCampagnaAziendaleAperta,
        setNuovaCampagnaForzata,
    } = useMutations("loginModule", [
        "setDataCampagnaAziendaleRiaperta",
        "setIsCampagnaAziendaleRiaperta",
        "setMostraPopupCampagnaAziendaleAperta",
        "setNuovaCampagnaForzata",
    ]);
    const { setRipristinoArchivi } = useMutations("strumentiModule", [
        "setRipristinoArchivi",
    ]);
    const { currentAzienda, currentStabilimento } = useState("loginModule", [
        "currentAzienda",
        "currentStabilimento",
    ]);

    if (apertura) {
        // storici(root)
        setIsCampagnaAziendaleRiaperta(true);
        const dataOdierna = new Date();
        setDataCampagnaAziendaleRiaperta(dataOdierna.toLocaleString());
        setMostraPopupCampagnaAziendaleAperta(false);
        if (forzatura) {
            setNuovaCampagnaForzata(true);
        }
    }

    // Aug 20, 2021
    initVignaOptions(
        currentAzienda.value.azienda_id,
        currentStabilimento.value.id,
        root
    );
    initVasoOptions(
        currentAzienda.value.azienda_id,
        currentStabilimento.value.id,
        root
    );
    initSoggettoOptions(currentAzienda.value.azienda_id, root);

    // November 15, 2021
    // pulisce le eventuali messaggi presenti in elenco registrazioni a seguito
    // di verfica, trasmissione o importazione asincrona di registrazioni
    erroriRegistrazioni.value = {};

    setRipristinoArchivi(false);
};

const failureCallback = () => {
    // const { apertura } = {...params}
    // const { setIsCampagnaAziendaleRiaperta } = useMutations("loginModule", [
    //   "setIsCampagnaAziendaleRiaperta",
    // ]);
    const { setRipristinoArchivi } = useMutations("strumentiModule", [
        "setRipristinoArchivi",
    ]);
    // if (apertura) {
    //   setIsCampagnaAziendaleRiaperta(false);
    // }
    setRipristinoArchivi(false);
};

const errorCallback = (response, params) => {
    // const { apertura } = {...params}
    // const { setIsCampagnaAziendaleRiaperta } = useMutations("loginModule", [
    //   "setIsCampagnaAziendaleRiaperta",
    // ]);
    const { setRipristinoArchivi } = useMutations("strumentiModule", [
        "setRipristinoArchivi",
    ]);
    // if (apertura) {
    //   setIsCampagnaAziendaleRiaperta(false);
    // }
    setRipristinoArchivi(false);
};

// Jan 07, 2020
// verifica se ci fossero record ancora da trasmettere al SIAN sul backend
const check_da_trasmettere = async (root) => {
    const { currentAzienda } = useState("loginModule", ["currentAzienda"]);
    const options = {
        method: "GET",
        url: `/api/check_da_trasmettere/${currentAzienda.value.azienda_id}`,
    };
    try {
        const response = await axios.request(options);
        const results = response.data.results.filter(
            (x) => x.check_result == true
        );
        return results.length > 0;
    } catch (error) {
        showAlert({ error: error }, root);
    }
};
</script>

<i18n>
{
  "en": {
  },
  "it": {
    "attenzione": "Attenzione!",
    "annulla": "Annulla",
    "cancel": "Annulla",
    "chiudi": "Chiudi",
    "conferma": "Conferma",
    "conferma_ripristina": "<p><b>ATTENZIONE!</b> I dati degli archivi e-Sian verranno sovrascritti con quelli presenti sugli archivi del SIAN.</p><p>Si perderanno TUTTI i dati non ancora trasmessi al SIAN.</p><p>Saranno inoltre cancellati anche tutti i dati degli archivi storici e, se necessario, <b>si dovrà nuovamente importarli</b>.</p>",
    "conferma_ripristina_0": "Si conferma il ripristino degli archivi dal SIAN?",
    "data": "Alla data",
    "data_fine": "Alla data",
    "data_inizio": "Dalla data",
    "elenchi_sian": "Elenchi SIAN",
    "esporta": "Esportazione",
    "importa": "Importazione",
    "operazione_irreversibile": "Attenzione!",
    "testo_elenco_dati_sian": "Acquisizione in tempo reale dei dati effettivamente presenti sul SIAN.",
    "testo_esporta": "Esportazione in altri archivi",
    "testo_importa": "Importazione da altri archivi",
    "titolo_ripristina": "Ripristino degli archivi",
    "titolo": "Strumenti",
    "titolo_sidebar": "Ripristina archivi"
  }
}
</i18n>
