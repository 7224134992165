
<b-container class="mt-3">
    <h4>Controllo giacenze</h4>
    <b-form>
        <b-form-row>
            <b-col>
                <b-form-group
                    label="Data controllo"
                    :state="v$.form.data.$error ? false : null"
                    :invalid-feedback="
                        v$.form.data.$error &&
                        v$.form.data.$errors.length > 0
                            ? v$.form.data.$errors[0].$response
                                ? v$.form.data.$errors[0].$response.data
                                      .message
                                : v$.form.data.$errors[0].$message
                            : null
                    "
                >
                    <b-form-datepicker
                        :disabled="loadingElencoGiacenzeSian"
                        v-model="form.data"
                        locale="it-IT"
                        label-help
                        start-weekday="1"
                        hide-header
                        :state="v$.form.data.$error ? false : null"
                        value-as-date
                        :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                        }"
                    />
                    <b-form-text>
                        Esclude le registrazioni delle campagne precedenti
                        (storiche). Considera solo quelle comprese nella
                        campagna in corso (dal
                        {{
                            DateJSToLocaleStringShort(
                                dataInizioCampagnaInCorso
                            )
                        }}
                        al
                        {{
                            DateJSToLocaleStringShort(
                                dataFineCampagnaInCorso
                            )
                        }})
                        <!-- ++{{isCampagnaAziendaleRiaperta}} -->
                    </b-form-text>
                </b-form-group>
            </b-col>
        </b-form-row>

        <hr />

        <b-btn class="mr-2" @click="onClose" variant="light">Chiudi</b-btn>

        <b-btn
            @click="onReset"
            class="mr-2"
            :disabled="loadingElencoGiacenzeSian"
        >
            Annulla
        </b-btn>

        <b-btn
            @click="onSubmit"
            :disabled="loading || loadingElencoGiacenzeSian || v$.$invalid"
            :variant="v$.$invalid ? 'danger' : 'primary'"
        >
            <span v-if="!loading">Conferma</span>
            <b-spinner v-else small class="ml-1"></b-spinner>
        </b-btn>

        <!-- <b-btn -->
        <!--     @click="_elencaGiacenzeSian" -->
        <!--     :disabled="loadingElencoGiacenzeSian || v$.$invalid" -->
        <!--     class="ml-1 float-right" -->
        <!--     variant="light" -->
        <!-- > -->
        <!--     Stampa SIAN -->
        <!--     <b-spinner -->
        <!--         v-if="loadingElencoGiacenzeSian" -->
        <!--         small -->
        <!--         class="ml-1" -->
        <!--     ></b-spinner> -->
        <!-- </b-btn> -->
    </b-form>
</b-container>
