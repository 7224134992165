<template>
    <b-container class="preferenze">
        <h4>{{ $t("titolo") }}</h4>
        <!-- <b-form @submit.prevent="useOnSubmitSavePreferenze"> -->
        <!---------------- primo set di righe ----------------------->
        <!-- <b-form-row class="mt-3"> -->

        <!------------- prima colonna ------------------>
        <!-- <b-col md=6 sm=12 v-if="!checkUserAccess(utente, {categoria: 'MVVONLY'})"> -->
        <!-- <h5>Visualizzazione</h5> -->
        <!-- <b-form-row class> -->
        <!--   <b-col> -->
        <!--     <b-form-group> -->
        <!--       <b-form-checkbox -->
        <!--         id="mostraSaldi" -->
        <!--         v-model="mostraSaldi" -->
        <!--         v-validate="{ required: false, }" -->
        <!--         data-vv-name="mostraSaldi" -->
        <!--       >Mostra saldi in registrazione</b-form-checkbox> -->
        <!--       <b-form-text>Mostra i saldi di carichi e scarichi, sia in Kg che litri, nella maschera di registrazione delle operazioni</b-form-text> -->
        <!--     </b-form-group> -->
        <!--   </b-col> -->
        <!-- </b-form-row> -->

        <!-- <b-form-row class> -->
        <!--   <b-col> -->
        <!--     <b-form-group> -->
        <!--       <b-form-checkbox -->
        <!--         id="mostraDisattivati" -->
        <!--         v-model="mostraDisattivati" -->
        <!--         v-validate="{ required: false, }" -->
        <!--         data-vv-name="mostraDisattivati" -->
        <!--       >Mostra dati cancellati</b-form-checkbox> -->
        <!--       <b-form-text>Mostra i dati cancellati (prima di un eventuale ripristino archivi dal SIAN)</b-form-text> -->
        <!--     </b-form-group> -->
        <!--   </b-col> -->
        <!-- </b-form-row> -->

        <!-- <b-form-row > -->
        <!--   <b-col> -->
        <!--     <b-form-group> -->
        <!--       <b-form-checkbox -->
        <!--         id="messaggiElenchi" -->
        <!--         v-model="messaggiElenchiRef" -->
        <!--         v-validate="{ required: false, }" -->
        <!--         data-vv-name="messaggiElenchiRef" -->
        <!--       >Visualizza messaggi negli elenchi (operazioni, prodotti, ecc.)</b-form-checkbox> -->
        <!--       <b-form-text>{{ $t('text_messaggiElenchi')}}</b-form-text> -->
        <!--     </b-form-group> -->
        <!--   </b-col> -->
        <!-- </b-form-row> -->

        <!--</b-col>-->

        <!--<!-1------------ seconda colonna -----------------1->-->
        <!--<b-col md=6 sm=12>-->

        <h5
            v-if="
                checkUserAccess(utente, {
                    servizio: 'ARC',
                    azienda: currentAzienda,
                })
            "
            class="mt-3"
        >
            Documenti
        </h5>
        <b-form-row
            v-if="
                checkUserAccess(utente, {
                    servizio: 'ARC',
                    azienda: currentAzienda,
                })
            "
        >
            <b-col>
                <b-form-group>
                    <b-form-checkbox v-model="ricevi_email_avvisi"
                        >Ricevi una email degli avvisi di
                        sistema</b-form-checkbox
                    >
                    <!-- <b-form-text>Ricevi una email di notifica dei documenti in scadenza</b-form-text> -->
                </b-form-group>
            </b-col>
        </b-form-row>

        <h5
            v-if="
                checkUserAccess(utente, { servizio: 'MVV' }) &&
                categoriaUtente(utente) != 'mvvonly'
            "
            class="mt-3"
        >
            MVV-E
        </h5>

        <b-form-row
            v-if="
                checkUserAccess(utente, { servizio: 'MVV' }) &&
                categoriaUtente(utente) != 'mvvonly'
            "
        >
            <b-col>
                <b-form-group>
                    <b-form-checkbox v-model="creaRegistrazioneDaMvvRef"
                        >Crea registrazione USSD da MVV-E</b-form-checkbox
                    >
                    <b-form-text>{{
                        $t("text_creaRegistrazioneDaMvv")
                    }}</b-form-text>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row
            v-if="
                checkUserAccess(utente, { servizio: 'MVV' }) &&
                categoriaUtente(utente) != 'mvvonly'
            "
        >
            <!-- <b-form-row v-if="checkUserAccess(utente, {servizio: 'MVV'})"> -->
            <b-col>
                <b-form-group>
                    <b-form-checkbox
                        v-model="giacenzeNegativeRegistrazioneDaMvv"
                        >Consenti giacenze negative in bozza
                        MVV-E</b-form-checkbox
                    >
                    <b-form-text>{{
                        $t("text_giacenzeNegativeRegistrazioneDaMvv")
                    }}</b-form-text>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row class>
            <b-col>
                <b-form-group>
                    <b-form-checkbox
                        v-model="checkAvvisiMvv"
                        v-validate="{ required: false }"
                        data-vv-name="checkAvvisiMvv"
                        >Controlla se ci sono MVV da ricevere
                        (avvisi)</b-form-checkbox
                    >
                    <b-form-text>
                        Se impostato, controlla dopo l'accesso al sistema, se ci
                        siano MVV da ricevere o meno.
                    </b-form-text>
                </b-form-group>
            </b-col>
        </b-form-row>

        <!-- <b-form-row v-if="checkUserAccess(utente, {servizio: 'MVV'})"> -->
        <!--   <b-col> -->
        <!--     <b-form-group> -->
        <!--       <b-form-checkbox -->
        <!--         v-model="creaRegistrazioneCASDDaMvv" -->
        <!--         disabled -->
        <!--       >Crea registrazione CASD da MVV-E</b-form-checkbox> -->
        <!--       <b-form-text> -->
        <!--         {{ $t('text_creaRegistrazioneCASDDaMvv')}} -->
        <!--       </b-form-text> -->
        <!--     </b-form-group> -->
        <!--   </b-col> -->
        <!-- </b-form-row> -->

        <!-- </b-col> -->
        <!-- </b-form-row> -->

        <!-- <hr v-if="categoriaUtente(utente) != 'mvvonly'"/> -->

        <!---------------- secondo set di righe ----------------------->
        <h5 class="mt-2">Notifiche</h5>

        <!-- <b-form-row class="mt-0"> -->

        <!------------- prima colonna ------------------>
        <!-- <b-col md=6 sm=12> -->

        <b-form-row class>
            <b-col>
                <b-form-group>
                    <b-form-checkbox
                        v-model="registrazioni_info"
                        v-validate="{ required: false }"
                        data-vv-name="registrazioni_info"
                        >Mostra avvisi esito operazioni sulle
                        registrazioni</b-form-checkbox
                    >
                    <b-form-text>
                        Se impostato, mostra gli avvisi informativi sull'esito
                        delle operazioni (trasmissione, verifica, importazione,
                        cancellazione) effettuate sulle registrazioni. Nota:
                        <b>gli avvisi di errore vengono sempre mostrati</b>
                    </b-form-text>
                </b-form-group>
            </b-col>
        </b-form-row>
        <b-form-row class>
            <b-col>
                <b-form-group>
                    <b-form-checkbox
                        v-model="registrazioni_alert"
                        v-validate="{ required: false }"
                        data-vv-name="registrazioni_alert"
                        >Mostra avvisi di sistema</b-form-checkbox
                    >
                    <b-form-text>
                        Se impostato, mostra gli avvisi informativi sull'esito
                        delle operazioni del sistema. Nota:
                        <b>gli avvisi di errore vengono sempre mostrati</b>
                    </b-form-text>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row class>
            <b-col>
                <b-form-group>
                    <b-form-checkbox
                        id="mostraAvvisiInHome"
                        v-model="mostraAvvisiInHome"
                        v-validate="{ required: false }"
                        data-vv-name="mostraAvvisiInHome"
                        >Mostra avvisi dopo la login</b-form-checkbox
                    >
                    <b-form-text
                        >Se impostato, mostra gli avvisi sulle registrazioni da
                        trasmettere o verificare e i documenti MVV-E da
                        ricevere, dopo la login, in home page.</b-form-text
                    >
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row class>
            <b-col>
                <b-form-group>
                    <b-form-checkbox
                        id="confermaTrasmissione"
                        v-model="confermaTrasmissioneRef"
                        v-validate="{ required: false }"
                        data-vv-name="confermaTrasmissioneRef"
                        >Conferma trasmissione</b-form-checkbox
                    >
                    <b-form-text>{{
                        $t("text_confermaTrasmissione")
                    }}</b-form-text>
                </b-form-group>
            </b-col>
        </b-form-row>

        <h5 class="mt-2">Altre preferenze</h5>

        <b-form-row class>
            <b-col>
                <b-form-group>
                    <b-form-checkbox
                        id="registrazioni_data_futura"
                        v-model="registrazioni_data_futura"
                        v-validate="{ required: false }"
                        data-vv-name="registrazioni_data_futura"
                        >Mostra registrazioni con data futura nell'elenco delle
                        registrazioni</b-form-checkbox
                    >
                    <b-form-text
                        >Se impostato, mostra le registrazioni che hanno data
                        futura nell'elenco delle registrazioni. Può essere
                        cambiato temporaneamente dalla pagina elenco delle
                        registrazioni</b-form-text
                    >
                </b-form-group>
            </b-col>
        </b-form-row>

        <!--</b-col>-->

        <!--<!-1------------ seconda colonna -----------------1->-->
        <!--<b-col md=6 sm=12>-->
        <!--<b-form-row>-->
        <!--  <!-1------------------- cuaa default ----------------------1->-->
        <!--  <b-col>-->
        <!--    <b-form-group>-->
        <!--      <b-form-checkbox-->
        <!--        :disabled="preferenze.last_cuaa == currentAzienda.cuaa && preferenze.last_icqrf == currentStabilimento.icqrf"-->
        <!--        id="replace"-->
        <!--        v-model="replaceDefault"-->
        <!--        v-validate="{ required: false, }"-->
        <!--        data-vv-name="replaceDefault"-->
        <!--        >Sostituire l'azienda e lo stabilimento di default ({{ preferenze.last_cuaa }} e {{preferenze.last_icqrf}}) con i valori attuali ({{ currentAzienda.cuaa }} e {{currentStabilimento.icqrf}})</b-form-checkbox>-->
        <!--      <b-form-text>I codici di default vengono sostituiti con quelli dell'azienda corrente e memorizzati dal sistema. {{ $t('nota')}}</b-form-text>-->
        <!--    </b-form-group>-->
        <!--  </b-col>-->
        <!--</b-form-row>-->
        <!-- </b-col> -->
        <!-- </b-form-row> -->

        <hr />

        <div class="text-left mb-5">
            <!-- :disabled="!isFormDirty" -->
            <b-button @click="useOnSubmitSavePreferenze" variant="primary">{{
                $t("conferma")
            }}</b-button>
        </div>
        <!-- </b-form> -->
    </b-container>
</template>

<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
import { useState, useMutations } from "@u3u/vue-hooks";

import { showAlert } from "../../utils/eSianUtils.js";
import { checkUserAccess } from "@/utils/access.js";
import { categoriaUtente } from "./categoriaUtente.js";

export default {
    name: "Preferenze",

    props: {
        userId: {
            type: Number,
            required: true,
        },
        utente: Object,
    },

    //i18n: {
    //  messages: messages
    //},

    setup(props, context) {
        const { preferenze, currentAzienda, currentStabilimento } = useState(
            "loginModule",
            ["preferenze", "currentAzienda", "currentStabilimento"]
        );
        const { setPreferences } = useMutations("loginModule", [
            "setPreferences",
        ]);

        const replaceDefault = ref(false);
        // le preferenze globali sono già state acquisite in login
        // le preferenze sono reattive (per via dell'hook...) Aug 26, 2019

        const ricevi_email_avvisi = ref(preferenze.value.ricevi_email_avvisi);
        const checkAvvisiMvv = ref(preferenze.value.checkAvvisiMvv);

        const confermaTrasmissioneRef = ref(
            preferenze.value.confermaTrasmissione
        );
        const registrazioni_data_futura = ref(
            preferenze.value.registrazioni_data_futura
        );
        const registrazioni_info = ref(preferenze.value.registrazioni_info);
        const registrazioni_alert = ref(preferenze.value.registrazioni_alert);
        const messaggiElenchiRef = ref(preferenze.value.messaggiElenchi);
        const creaRegistrazioneDaMvvRef = ref(
            preferenze.value.creaRegistrazioneDaMvv
        );
        const giacenzeNegativeRegistrazioneDaMvv = ref(
            preferenze.value.giacenzeNegativeRegistrazioneDaMvv
        );
        const mostraSaldi = ref(preferenze.value.mostraSaldi);
        const mostraDisattivati = ref(preferenze.value.mostraDisattivati);
        const mostraAvvisiInHome = ref(preferenze.value.mostraAvvisiInHome);

        // debugger
        // console.log(document.cookie)

        function useOnSubmitSavePreferenze(evt) {
            const payload = {
                //
                ricevi_email_avvisi: ricevi_email_avvisi.value,
                checkAvvisiMvv: checkAvvisiMvv.value,

                mostraAvvisiInHome: mostraAvvisiInHome.value,
                mostraSaldi: mostraSaldi.value,
                mostraDisattivati: mostraDisattivati.value,
                //
                messaggiElenchi: messaggiElenchiRef.value,
                registrazioni_data_futura: registrazioni_data_futura.value,
                registrazioni_info: registrazioni_info.value,
                registrazioni_alert: registrazioni_alert.value,
                confermaTrasmissione: confermaTrasmissioneRef.value,
                creaRegistrazioneDaMvv: creaRegistrazioneDaMvvRef.value,
                giacenzeNegativeRegistrazioneDaMvv:
                    giacenzeNegativeRegistrazioneDaMvv.value,
                last_cuaa: replaceDefault.value
                    ? currentAzienda.value.cuaa
                    : preferenze.value.last_cuaa,
                last_icqrf: replaceDefault.value
                    ? currentStabilimento.value.icqrf
                    : preferenze.value.last_icqrf,
            };

            // salvataggio/aggiornamento in locale (vuex)
            setPreferences(payload);

            // salvataggio/aggiornamento in remoto delle preferenze
            const path = `/api/preferenze/${props.userId}`;
            axios
                .patch(path, payload)
                .then((response) => {
                    // deve essere sempre mostrato un feedback in questo caso - April 08, 2022
                    // if (preferenze.value.registrazioni_alert) {
                    showAlert(
                        {
                            // message: $i18n.t("preferenze_memorizzate"),
                            message: "Preferenze memorizzate",
                            variant: "success",
                        },
                        context.root
                    );
                    // }
                })
                .catch((error) => {
                    showAlert({ error: error }, context.root);
                });
        }

        //console.log(isRef(currentAzienda))
        return {
            categoriaUtente,
            //isFormDirty,

            ricevi_email_avvisi,
            checkAvvisiMvv,

            mostraAvvisiInHome,
            mostraDisattivati,
            mostraSaldi,
            useOnSubmitSavePreferenze,
            currentAzienda,
            currentStabilimento,
            replaceDefault,
            preferenze,
            creaRegistrazioneDaMvvRef,
            giacenzeNegativeRegistrazioneDaMvv,
            registrazioni_data_futura,
            registrazioni_info,
            registrazioni_alert,
            confermaTrasmissioneRef,
            messaggiElenchiRef,
            checkUserAccess,
        };
    },
};
</script>

<style scoped>
.preferenze {
    margin-top: 1rem;
}
</style>

<i18n>
{
  "en": {
  },
  "it": {
    "text_creaRegistrazioneCASDDaMvv": "Se impostato, il sistema crea automaticamente una registrazione di carico (CASD) contestuale alla ricezione di un documento MVV-E. NOTA: la registrazione creata NON verrà trasmessa automaticamente al SIAN.",
    "text_creaRegistrazioneDaMvv": "Se impostato, il sistema crea automaticamente una registrazione di scarico (USSD) contestuale alla creazione di un documento MVV-E. NOTA: la registrazione creata NON verrà trasmessa automaticamente al SIAN.",
    "text_giacenzeNegativeRegistrazioneDaMvv": "Se impostato, il sistema accetta giacenze negative 1) nella maschera di immissione dati per la bozza di MVV-E e 2) nella creazione automatica di una registrazione di scarico (USSD). NOTA: la registrazione creata NON verrà trasmessa automaticamente al SIAN.",
    "text_messaggiElenchi": "Visualizza i messaggi relativi alla trasmissione e alla verifica dei dati trasmessi al SIAN",
    "text_confermaTrasmissione": "Se impostato, il sistema chiede conferma della trasmissione dei dati al SIAN.",
    "text_debug": "Attiva le sezioni 'Altri dati' e 'Debug' nella finestra informativa di ciascun elemento negli elenchi prodotti, operazioni, soggetti, ecc.",
    "preferenze_memorizzate": "Preferenze memorizzate",
    "nota": "I valori di default per CUAA e ICQRF sono impostati automaticamente sulla base di quelli selezionati al momento dell'uscita dal sistema.",
    "titolo": "Preferenze ",
    "conferma_cancellazione": "Confermare la cancellazione",
    "conferma": "Memorizza le preferenze ",
    "chiudi": "Chiudi ",
    "cancel": "Annulla "
  }
}
</i18n>
