import axios from "axios";
import { useState, useMutations } from "@u3u/vue-hooks";
import { set } from "@vue/composition-api";

import { getProgress, loading } from "../../utils/getProgress.js";
import { showAlert } from "../../utils/eSianUtils.js";

import { getAvvisiArchiviazione } from "./getAvvisiArchiviazione.js";

// tipo avviso
export const UTENTE = 0;
export const AZIENDA = 1;
export const GENERALE = 2;

// controlla se eventuali nuovi avvisi fossero presenti sul server ogni 16
// minuti. va installato in navBar.js
// export const checkAvvisi = async (root) => {
//     const { isAuthenticated, } = useState("loginModule", [ "isAuthenticated", ]);
//     setInterval(() => {
//         if (isAuthenticated.value) {
//             getAvvisi(root)
//         }
//     }, 60000*15) // ogni 15 minuti
//     // }, 30000) // ogni 30 secs
// }

/*
 * Jun 23, 2021-January 22, 2022
 *
 * CATEGORIE DI AVVISI
 *
 * - UTENTI
 * - UTENTI (SISTEMA)
 * - MVV
 * - TRASMISSIONI
 *
 * getAvvisi - RECUPERO DI TUTTI GLI AVVISI PER GLI UTENTI DAL SERVER
 *
 * Il recupero degli avvisi, cioè la chiamata di questa funzione, si chiede al
 * server attraverso:
 *
 * 1) il menu di "Verifica degli avvisi" dalla navbar
 *
 * 2) in automatico, subito dopo la login (dalla "Login.vue" component),
 *
 * Sul server:
 *
 * A) Gli avvisi di sistema vengono cancellati (trasmissioni e mvv da ricevere)
 * e ricreati ogni volta,
 *
 * B) gli avvisi utente amministrativi (quelli imessi dall'amministratore
 * attraverso l'interfaccia amministrativa del cliente--non del concessionario)
 * vengono tutti consolidati in una lista che viene visualizzata dalla
 * componento "Avvisi.vue".
 */

// January 13, 2023
export const getAllAvvisi = (root, fromRefreshButton = false) => {
    const { avvisi } = useState("navbarModule", ["avvisi"]);
    const { setAvvisiModalIsShown } = useMutations("navbarModule", [
        "setAvvisiModalIsShown",
    ]);
    const { preferenze } = useState("loginModule", ["preferenze"]);

    const showAvvisi = (root) => {
        setAvvisiModalIsShown(true);
        root.$bvModal.show("modaleAvvisi");
    };

    // recupera avvisi amministrativi
    getAvvisi(root).then(() => {
        if (avvisi.value.length > 0) {
            showAvvisi(root);
        }
    });

    // recupera avvisi di sistema
    getAvvisiTrasmissioni(root);

    // January 13, 2023
    getAvvisiArchiviazione(root);

    if (preferenze.value.checkAvvisiMvv || fromRefreshButton) {
        getAvvisiMVV(root);
    }
};

// prende solo gli avvisi amministrativi
export const getAvvisi = async (root) => {
    // pulizia variabili locali che contengono gli avvisi per tipo
    clearAvvisi();

    const options = {
        method: "GET",
        url: "/api/avviso/avvisi",
    };
    try {
        const response = await axios.request(options);
        handleAvvisi(response);
    } catch (error) {
        showAlert({ error: error }, root);
    }
};

/*
 * Gestisce l'acquisizione degli avvisi per gli utenti di sistema (system) e non:
 *
 * - avvisi utente: mostrati al'utente sempre
 * - avvisi generali: mostrati al'utente sempre
 * - avvisi aziendali: mostrati al'utente solo per l'azienda correntemente selezionata se corrisponde
 */

export const handleAvvisi = (response) => {
    const { currentAzienda } = useState("loginModule", ["currentAzienda"]);
    const { setAvvisi } = useMutations("navbarModule", ["setAvvisi"]);

    // filtra la risposta ricevuta da getAvvisi ed elimina gli avvisi che non
    // sono da mostrare (è un parametro impostato dal creatore
    // dell'avviso--amministratore)
    let elencoAvvisi = response.data.filter((x) => {
        // non è un avviso di aziendale (tipo == 1) ed è da mostrare
        if (x.tipo != AZIENDA && x.show) {
            return true;
            // non è un avviso di aziendale (tipo == 1) ma non è da mostrare
        } else if (x.tipo != AZIENDA && !x.show) {
            return false;
        } else {
            // in caso di avvisi aziendali tiene solo quelli dell'azienda corrente
            // (correntemente selezionata nel select della navbar)
            return x.azienda.cuaa == currentAzienda.value.cuaa;
        }
    });

    setAvvisi(elencoAvvisi); // per il calcolo del nume. avvisi da mostrare in navBar.js
};

/*********************************************************************************
 * avvisi trasmissioni
 *********************************************************************************/

// chiamata di un task celery (usa getProgress)
export const getAvvisiTrasmissioni = async (root) => {
    const { setAvvisiDiSistemaTrasmissioni } = useMutations("navbarModule", [
        "setAvvisiDiSistemaTrasmissioni",
    ]);
    setAvvisiDiSistemaTrasmissioni([]);
    const options = {
        method: "GET",
        url: "/api/avviso/trasmissioni",
    };
    try {
        const response = await axios.request(options);
        try {
            getProgress(
                response.data.task_id,
                root,
                "", // id modale
                "avvisiTrasmissioni", // identificativo
                null, //`Controllo avvisi trasmissioni completato.`, // messaggio finale mostrato in showAlert
                avvisiTrasmissioniCallback, // callback
                null, // failure callback
                null, // error callback
                { root: root } // callback params
            );
        } catch (error) {
            set(loading.value, "avvisiTrasmissioni", false);
            showAlert({ error: error }, root);
        }
    } catch (error) {
        set(loading.value, "avvisiTrasmissioni", false);
        showAlert({ error: error }, root);
    }
};

export const avvisiTrasmissioniCallback = (params, response) => {
    const { root } = { ...params };
    const { currentAzienda } = useState("loginModule", ["currentAzienda"]);
    const { setAvvisiDiSistemaTrasmissioni } = useMutations("navbarModule", [
        "setAvvisiDiSistemaTrasmissioni",
    ]);
    let elencoAvvisi = response.data.details.filter((x) => {
        // non è un avviso di tipo aziendale (tipo 1)
        if (x.tipo != AZIENDA && x.show) {
            return true;
        } else if (x.tipo != AZIENDA && !x.show) {
            return false;
        } else {
            return x.azienda.cuaa == currentAzienda.value.cuaa;
        }
    });
    setAvvisiDiSistemaTrasmissioni(
        elencoAvvisi.filter((x) => x.system && x.category == 11)
    );
    showAlert(
        {
            message: `Controllo avvisi trasmissioni azienda ${currentAzienda.value.ragione_sociale} completato`,
        },
        root
    );
};

/*********************************************************************************
 * avvisi mvv
 *********************************************************************************/

// chiamata di un task celery (usa getProgress)
export const getAvvisiMVV = async (root) => {
    const { setAvvisiDiSistemaMVV } = useMutations("navbarModule", [
        "setAvvisiDiSistemaMVV",
    ]);
    setAvvisiDiSistemaMVV([]);
    const options = {
        method: "GET",
        url: "/api/avviso/mvv",
    };
    try {
        const response = await axios.request(options);
        try {
            getProgress(
                response.data.task_id,
                root,
                "", // id modale
                "avvisiMVV", // identificativo
                null, // `Controllo avvisi MVV completato.`, // messaggio finale mostrato in showAlert
                avvisiMVVCallback, // callback
                null, // failure callback
                null, // error callback
                { root: root } // callback params
            );
        } catch (error) {
            set(loading.value, "avvisiMVV", false);
            showAlert({ error: error }, root);
        }
    } catch (error) {
        set(loading.value, "avvisiMVV", false);
        showAlert({ error: error }, root);
    }
};

/*
 * usata come callback in getProgress.js per impostare gli avvisi ricevuti dal
 * server al termine del processo asincrono
 */
export const avvisiMVVCallback = (params, response) => {
    const { root } = { ...params };
    const { currentAzienda } = useState("loginModule", ["currentAzienda"]);
    const { setAvvisiDiSistemaMVV } = useMutations("navbarModule", [
        "setAvvisiDiSistemaMVV",
    ]);
    let elencoAvvisi = response.data.details.filter((x) => {
        // non è un avviso di aziendale (tipo 1)
        if (x.tipo != AZIENDA && x.show) {
            return true;
        } else if (x.tipo != AZIENDA && !x.show) {
            return false;
        } else {
            return x.azienda.cuaa == currentAzienda.value.cuaa;
        }
    });
    setAvvisiDiSistemaMVV(
        elencoAvvisi.filter((x) => x.system && x.category == 10)
    );
    showAlert(
        {
            message: `Controllo avvisi MVV-E da ricevere azienda ${currentAzienda.value.ragione_sociale} completato`,
        },
        root
    );
};

// pulizia variabili locali che contengono gli avvisi per tipo
const clearAvvisi = () => {
    const { setAvvisi } = useMutations("navbarModule", ["setAvvisi"]);

    setAvvisi([]);
};
