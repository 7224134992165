// const Importazione = () => import("../components/strumenti/Importazione.vue");
const Registrazione = () => import("../components/registrazione/Registrazione.vue");
const ElencoRegistrazioni = () => import("../components/registrazione/elenco-registrazioni/ElencoRegistrazioni.vue");


export const registrazione_routes = [
  {
    path: "/elenco-registrazioni",
    name: "elenco-registrazioni",
    component: ElencoRegistrazioni,
    meta: { title: 'Elenco operazioni' + '- e-Sian', requiresAuth: true, },
  },
  {
    path: "/aggiungi-registrazione",
    name: "aggiungi-registrazione",
    component: Registrazione,
    // meta: { title: app.$i18n.t('aggiungi-registrazione') + '- e-Sian', }
    meta: { title: 'Nuova registrazione' + '- e-Sian', requiresAuth: true, },
    props: true,
  },
  {
    path: "/modifica-registrazione/:id",
    name: "modifica-registrazione",
    component: Registrazione,
    meta: { title: 'Modifica registrazione' + '- e-Sian', requiresAuth: true, },
    props: true,
  },
  {
    path: "/clone-registrazione/:id",
    name: "clone-registrazione",
    component: Registrazione,
    meta: { title: 'Duplica registrazione' + '- e-Sian', requiresAuth: true, },
    props: true,
  },
  {
    path: "/view-registrazione/:id",
    name: "view-registrazione",
    component: Registrazione,
    meta: { title: 'Visualizza registrazione ' + '- e-Sian', requiresAuth: true, },
    props: true,
  },
  // {
  //   path: "/importazione",
  //   name: "importazione-ussd",
  //   component: Importazione,
  //   meta: { title: 'Importazione registrazioni' + '- e-Sian', requiresAuth: true, },
  //   props: true,
  // },
]
