<template>
    <!-- <b-overlay :show="verificaCampagnaInCorso" variant="transparent" rounded="sm"> -->
    <b-container class="strumenti">
        <h3>{{$t('titolo')}}</h3>

        <b-tabs content-class="mt-3" v-model="tabIndex">

            <b-tab lazy :title="$t('storici')" 
                :disabled="blockAccess || !termineInitOptions"
            >
                <storici :azienda_id="azienda_id" :stabilimento_id="stabilimento_id" v-if='!blockAccess' />
            </b-tab>

            <b-tab :title="$t('ripristina')"
                :disabled='blockAccess'
            >
                <ripristina :apertura="false" v-if='!blockAccess'>
                    <!-- slot content -->
                    <h4>{{$t('ripristina')}}</h4>
                    <p
                        class="font-weight-light"
                    >{{currentAzienda != null ? currentAzienda.ragione_sociale : ''}} ({{currentAzienda != null ? currentAzienda.cuaa : ""}}). Tutti gli stabilimenti.</p>

                    <p
                        class="lead"
                    >Ripristina dal SIAN, per ciascun stabilimento, le giacenze ufficiali, le registrazioni, i prodotti, i vasi, le vigne e i soggetti (clienti, committenti) e trasportatori aziendali.</p>
                </ripristina>
            </b-tab>

            <b-tab
                :disabled='blockAccess'
            >
                <template v-slot:title>
                    <font-awesome-icon
                        v-if="situazioneCurrentStabilimento.stato != 'chiuso'"
                        icon="exclamation-triangle"
                        class="mr-2"
                        :class="{'text-warning': situazioneCurrentStabilimento.stato != 'chiuso'}"
                    />
                    {{$t('Chiusura campagna stabilimento')}}
                </template>

                <chiusura-campagna :azienda_id="azienda_id" :stabilimento_id="stabilimento_id" v-if='!blockAccess' />
            </b-tab>

            <b-tab lazy title="Dichiarazione di giacenza">
                <dichiarazione-giacenza />
            </b-tab>

            <b-tab lazy
                :disabled='blockAccess'
            >
                <template #title>
                    <font-awesome-icon
                        v-if="!isCampagnaAziendaleRiaperta && statoStabilimenti.stabilimenti_aperti == 0"
                        icon="exclamation-triangle"
                        class="mr-2"
                        :class="{'text-warning': !isCampagnaAziendaleRiaperta}"
                    />
                    Apertura campagna aziendale
                </template>
                <apertura-campagna vi-if='!blockAccess' />
            </b-tab>

            <b-tab v-if="!currentAzienda.ambiente_operativo_esercizio"
            >
                <template #title> 
                    <span v-b-tooltip.hover variant="danger" title="Cancella dati di prova">
                        <font-awesome-icon
                            icon="trash"
                            class="text-danger"
                        />
                    </span>
                </template>
                <cancella-azienda
                    :cuaa="cuaa"
                    :azienda_id="azienda_id"
                    :stabilimento_id="stabilimento_id"
                />
            </b-tab>

            <!-- <b-tab v-if="utente.email == 'bassotto@bassetti.it' || utente.email == 'maurizio+operatore+qat@gmail.com'" title="Vasi sian"> -->
            <!--     <b-btn variant="primary" class="mr-2" @click="getVasi">Get vasi</b-btn> -->
            <!--     <b-btn @click="vasi=null">Clear -->
            <!--         <b-badge variant="light">{{vasi ? vasi.length : null}}</b-badge> -->
            <!--     </b-btn> -->
            <!--     <!-1- ++{{vasi}} -1-> -->
            <!--     <table class="table mt-3" v-if="vasi ? (vasi.length > 0 ? true : false) : false"> -->
            <!--         <tr> -->
            <!--             <th>Codice</th> -->
            <!--             <th>Descrizione</th> -->
            <!--             <th>Tipo Vaso</th> -->
            <!--             <th class="text-right">Volume</th> -->
            <!--         </tr> -->
            <!--         <tr v-for="vaso in vasi" :key="vaso.CodVaso"> -->
            <!--             <td>{{vaso.codice}}</td> -->
            <!--             <td>{{vaso.descrizione}}</td> -->
            <!--             <td>{{vaso.descrizione_tipo}} ({{vaso.codice_tipo}})</td> -->
            <!--             <td class="text-right">{{vaso.volume}}</td> -->
            <!--         </tr> -->
            <!--     </table> -->               
            <!--     <hr /> -->
            <!--     <b-btn variant="primary" class="mr-2" @click="useGetVasiSIAN"> -->
            <!--         Get vasi SIAN -->
            <!--         <b-spinner type="grow" small v-show="gettingVasiSIAN" class="ml-1"/> -->
            <!--     </b-btn> -->
            <!--     <b-btn @click="vasiSIAN=null"> -->
            <!--         Clear -->
            <!--         <b-badge variant="light">{{vasiSIAN ? vasiSIAN.length : null}}</b-badge> -->
            <!--     </b-btn> -->
            <!--     <table class="table mt-3" v-if="vasiSIAN ? (vasiSIAN.length > 0 ? true : false) : false"> -->
            <!--         <tr> -->
            <!--             <th>CodVaso</th> -->
            <!--             <th>Descrizione</th> -->
            <!--             <th>TipoVaso</th> -->
            <!--             <th class="text-right">Volume</th> -->
            <!--         </tr> -->
            <!--         <tr v-for="vaso in vasiSIAN" :key="vaso.CodVaso"> -->
            <!--             <td>{{vaso.CodVaso}}</td> -->
            <!--             <td>{{vaso.Descrizione}}</td> -->
            <!--             <td>{{vaso.TipoVaso}}</td> -->
            <!--             <td class="text-right">{{vaso.Volume}}</td> -->
            <!--         </tr> -->
            <!--     </table> -->
            <!-- </b-tab> -->

        </b-tabs>
    </b-container>
</template>

<script>
import {ref, computed, onMounted} from "@vue/composition-api";
import {useState, useRouter} from "@u3u/vue-hooks";
import {DateTime} from "luxon";

import DichiarazioneGiacenza from "./DichiarazioneGiacenza.vue";
// import Importazione from "./Importazione.vue";
import Storici from "./Storici.vue";
import Ripristina from "./Ripristina.vue";
import ChiusuraCampagna from "./ChiusuraCampagna.vue";
import AperturaCampagna from "./AperturaCampagna.vue";
// import Esportazione from "./Esportazione.vue";
import CancellaAzienda from "./CancellaAzienda.vue";
//import { showAlert } from "../../utils/eSianUtils.js";
import {checkUserAccess} from "../../utils/access.js";

// February 10, 2022 
import {getVasi, getVasiSIAN, vasi, vasiSIAN, gettingVasiSIAN} from "./getVasi.js";


export default {
    name: "strumenti",

    components: {
        Storici,
        // Importazione,
        ChiusuraCampagna,
        AperturaCampagna,
        DichiarazioneGiacenza,
        Ripristina,
        CancellaAzienda
    },

    props: {
        azienda_id: Number,
        stabilimento_id: Number,
        cuaa: String,
        icqrf: String,
        // utente: Object
    },

    beforeRouteLeave(to, from, next) {
        if (this.ripristinoArchivi) {
            this.$bvModal.msgBoxOk("Per favore, attendere il completamento di questa operazione.", {centered: true}).then(() => {next(false)}).catch((err) => {console.log(err);});
        } else {
            next()
        }
    },

    setup(props, context) {
        const {route} = useRouter();
        const {
            utente,
            mostraPopupCampagnaAziendaleAperta,
            isCampagnaAziendaleRiaperta,
            termineInitOptions,
            statoStabilimenti,
            currentAzienda,
            currentStabilimento,
            verificaCampagnaInCorso,
        } = useState("loginModule", [
            "utente",
            "mostraPopupCampagnaAziendaleAperta",
            "isCampagnaAziendaleRiaperta",
            "termineInitOptions",
            "statoStabilimenti",
            "currentAzienda",
            "currentStabilimento",
            "verificaCampagnaInCorso",
        ]);
        const {ripristinoArchivi} = useState("strumentiModule", ["ripristinoArchivi"]);
        // apre la quinta tab 
        const tabIndex = ref(4)

        const isCampagnaChiusaCurrentStabilimento = computed(() => {
            if (statoStabilimenti.value.stato_stabilimenti) {
                let found = statoStabilimenti.value.stato_stabilimenti.find(x => x.icqrf == currentStabilimento.value.icqrf);
                return found ? found.stato == "chiuso" : null;
            }
        });

        onMounted(() => {
                vasi.value = null; 
                vasiSIAN.value = null
        })

        const useGetVasiSIAN = () => getVasiSIAN(context.root)

        // apre la tab di apertura campagna se chiamata da "verificaChiusuraCampagna"
        tabIndex.value = route.value.params.tabIndex ? route.value.params.tabIndex : 1

        const situazioneCurrentStabilimento = computed(() => _situazioneCurrentStabilimento())

        // August 12, 2022 
        // blocca l'accesso se appartiene ad una delle seguenti categorie: 
        const blockAccess = computed(() => {
            const user = utente.value
            return Boolean(
                checkUserAccess(user, {categoria: 'MVVPLUS'}) ||
                checkUserAccess(user, {categoria: 'MVVONLY'}) || 
                checkUserAccess(user, {categoria: 'READONLY'})
            )
        })

        return {
            blockAccess,
            // starlette:  import.meta.env.VITE_APP_MICROSERVICE,
            gettingVasiSIAN,
            vasi,
            vasiSIAN,
            getVasi,
            useGetVasiSIAN,
            utente,
            mostraPopupCampagnaAziendaleAperta,
            situazioneCurrentStabilimento,
            ripristinoArchivi,
            isCampagnaAziendaleRiaperta,
            currentAzienda,
            currentStabilimento,
            isCampagnaChiusaCurrentStabilimento,
            tabIndex,
            checkUserAccess,
            termineInitOptions,
            statoStabilimenti,
            verificaCampagnaInCorso,
        };
    }
};

// prende una ISO date...
export const DateToLocaleString = (value) => {
    var dt = DateTime.fromISO(value);
    return dt.toLocaleString(DateTime.DATETIME_MED);
}

export const _situazioneCurrentStabilimento = () => {
    const {
        statoStabilimenti,
        currentStabilimento,
    } = useState("loginModule", [
        "statoStabilimenti",
        "currentStabilimento",
    ]);

    if (statoStabilimenti.value.stato_stabilimenti) {
        let found = statoStabilimenti.value.stato_stabilimenti.find(x => x.icqrf == currentStabilimento.value.icqrf);
        return {
            stato: found ? found.stato : null,
            data: found ? (found.data_campagna_chiusa ? 'il ' + DateToLocaleString(found.data_campagna_chiusa) : null) : null,
        }
    }
    return {stato: null, data: null}
}

</script>

<style scoped>
/* minore di ... */
@media (max-width: 608px) {
    .strumenti {
        margin-top: 4rem;
    }
}
</style>

<i18n>
{
  "en": {
  },
  "it": {
    "archiviazione": "Archiviazione",
    "storici": "Storici",
    "titolo_storici": "Archivi storici",
    "cancella_azienda": "Cancella dati di prova",
    "campagna": "Campagna",
    "chiusura_campagna": "Chiusura campagna stabilimento",
    "elenchi_sian": "Elenchi SIAN",
    "esporta": "Esportazione",
    "importa": "Importazione",
    "importa_ussd": "Registrazione automatica USSD",
    "operazione_irreversibile": "Attenzione!",
    "ripristina": "Ripristina",
    "titolo_ripristina": "Ripristina archivi",
    "testo_elenco_dati_sian": "Acquisizione in tempo reale dei dati effettivamente presenti sul SIAN.",
    "testo_esporta": "Esportazione in altri archivi",
    "testo_importa": "Importazione da altri archivi",
    "testo_ripristina": "Ripristino degli archivi da quelli SIAN. Gli archivi vengono ripristinati per tutti gli stabilimenti aziendali.",
    "titolo_ripristina": "Ripristino degli archivi",
    "titolo": "Strumenti",
    "titolo_sidebar": "Ripristina archivi"
  }
}
</i18n>
