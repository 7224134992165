import { DateTime } from "luxon";
export const prodottoModule = {
    namespaced: true,

    state: {

        formCodiciAziendali: [{ 
            formato: null, 
            codice_aziendale: null }
        ],

        // March 08, 2022 
        codiceAziendaleAtEntering: {
            codiceAziendale: null,
            isEmpty: null,
        },

        prodottiPropriProblematici: [],
        prodottiCommittentiProblematici: [],
        showControlloGiacenze: false,
        showControlloGiacenzeCommittenti: false,
        azioneControlloGiacenze: "",
        dataControlloGiacenze: DateTime.local(),
        // categoriaProdotto: null,
        form: {}, // Jul 05, 2020
        showOverlay: false,
        erroreProdotto: null,
        reasonDisabledUpdate: "",
        infoProdottoInModal: false,
        addendumPath: "",
        daEsportare: [], // Oct 21, 2019
        loadingRipristina: false,
        
        giacenzaCorrenteDate: DateTime.local(),
        giacenzaStoriciDate: DateTime.local(),
        giacenzaCorrenteCompute: 0,

        modalProdottoSearch: false,
        modalProdottoSearchPath: "",

        visualizzaProdottoSearchPath: "",
        visualizzaGiacenzeStorici: false,
        visualizzaGiacenzePath: "",
        visualizzaGiacenzeAll: true,
        visualizzaSoloGiacenzePositive: true,
        visualizzaGiacenzePropri: false,
        visualizzaGiacenzeCommittenti: false,
        visualizzaGiacenzeDataOdierna: false,

        searchByCommittente: false,
        codiceCommittente: null,
        visualizzaGiacenzeParams: {
            giacenze: "giacenze_all",
            data_giacenza: new Date(),
            giacenze_data_odierna: false,
            giacenze_storici: false,
        },
        prodottoSearchParams: {
            annata: null,
            categoria: null,
            classificazione: null,
            pratiche_enologiche: null,
            committente: null,
            query: "",
        },
        fieldDisabled: new Map(),
        fieldRequired: new Map(),
        // il campo NON deve essere disabilitato se la categoria del prodotto è ...
        attivazioneCampo: [
            {
                campo: "altre_varieta",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "13",
                    "15",
                    "16",
                    "17",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "123",
                    "125",
                    "131",
                    "132",
                    "133",
                    "134",
                ],
            },
            {
                campo: "altri_trattamenti",
                categoria: [
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "10",
                    "12",
                    "13",
                    "14",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "41",
                    "42",
                ],
            },
            {
                campo: "annata_codice",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "07",
                    "08",
                    "12",
                    "13",
                    "14",
                    "16",
                    "17",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "131",
                    "132",
                    "133",
                    "134",
                ],
            },
            {
                campo: "annata_percentuale",
                categoria: [
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                ],
            },
            {
                campo: "atto_certificato",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "123",
                    "125",
                    "131",
                    "132",
                    "133",
                    "134",
                    "200",
                ],
            },
            {
                campo: "biologico",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "102",
                    "113",
                    "114",
                    "115",
                    "123",
                    "124",
                    "125",
                    "126",
                    "128",
                    "129",
                    "131",
                    "132",
                    "133",
                    "134",
                    "200",
                    "201",
                    "202",
                    "203",
                    "204",
                    "205",
                    "206",
                    "207",
                    "208",
                    "209",
                ],
            }, // elimino la cat. 18=raspi, anche se c'è nella matrice ma il sian la rifiuta!
            {
                campo: "classificazione",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "123",
                    "125",
                    "128",
                    "129",
                    "131",
                    "132",
                    "133",
                    "134",
                    "200",
                    "201",
                    "202",
                    "203",
                    "204",
                    "205",
                    "206",
                    "207",
                    "208",
                    "209",
                ],
            },
            {
                campo: "cod_dop_igp",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "07",
                    "08",
                    "09",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "28",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "115",
                    "123",
                    "125",
                    "131",
                    "132",
                    "133",
                    "134",
                ],
            },
            {
                campo: "cod_partita",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "07",
                    "08",
                    "12",
                    "13",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "131",
                    "132",
                    "133",
                    "134",
                ],
            },
            {
                campo: "codice",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "12",
                    "13",
                    "15",
                    "16",
                    "17",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "123",
                    "125",
                    "131",
                    "132",
                    "133",
                    "134",
                ],
            }, // varietà
            {
                campo: "colore",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "12",
                    "13",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "131",
                    "132",
                    "133",
                    "134",
                ],
            },
            {
                campo: "data_cert_dop",
                categoria: [
                    "08",
                    "21",
                    "22",
                    "24",
                    "25",
                    "26",
                    "28",
                    "30",
                    "31",
                ],
            },
            {
                campo: "descrizione",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "101",
                    "102",
                    "103",
                    "104",
                    "105",
                    "106",
                    "107",
                    "108",
                    "110",
                    "111",
                    "112",
                    "113",
                    "114",
                    "115",
                    "116",
                    "117",
                    "118",
                    "119",
                    "120",
                    "121",
                    "122",
                    "123",
                    "124",
                    "125",
                    "126",
                    "128",
                    "129",
                    "130",
                    "131",
                    "132",
                    "133",
                    "134",
                    "200",
                    "201",
                    "202",
                    "203",
                    "204",
                    "205",
                    "206",
                    "207",
                    "208",
                    "209",
                ],
            },
            {
                campo: "ebacchus",
                categoria: [
                    "21",
                    "22",
                    "24",
                    "25",
                    "26",
                    "28",
                    "30",
                    "31",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                ],
            },
            // { campo: 'gg_invecchiamento', categoria: ['21', '22', '24', '25', '26', '30', '31', '35', '37', '39', '40', '114', '115'] },
            {
                campo: "grado_acidita",
                categoria: ["21", "34", "35", "37", "38", "39", "40"],
            },
            {
                campo: "massa_volumica",
                categoria: [
                    "05",
                    "06",
                    "07",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "41",
                    "42",
                ],
            },
            {
                campo: "menzioni",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "07",
                    "08",
                    "13",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "28",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "123",
                    "125",
                ],
            },
            // { campo: 'metodo_prat_eno', categoria: ['01', '02', '03', '04', '07', '08', '12', '13', '14', '21', '22', '23', '41'] },
            {
                campo: "montegradi",
                categoria: ["21", "34", "35", "37", "38", "39", "40"],
            },
            {
                campo: "num_cert_dop",
                categoria: [
                    "08",
                    "21",
                    "22",
                    "24",
                    "25",
                    "26",
                    "28",
                    "30",
                    "31",
                ],
            },
            {
                campo: "origine_uve",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "07",
                    "08",
                    "13",
                    "14",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "123",
                    "124",
                    "125",
                ],
            },
            {
                campo: "paesi_prov",
                categoria: [
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "131",
                    "132",
                    "133",
                    "134",
                ],
            },
            // { campo: 'perc_igp', categoria: ['01', '02', '03', '04', '07', '08', '13', '14', '21', '22', '23', '24', '25', '26', '28', '30', '31', '41']  },
            {
                campo: "perc_varieta",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "07",
                    "08",
                    "13",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "41",
                ],
            }, // varietà
            {
                campo: "pratiche_enologiche",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "07",
                    "08",
                    "09",
                    "10",
                    "12",
                    "13",
                    "14",
                    "15",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "33",
                    "34",
                    "41",
                ],
            }, // no fecce March 27, 2019 // aggiunte nuovamente le fecce Sep 26, 2019
            {
                campo: "provenienza",
                categoria: [
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "123",
                    "124",
                    "125",
                    "131",
                    "132",
                    "133",
                    "134",
                ],
            },
            {
                campo: "sottozona",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "07",
                    "08",
                    "09",
                    "13",
                    "15",
                    "16",
                    "17",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "28",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "123",
                    "125",
                    "131",
                    "132",
                    "133",
                    "134",
                ],
            },
            {
                campo: "stato_fisico",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "102",
                    "113",
                    "114",
                    "115",
                    "123",
                    "124",
                    "125",
                    "126",
                    "128",
                    "129",
                    "131",
                    "132",
                    "133",
                    "134",
                    "200",
                    "201",
                    "202",
                    "203",
                    "204",
                    "205",
                    "206",
                    "207",
                    "208",
                    "209",
                ],
            },
            // { campo: 'tenore_zuccheri', categoria: ['04', '08', '21', '22', '24', '25', '26', '27', '28', '29', '30', '31'] },
            // { campo: 'tit_alcol_eff', categoria: ['12', '14', '15', '16', '17', '19', '20', '21', '22', '24', '25', '26', '27', '28', '29', '30', '31', '33', '35', '37', '38', '39', '40', '113', '114', '115', '123', '124', '125', '126', '128', '129', '131', '132', '133', '134'] },
            // { campo: 'tit_alcol_pot', categoria: ['01', '02', '03', '07', '09', '10', '11', '12', '13', '16', '17', '21', '35', '37', '38', '39', '40', '41', '102', '200', '201', '202', '203', '204', '205', '206', '207', '208', '209'] },
            // { campo: 'tit_alcol_tot', categoria: ['01', '04', '07', '08', '14', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '37', '38', '39', '40', '41', '113', '114', '115', '123', '124', '125', '126', '128', '129', '131', '132', '133', '134'] },
            {
                campo: "vigna",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "07",
                    "08",
                    "13",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "28",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "131",
                    "132",
                    "133",
                    "134",
                ],
            },
            {
                campo: "zona_viticola",
                categoria: [
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "41",
                    "42",
                ],
            },
        ],
        // obbligatorio o meno...
        requiredCampo: [
            //{ campo: 'categoria', categoria: ['01',	'02',	'03',	'04',	'05',	'06',	'07',	'08',	'09',	'10',	'11',	'12',	'13',	'14',	'15',	'16',	'17',	'18',	'19',	'20',	'21',	'22',	'23',	'24',	'25',	'26',	'27',	'28',	'29',	'30',	'31',	'32',	'33',	'34',	'35',	'37',	'38',	'39',	'40',	'41',	'42',	'101',	'102',	'103',	'104',	'105',	'106',	'107',	'108',	'110',	'111',	'112',	'113',	'114',	'115',	'116',	'117',	'118',	'119',	'120',	'121',	'122',	'123',	'124',	'125',	'126',	'128',	'129',	'130',	'131',	'132',	'133',	'134',	'200',	'201',	'202',	'203',	'204',	'205',	'206',	'207',	'208',	'209'] },
            { campo: "altre_varieta", categoria: [] },
            { campo: "altri_trattamenti", categoria: [] },
            { campo: "annata_codice", categoria: [] },
            { campo: "annata_percentuale", categoria: [] },
            { campo: "atto_certificato", categoria: [] },
            { campo: "biologico", categoria: [] }, // elimino la cat. 18=raspi, anche se c'è nella matrice ma il sian la rifiuta!
            { campo: "classificazione", categoria: [] },
            { campo: "cod_dop_igp", categoria: [] },
            { campo: "cod_partita", categoria: [] },
            { campo: "codice_prodotto", categoria: [] },
            { campo: "codice", categoria: [] }, //varietà
            { campo: "codice_secondario", categoria: [] },
            { campo: "colore", categoria: [] },
            { campo: "data_cert_dop", categoria: [] },
            { campo: "descrizione", categoria: [] },
            { campo: "ebacchus", categoria: [] },
            // { campo: 'gg_invecchiamento', categoria: [] },
            { campo: "grado_acidita", categoria: [] },
            { campo: "massa_volumica", categoria: [] },
            { campo: "menzioni", categoria: [] }, // ['21']
            // { campo: 'metodo_prat_eno', categoria: [] },
            { campo: "montegradi", categoria: [] },
            { campo: "num_cert_dop", categoria: [] },
            { campo: "origine_uve", categoria: [] },
            { campo: "paesi_prov", categoria: [] },
            // { campo: 'perc_igp', categoria: [] },
            { campo: "pratiche_enologiche", categoria: [] },
            { campo: "provenienza", categoria: [] },
            { campo: "sottozona", categoria: [] },
            { campo: "stato_fisico", categoria: [] },
            // { campo: 'tenore_zuccheri', categoria: [] },
            // { campo: 'tit_alcol_eff', categoria: [] },
            // { campo: 'tit_alcol_pot', categoria: [] },
            // { campo: 'tit_alcol_tot', categoria: [] },
            // { campo: 'varieta', categoria: [] },
            { campo: "codice", categoria: [] },
            { campo: "perc_varieta", categoria: [] },
            { campo: "vigna", categoria: [] },

            // March 06, 2022 
            { campo: "zona_viticola", categoria: [] },

        ],
    },
    getters: {
        getFieldsDisabledStatus: (state, getters) => (field) => {
            if (state.form.categoria != null) {
                let statusAttivazione = state.attivazioneCampo.find(
                    (x) => x.campo == field
                );
                if (statusAttivazione != null) {
                    let attivazione = statusAttivazione.categoria.includes(
                        state.form.categoria.codice
                    );
                    if (attivazione) {
                        return false; // non lo disabilita
                    } else {
                        if (field == "codice" || field == "perc_varieta") {
                            state.form.varieta = [
                                {
                                    // lo svuota
                                    codice: null,
                                    perc_varieta: null,
                                },
                            ];
                        } else {
                            state.form[field] = null; // lo svuota
                        }
                        return true; // lo disabilita
                    }
                }
            }
        },
        getFieldsRequiredStatus: (state, getters) => (field) => {
            if (state.form.categoria != null) {
                let statusRichiesto = state.requiredCampo.find(
                    (x) => x.campo == field
                );
                if (statusRichiesto != null) {
                    return statusRichiesto.categoria.includes(
                        state.form.categoria.codice
                    );
                }
            }
            return false
        },
    },
    mutations: {

        // December 24, 2022 
        setFormCodiciAziendali(state, payload) {
            state.formCodiciAziendali = payload;
        },

        // March 08, 2022 
        setCodiceAziendaleAtEntering(state, payload) {
            state.codiceAziendaleAtEntering = payload;
        },


        setProdottiPropriProblematici(state, payload) {
            state.prodottiPropriProblematici = payload;
        },
        setProdottiCommittentiProblematici(state, payload) {
            state.prodottiCommittentiProblematici = payload;
        },

        setShowControlloGiacenze(state, payload) {
            state.showControlloGiacenze = payload;
        },
        setShowControlloGiacenzeCommittenti(state, payload) {
            state.showControlloGiacenzeCommittenti = payload;
        },
        setAzioneControlloGiacenze(state, payload) {
            state.azioneControlloGiacenze = payload;
        },
        setDataControlloGiacenze(state, payload) {
            state.dataControlloGiacenze = payload;
        },

        setFieldRequired(state, payload) {
            state.fieldRequired = payload;
        },
        setFieldDisabled(state, payload) {
            state.fieldDisabled = payload;
        },
        // setCategoriaProdotto(state, payload) {
        //   state.categoriaProdotto = payload;
        // },
        setForm(state, payload) {
            state.form = payload;
        },
        setShowOverlay(state, payload) {
            state.showOverlay = payload;
        },
        setGiacenzaStoriciDate(state, payload) {
            // 8 agosto 2020
            state.giacenzaStoriciDate = payload;
        },
        setGiacenzaCorrenteDate(state, payload) {
            // Oct 17, 2019
            state.giacenzaCorrenteDate = payload;
        },
        setGiacenzaCorrenteCompute(state, payload) {
            // Oct 17, 2019
            state.giacenzaCorrenteCompute = payload;
        },
        setErroreProdotto(state, payload) {
            // Mar 13, 2020
            state.erroreProdotto = payload;
        },
        setInfoProdottoInModal(state, payload) {
            // Jan 24, 2020
            state.infoProdottoInModal = payload;
        },
        setReasonDisabledUpdate(state, payload) {
            // Nov 30, 2019
            state.reasonDisabledUpdate = payload;
        },

        setProdottoAddendumPath(state, payload) {
            // Nov 09, 2019
            state.addendumPath = payload;
        },
        // Oct 21, 2019
        setDaEsportare(state, payload) {
            state.daEsportare = payload.daEsportare;
        },
        setLoadingRipristina(state, payload) {
            state.loadingRipristina = payload;
        },
        setGiacenzaCorrente(state, payload) {
            // Oct 17, 2019
        },
        setModalProdottoSearchPath(state, payload) {
            state.modalProdottoSearchPath = payload;
        },
        setModalProdottoSearch(state, payload) {
            state.modalProdottoSearch = payload;
        },
        setProdottoSearchParams(state, payload) {
            // il payload è un oggetto
            state.prodottoSearchParams = payload;
        },
        setVisualizzaGiacenzePath(state, payload) {
            state.visualizzaGiacenzePath = payload;
        },

        setVisualizzaGiacenzeStorici(state, payload) {
            state.visualizzaGiacenzeStorici = payload;
        },

        setVisualizzaGiacenzeDataOdierna(state, payload) {
            state.visualizzaGiacenzeDataOdierna = payload;
        },
        setVisualizzaGiacenzeAll(state, payload) {
            state.visualizzaGiacenzeAll = payload;
        },
        setVisualizzaSoloGiacenzePositive(state, payload) {
            state.visualizzaSoloGiacenzePositive = payload;
        },
        setVisualizzaGiacenzePropri(state, payload) {
            state.visualizzaGiacenzePropri = payload;
        },
        setVisualizzaGiacenzeCommittenti(state, payload) {
            state.visualizzaGiacenzeCommittenti = payload;
        },
        setVisualizzaProdottoSearchPath(state, payload) {
            state.visualizzaProdottoSearchPath = payload;
        },
        setVisualizzaGiacenzeParams(state, payload) {
            state.visualizzaGiacenzeParams = payload;
        },
        setSearchByCommittente(state, payload) {
            // il payload è un oggetto
            state.searchByCommittente = payload.searchByCommittente;
        },
        setCodiceCommittente(state, payload) {
            // il payload è un oggetto
            state.codiceCommittente = payload.codiceCommittente;
        },
    },
};
