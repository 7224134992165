import { checkUserAccess } from "../../utils/access.js";


export const categoriaUtente = (utente) => {
  const readonly = checkUserAccess(utente, { categoria: "READONLY" })
  const mvvonly = checkUserAccess(utente, { categoria: "MVVONLY" })
  const mvvplus = checkUserAccess(utente, { categoria: "MVVPLUS" })
  if (mvvonly && !readonly && !mvvplus) {
    return 'mvvonly' 
  } else if (!mvvonly && !mvvplus && readonly) {
    return 'readonly' 
  } else if (mvvplus && readonly) {
    return 'readonlymvvplus' 
  } else if (mvvplus && !mvvonly && !readonly) {
    return 'mvvplus' 
  } else {
    return ''
  }
}
