
<b-modal
    v-model="modaleElencaGiacenzeSianShow"
    centered
    hide-footer
    title="Elenco giacenze SIAN"
    @ok="stampa(cuaa, icqrf, utente)"
    ok-title="Stampa"
    :ok-disabled="loadingElencoGiacenzeSian"
>
    <!-- <b-alert show> -->
    <!--     <b-form-text> -->
    La <b>Stampa SIAN</b> è una stampa grezza delle registrazioni contentute
    nel SIAN per l'annata in corso (termine:
    {{ dataFineCampagnaInCorso.toLocaleString() }}), da utilizzarsi per
    eventuali controlli incrociati tra e-Sian e gli archivi SIAN. Tale
    stampa può richiedere anche alcuni minuti. Verrà prodotto un file PDF.
    <!-- </b-form-text> -->
    <!-- </b-alert> -->
    <div class="h-divider my-3"></div>
    <b-form-row>
        <b-col>
            <b-form-group>
                <b-form-checkbox v-model="soloGiacenzePositive"
                    >Solo giacenze positive</b-form-checkbox
                >
            </b-form-group>
        </b-col>
    </b-form-row>
    <b-form-row>
        <b-col>
            <b-form-group>
                <b-form-checkbox v-model="conDesignazione"
                    >Mostra anche la designazione</b-form-checkbox
                >
            </b-form-group>
        </b-col>
    </b-form-row>
    <div>
        <div class="h-divider mb-3 mt-2"></div>

        <b-btn
            @click="_elencaGiacenzeSian"
            :disabled="loadingElencoGiacenzeSian"
            class="ml-1 float-right"
            variant="light"
        >
            Stampa SIAN
            <b-spinner
                v-if="loadingElencoGiacenzeSian"
                small
                class="ml-1"
            ></b-spinner>
        </b-btn>
    </div>
    <!-- id="printableArea" -->
    <!-- @ok="printpart('printableArea')" -->
    <!-- <b-table-simple> -->
    <!--     <b-tr> -->
    <!--         <b-td cols="3"> -->
    <!--             <b>Codice</b> -->
    <!--         </b-td> -->
    <!--         <b-td><b>Designazione</b></b-td> -->
    <!--         <b-td cols="1"><b>Giacenza</b></b-td> -->
    <!--         <b-td cols="1"><b>Unità di misura</b></b-td> -->
    <!--     </b-tr> -->
    <!--     <b-tr v-if="loadingElencoGiacenzeSian"> -->
    <!--         <b-td colspan="4"> -->
    <!--             <b-skeleton-table -->
    <!--                 :rows="5" -->
    <!--                 :columns="4" -->
    <!--                 animation="wave" -->
    <!--             ></b-skeleton-table> -->
    <!--         </b-td> -->
    <!--     </b-tr> -->
    <!--     <b-tr -->
    <!--         v-for="(prodotto, i) in giacenze_sian" -->
    <!--         :key="i" -->
    <!--         v-if="!loadingElencoGiacenzeSian" -->
    <!--     > -->
    <!--         <b-td> -->
    <!--             {{ prodotto["ns1:CodiceProdotto"]["ns1:CodPrimario"] }} -->
    <!--             {{ prodotto["ns1:CodiceProdotto"]["ns1:CodSecondario"] }} -->
    <!--         </b-td> -->
    <!--         <b-td>{{ prodotto["ns1:Designazione"] }}</b-td> -->
    <!--         <b-td class="text-right"> -->
    <!--             {{ prodotto["ns1:QtaGiacenza"] }} -->
    <!--         </b-td> -->
    <!--         <b-td class="text-right"> -->
    <!--             {{ prodotto["ns1:UnMis"] }} -->
    <!--         </b-td> -->
    <!--     </b-tr> -->
    <!-- </b-table-simple> -->
</b-modal>
