
<!-- <b-overlay :show="verificaCampagnaInCorso" variant="transparent" rounded="sm"> -->
<b-container class="strumenti">
    <h3>{{$t('titolo')}}</h3>

    <b-tabs content-class="mt-3" v-model="tabIndex">

        <b-tab lazy :title="$t('storici')" 
            :disabled="blockAccess || !termineInitOptions"
        >
            <storici :azienda_id="azienda_id" :stabilimento_id="stabilimento_id" v-if='!blockAccess' />
        </b-tab>

        <b-tab :title="$t('ripristina')"
            :disabled='blockAccess'
        >
            <ripristina :apertura="false" v-if='!blockAccess'>
                <!-- slot content -->
                <h4>{{$t('ripristina')}}</h4>
                <p
                    class="font-weight-light"
                >{{currentAzienda != null ? currentAzienda.ragione_sociale : ''}} ({{currentAzienda != null ? currentAzienda.cuaa : ""}}). Tutti gli stabilimenti.</p>

                <p
                    class="lead"
                >Ripristina dal SIAN, per ciascun stabilimento, le giacenze ufficiali, le registrazioni, i prodotti, i vasi, le vigne e i soggetti (clienti, committenti) e trasportatori aziendali.</p>
            </ripristina>
        </b-tab>

        <b-tab
            :disabled='blockAccess'
        >
            <template v-slot:title>
                <font-awesome-icon
                    v-if="situazioneCurrentStabilimento.stato != 'chiuso'"
                    icon="exclamation-triangle"
                    class="mr-2"
                    :class="{'text-warning': situazioneCurrentStabilimento.stato != 'chiuso'}"
                />
                {{$t('Chiusura campagna stabilimento')}}
            </template>

            <chiusura-campagna :azienda_id="azienda_id" :stabilimento_id="stabilimento_id" v-if='!blockAccess' />
        </b-tab>

        <b-tab lazy title="Dichiarazione di giacenza">
            <dichiarazione-giacenza />
        </b-tab>

        <b-tab lazy
            :disabled='blockAccess'
        >
            <template #title>
                <font-awesome-icon
                    v-if="!isCampagnaAziendaleRiaperta && statoStabilimenti.stabilimenti_aperti == 0"
                    icon="exclamation-triangle"
                    class="mr-2"
                    :class="{'text-warning': !isCampagnaAziendaleRiaperta}"
                />
                Apertura campagna aziendale
            </template>
            <apertura-campagna vi-if='!blockAccess' />
        </b-tab>

        <b-tab v-if="!currentAzienda.ambiente_operativo_esercizio"
        >
            <template #title> 
                <span v-b-tooltip.hover variant="danger" title="Cancella dati di prova">
                    <font-awesome-icon
                        icon="trash"
                        class="text-danger"
                    />
                </span>
            </template>
            <cancella-azienda
                :cuaa="cuaa"
                :azienda_id="azienda_id"
                :stabilimento_id="stabilimento_id"
            />
        </b-tab>

        <!-- <b-tab v-if="utente.email == 'bassotto@bassetti.it' || utente.email == 'maurizio+operatore+qat@gmail.com'" title="Vasi sian"> -->
        <!--     <b-btn variant="primary" class="mr-2" @click="getVasi">Get vasi</b-btn> -->
        <!--     <b-btn @click="vasi=null">Clear -->
        <!--         <b-badge variant="light">{{vasi ? vasi.length : null}}</b-badge> -->
        <!--     </b-btn> -->
        <!--     <!-1- ++{{vasi}} -1-> -->
        <!--     <table class="table mt-3" v-if="vasi ? (vasi.length > 0 ? true : false) : false"> -->
        <!--         <tr> -->
        <!--             <th>Codice</th> -->
        <!--             <th>Descrizione</th> -->
        <!--             <th>Tipo Vaso</th> -->
        <!--             <th class="text-right">Volume</th> -->
        <!--         </tr> -->
        <!--         <tr v-for="vaso in vasi" :key="vaso.CodVaso"> -->
        <!--             <td>{{vaso.codice}}</td> -->
        <!--             <td>{{vaso.descrizione}}</td> -->
        <!--             <td>{{vaso.descrizione_tipo}} ({{vaso.codice_tipo}})</td> -->
        <!--             <td class="text-right">{{vaso.volume}}</td> -->
        <!--         </tr> -->
        <!--     </table> -->               
        <!--     <hr /> -->
        <!--     <b-btn variant="primary" class="mr-2" @click="useGetVasiSIAN"> -->
        <!--         Get vasi SIAN -->
        <!--         <b-spinner type="grow" small v-show="gettingVasiSIAN" class="ml-1"/> -->
        <!--     </b-btn> -->
        <!--     <b-btn @click="vasiSIAN=null"> -->
        <!--         Clear -->
        <!--         <b-badge variant="light">{{vasiSIAN ? vasiSIAN.length : null}}</b-badge> -->
        <!--     </b-btn> -->
        <!--     <table class="table mt-3" v-if="vasiSIAN ? (vasiSIAN.length > 0 ? true : false) : false"> -->
        <!--         <tr> -->
        <!--             <th>CodVaso</th> -->
        <!--             <th>Descrizione</th> -->
        <!--             <th>TipoVaso</th> -->
        <!--             <th class="text-right">Volume</th> -->
        <!--         </tr> -->
        <!--         <tr v-for="vaso in vasiSIAN" :key="vaso.CodVaso"> -->
        <!--             <td>{{vaso.CodVaso}}</td> -->
        <!--             <td>{{vaso.Descrizione}}</td> -->
        <!--             <td>{{vaso.TipoVaso}}</td> -->
        <!--             <td class="text-right">{{vaso.Volume}}</td> -->
        <!--         </tr> -->
        <!--     </table> -->
        <!-- </b-tab> -->

    </b-tabs>
</b-container>
