<template>
  <!-- <div class="container mt-5"> -->
  <b-container fluid class="h-100 v-100">
    <ValidationObserver ref="observer" v-slot="{ valid, invalid, dirty }">
    <b-row class="h-100" align-h="center">
      <b-col align-self="center" md="8" lg="4">

    <!-- <b-row align-h="center"> -->
    <!--   <b-col sm="7" md="6" lg="5" > -->
        <b-card :title="$t('titolo')" class="mt-3 text-white" style="background: rgba(0,0,0,0.3);">
          <b-card-body>

          <p>{{ $t('forgot') }}</p>

          <ValidationProvider
            :name="$t('email')"
            :rules="{ 
              required: true,
              email: true,
            }"
            v-slot="{ valid, errors }"
            mode="lazy"
          >          
              <!-- class="login-invalid-feedback" -->
            <b-form-group 
              id="form-email-group" 
              :label="$t('email')" 
              label-for="form-email-input" 
              label-class="font-weight-bold pt-0"
              :state="errors[0] ? false : null"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                ref="refEmail"
                id="refEmail" 
                type="text" 
                v-model="form.email" 
                :placeholder="$t('email_placeholder')"
                :state="errors[0] ? false : null"
                />
            </b-form-group>
          </ValidationProvider>
          </b-card-body>

          <b-card-footer class="text-right">
            <b-button @click="onReset" class="mr-2" type="reset" variant="secondary">{{ $t('annulla') }}</b-button>
            <b-button @click="onSubmit" 
              type="submit" 
              :variant="invalid ? 'danger' : 'primary'"
              :disabled="invalid" 
            >
              {{ $t('conferma') }}
              <b-spinner small class="ml-1" v-show="resetLoading" />
            </b-button>
          </b-card-footer>

        </b-card>
      </b-col>
    </b-row>
    </ValidationObserver>
    <b-alert
      v-model="show"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="warning"
      dismissible
    >
      {{ loginMessage }}
    </b-alert>
  </b-container>
</template>

<i18n>
{
  "en": {
   },
  "it": {
    "forgot": "Inserisci il tuo indirizzo email qui sotto e ti invieremo le istruzioni per impostare una password nuova.",
    "email_placeholder": "Indirizzo e-mail utilizzato per l'iscrizione... ",
    "email": "Indirizzo e-mail",
    "annulla": "Annulla",
    "conferma": "Conferma",
    "titolo": "Reimpostazione password"
  }
}
</i18n>,l

<script>
import axios from 'axios'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapState, mapMutations } from "vuex";

import { showAlert } from "../../utils/eSianUtils.js";

export default {

  name: 'password-reset',

  components: {
    ValidationProvider,
    ValidationObserver
  },

  computed: {
    ...mapState("loginModule", ["resetLoading"]),
  },

  data() {
    return {
      show: false,
      variant: "warning",
      loginMessage: null, 
      form: {
        email: this.$route.params.email != null ? this.$route.params.email : null,
      },
      from_contract: this.$route.params.from_contract,
    };
  },

  beforeDestroy() {
    this.setResetLoading(false)
  },

  methods: {
    ...mapMutations("loginModule", ["setResetLoading"]),

    async onSubmit(evt) {
      evt.preventDefault();
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }    
      this.sendPasswordResetData(this.form.email, this.from_contract)
    },

    sendPasswordResetData(email, from_contract) {
      this.setResetLoading(true)
      /* 
       * in login associo ad axios la baseurl per poi fare tutte 
       * le successive chiamate alle api correttamente. in questo caso, 
       * non essendomi ancora loggato devo premettere la baseurl esplicitamente
       * Jul 02, 2020 
       */
      // console.log(import.meta.env.VITE_APP_SCALEWAY) 
      const root_api = import.meta.env.VITE_APP_ROOT_API
      const options = {
        method: 'post',
        url: `${root_api}/api/password/reset/`,
        data:  {
          email: email,
          from_contract: from_contract,
        }
      };
      axios.request(options)
        .then(() => this.$router.push({ name: 'password-reset-done' }))
        .catch((error) => { 
          this.setResetLoading(false)
          if (error.response.status == 401) { 
            this.show = true
            this.loginMessage = error.response.data.detail
            //showAlert({ message: error.response.data.detail, variant: 'warning'}, this)
            this.form.email = null
          } else {
            showAlert({ error: error}, this)
          }
        });
    },

    onReset(evt) {
      evt.preventDefault();
      this.form.email = null;
      this.$router.push({ name: 'login' })
    },
  },
};

</script>
