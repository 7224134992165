import axios from 'axios'
import { mapState, mapMutations } from "vuex";

import { showAlert, UserException } from "../../utils/eSianUtils.js";
import { ResetAllSearch } from "../../mixins/ResetAllSearch.js";

const POLLING_INTERVAL = 1500  // millisecs

export const strumentiMixin = {

    computed: {
        ...mapState("strumentiModule", ["importato", "importazioneStorici", "ripristinoArchivi" ])
    },

    mixins: [ResetAllSearch],

    methods: {
        ...mapMutations("loginModule", ["setCarico", "setIsCampagnaAziendaleRiaperta"]),
        ...mapMutations("strumentiModule", [
            "setImportato",
            "setImportazioneStorici",
            "setRipristinoArchivi",
        ]),

        getProgress(task_id, operazione="Operazione", modalRef="loadingModal") {
            const t0 = performance.now();
            let polling = setInterval(() => {
                const options = {
                    method: 'get',
                    url: `/api/get_progress/${task_id}`
                }
                axios.request(options)
                    .then(response => {
                        if (response.data.details != null) {
                            this.loadingMessage = `${response.data.state} ` + (response.data.details['total'] != 0 ? `${response.data.details['current']}/${response.data.details['total']}` : '');
                        }
                        if (response.data.state == "FAILURE") {
                            showAlert( { message: response.data.details, variant: "error" }, this);
                            this.setImportazioneStorici(false);
                            this.setRipristinoArchivi(false);
                            this.$bvModal.hide(modalRef);
                            clearInterval(polling);
                        } else if (response.data.state == "SUCCESS") {
                            if (response.data.details.content != null && response.data.details.content.codice == "013") {
                                this.$bvModal.hide(modalRef);
                                this.setImportazioneStorici(false);
                                this.setRipristinoArchivi(false);
                                clearInterval(polling);
                                throw new UserException(
                                    "Non ci sono dati da importare negli archivi storici SIAN o che soddisfano i criteri di ricerca selezionati.",
                                    "info", "Informazione"
                                );

                            } else {
                                // termine con successo del ripristino 
                                this.apertura ? this.setIsCampagnaAziendaleRiaperta(true) : null
                                const t1 = performance.now();              
                                showAlert({ message: `${operazione} in ` + Math.round((t1 - t0) / 1000) + ` secondi.`, variant: "success" }, this);
                            }
                            this.$bvModal.hide(modalRef);
                            clearInterval(polling);
                            // Jan 10, 2020
                            if (response.data.details.results != undefined) {
                                this.setCarico(response.data.details.results)
                            } else {
                                // ripristino
                                this.setCarico(response.data.details)
                            }

                            this.setImportazioneStorici(false);
                            this.setRipristinoArchivi(false);

                            // Dec 03, 2019
                            // per ottimizzare l'accesso a registrazione (operazioni) vengono caricate in Home.
                            // al cambio di azienda vengono caricate da NavBar e in caso di Ripristina, qui:
                            // initVignaOptions(this.currentAzienda.azienda_id, this.currentAzienda.stabilimento_id, this);
                            // initVasoOptions(this.currentAzienda.azienda_id, this.currentAzienda.stabilimento_id, this);
                            // initSoggettoOptions(this.currentAzienda.azienda_id, this);

                            // reset tutti i filtri alla fine del ripristino
                            this.resetAllSearch();

                        } // success 
                    })
                    .catch(error => {
                        this.setRipristinoArchivi(false);
                        this.setImportazioneStorici(false);
                        clearInterval(polling);
                        this.$bvModal.hide(modalRef);
                        if (error.isAxiosError && error.response == null) {
                            // Nov 17, 2019
                            showAlert({ message: error.message, variant: "error" }, this);
                        } else if (error.response.config.url.includes("/api/get_progress/")) {
                            // cosi' non mostro il messaggio di errore 
                            console.log("Errore di autorizzazione in getProgress")
                            // console.log(error)
                        } else {
                            showAlert({ error: error, variant: error.variant, title: error.title }, this);
                        }
                    })
            }, POLLING_INTERVAL);

        }
    }
};
