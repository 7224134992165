<template>
    <b-modal
        v-model="modaleElencaGiacenzeSianShow"
        centered
        hide-footer
        title="Elenco giacenze SIAN"
        @ok="stampa(cuaa, icqrf, utente)"
        ok-title="Stampa"
        :ok-disabled="loadingElencoGiacenzeSian"
    >
        <!-- <b-alert show> -->
        <!--     <b-form-text> -->
        La <b>Stampa SIAN</b> è una stampa grezza delle registrazioni contentute
        nel SIAN per l'annata in corso (termine:
        {{ dataFineCampagnaInCorso.toLocaleString() }}), da utilizzarsi per
        eventuali controlli incrociati tra e-Sian e gli archivi SIAN. Tale
        stampa può richiedere anche alcuni minuti. Verrà prodotto un file PDF.
        <!-- </b-form-text> -->
        <!-- </b-alert> -->
        <div class="h-divider my-3"></div>
        <b-form-row>
            <b-col>
                <b-form-group>
                    <b-form-checkbox v-model="soloGiacenzePositive"
                        >Solo giacenze positive</b-form-checkbox
                    >
                </b-form-group>
            </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
                <b-form-group>
                    <b-form-checkbox v-model="conDesignazione"
                        >Mostra anche la designazione</b-form-checkbox
                    >
                </b-form-group>
            </b-col>
        </b-form-row>
        <div>
            <div class="h-divider mb-3 mt-2"></div>

            <b-btn
                @click="_elencaGiacenzeSian"
                :disabled="loadingElencoGiacenzeSian"
                class="ml-1 float-right"
                variant="light"
            >
                Stampa SIAN
                <b-spinner
                    v-if="loadingElencoGiacenzeSian"
                    small
                    class="ml-1"
                ></b-spinner>
            </b-btn>
        </div>
        <!-- id="printableArea" -->
        <!-- @ok="printpart('printableArea')" -->
        <!-- <b-table-simple> -->
        <!--     <b-tr> -->
        <!--         <b-td cols="3"> -->
        <!--             <b>Codice</b> -->
        <!--         </b-td> -->
        <!--         <b-td><b>Designazione</b></b-td> -->
        <!--         <b-td cols="1"><b>Giacenza</b></b-td> -->
        <!--         <b-td cols="1"><b>Unità di misura</b></b-td> -->
        <!--     </b-tr> -->
        <!--     <b-tr v-if="loadingElencoGiacenzeSian"> -->
        <!--         <b-td colspan="4"> -->
        <!--             <b-skeleton-table -->
        <!--                 :rows="5" -->
        <!--                 :columns="4" -->
        <!--                 animation="wave" -->
        <!--             ></b-skeleton-table> -->
        <!--         </b-td> -->
        <!--     </b-tr> -->
        <!--     <b-tr -->
        <!--         v-for="(prodotto, i) in giacenze_sian" -->
        <!--         :key="i" -->
        <!--         v-if="!loadingElencoGiacenzeSian" -->
        <!--     > -->
        <!--         <b-td> -->
        <!--             {{ prodotto["ns1:CodiceProdotto"]["ns1:CodPrimario"] }} -->
        <!--             {{ prodotto["ns1:CodiceProdotto"]["ns1:CodSecondario"] }} -->
        <!--         </b-td> -->
        <!--         <b-td>{{ prodotto["ns1:Designazione"] }}</b-td> -->
        <!--         <b-td class="text-right"> -->
        <!--             {{ prodotto["ns1:QtaGiacenza"] }} -->
        <!--         </b-td> -->
        <!--         <b-td class="text-right"> -->
        <!--             {{ prodotto["ns1:UnMis"] }} -->
        <!--         </b-td> -->
        <!--     </b-tr> -->
        <!-- </b-table-simple> -->
    </b-modal>
</template>

<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
// import { DateTime } from "luxon";
import { isMobile, isSafari } from "mobile-device-detect";
import { useState } from "@u3u/vue-hooks";

import "../../utils/pdfMake.js";
import { showAlert } from "../../utils/eSianUtils.js";
import { calcolaDataFineCampagna } from "../strumenti/campagnaUtils.js";

const giacenze_sian = ref([]);
// const dataGiacenza = ref();
export const modaleElencaGiacenzeSianShow = ref(false);
export const loadingElencoGiacenzeSian = ref(false);
const dataFineCampagnaInCorso = ref();
const soloGiacenzePositive = ref(true);
const conDesignazione = ref(false);

export default {
    name: "ElencaGiacenzeSian",
    props: {
        cuaa: String,
        icqrf: String,
        utente: Object,
    },
    setup(props, context) {
        // August 03, 2023
        const { currentAzienda, currentStabilimento } = useState(
            "loginModule",
            ["currentAzienda", "currentStabilimento"]
        );
        dataFineCampagnaInCorso.value = calcolaDataFineCampagna();
        const _elencaGiacenzeSian = async () => {
            await elencaGiacenzeSian(
                // dataFineCampagnaInCorso.value,
                currentAzienda.value,
                currentStabilimento.value,
                context.root
            );
            context.root.$bvModal.hide("modaleDataControlloGiacenze");
        };

        return {
            modaleElencaGiacenzeSianShow,
            conDesignazione,
            soloGiacenzePositive,
            dataFineCampagnaInCorso,
            _elencaGiacenzeSian,
            elencaGiacenzeSian,
            loadingElencoGiacenzeSian,
        };
    },

    // setup(props) {
    //     return {
    //         stampa,
    //         // printpart,
    //         dataGiacenza,
    //         giacenze_sian,
    //         modaleElencoGiacenzeSianShow,
    //         loadingElencoGiacenzeSian,
    //     };
    // },
};

// August 03, 2023
export const elencaGiacenzeSian = async (azienda, stabilimento, root) => {
    modaleElencaGiacenzeSianShow.value = true;
    loadingElencoGiacenzeSian.value = true;
    // const data_giacenza = DateTime.fromJSDate(data);
    // dataGiacenza.value = data_giacenza.toLocaleString(DateTime.DATE_SHORT);
    const options = {
        method: "GET",
        url: `/api/prodotto/elenca_giacenze_sian/${azienda.azienda_id}/${stabilimento.id}`,
        // url: `/api/prodotto/elenca_giacenze_sian/${azienda.azienda_id}/${stabilimento.id}/${data_giacenza.year}/${data_giacenza.month}/${data_giacenza.day}`,
    };

    try {
        const response = await axios.request(options);
        giacenze_sian.value = response.data;
        stampa(
            azienda,
            stabilimento,
            root,
            soloGiacenzePositive.value,
            conDesignazione.value
        );
        loadingElencoGiacenzeSian.value = false;
    } catch (error) {
        if (error.response != null && error.response.status == 409) {
            showAlert(
                { message: error.response.data.detail, variant: "warning" },
                root
            );
        } else {
            showAlert({ error: error }, root);
        }
        loadingElencoGiacenzeSian.value = false;
    }
};

// August 03, 2023
function stampa(
    azienda,
    stabilimento,
    root,
    soloGiacenzePositive,
    conDesignazione
) {
    // August 04, 2023
    // let conDesignazione = false;
    // let soloGiacenzePositive = true;
    //
    var prodotti = [];
    var prodotti_committente = [];
    var dataStampa = new Date().toLocaleString();
    var fileName = "";

    prodotti.push([
        { text: "Codice primario", bold: true, fillColor: "#CCCCCC" }, // serve per collegare
        { text: "Codice secondario", bold: true, fillColor: "#CCCCCC" }, // serve per collegare
        {
            text: `Giacenza`,
            bold: true,
            fillColor: "#CCCCCC",
        },
        { text: "Unità di misura", bold: true, fillColor: "#CCCCCC" },
    ]);

    prodotti_committente.push([
        { text: "Codice primario", bold: true, fillColor: "#CCCCCC" }, // serve per collegare
        { text: "Codice secondario", bold: true, fillColor: "#CCCCCC" }, // serve per collegare
        { text: "Codice committente", bold: true, fillColor: "#CCCCCC" }, // serve per collegare
        {
            text: `Giacenza`,
            bold: true,
            fillColor: "#CCCCCC",
        },
        { text: "Unità di misura", bold: true, fillColor: "#CCCCCC" },
    ]);

    // parte da 1 perchè la prima riga (vettore di iggetti) è già a posto (header)
    // let numProdotti=1 // Jan 10, 2020.
    let riga = [];
    let riga_committente = [];

    let widths = ["auto", "auto", "auto", "auto"];
    let widthsCommittenti = ["auto", "auto", "auto", "auto", "auto"];
    if (conDesignazione) {
        widths.push("*");
        widthsCommittenti.push("*");
        prodotti[0].push({
            text: "Designazione",
            bold: true,
            fillColor: "#CCCCCC",
        });
        prodotti_committente[0].push({
            text: "Designazione",
            bold: true,
            fillColor: "#CCCCCC",
        });
    }

    // debugger;
    if (giacenze_sian.value.content) {
        showAlert(
            {
                message: giacenze_sian.value.content.message,
                variant: "warning",
            },
            root
        );
    } else {
        giacenze_sian.value.forEach((prodotto) => {
            // if (
            //     prodotto["ns1:CodiceProdotto"]["ns1:CodSecondario"] == "FJ0QJ"
            // ) {
            //     console.log(JSON.stringify(prodotto, null, 4));
            //     debugger;
            // }

            if (prodotto["ns1:CodCommittente"]) {
                riga_committente = [
                    {
                        text: prodotto["ns1:CodiceProdotto"]["ns1:CodPrimario"],
                    },
                    {
                        text: prodotto["ns1:CodiceProdotto"][
                            "ns1:CodSecondario"
                        ],
                    },
                    {
                        text: prodotto["ns1:CodCommittente"],
                    },
                    {
                        text: prodotto["ns1:QtaGiacenza"],
                        alignment: "right",
                    },
                    {
                        text: prodotto["ns1:UnMis"],
                        alignment: "right",
                    },
                ];
                if (conDesignazione) {
                    riga_committente.push({
                        text: JSON.stringify(
                            prodotto["ns1:Designazione"],
                            null,
                            4
                        ),
                        preserveLeadingSpaces: true,
                    });
                }
                if (soloGiacenzePositive) {
                    if (Number(prodotto["ns1:QtaGiacenza"]) > 0) {
                        prodotti_committente.push(riga_committente);
                    }
                } else {
                    prodotti_committente.push(riga_committente);
                }
            } else {
                riga = [
                    {
                        text: prodotto["ns1:CodiceProdotto"]["ns1:CodPrimario"],
                    },
                    {
                        text: prodotto["ns1:CodiceProdotto"][
                            "ns1:CodSecondario"
                        ],
                    },
                    {
                        text: prodotto["ns1:QtaGiacenza"],
                        alignment: "right",
                    },
                    {
                        text: prodotto["ns1:UnMis"],
                        alignment: "right",
                    },
                ];
                if (conDesignazione) {
                    riga.push({
                        text: JSON.stringify(
                            prodotto["ns1:Designazione"],
                            null,
                            4
                        ),
                        preserveLeadingSpaces: true,
                    });
                }
                if (soloGiacenzePositive) {
                    if (Number(prodotto["ns1:QtaGiacenza"]) > 0) {
                        prodotti.push(riga);
                    }
                } else {
                    prodotti.push(riga);
                }
            }
        }); // foreach
    }

    const info = {
        title: "Giacenze SIAN",
        author: "e-Sian " + import.meta.env.VITE_APP_VERSIONE,
        creationDate: new Date(),
    };

    const infoCommittente = {
        title: "Giacenze COMMITTENTI SIAN",
        author: "e-Sian " + import.meta.env.VITE_APP_VERSIONE,
        creationDate: new Date(),
    };

    var docDefinition = {
        info: info,
        header: function (currentPage, pageCount) {
            return {
                columns: [
                    {
                        text:
                            azienda.ragione_sociale + " (" + azienda.cuaa + ")",
                        alignment: "left",
                        fontSize: 10,
                        margin: [15, 10],
                    },
                    {
                        text: `Giacenze SIAN ${calcolaDataFineCampagna().toLocaleString(
                            [],
                            {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                            }
                        )} (n. ${prodotti.length - 1})`,
                        alignment: "center",
                        bold: true,
                        fontSize: 12,
                        margin: [15, 10],
                    },
                    {
                        text: "Cod. stabilimento: " + stabilimento.icqrf,
                        alignment: "right",
                        fontSize: 10,
                        margin: [15, 10],
                    },
                ],
            };
        }, // function
        footer: function (currentPage, pageCount) {
            return {
                columns: [
                    {
                        text: currentPage.toString() + " / " + pageCount,
                        alignment: "left",
                        fontSize: 8,
                        margin: [15, 5],
                    },
                    {
                        // - Atlantic Sky Corporation (C)MMXVIII-MMXIX
                        text: "e-Sian " + import.meta.env.VITE_APP_VERSIONE,
                        alignment: "center",
                        fontSize: 8,
                        margin: [15, 5],
                    },
                    {
                        text: dataStampa,
                        alignment: "right",
                        fontSize: 8,
                        margin: [15, 5],
                    },
                ], // columns
            }; // return
        }, // footer function
        pageSize: "A4",
        // pageOrientation: "landscape",
        // pageMargins: [15, 40, 15, 30],
        content: [
            // primaRiga,
            // secondaRiga,
            {
                fontSize: 9,
                table: {
                    body: prodotti, // è un vettore di vettori (le righe)
                    headerRows: 1,
                    widths: widths,
                },
            },
        ],
    };

    var docDefinitionCommittente = {
        info: infoCommittente,
        header: function (currentPage, pageCount) {
            return {
                columns: [
                    {
                        text:
                            azienda.ragione_sociale + " (" + azienda.cuaa + ")",
                        alignment: "left",
                        fontSize: 10,
                        margin: [15, 10],
                    },
                    {
                        text: `Giacenze COMMITTENTI SIAN ${calcolaDataFineCampagna().toLocaleString(
                            [],
                            {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                            }
                        )} (n. ${prodotti_committente.length - 1})`,
                        alignment: "center",
                        bold: true,
                        fontSize: 12,
                        margin: [15, 10],
                    },
                    {
                        text: "Cod. stabilimento: " + stabilimento.icqrf,
                        alignment: "right",
                        fontSize: 10,
                        margin: [15, 10],
                    },
                ],
            };
        }, // function
        footer: function (currentPage, pageCount) {
            return {
                columns: [
                    {
                        text: currentPage.toString() + " / " + pageCount,
                        alignment: "left",
                        fontSize: 8,
                        margin: [15, 5],
                    },
                    {
                        // - Atlantic Sky Corporation (C)MMXVIII-MMXIX
                        text: "e-Sian " + import.meta.env.VITE_APP_VERSIONE,
                        alignment: "center",
                        fontSize: 8,
                        margin: [15, 5],
                    },
                    {
                        text: dataStampa,
                        alignment: "right",
                        fontSize: 8,
                        margin: [15, 5],
                    },
                ], // columns
            }; // return
        }, // footer function
        pageSize: "A4",
        // pageOrientation: "landscape",
        // pageMargins: [15, 40, 15, 30],
        content: [
            // primaRiga,
            // secondaRiga,
            {
                fontSize: 9,
                table: {
                    body: prodotti_committente, // è un vettore di vettori (le righe)
                    headerRows: 1,
                    widths: widthsCommittenti,
                },
            },
        ],
    };

    if (prodotti_committente.length > 1) {
        fileName = `${azienda.cuaa}_${
            stabilimento.icqrf
        }_COMMITTENTI_${new Date().toLocaleTimeString()}-${
            import.meta.env.VITE_APP_NAME_PART1 +
            import.meta.env.VITE_APP_NAME_PART2
        }.pdf`;
        if (isMobile && isSafari) {
            pdfMake.createPdf(docDefinitionCommittente).open();
        } else {
            pdfMake.createPdf(docDefinitionCommittente).download(fileName);
        }
    }

    fileName = `${azienda.cuaa}_${
        stabilimento.icqrf
    }_${new Date().toLocaleTimeString()}-${
        import.meta.env.VITE_APP_NAME_PART1 +
        import.meta.env.VITE_APP_NAME_PART2
    }.pdf`;
    if (isMobile && isSafari) {
        pdfMake.createPdf(docDefinition).open();
    } else {
        pdfMake.createPdf(docDefinition).download(fileName);
    }

    // return { fileName };
    return;
}

// function printpart(name) {
//     var printwin = window.open("");
//     printwin.document.write(document.getElementById(name).innerHTML);
//     printwin.stop();
//     printwin.print();
//     printwin.close();
// }
</script>
