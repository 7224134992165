<template>
    <div>
    <b-dropdown
        variant="link"
        no-caret
        right
        toggle-class="text-decoration-none mt-0 pt-0 pb-0 pr-0 pl-0 mb-0 mt-0"
    >
        <template slot="button-content">
            <font-awesome-layers class="fa-lg ml-0">
                <font-awesome-icon icon="circle" />
                <font-awesome-icon
                    icon="ellipsis-h"
                    transform="shrink-6"
                    :style="{ color: 'white' }"
                />
            </font-awesome-layers>
        </template>

        <b-dropdown-text>{{ operatore.email }}</b-dropdown-text>
        <b-dropdown-text :class="getRuoloClass(operatore)" class="small">{{
            getRuolo(operatore)
        }}</b-dropdown-text>

        <b-dd-divider />

        <!--------------------- testo eliminazione operatore --------------->
        <b-dropdown-text
            v-if="
                amministrazioneCliente &&
                (operatore.categorie.includes('STAFF') ||
                    operatore.categorie.includes('ADMIN'))
            "
        >
            Questo operatore può essere rimosso in Modifica Azienda
        </b-dropdown-text>

        <!--------------------- modifica operatore --------------->
        <b-dropdown-item
            @click="useEditOperatore(operatore, azienda)"
            v-if="
                (!elencoOperatori &&
                    amministrazioneCliente &&
                    operatore.categorie.includes('AZIENDA')) ||
                amministrazioneConcessionario
            "
            :disabled="disableUpdateOperatore(operatore)"
            :title="reasonUpdate[index]"
            v-b-tooltip.hover.right.v-info
        >
            <font-awesome-icon
                icon="user-edit"
                class="text-primary mr-2"
                :class="{ 'text-muted': disableUpdateOperatore(operatore) }"
                v-if="
                    (!elencoOperatori &&
                        amministrazioneCliente &&
                        operatore.categorie.includes('AZIENDA')) ||
                    amministrazioneConcessionario
                "
            />Modifica
        </b-dropdown-item>

        <!--------------------- rimuovi operatore --------------->
        <b-dropdown-item
            v-if="
                amministrazioneCliente &&
                operatore.categorie.includes('AZIENDA') &&
                !elencoOperatori
            "
            @click="removeOperatoreFromGroup(operatore, azienda)"
        >
            <font-awesome-icon
                icon="user-minus"
                class="text-warning mr-2"
            />Rimuovi
        </b-dropdown-item>

        <!--------------------- cancella operatore --------------->
        <b-dropdown-item
            v-if="
                (amministrazioneCliente &&
                    operatore.categorie.includes('AZIENDA')) ||
                amministrazioneConcessionario
            "
            @click="useDeleteOperatore(operatore)"
        >
            <font-awesome-icon
                icon="user-times"
                class="text-danger mr-2"
            />Elimina
        </b-dropdown-item>

        <!-- <b-dropdown-item @click=""> -->
        <!--   <font-awesome-icon icon="info-circle" class="text-info mr-2" /> -->
        <!--     Informazioni -->
        <!-- </b-dropdown-item> -->
    </b-dropdown>
</div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import { useState, useMutations } from "@u3u/vue-hooks";

import { showAlert } from "@/utils/eSianUtils.js";
import {
    removeOperatoreFromGroup,
    editOperatore,
    deleteOperatore,
    getRuolo,
    getRuoloClass,
} from "./functions.js";
import {alertText} from "../clienti/functions.js";

export default {
    name: "operatori-contextual-menu",

    props: {
        operatore: Object,
        azienda: Object,
        elencoOperatori: Boolean,
        index: Number,
    },

    setup(props, context) {
        const { utente } = useState("loginModule", ["utente"]);
        const { setCurrentCliente, setCurrentOperatore, setCurrentAzienda } =
            useMutations("amministrazioneModule", [
                "setCurrentCliente",
                "setCurrentOperatore",
                "setCurrentAzienda",
            ]);
        const { amministrazioneCliente, amministrazioneConcessionario } =
            useState("amministrazioneModule", [
                "amministrazioneCliente",
                "amministrazioneConcessionario",
        ]);


        const useEditOperatore = (operatore, azienda) => {
            setCurrentOperatore(operatore);
            setCurrentAzienda(azienda);
            setCurrentCliente(operatore.cliente);
            editOperatore(operatore, azienda, context);
        };

        const useDeleteOperatore = (operatore) => {
            setCurrentOperatore(operatore);
            if (!isLastAdmin(operatore)) {
                deleteOperatore(operatore, context);
            } else {
                alertText.value = "L'ultimo operatore amministrativo non può essere cancellato. Cancellare eventualmente il cliente.",
                showAlert(
                    { message: alertText.value, variant: "warning" },
                    context.root
                );
            }
        };

        const isLastAdmin = (operatore) => {
            return (
                operatore.cliente.numero_amministratori == 1 &&
                operatore.categorie.includes("ADMIN")
            );
        };

        const reasonUpdate = ref([]);
        let index = props.index;
        let elencoOperatori = props.elencoOperatori;

        const disableUpdateOperatore = (operatore, index) =>
            _disableUpdateOperatore(
                operatore,
                index,
                reasonUpdate,
                utente,
                elencoOperatori
            );

        return {
            alertText,

            reasonUpdate,
            disableUpdateOperatore,
            getRuolo,
            getRuoloClass,
            utente,
            useEditOperatore,
            useDeleteOperatore,
            removeOperatoreFromGroup,
            amministrazioneCliente,
            amministrazioneConcessionario,
        };
    },
};

function _disableUpdateOperatore(
    operatore,
    index,
    reasonUpdate,
    utente,
    elencoOperatori
) {
    if (
        elencoOperatori &&
        (operatore.email == utente.email ||
            operatore.categorie.includes("AZIENDA"))
    ) {
        reasonUpdate.value[index] =
            "Operatore aziendale. Può essere modificato in elenco aziende.";
        return true;
    }
}
</script>
