
<b-container fluid class="h-100 v-100">
    <b-row class="h-100" align-h="center">
        <b-col align-self="center" md="8" lg="4">
            <b-card
                :title="$t('titolo')"
                class="mt-3 text-white"
                style="background: rgba(0,0,0,0.3);"
            >
                <b-card-body>
                    <p>{{$t('forgot_done')}}</p>
                    <p>{{$t('forgot_done_1')}}</p>
                </b-card-body>
                <b-card-footer class="text-right">
                    <b-button @click="onReset" type="reset" variant="light">{{$t('annulla')}}</b-button>
                </b-card-footer>
            </b-card>
        </b-col>
    </b-row>
</b-container>
