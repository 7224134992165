import Vue from "vue";
import Vuex from "vuex";

import { loginModule } from "./modules/login.module";
import { operazioneModule } from "./modules/operazione.module";
import { prodottoModule } from "./modules/prodotto.module";
import { strumentiModule } from "./modules/strumenti.module";
import { sidebarModule } from "./modules/sidebar.module";
import { navbarModule } from "./modules/navbar.module";
import { soggettoModule } from "./modules/soggetto.module";
import { archivioModule } from "./modules/archivio.module";
import { mvvModule } from "./modules/mvv.module";
import { amministrazioneModule } from "./modules/amministrazione.module";
import { vinificazioneModule } from "./modules/vinificazione.module";
import { commonModule } from "./modules/common.module";


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loginModule,
    operazioneModule,
    prodottoModule,
    strumentiModule,
    sidebarModule,
    navbarModule,
    soggettoModule,
    archivioModule,
    mvvModule,
    amministrazioneModule,
    vinificazioneModule,
    commonModule,
  }
});
