// Apr 20, 2020
import axios from "axios";
import { useState, useMutations } from "@u3u/vue-hooks";
import { Validator } from "vee-validate";

import { showAlert } from "../../../utils/eSianUtils.js";
import { loadOptionsCliente } from "../clienti/functions.js";
import { useLoadOptionsAziende } from "../aziende/functions.js";
import { checkAddOperatore } from "../utils";

export function getRuolo(operatore) {
    if (operatore.categorie.includes("API")) {
        return "API";
    } else if (operatore.categorie.includes("ADMIN")) {
        return "Amministrativo";
    } else if (operatore.categorie.includes("STAFF")) {
        return "Cliente";
    } else if (operatore.categorie.includes("AZIENDA")) {
        return "Aziendale";
    }
}

export function getRuoloClass(operatore) {
    if (operatore.categorie.includes("API")) {
        return "text-warning";
    } else if (operatore.categorie.includes("ADMIN")) {
        return "text-black font-weight-bold";
    } else if (operatore.categorie.includes("STAFF")) {
        return "text-info";
    } else if (operatore.categorie.includes("AZIENDA")) {
        return "text-dark";
    }
}

export function editOperatore(operatore, azienda, context) {
    const { setOperatoreAction } = useMutations("amministrazioneModule", [
        "setOperatoreAction",
    ]);
    let azienda_id = 0;
    if (azienda != null) {
        azienda_id = azienda.id;
    }
    retrieveOperatore(operatore.id, azienda_id, context);
    setOperatoreAction("edit");
}

export function retrieveOperatore(id, azienda_id, context) {
    const { optionsCategorie } = useState("amministrazioneModule", [
        "optionsCategorie",
    ]);
    const { setOperatoreForm } = useMutations("amministrazioneModule", [
        "setOperatoreForm",
    ]);
    axios
        .get("/api/operatori/" + id, { params: { azienda: azienda_id } })
        .then((response) => {
            let categorie = response.data.categorie.split(",");
            // se nella lista ricevuta dal server c'è ADMIN, è un operatore amministratore,
            // cioè non può essere assegnato come operatore del cliente alla gestione diretta  aziendale
            if (categorie.includes("ADMIN")) {
                response.data.is_admin = true;
            } else {
                response.data.is_admin = false;
            }
            let elencoCategorie = [];
            categorie.forEach((categoria) => {
                let found = optionsCategorie.value.find(
                    (x) => x.id == categoria
                );
                if (found != null) {
                    elencoCategorie.push(found);
                }
            });
            // devo eliminare AZIENDA cosicchè l'utente non la veda e pertanto non la possa cancellare
            response.data.categorie_originali = elencoCategorie;
            response.data.categorie = elencoCategorie.filter(
                (x) => x.id != "AZIENDA"
            ); // && x.id != "CLIENTE" && x.id != "CONCESSIONARIO");
            // campo non esistente nel database e pertanto non ricevuto. viene però
            // spedito e recepito dal serializzatore
            (response.data.sendEmail = false), setOperatoreForm(response.data);
            context.root.$bvModal.show("operatoreModal");
        })
        .catch((error) => {
            showAlert({ error: error }, context.root);
        });
}

export function deleteOperatore(operatore, context) {
    const { setCurrentOperatore } = useMutations("amministrazioneModule", [
        "setCurrentOperatore",
    ]);
    let messaggio =
        'Conferma cancellazione operatore "' +
        operatore.email +
        '" (' +
        operatore.first_name +
        " " +
        operatore.last_name +
        ")?";
    context.root.$bvModal
        .msgBoxConfirm(messaggio, {
            okVariant: "danger",
            cancelVariant: "light",
            okTitle: "Cancella",
            cancelTitle: "Annulla",
            footerClass: "p-2",
            centered: true,
        })
        .then((value) => {
            if (value) {
                let options = {
                    method: "delete",
                    url: "/api/operatori/" + operatore.id,
                };
                axios
                    .request(options)
                    .then(() => {
                        setCurrentOperatore({});
                        // TODO da ottimizzare il reload!
                        loadOptionsCliente(context);
                        loadOptionsOperatore(context);
                        useLoadOptionsAziende();
                    })
                    .catch((error) => {
                        showAlert({ error: error }, context.root);
                    });
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

async function getOperatoriAziendaliGruppo(root) {
    const { currentAzienda } = useState("amministrazioneModule", [
        "currentAzienda",
    ]);
    const { setOperatoriAziendaliGruppo, setAziendeGruppo } = useMutations(
        "amministrazioneModule",
        ["setOperatoriAziendaliGruppo", "setAziendeGruppo"]
    );
    let options = {
        method: "get",
        url: `/api/azienda/get_operatori_aziendali_del_gruppo/${currentAzienda.value.id}`,
    };
    try {
        const response = await axios.request(options);
        setOperatoriAziendaliGruppo(
            response.data.operatori_aziendali_del_gruppo.filter((x) => {
                return x.aziende_di_cui_e_operatore.every(
                    (x) => x.id != currentAzienda.value.id
                );
            })
        );
        setAziendeGruppo(response.data.aziende_del_gruppo);
    } catch (error) {
        showAlert({ error: error }, root);
    }
}

export function addOperatore(context) {
    // controlla che sia nei limiti ammessi per il cliente
    checkAddOperatore(context).then((result) => {
        if (result) {
            const { utente } = useState("loginModule", ["utente"]);
            const {
                currentAzienda,
                currentCliente,
                amministrazioneConcessionario,
                optionsCategorie,
            } = useState("amministrazioneModule", [
                "currentAzienda",
                "currentCliente",
                "amministrazioneConcessionario",
                "optionsCategorie",
            ]);
            const { setOperatoreAction, setOperatoreForm } = useMutations(
                "amministrazioneModule",
                ["setOperatoreAction", "setOperatoreForm"]
            );

            if (currentAzienda.value.gruppo != null) {
                // inizializza il vettore delle options per la lista degli operatori aziendali
                // in caso di aziende facenti parte di un gruppo
                getOperatoriAziendaliGruppo(context.root);
            }

            setOperatoreAction("add");

            let concessionario = null;
            let categorie = [];

            // se è un concessionario, l'operatore che viene aggiunto è un operatore-cliente...
            if (amministrazioneConcessionario.value) {
                concessionario = utente.value.concessionario;
                categorie = [
                    optionsCategorie.value.find((x) => x.id == "CLIENTE"),
                ];
            }

            let servizi = [];
            let aziende_di_cui_e_operatore = [];
            // se si tratta di un operatore del cliente eredita tutti i servizi comprati dal cliente
            if (amministrazioneConcessionario.value) {
                servizi = currentCliente.value.servizi_ammessi;
                aziende_di_cui_e_operatore = [];
            } else {
                servizi = currentAzienda.value.servizi_ammessi;
                // aggiunge alle aziende di cui è operatore l'azienda corrente
                aziende_di_cui_e_operatore = [currentAzienda.value.id];
            }

            let operatoreForm = {
                operatore: null,
                email: "",
                password: null,
                first_name: null,
                last_name: null,
                titolo: null,
                telefono: null,
                note: null,
                is_active: true,
                is_new: true,
                is_admin:
                    currentCliente.value.operatori != null
                        ? currentCliente.value.operatori.length == 0
                            ? true
                            : false
                        : null,
                sendEmail: false,
                categorie: categorie,
                servizi: servizi,
                aziende_di_cui_e_operatore: aziende_di_cui_e_operatore,
                cliente: currentCliente.value.id,
                concessionario: concessionario,
            };

            setOperatoreForm(operatoreForm);

            context.root.$bvModal.show("operatoreModal");
        } else {
            showAlert(
                {
                    message: "Numero massimo operatori raggiunto",
                    variant: "warning",
                },
                context.root
            );
        }
    });
}

// export const creazioneOperatore = ref(false)

export async function onSubmit(context) {
    const { operatoreForm, operatoreAction } = useState(
        "amministrazioneModule",
        ["operatoreForm", "operatoreAction"]
    );

    const isValid = await context.refs.observer.validate();
    if (!isValid) {
        return;
    }

    // in caso di aggiunta ad un grupppo aziendale di un operatore già esistente,
    // aggiorna l'elenco degli operatori aziendali
    if (operatoreAction.value == "add" && !operatoreForm.value.is_new) {
        if (
            addOperatoreToGroup(context.root, "operatoreModal").then(
                (res) => res
            )
        ) {
            return;
        }
    }

    // se si è chiesta la disattivazione (checkbox)...
    if (operatoreForm.value.is_active == false) {
        // verifica (richiesta dellla conferma) della disattivazione nel form...
        let messaggio =
            'Conferma disattivazione operatore "' +
            operatoreForm.value.email +
            '" (' +
            operatoreForm.value.first_name +
            " " +
            operatoreForm.value.last_name +
            ")?";
        context.root.$bvModal
            .msgBoxConfirm(messaggio, {
                okVariant: "warning",
                cancelVariant: "light",
                okTitle: "Disattiva",
                cancelTitle: "Annulla",
                footerClass: "p-2",
                centered: true,
            })
            .then((value) => {
                // se non lo si vuole disattivare
                if (!value) {
                    operatoreForm.value.is_active = true;
                    return;
                }
                // se lo si vuole disattivare
                _sendOperatore(context, operatoreForm, operatoreAction);
            });
    } else {
        // invia al server i dati dell'operatore
        _sendOperatore(context, operatoreForm, operatoreAction);
    }
}

/*
 * crea un operatore di gruppo, !is_new, e aggiorna la lista degli operatori
 * del gruppo sul server
 */
export async function addOperatoreToGroup(root, modal) {
    const { setCreazioneOperatore } = useMutations("amministrazioneModule", [
        "setCreazioneOperatore",
    ]);
    const { operatoreForm, currentAzienda } = useState(
        "amministrazioneModule",
        ["operatoreForm", "currentAzienda"]
    );
    setCreazioneOperatore(true);
    let operatori = currentAzienda.value.operatori.map((x) => x.id);
    operatori.push(operatoreForm.value.operatore.id);
    let options = {
        method: "patch",
        url: "/api/azienda/" + currentAzienda.value.id,
        data: {
            operatori: operatori,
        },
    };
    try {
        await axios.request(options);
        setCreazioneOperatore(false);
        useLoadOptionsAziende();
        root.$bvModal.hide(modal);
        return true;
    } catch (error) {
        throw error;
    }
}

export async function removeOperatoreFromGroup(operatore, azienda) {
    if (!operatore.is_new) {
        let operatori = azienda.operatori.map((x) => x.id);
        const index = operatori.indexOf(operatore.id);
        if (index > -1) {
            operatori.splice(index, 1);
        }
        let options = {
            method: "patch",
            url: "/api/azienda/" + azienda.id,
            data: {
                operatori: operatori,
            },
        };
        try {
            await axios.request(options);
            useLoadOptionsAziende();
            return true;
        } catch (error) {
            throw error;
        }
    }
}

function _sendOperatore(context, operatoreForm, operatoreAction) {
    const { setCreazioneOperatore } = useMutations("amministrazioneModule", [
        "setCreazioneOperatore",
    ]);
    setCreazioneOperatore(true);
    // invia al server i dati dell'operatore
    sendOperatore(context)
        .then(() => {
            loadOptionsOperatore(context);
            // se sto modificando un cliente, sono nell'elenco operatori di un concessionario,
            // quindi non serve rinfrescare l'elenco delle aziende
            if (
                operatoreForm.value.categorie.some(
                    (x) => x.categoria == "CLIENTE"
                )
            ) {
                loadOptionsCliente(context);
            }
            if (
                !operatoreForm.value.categorie.some(
                    (x) => x.categoria == "CLIENTE"
                ) &&
                !operatoreForm.value.categorie.some(
                    (x) => x.categoria == "CONCESSIONARIO"
                )
            ) {
                useLoadOptionsAziende();
            }
            setCreazioneOperatore(false);
            context.root.$bvModal.hide("operatoreModal");
            showAlert(
                {
                    message:
                        operatoreAction.value == "add"
                            ? "Operatore creato"
                            : "Operatore aggiornato",
                    variant: "success",
                },
                context.root
            );
        })
        .catch((error) => {
            setCreazioneOperatore(false);
            showAlert({ error: error }, context.root);
        });
}

export async function loadOptionsOperatore(root, pageNum = 1) {
    const { operatoreElencoQuery } = useState("amministrazioneModule", [
        "operatoreElencoQuery",
    ]);
    const { setOptionsOperatore, setNumberOfOperatore } = useMutations(
        "amministrazioneModule",
        ["setOptionsOperatore", "setNumberOfOperatore"]
    );
    try {
        const response = await axios.get(
            `/api/operatori?page=${pageNum}&search=${operatoreElencoQuery.value}`
        );
        setNumberOfOperatore(response.data.count);
        setOptionsOperatore(response.data.results);
        return response;
    } catch (error) {
        showAlert({ error: error }, root);
    }
}

export function onCloseAddOperatore(context) {
    context.root.$bvModal.hide("operatoreModal");
}

export async function sendOperatore(context) {
    const { utente } = useState("loginModule", ["utente"]);
    const {
        amministrazioneCliente,
        amministrazioneConcessionario,
        operatoreAction,
        operatoreForm,
        currentCliente,
        optionsCategorie,
    } = useState("amministrazioneModule", [
        "amministrazioneCliente",
        "amministrazioneConcessionario",
        "operatoreAction",
        "operatoreForm",
        "currentCliente",
        "optionsCategorie",
    ]);

    let payload = JSON.parse(JSON.stringify(operatoreForm.value)); // December 28, 2020

    let servizi = [];
    payload.servizi.forEach((servizio) => {
        if (typeof servizio == "number") {
            servizi.push(servizio);
        } else {
            servizi.push(servizio.id);
        }
    });

    payload.servizi = servizi;
    // modifica operatore ("edit")
    if (operatoreAction.value == "edit") {
        // non si cambia né cliente, né concessionario
        delete payload.cliente;
        delete payload.concessionario;
        delete payload.aziende_di_cui_e_operatore;
        // in fase di retrieve avevo totlo AZIENDA per evitare che possa essere rimossa
        // dall'utente (trasparente all'utente)
        if (amministrazioneCliente.value) {
            payload.categorie.push(
                optionsCategorie.value.find((x) => x.id == "AZIENDA")
            );
        }
    } else {
        // aggiunta operatore ("add")
        if (amministrazioneConcessionario.value) {
            // sta aggiungendo l'operatore il "concessionario"...
            payload.cliente = currentCliente.value.id;
            // l'operatore aggiunto è un operatore CLIENTE amministratore, cioè non può essere
            // assegnato ad operare sull'azienda direttamente
            if (payload.is_admin) {
                payload.categorie.push(
                    optionsCategorie.value.find((x) => x.id == "ADMIN")
                );
            } else {
                payload.categorie.push(
                    optionsCategorie.value.find((x) => x.id == "STAFF")
                );
            }
        } else {
            payload.cliente = currentCliente.value.id;
        }
        // aggiunge la categoria di default per un NUOVO operatore
        // di un CLIENTE (è un'azienda)
        if (amministrazioneCliente.value) {
            payload.categorie.push(
                optionsCategorie.value.find((x) => x.id == "AZIENDA")
            );
        }
        payload.concessionario = utente.value.concessionario.id;
    }

    let options = {};
    if (operatoreAction.value == "add") {
        options = {
            method: "post",
            url: "/api/operatori",
            data: payload,
        };
    } else if (operatoreAction.value == "edit") {
        options = {
            method: "patch",
            url: "/api/operatori/" + payload.id,
            data: payload,
        };
    }
    try {
        return await axios.request(options);
    } catch (error) {
        throw error;
    }
}

export function formatter(value) {
    return value.toUpperCase();
}

export async function onSubmitOperatoreSearch(query, context) {
    const { setOperatoreElencoQuery } = useMutations("amministrazioneModule", [
        "setOperatoreElencoQuery",
    ]);
    setOperatoreElencoQuery(query);
    loadOptionsOperatore(context);
}

export function onResetOperatoreSearch(search_form, context) {
    const { setOperatoreElencoQuery } = useMutations("amministrazioneModule", [
        "setOperatoreElencoQuery",
    ]);
    search_form.value.query = "";
    setOperatoreElencoQuery("");
    loadOptionsOperatore(context);
}

/*
 * categorie da escludere dal select delle categorie ammissibili
 * per un operatore (quando aggiunto dal cliente) -- October 03, 2020
 */
const categorieDaEscludereConcessionario = [
    "CONCESSIONARIO",
    "GRUPPO",
    "AZIENDA",
    "API",
    "MVVONLY",
    "READONLY",
];
const categorieDaEscludereCliente = [
    "CLIENTE",
    "CONCESSIONARIO",
    "API",
    "STAFF",
    "ADMIN",
    "AZIENDA",
    "GRUPPO",
];

export function getOptionsCategorie() {
    const { amministrazioneCliente, amministrazioneConcessionario } = useState(
        "amministrazioneModule",
        ["amministrazioneCliente", "amministrazioneConcessionario"]
    );
    const { optionsCategorie } = useState("amministrazioneModule", [
        "optionsCategorie",
    ]);
    let options = [];
    options = optionsCategorie.value.filter((x) => {
        if (amministrazioneConcessionario.value) {
            return !categorieDaEscludereConcessionario.includes(x.categoria);
        } else if (amministrazioneCliente.value) {
            return !categorieDaEscludereCliente.includes(x.categoria);
        }
    });
    return options;
}

export function extendValidator() {
    var paramNames = ["action"];
    Validator.extend(
        "isOperatoreUnico",
        {
            validate: isOperatoreUnico,
            getMessage: (field, params, data) => {
                return data != undefined ? data.message : ""; // Aug 13, 2019
            },
        },
        {
            immediate: false,
            paramNames,
        }
    );
}
// MAURIZIO+FRANCHETTI+ADMIN@GMAIL.COM

export const isOperatoreUnico = (value, args) => {
    if (args.action == "add") {
        let options = {
            method: "get",
            url: `/api/validate/operatore_unico/${value}`,
        };
        return axios
            .request(options)
            .then((response) => {
                return {
                    valid: response.data.valid,
                    data: {
                        message: response.data.message,
                    },
                };
            })
            .catch((error) => {
                console.log(error);
            });
    } else {
        return {
            valid: true,
            data: {
                message: "",
            },
        };
    }
};

export function customServizioLabel({ codice, note }) {
    return `${note} (${codice})`;
}
