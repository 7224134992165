export const messages = {
    en: {
        message: {
        },
    },
    it: {
        message: {
            importazione_storici_in_corso: "Importazione archivi storici in corso",
            archivio_vuoto: 'L\'archivio è vuoto. Ripristinare i dati dagli archivi SIAN o aggiungerli.',
            no_da_trasmettere: 'Non ci sono dati da trasmettere al SIAN.',
            no_da_completare: 'Non ci sono dati trasmessi da verificare.',
            busta_soap: 'Contenuto della trasmissione al SIAN',
            conferma_mount: 'Si conferma il montaggio degli archivi SIAN?',
            conferma_unmount: 'Si conferma l\'uscita da questa pagina?',
            da_aggiungere: 'Da aggiungere',
            da_cancellare: 'Da cancellare',
            da_completare: 'Da completare',
            da_completare: 'Da completare',
            da_correggere: 'Da correggere',
            da_modificare: 'Da modificare',
            da_reset: 'Da ripristino',
            da_trasmettere: 'Da trasmettere',
            data_allineamento: 'Data allineamento',
            data_disattivazione: 'Data disattivazione',
            data_trasmissione: 'Data trasmissione',
            data_ultima_operazione: 'Data ultimo aggiornamento',
            disattivato: 'Disattivato',
            designazione: 'Designazione',
            estrazione_in_corso: 'Recupero dati dagli archivi SIAN in corso',
            id: 'Id',
            id_trasmissione: 'Id trasmissione',
            messaggio_trasmissione: 'Messaggio',
            nessun_risultato: 'Nessun risultato trovato',
            non_mostrare_ancora: 'Non mostrare ancora',
            resetta_oggetto: 'Ripristina',
            ripristina_prodotto: 'Ripristina prodotto da SIAN',
            ripristina_registrazione: 'Ripristina registrazione da SIAN',
            ripristino_in_corso: 'Ripristino archivi dal SIAN in corso...',
            ripristino_registrazione_in_corso: 'Ripristino registrazione dal SIAN in corso...',
            ripristino_prodotto_in_corso: 'Ripristino prodotto dal SIAN in corso...',
            reset_in_corso: 'Reset in corso...',
            text_conferma_mount: 'I dati presenti negli archivi del SIAN dovranno essere montati per potere essere interrogati. Questa operazione potrebbe richiedere qualche secondo.',
            text_conferma_unmount: 'NOTA. I dati presenti negli archivi del SIAN dovranno essere nuovamente montati per potere essere interrogati.',
            tipo_richiesta: 'Tipo richiesta'
        },
    },
};


