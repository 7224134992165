import axios from "axios";
import { DateTime } from "luxon";
import { useMutations, useRouter, useState } from "@u3u/vue-hooks";
import { mapMutations, mapState } from "vuex";
import { ref, computed } from "@vue/composition-api";
// import { ref, computed } from 'vue'

import { EventBus } from "../../EventBus.js";
import { ResetAllSearch } from "../../mixins/ResetAllSearch.js";
import { checkUserAccess } from "../../utils/access.js";
import { showAlert } from "../../utils/eSianUtils.js";
import { useLoadOptionsComuni } from "../amministrazione/aziende/functions.js";
import {
    initSoggettoOptions,
    initVasoOptions,
    initVignaOptions,
} from "../soggetto/functions.js";
import { verificaChiusuraCampagna } from "../strumenti/verificaChiusuraCampagna.js";
import { categoriaUtente } from "../utente/categoriaUtente.js";
import { getCategoria } from "../utente/InfoUtente.vue";
import SideBarText from "../layouts/SideBarText.vue";
// import SideBar from "./SideBar.vue";
import Logo from "../logo/Logo.vue";
import { getAllAvvisi, getAvvisi } from "../avviso/getAvvisi.js";
import { loading } from "@/utils/getProgress.js";
import { _situazioneCurrentStabilimento } from "@/components/strumenti/Strumenti.vue";
import { verificaRegistrazioni } from "@/components/registrazione/elenco-registrazioni/verifica.js";
import {
    erroriRegistrazioni,
    risultatiRegistrazioni,
} from "@/components/registrazione/store.js";

// amministrazione
import Amministrazione from "./Amministrazione.vue";
import AmministrazioneInfo from "../amministrazione/AmministrazioneInfo.vue";

// spark
// August 14, 2023
// import VasiSIAN from "../vaso/VasiSIAN.vue";

import {
    prodotti_non_tovati_su_e_sian,
    prodotti_committente_non_tovati_su_e_sian,
} from "../prodotto/DataControlloGiacenze.vue";

// import { checkAvvisi } from "../avviso/pollAvvisi.js";

// const comunicazioni = [
//     "DPMC - Produzione Una tantum per azienda Mosto Cotto",
//     "DCRS - Centri Temporanei Raccolta Sottoprodotti",
//     "DVIN - Detenzione Vinacce Per Usi Diversi",
//     "DSTT - Comunicazione Ritiro Sotto Controllo Dei Sottoprodotti Della Vinificazione",
//     "DPVM - Elaborazioni Prodotti A Base Di Vini E Mosti In Stabilimenti Promiscui",
//     "DVMS - Elaborazioni Con Vini E Mosti Presso Spumantifici",
//     "DMO8 - Detenzione Mosti Con Titolo Alcolometrico 8%Vol",
//     "DCO2 - Introduzione Anidride Carbonica",
//     "DSCH - Detenzione Sostanze Chimiche Non Ammesse",
//     "DMCR - Elaborazione Mosto",
//     "DPRA - Dichiarazione Preventiva Pratiche Enologiche",
//     "DLSD - Avviamento Lieviti E Sciroppo Di Dosaggio In Stabilimenti Promiscui",
// ]

let doPollingId = null;

export const sparkSIANIsRunning = ref(false);
export const sparkESianIsRunning = ref(false);
export const showSparkSIANResponse = ref(false);
export const showSparkESianResponse = ref(false);
export const sparkSIANResponse = ref();
export const sparkESianResponse = ref();

const sparkESian = async (root) => {
    showSparkESianResponse.value = true;
    sparkESianResponse.value = [];
    sparkESianIsRunning.value = true;
    const { currentAzienda, currentStabilimento } = useState("loginModule", [
        "currentAzienda",
        "currentStabilimento",
    ]);
    const baseUrl = import.meta.env.VITE_APP_MICROSERVICE;
    const options = {
        method: "get",
        url: `${baseUrl}/getVasiEsian/${currentAzienda.value.azienda_id}/${currentStabilimento.value.id}`,
        withCredentials: true,
    };
    try {
        const response = await axios.request(options);
        sparkESianResponse.value = response;
        sparkESianIsRunning.value = false;
    } catch (err) {
        sparkESianIsRunning.value = false;
        showAlert(
            {
                message: err.response.data,
                variant: "error",
            },
            root
        );
    }
};

const sparkSIAN = async (root) => {
    sparkSIANResponse.value = [];
    showSparkSIANResponse.value = true;
    sparkSIANIsRunning.value = true;
    const { currentAzienda, currentStabilimento } = useState("loginModule", [
        "currentAzienda",
        "currentStabilimento",
    ]);
    const baseUrl = import.meta.env.VITE_APP_MICROSERVICE;
    const options = {
        method: "get",
        url: `${baseUrl}/getVasiSIAN/${currentAzienda.value.azienda_id}/${currentStabilimento.value.id}`,
        withCredentials: true,
    };
    try {
        const response = await axios.request(options);
        sparkSIANResponse.value = response;
        sparkSIANIsRunning.value = false;
    } catch (err) {
        sparkSIANIsRunning.value = false;
        showAlert(
            {
                message: err.response.data,
                variant: "error",
            },
            root
        );
    }
};

export default {
    name: "NavBar",
    components: {
        // ElencoAvvisi,
        Logo,
        SideBarText,
        AmministrazioneInfo,
        Amministrazione,
    },

    setup(props, context) {
        const {
            preferenze,
            nuovaCampagnaForzata,
            isCampagnaAziendaleRiaperta,
            statoCampagnaNonAcquisito,
            systemMessage,
            systemMessageHeight,
            navBarStyleObject,
            systemMessageStyleObject,
            utente,
            isAuthenticated,
            statoStabilimenti,
            currentStabilimento,
            termineInitOptions,
            termineInitOptionsComune,
        } = useState("loginModule", [
            "preferenze",
            "nuovaCampagnaForzata",
            "isCampagnaAziendaleRiaperta",
            "statoCampagnaNonAcquisito",
            "systemMessage",
            "navBarStyleObject",
            "systemMessageHeight",
            "systemMessageStyleObject",
            "utente",
            "isAuthenticated",
            "statoStabilimenti",
            "currentStabilimento",
            "termineInitOptions",
            "termineInitOptionsComune",
        ]);
        const { setSourceArchivio, setTarget } = useMutations(
            "archivioModule",
            ["setSourceArchivio", "setTarget"]
        );

        const showSidebar = ref(false);

        const {
            optionsIcqrf,
            optionsCuaa,
            selectedCuaaObj,
            selectedIcqrf,
            preferred_cuaa,
            preferred_icqrf,
        } = useState("navbarModule", [
            "optionsIcqrf",
            "optionsCuaa",
            "selectedCuaaObj",
            "selectedIcqrf",
            "preferred_cuaa",
            "preferred_icqrf",
        ]);

        // August 12, 2023
        const _sparkSIAN = () => sparkSIAN(context.root);
        const _sparkESian = () => sparkESian(context.root);

        const useOnChangeCuaa = (e) => onChangeCuaa(e, context.root);
        const useOnChangeIcqrf = (e) => onChangeIcqrf(e, context.root);

        const { isInBozzaMVV } = useState("mvvModule", ["isInBozzaMVV"]);

        /*
         * avvisi
         * 2022-12-22
         */
        const { setAvvisiModalIsShown } = useMutations("navbarModule", [
            "setAvvisiModalIsShown",
        ]);
        const {
            avvisi,
            avvisiDiSistemaTrasmissioni,
            avvisiDiSistemaMVV,
            avvisiDiSistemaArchiviazione,
            avvisiModalIsShown,
        } = useState("navbarModule", [
            "avvisi",
            "avvisiDiSistemaTrasmissioni",
            "avvisiDiSistemaMVV",
            "avvisiDiSistemaArchiviazione",
            "avvisiModalIsShown",
        ]);

        const numeroAvvisi = computed(() => {
            let totaleAvvisi = 0;
            if (avvisi.value) {
                totaleAvvisi = avvisi.value?.length;
            }
            if (avvisiDiSistemaMVV.value) {
                totaleAvvisi = totaleAvvisi + avvisiDiSistemaMVV.value.length;
            }
            if (avvisiDiSistemaTrasmissioni.value) {
                totaleAvvisi =
                    totaleAvvisi + avvisiDiSistemaTrasmissioni.value.length;
            }
            if (avvisiDiSistemaArchiviazione.value) {
                totaleAvvisi =
                    totaleAvvisi + avvisiDiSistemaArchiviazione.value.length;
            }
            return totaleAvvisi;
        });

        const showAvvisi = () => {
            setAvvisiModalIsShown(true);
            context.root.$bvModal.show("modaleAvvisi");
        };

        // December 22, 2022
        // let doPollingId = null
        // let pollingCounter = null;
        // let polledAt = null;
        // watchEffect(() => {
        //     debugger
        //     if (isAuthenticated.value && !avvisiModalIsShown.value) {
        //         doPollingId = setTimeout(checkAvvisi, 3000);
        //         pollingCounter++
        //         polledAt = new Date()
        //         console.log(`Server polled at: ${polledAt}, ${pollingCounter} times, (doPollingId: ${doPollingId})`)
        //     }
        // })

        /****************************** end avvisi *****************************/

        const { amministrazioneCliente, amministrazioneConcessionario } =
            useState("amministrazioneModule", [
                "amministrazioneCliente",
                "amministrazioneConcessionario",
            ]);

        const isCampagnaChiusaCurrentStabilimento = computed(() => {
            if (statoStabilimenti.value.stato_stabilimenti) {
                let found = statoStabilimenti.value.stato_stabilimenti.find(
                    (x) => x.icqrf == currentStabilimento.value.icqrf
                );
                return found ? found.stato == "chiuso" : null;
            }
        });

        const classeUtente = (utente) => {
            let categoria = categoriaUtente(utente);
            switch (categoria) {
                case "readonly":
                    return "text-info";
                case "mvvonly":
                    return "text-warning";
                case "mvvplus":
                    return "text-danger";
                case "readonlymvvplus":
                    return "text-success";
            }
        };

        const categorieUtente = (utente) => {
            let categorie = Array();
            utente.categorie.forEach((categoria) => {
                categorie.push(getCategoria(categoria));
            });
            return categorie.join(", ");
        };

        const situazioneCurrentStabilimento = computed(() =>
            _situazioneCurrentStabilimento()
        );

        const classeStrumenti = computed(() => {
            if (statoCampagnaNonAcquisito.value) {
                return "text-danger";
            } else if (!nuovaCampagnaForzata.value) {
                if (
                    statoStabilimenti.value.stabilimenti_aperti != 0 ||
                    !isCampagnaAziendaleRiaperta.value
                ) {
                    return "text-warning";
                }
            }
        });

        // October 22, 2021
        const { router } = useRouter();
        const handleStrumenti = () => {
            if (!statoCampagnaNonAcquisito.value) {
                router.push({ name: "strumenti" });
            } else {
                verificaChiusuraCampagna(
                    selectedCuaaObj.value.id,
                    selectedIcqrf.value.id,
                    context.root
                );
            }
        };

        const blockAccess = computed(() => {
            return false;
        });

        const logoutRoute = computed(() => {
            const user = utente.value;
            const adminUsers = Boolean(
                checkUserAccess(user, { categoria: "CONCESSIONARIO" }) ||
                    (checkUserAccess(user, { categoria: "CLIENTE" }) &&
                        checkUserAccess(user, { categoria: "ADMIN" }))
            );
            return adminUsers ? "logout-administration" : "logout";
        });

        // chiamata dal bottone refresh nel modale avvisi
        const getAvvisiFromModaleAvvisi = () => {
            const fromRefreshButton = true;
            getAllAvvisi(context.root, fromRefreshButton);
        };

        return {
            // August 9-15, 2023
            _sparkSIAN,
            _sparkESian,
            sparkSIANIsRunning,
            sparkESianIsRunning,
            sparkSIANResponse,
            showSparkSIANResponse,
            sparkESianResponse,
            showSparkESianResponse,
            isDevelopment: Boolean(
                import.meta.env.VITE_APP_ENVIRONMENT == "dev"
            ),

            //
            getAvvisiFromModaleAvvisi,
            //
            setAvvisiModalIsShown,
            avvisiModalIsShown,
            //
            logoutRoute,
            blockAccess,
            //
            setTarget,
            setSourceArchivio,
            handleStrumenti,
            classeStrumenti,
            nuovaCampagnaForzata,
            statoStabilimenti,
            isCampagnaAziendaleRiaperta,
            situazioneCurrentStabilimento,
            statoCampagnaNonAcquisito,
            loading,
            systemMessageStyleObject,
            systemMessage, // viene direttamente da spaces su DO
            navBarStyleObject,
            systemMessageHeight,
            useOnChangeCuaa,
            useOnChangeIcqrf,
            avvisi,
            amministrazioneConcessionario,
            amministrazioneCliente,
            showAvvisi,
            classeUtente,
            categoriaUtente,
            categorieUtente,
            isInBozzaMVV,
            termineInitOptions,
            termineInitOptionsComune,
            numeroAvvisi,
            utente,
            isAuthenticated,
            checkUserAccess,
            isCampagnaChiusaCurrentStabilimento,
            //
            nome: import.meta.env.VITE_APP_NOME,
            showSidebar,
            optionsIcqrf,
            optionsCuaa,
            selectedCuaaObj,
            selectedIcqrf,
            preferred_cuaa,
            preferred_icqrf,
        };
    },

    mixins: [ResetAllSearch],

    data() {
        return {
            route: this.$route.name,
        };
    },

    computed: {
        ...mapState("strumentiModule", [
            "ripristinoArchivi",
            "importazioneStorici",
        ]),
        ...mapState("loginModule", [
            "carico",
            "currentAzienda",
            "currentStabilimento",
            "isCampagnaChiusa",
            "verificaCampagnaInCorso",
        ]),
    },

    mounted() {
        this.loadListeners();
    },

    methods: {
        ...mapMutations("loginModule", [
            "setCurrentAzienda",
            "setCurrentStabilimento",
            "setPreferences",
        ]),
        ...mapMutations("navbarModule", [
            "setAvvisi",
            "setPreferred_cuaa",
            "setPreferred_icqrf",
            "setSelectedCuaaObj",
            "setSelectedIcqrf",
            "setOptionsIcqrf",
            "setOptionsCuaa",
        ]),

        /************************************************************
         * imposta la lingua
         ************************************************************/
        // setI18nLanguage,

        /*
         * Jan 19, 2021
         * aziende che abilitate sul SIAN al solo servizio mvv no registri v-v il
         * supporto è parziale perchè ad oggi non sappiamo se il mercato lo
         * richiede. la soluzione è abilitare tutto per una data azienda, sia mvv-
         * che registri così si ha anche lintegrazione con i prodotti, i soggetti
         * ecc. senza dovere gestirli "per designazione"
         */
        soloMvv() {
            return this.selectedCuaaObj != null
                ? this.selectedCuaaObj.solo_mvv
                : false;
        },

        loadListeners() {
            /*
             * SEGNALE RICEVUTO AL LOGIN
             * è ricevuto immediatamente dopo il submit della login
             */

            EventBus.$on("Login:NavBar", () => {
                if (
                    !this.amministrazioneConcessionario &&
                    !this.amministrazioneCliente
                ) {
                    // se ci sono delle preferenze settate, lette in login, le recupera...
                    this.setPreferred_cuaa(
                        this.utente.preferenze != null
                            ? this.utente.preferenze.last_cuaa
                            : null
                    );
                    this.setPreferred_icqrf(
                        this.utente.preferenze != null
                            ? this.utente.preferenze.last_icqrf
                            : null
                    );
                    // crea le optionsCuaa rimappando aziende
                    this.utente.aziende_di_cui_e_operatore?.forEach((obj) => {
                        this.optionsCuaa.push({
                            id: obj.id,
                            ragione_sociale: this.$filters.truncate(
                                obj.ragione_sociale,
                                48
                            ),
                            solo_mvv: obj.solo_mvv,
                            cuaa: obj.cuaa,
                            ambiente_operativo_esercizio:
                                obj.ambiente_operativo_esercizio,
                            stabilimenti_aziendali: obj.stabilimenti_aziendali,
                            // December 28, 2022
                            servizi_ammessi: obj.servizi_ammessi,
                        });
                    });

                    // assegna la eventuale preferenza al cuaa
                    if (this.preferred_cuaa != null) {
                        this.setSelectedCuaaObj(
                            this.optionsCuaa.find(
                                (x) => x.cuaa == this.preferred_cuaa
                            )
                        );
                    }

                    // solo dopo avere eventualmente assegnato il cuaa, crea le
                    // optionsIcqrf
                    var optionsIcqrf =
                        this.selectedCuaaObj != null
                            ? this.optionsCuaa.find(
                                  (x) => x.cuaa == this.selectedCuaaObj.cuaa
                              )
                            : null;
                    this.setOptionsIcqrf(
                        optionsIcqrf != null
                            ? optionsIcqrf.stabilimenti_aziendali
                            : []
                    );
                    // assegna la eventuale preferenza all'icqrf
                    if (this.preferred_icqrf != null) {
                        this.setSelectedIcqrf(
                            this.optionsIcqrf.find(
                                (x) => x.icqrf == this.preferred_icqrf
                            )
                        );
                    }

                    // June 22, 2019
                    // imposta l'azienda corrente in vuex
                    this.setCurrentAzienda({
                        ...this.$store.state.loginModule.currentAzienda,
                        solo_mvv:
                            this.selectedCuaaObj != null
                                ? this.selectedCuaaObj.solo_mvv
                                : false,
                        azienda_id:
                            this.selectedCuaaObj != null
                                ? this.selectedCuaaObj.id
                                : null,
                        stabilimento_id:
                            this.selectedIcqrf != null
                                ? this.selectedIcqrf.id
                                : null,
                        icqrf:
                            this.selectedIcqrf != null
                                ? this.selectedIcqrf.icqrf
                                : null,
                        // Sep 05, 2019
                        ragione_sociale:
                            this.selectedCuaaObj != null
                                ? this.selectedCuaaObj.ragione_sociale
                                : null,
                        cuaa:
                            this.selectedCuaaObj != null
                                ? this.selectedCuaaObj.cuaa
                                : null,
                        stabilimenti_aziendali:
                            this.selectedCuaaObj != null
                                ? this.selectedCuaaObj.stabilimenti_aziendali
                                : null,
                        ambiente_operativo_esercizio:
                            this.selectedCuaaObj != null
                                ? this.selectedCuaaObj
                                      .ambiente_operativo_esercizio
                                : null,
                        // December 28, 2022
                        servizi_ammessi:
                            this.selectedCuaaObj != null
                                ? this.selectedCuaaObj.servizi_ammessi
                                : null,
                    });

                    // imposta lo stabilimento corrente in vuex
                    this.setCurrentStabilimento(this.selectedIcqrf);

                    /*
                     * ASSEGNAZIONE PREFERENZE in vuex
                     * le preferenze sono ricevute assieme all'utente in login
                     */
                    this.setPreferences({
                        ...this.preferenze,
                        checkAvvisiMvv: this.utente.preferenze.checkAvvisiMvv,
                        ricevi_email_avvisi:
                            this.utente.preferenze.ricevi_email_avvisi, //  January 23, 2022  January 15, 2023
                        mostraAvvisiInHome:
                            this.utente.preferenze.mostraAvvisiInHome, //  November 24, 2021
                        mostraDisattivati:
                            this.utente.preferenze.mostraDisattivati, //  November 23, 2020
                        mostraSaldi: this.utente.preferenze.mostraSaldi, //  November 19, 2020
                        messaggiElenchi: this.utente.preferenze.messaggiElenchi, //
                        creaRegistrazioneDaMvv:
                            this.utente.preferenze.creaRegistrazioneDaMvv, // Dec 05, 2019
                        giacenzeNegativeRegistrazioneDaMvv:
                            this.utente.preferenze
                                .giacenzeNegativeRegistrazioneDaMvv, // December 02, 2020
                        confermaTrasmissione:
                            this.utente.preferenze.confermaTrasmissione, // Sep 05, 2019
                        last_cuaa: this.utente.preferenze.last_cuaa,
                        last_icqrf: this.utente.preferenze.last_icqrf,
                        //
                        registrazioni_data_futura:
                            this.utente.preferenze.registrazioni_data_futura,
                        registrazioni_info:
                            this.utente.preferenze.registrazioni_info,
                        registrazioni_alert:
                            this.utente.preferenze.registrazioni_alert,
                    });

                    EventBus.$emit("NavBar:App", {
                        selectedCuaaObj: this.selectedCuaaObj,
                        selectedIcqrf: this.selectedIcqrf,
                        cuaa:
                            this.selectedCuaaObj != null
                                ? this.selectedCuaaObj.cuaa
                                : null,
                        solo_mvv:
                            this.selectedCuaaObj != null
                                ? this.selectedCuaaObj.solo_mvv
                                : false,
                        azienda_id:
                            this.selectedCuaaObj != null
                                ? this.selectedCuaaObj.id
                                : null,
                        ambiente_operativo_esercizio:
                            this.selectedCuaaObj != null
                                ? this.selectedCuaaObj
                                      .ambiente_operativo_esercizio
                                : null,
                        icqrf:
                            this.selectedIcqrf != null
                                ? this.selectedIcqrf.icqrf
                                : null,
                        stabilimento_id:
                            this.selectedIcqrf != null
                                ? this.selectedIcqrf.id
                                : null,

                        utente: this.utente,
                    });

                    // February 13, 2023
                    if (!this.currentAzienda.cuaa) {
                        showAlert(
                            {
                                message: "Selezionare un'azienda",
                                variant: "warning",
                            },
                            this
                        );
                    } else {
                        getAllAvvisi(this);

                        // aggiornaGiacenza();

                        /********************************************************************
                         * VERIFICA CHIUSURA CAMPAGNA
                         * per la gestione dei locked delle registrazioni nell'intercampagna
                         * alla login. una volta conosciuti l'azienda e lo stabilimento...
                         *********************************************************************/
                        if (
                            this.selectedCuaaObj != null &&
                            this.selectedIcqrf != null
                        ) {
                            verificaChiusuraCampagna(
                                this.selectedCuaaObj.id,
                                this.selectedIcqrf.id,
                                this
                            );
                        }

                        if (
                            this.selectedCuaaObj != null &&
                            this.selectedIcqrf != null
                        ) {
                            initVignaOptions(
                                this.selectedCuaaObj.id,
                                this.selectedIcqrf.id,
                                this
                            );

                            initVasoOptions(
                                this.selectedCuaaObj.id,
                                this.selectedIcqrf.id
                            );

                            initSoggettoOptions(this.selectedCuaaObj.id, this);
                        }

                        // November 10, 2021
                        // verifica le registrazioni in entrata in una azienda/stabilimento
                        // erroriRegistrazioni.value.verificaRegistrazioni = []
                        // if (!loading.value.verificaRegistrazioni) {
                        //     set(erroriRegistrazioni.value, "verificaRegistrazioni", []);
                        //     verificaRegistrazioni(true, this);
                        // }
                    }

                    // December 24, 2022
                    initFormatiOptions();
                }

                // caricamento opzioni comuni italiani
                useLoadOptionsComuni();
            });

            // segnale ricevuto al logout
            // EventBus.$on("Logout:NavBar", () => {
            //     this.optionsCuaa = [];
            //     this.optionsIcqrf = [];
            // });
        },
    },

    beforeDestroy() {
        EventBus.$off("Login:NavBar");
        // EventBus.$off("Logout:NavBar");
    },
};

const initFormatiOptions = async (optionsFormati) => {
    const { setFormato } = useMutations("operazioneModule", ["setFormato"]);
    const options = {
        method: "get",
        url: `/api/prodotto/get_formati`,
    };
    try {
        const response = await axios.request(options);
        setFormato(response.data);
    } catch (error) {
        console.log(error);
    }
};

const saveCurrentAziendaPreferences = async (root) => {
    const { utente } = useState("loginModule", ["utente"]);
    const { currentAzienda, currentStabilimento } = useState("loginModule", [
        "currentAzienda",
        "currentStabilimento",
    ]);
    const options = {
        method: "PATCH",
        url: `/api/preferenze/${utente.value.id}`,
        data: {
            last_cuaa: currentAzienda.value.cuaa,
            last_icqrf: currentStabilimento.value.icqrf,
        },
    };
    try {
        await axios.request(options);
        // console.log(`Azienda e stabilimento corrente salvati nelle preferenze dell'utente (${utente.value.email}).`)
    } catch (error) {
        showAlert({ error: error }, root);
    }
};

export const onChangeCuaa = (
    e,
    root,
    noMessage = false,
    routeName = null,
    hash = "",
    doNotVerificaChiusuraCampagna = false
) => {
    const { route, router } = useRouter();
    const { setCurrentAzienda } = useMutations("loginModule", [
        "setCurrentAzienda",
    ]);
    const { currentAzienda } = useState("loginModule", ["currentAzienda"]);
    const { optionsIcqrf, optionsCuaa } = useState("navbarModule", [
        "optionsIcqrf",
        "optionsCuaa",
    ]);
    const { setOptionsIcqrf, setSelectedCuaaObj, setSelectedIcqrf } =
        useMutations("navbarModule", [
            "setOptionsIcqrf",
            "setSelectedCuaaObj",
            "setSelectedIcqrf",
        ]);
    const { setAvvisoImportazioneStruttura } = useMutations(
        "vinificazioneModule",
        ["setAvvisoImportazioneStruttura"]
    );

    const { avvisi } = useState("navbarModule", ["avvisi"]);

    // importazione struttura viticola - Jun 08, 2021
    setAvvisoImportazioneStruttura(false);

    EventBus.$emit("NavBar:cuaa:App", {
        cuaa: e.cuaa,
        azienda_id: e.id,
    });
    setSelectedCuaaObj(e);
    setSelectedIcqrf(null);

    // crea la lista di stabilimenti
    setOptionsIcqrf(
        optionsCuaa.value.find((x) => x.cuaa == e.cuaa).stabilimenti_aziendali
    );

    resetAllSearch();

    setCurrentAzienda({
        ...currentAzienda.value,
        cuaa: e.cuaa,
        azienda_id: e.id, // July 13, 2019
        ragione_sociale: e.ragione_sociale, // Sep 06, 2019
        stabilimenti_aziendali: e.stabilimenti_aziendali, //  August 09, 2020
        ambiente_operativo_esercizio: e.ambiente_operativo_esercizio, // Apr 16, 2021
        solo_mvv: e.solo_mvv,
        // January 16, 2023
        servizi_ammessi: e?.servizi_ammessi,
    });
    if (optionsIcqrf.value.length == 1) {
        // assegna come icqrf quello dell'unico stabilimento ...
        setSelectedIcqrf(optionsIcqrf.value[0]);
        onChangeIcqrf(optionsIcqrf.value[0], root);
    } else if (!noMessage) {
        // ...altrimenti chiede all'utente di scegliere quale usare
        showAlert(
            { message: "Immettere il codice ICQRF dello stabilimento" },
            root
        );
    }

    /*
     * dopo avere cambiato l'azienda:
     * - carica gli avvisi
     * - va automaticamente ad home
     *
     */

    // riprende gli avvisi amministrativi (che potrebbero essere diversi)
    getAvvisi(root).then(() => {
        if (avvisi.value.length > 0) {
            root.$bvModal.show("modaleAvvisi");
        }
    });

    if (
        (routeName == null || routeName == "home") &&
        route.value.name != "home"
    ) {
        router.push({
            name: "home",
            params: { fromNavBar: true },
            hash: hash,
        });
        // } else {
        //     // se arrriva dall'elenco avvisi mvv/trasmissioni (goToAzienda), perchè
        //     // l'utente ha cliccato sulla freccia legata allo stabilimento
        //     // aziendale, non fa rivedere gli avvisi
        //     if (!noMessage) {
        //         getAvvisi(root).then(() => {
        //             if (avvisi.value.length > 0) {
        //                 root.$bvModal.show("modaleAvvisi");
        //             }
        //         });
        //     }
    }
};

export const onChangeIcqrf = async (
    e,
    root,
    routeName = null,
    hash = "",
    doNotVerificaChiusuraCampagna = false
) => {
    const { route, router } = useRouter();
    const { setCurrentAzienda, setCurrentStabilimento } = useMutations(
        "loginModule",
        ["setCurrentAzienda", "setCurrentStabilimento"]
    );
    const { currentAzienda, statoCampagnaNonAcquisito } = useState(
        "loginModule",
        ["currentAzienda", "statoCampagnaNonAcquisito"]
    );
    const { selectedCuaaObj } = useState("navbarModule", ["selectedCuaaObj"]);
    const { setSelectedIcqrf } = useMutations("navbarModule", [
        "setSelectedIcqrf",
    ]);

    setSelectedIcqrf(e);

    EventBus.$emit("NavBar:icqrf:App", {
        icqrf: e.icqrf,
        stabilimento_id: e.id,
    });

    // June 22, 2019
    setCurrentAzienda({
        ...currentAzienda.value,
        icqrf: e.icqrf,
        stabilimento_id: e.id, // Jul 13, 2019
    });

    setCurrentStabilimento(e);

    resetAllSearch();

    // appena sono impostati cuaa e icqrf, salvo queste preferenze
    saveCurrentAziendaPreferences(root);

    // aggiornaGiacenza();

    /********************************************************************
     * ogni volta che cambio lo stabilimento verifico la chiusura della
     * campagna (anche in entrata nella app...) se lo stato non è stato
     * acquisito in precedenza per problemi del sian, allora forzo comunque il
     * check  || statoCampagnaNonAcquisito.value
     *********************************************************************/
    // if (!currentAzienda.value.solo_mvv) {
    // if (!doNotVerificaChiusuraCampagna) {
    // al suo interno resetta il parametro "statoCampagnaNonAcquisito"
    verificaChiusuraCampagna(selectedCuaaObj.value.id, e.id, root);
    // }
    // }

    // Nov 12, 2019
    initVignaOptions(currentAzienda.value.azienda_id, e.id, root);
    initVasoOptions(currentAzienda.value.azienda_id, e.id, root);
    initSoggettoOptions(currentAzienda.value.azienda_id, root);

    // November 10, 2021
    // verifica le registrazioni in entrata in una azienda/stabilimento
    // erroriRegistrazioni.value.verificaRegistrazioni = []
    // set(erroriRegistrazioni.value, "verificaRegistrazioni", []);
    // verificaRegistrazioni(true, root);

    /*
     * dopo avere cambiato lo stabilimento...
     */
    if (
        routeName != null &&
        routeName != "home" /* && route.value.name != routeName */
    ) {
        router.push(
            {
                name: routeName,
                params: { fromNavBar: true },
                hash: hash,
            },
            () => {}
        ); // https://stackoverflow.com/questions/58540009/got-uncaught-in-promise-navigationduplicated-error-on-invalid-credentials
    }
    // manda sempre ad home e si evitano effetti collaterali su eventuali
    // operazioni che si erano cominciate nello stabilimento precedente (September 02, 2020)
    if (
        (routeName == null || routeName == "home") &&
        route.value.name != "home"
    ) {
        router.push({
            name: "home",
            params: { fromNavBar: true },
            hash: hash,
        });
    }
};

import { results } from "@/components/archiviazione/getDocumenti.js";

export const resetAllSearch = () => {
    const { setModalSearch, setModalSearchPath, setSearchRegistrazioneParams } =
        useMutations("operazioneModule", [
            "setModalSearch",
            "setModalSearchPath",
            "setSearchRegistrazioneParams",
        ]);

    const {
        setProdottiPropriProblematici,
        setProdottiCommittentiProblematici,
        setCodiceCommittente,
        setGiacenzaCorrenteCompute,
        setGiacenzaCorrenteDate,
        setVisualizzaGiacenzeCommittenti,
        setVisualizzaGiacenzePropri,
        setVisualizzaGiacenzePath,
        setModalProdottoSearch,
        setModalProdottoSearchPath,
        setProdottoAddendumPath,
        setProdottoSearchParams,
        setSearchByCommittente,
        setVisualizzaGiacenzeParams,
        setVisualizzaProdottoSearchPath,
    } = useMutations("prodottoModule", [
        "setProdottiPropriProblematici",
        "setProdottiCommittentiProblematici",
        "setCodiceCommittente",
        "setGiacenzaCorrenteCompute",
        "setGiacenzaCorrenteDate",
        "setVisualizzaGiacenzeCommittenti",
        "setVisualizzaGiacenzePropri",
        "setVisualizzaGiacenzePath",
        "setModalProdottoSearch",
        "setModalProdottoSearchPath",
        "setProdottoAddendumPath",
        "setProdottoSearchParams",
        "setSearchByCommittente",
        "setVisualizzaGiacenzeParams",
        "setVisualizzaProdottoSearchPath",
    ]);

    /*
     * pulizia vettore documenti
     */
    results.value = [];

    // dati controllo giacenze
    setProdottoAddendumPath("");
    setProdottiPropriProblematici([]);
    setProdottiCommittentiProblematici([]);
    prodotti_non_tovati_su_e_sian.value = [];
    prodotti_committente_non_tovati_su_e_sian.value = [];

    EventBus.$emit("resetModalProdottoSearch");
    EventBus.$emit("resetModalSearch");

    setCodiceCommittente({ codiceCommittente: null });
    setVisualizzaGiacenzePath("");
    setGiacenzaCorrenteDate(DateTime.local());
    setGiacenzaCorrenteCompute(0);
    setVisualizzaGiacenzePropri(true);
    setVisualizzaGiacenzeCommittenti(false);

    setModalProdottoSearch(false);
    setModalProdottoSearchPath("");

    setModalSearch(false);
    setModalSearchPath("");

    // setMvvReceivedList([]);
    // setMvvSentList([]);
    // setNumberDraft(0);
    // setNumberReceived(0);
    // setNumberSent(0);

    setProdottoAddendumPath("");
    setSearchByCommittente({ searchByCommittente: false });
    setVisualizzaProdottoSearchPath("");
    setSearchRegistrazioneParams({
        data_inizio: "",
        data_fine: "",
        num_registrazione: "",
        codice_registrazione: "",
        num_documento: "",
        filtraProdotti: "",
        vinificazione: "",
        vinificazione_id: "",
        con_documenti: "",
        con_documenti_in_scadenza: "",
        annata: "",
        data_documento: "",
        cliente: null,
        committente: null,
        query: "",
        annata: "",
        note: "",
    });

    setProdottoSearchParams({
        con_documenti: null,
        con_documenti_in_scadenza: null,
        annata: "",
        categoria: null,
        classificazione: null,
        pratiche_enologiche: null,
        committente: null,
        vinificazione_fk: null,
        stato_fisico: null,
        query: "",
    });

    setVisualizzaGiacenzeParams({
        data_giacenza: new Date(),
        giacenze_propri: false,
        giacenze_committenti: false,
    });
};
