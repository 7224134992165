
<div>
    <b-container class="mt-3">
        <b-form-row>
            <b-col>
                <h4>
                    {{ $t("chiusura_campagna") }}
                </h4>

                <p class="font-weight-light">
                    {{
                        currentAzienda != null
                            ? currentAzienda.ragione_sociale
                            : ""
                    }}
                    ({{
                        currentAzienda != null ? currentAzienda.cuaa : ""
                    }}), stabilimento
                    {{
                        currentStabilimento != null
                            ? '"' + currentStabilimento.descrizione + '"'
                            : ""
                    }}
                    ({{
                        currentStabilimento != null
                            ? currentStabilimento.icqrf
                            : ""
                    }})
                </p>

                <p>
                    La chiusura viene chiesta al SIAN che effettuerà
                    automaticamente le registrazioni chiusura e riapertura
                    (GIIN) della campagna per lo stabilimento.
                </p>

                <div v-if="situazioneCurrentStabilimento.stato == 'aperto'">
                    <b-alert show variant="warning" class="mt-3"
                        >La campagna dello <b>stabilimento</b> va chiusa
                        (campagna aziendale corrente:
                        {{
                            dataInizioCampagna.toLocaleDateString("it-IT")
                        }}-{{
                            dataFineCampagna.toLocaleDateString("it-IT")
                        }}).
                    </b-alert>
                </div>

                <div
                    v-if="
                        situazioneCurrentStabilimento.stato == 'chiuso' &&
                        !isCampagnaAziendaleRiaperta
                    "
                >
                    <b-alert
                        show
                        variant="warning"
                        class="mt-3"
                        v-if="!isCampagnaAziendaleRiaperta"
                    >
                        <p>
                            La campagna dello <b>stabilimento</b> è stata
                            chiusa
                        </p>
                        <p>
                            La campagna <b>aziendale</b> va aperta (campagna
                            aziendale corrente:
                            {{
                                dataInizioCampagna.toLocaleDateString(
                                    "it-IT"
                                )
                            }}-{{
                                dataFineCampagna.toLocaleDateString(
                                    "it-IT"
                                )
                            }}).
                        </p>
                    </b-alert>
                </div>
                <!--------------------- bottone richiesta chiusura campagna stabilimento ---------------------->
                <b-form-group>
                    <b-btn
                        variant="primary"
                        class="mt-2"
                        :disabled="
                            situazioneCurrentStabilimento.stato == 'chiuso'
                        "
                        @click.prevent="handleChiusuraCampagna"
                    >
                        <template v-if="loadingRichiestaChiusura">
                            <b-spinner small></b-spinner>
                            Richiesta in corso...
                        </template>
                        <template v-else
                            >Richiedi chiusura stabilimento
                            <b>{{ currentStabilimento.icqrf }}</b>
                        </template>
                    </b-btn>
                </b-form-group>
            </b-col>
        </b-form-row>

        <p class="lead mt-3">
            ATTENZIONE! <b>Dopo</b> avere richiesto la chiusura al SIAN è
            necessario verificare lo stato della richiesta.
        </p>

        <b-form-row class="mt-3">
            <b-col>
                <b-form-group>
                    <b-input-group-append>
                        <b-btn
                            @click.prevent="handleVerificaCampagna"
                            :disabled="verificaCampagnaInCorso"
                            variant="primary"
                        >
                            <template v-if="verificaCampagnaInCorso">
                                <b-spinner small></b-spinner>
                                Verifica in corso...
                            </template>
                            <template v-else>Verifica chiusura </template>
                        </b-btn>
                    </b-input-group-append>
                </b-form-group>
            </b-col>
        </b-form-row>

        <hr />
    </b-container>
</div>
