
<b-container class="mt-3">
  <b-tabs>
      
    <!-- <b-tab active title="Indirizzo"> -->
    <!--   <azienda-indirizzo -->
    <!--     :azienda_id="azienda_id" -->
    <!--     :form="form" -->
    <!--   /> -->
    <!-- </b-tab> -->

    <b-tab title="Stabilimenti">
      <azienda-stabilimento :stabilimenti="stabilimenti" />
    </b-tab>

    <b-tab title="Parametri codici aziendali" 
          v-if="checkUserAccess(utente, {servizio: 'XML', azienda: currentAzienda})"
    >
        <parametri-codici-aziendali 
            :cuaa="cuaa" 
            :azienda_id="azienda_id" 
        />
    </b-tab>

    <b-tab title="Servizi ammessi" 
    >
      <h5 class="mt-3">Servizi ammessi per l'azienda</h5>
      <ul>
        <li v-for="servizio in currentAzienda.servizi_ammessi">
          {{ servizio. codice }} <span class="text-muted">({{ servizio.stato }})</span>
        </li>
      </ul>
    </b-tab>

  </b-tabs>
</b-container>
