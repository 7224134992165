
<div>
  <b-container class="mt-3 mb-5">
    <h4>{{ $t("storici") }}</h4>

    <p class="font-weight-light">
      {{ currentAzienda != null ? currentAzienda.ragione_sociale : "" }} ({{
        currentAzienda != null ? currentAzienda.cuaa : ""
      }}). Tutti gli stabilimenti.
    </p>

    <p class="lead">{{ $t("testo_storici") }}</p>

    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
      Formulare una nuova richiesta limitando il numero di dati storici
      richiesti attraverso l'uso dei filtri di ricerca
    </b-alert>

    <b-form-row class="mt-3">
      <b-col sm="12" lg="6">
        <b-form-group
          :label="$t('data_inizio') + ' (data di inizio)'"
          :state="v$.form.data_inizio.$error ? false : null"
          :invalid-feedback="
            v$.form.data_inizio.$error &&
            v$.form.data_inizio.$errors.length > 0
              ? v$.form.data_inizio.$errors[0].$response
                ? v$.form.data_inizio.$errors[0].$response.data.message
                : v$.form.data_inizio.$errors[0].$message
              : null
          "
        >
          <b-form-datepicker
            v-model="form.data_inizio"
            locale="it-IT"
            label-help=""
            start-weekday="1"
            hide-header
            value-as-date
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            placeholder="Scegliere una data..."
            :state="v$.form.data_inizio.$error ? false : null"
            :disabled="importazioneStorici || loading.storici || ripristinoArchivi || !isCampagnaAziendaleRiaperta"
          />
          <b-form-text
            >Data da cui iniziare l'importazione degli archivi storici
            (operazioni).</b-form-text
          >
        </b-form-group>
      </b-col>

      <b-col sm="12" lg="6">
        <b-form-group
          :label="$t('data_fine') + ' (data di fine)'"
          :state="v$.form.data_fine.$error ? false : null"
          :invalid-feedback="
            v$.form.data_fine.$error && v$.form.data_fine.$errors.length > 0
              ? v$.form.data_fine.$errors[0].$response
                ? v$.form.data_fine.$errors[0].$response.data.message
                : v$.form.data_fine.$errors[0].$message
              : null
          "
        >
          <b-form-datepicker
            v-model="form.data_fine"
            locale="it-IT"
            label-help=""
            start-weekday="1"
            hide-header
            value-as-date
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            placeholder="Scegliere una data..."
            :state="v$.form.data_fine.$error ? false : null"
            :disabled="importazioneStorici || loading.storici || ripristinoArchivi || !isCampagnaAziendaleRiaperta"
          />
          <b-form-text
            >Data in cui terminare l'importazione degli archivi
            storici</b-form-text
          >
        </b-form-group>
      </b-col>
    </b-form-row>

    <hr class="mt-0" />

    <b-form-row>
      <b-col class="text-left">
        <b-button
          @click="onReset"
          variant="light"
          class="mr-2"
          :disabled="loading.storici"
          >Annulla</b-button
        >
        <b-button
          @click="onSubmit"
          :variant="v$.$invalid ? 'danger' : 'primary'"
          :disabled="v$.$invalid || loading.storici || ripristinoArchivi || !isCampagnaAziendaleRiaperta"
          >{{ $t("conferma") }}</b-button
        >
      </b-col>
    </b-form-row>

    <!-- <b-progress v-if="loading.storici" :max="loadingTotal['storici']" class="mt-3" height="1.5rem"> -->
    <!--     <b-progress-bar show-value :value="loadingCurrent.storici">{{ loadingMessage.storici }}</b-progress-bar> -->
    <!-- </b-progress> -->

    <h4 class="mt-5">Rimozione dati storici importati</h4>
    <p class="lead">Rimozione da e-Sian dei dati storici importati</p>
    <b-btn
      @click="handleStorici"
      id="id_storici"
      :disabled="importazioneStorici || loading.storici || ripristinoArchivi"
      variant="primary"
    >
      <template>{{ $t("Rimuovi dati storici") }}</template>
      <!-- <template v-else> -->
      <!--   {{ loadingMessage }} -->
      <!--   <b-spinner small class="ml-1"></b-spinner> -->
      <!-- </template> -->
    </b-btn>

  </b-container>

  <b-modal id="loadingModalStorici" @hide="handleHideStorici" class="text-center" centered hide-header hide-footer>
    <!-- <b-progress :value="current" :max="total"></b-progress> -->
    <b-spinner class="mr-1" small :label="loadingMessage.storici" />{{
      loadingMessage.storici
    }}
  </b-modal>

</div>
