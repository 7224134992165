
export const operazioneModule = {
  namespaced: true,
  state: {

      // December 20, 2022 
      titolo: "",
      identificativo: "",

      registrazioneDaClonare: null,

      numberOfRegistrazioniImportate: null,
      requiredFieldsDati: null,
      disabledFieldsDati: null,
      // requiredFieldsRighe: null,
      // disabledFieldsRighe: null,

    //tabMovimentiClicked: false,
    //semaforo: false,
    //vettoreOptionsProdotti: [],
    registrazioneErrors: [],
    buttonErrors: [],
    erroreDatiAccessori: false,
    firstEntryInRighe: false,
    erroreGiacenza: false,
    action: null,
    form: {
      azienda: "",
      stabilimento: "",
      codice_registrazione: null,
      num_registrazione: null,
      data_registrazione: "",
      num_documento: null,
      data_documento: "",
      cliente: null,
      committente: null,
      esonero_deroga: null,
      note: null,
      // è un vettore di oggetti!
      righe: [
        {
          nuova: true,
          ordine: 0,
          carico_scarico: null,
          prodotto: null,
          quantita: null,
          quantita_iniziale: 0,
          //
          quantita_perdita: null,
          tit_alcol_pot: null,
          tit_alcol_eff: null,
          tit_alcol_tot: null,
          perc_igp: null,
          gg_invecchiamento: null,
          tenore_zuccheri: null,
          metodo_prat_eno: null,
          altri_trattamenti: null,
          recipienti_scarico: [],
          dati: [
            {
              vol_conf_iniziale: 0,
              num_conf_iniziale: 0,
              vol_conf: 0,
              num_conf: null,
              lotto: null,
              serie_contr: null,
              n_iniz_serie: null,
              n_fine_serie: null,
            },
          ],
        },
      ],
    },
    verificaRegistrazioni: false,
    formato: null,
    // formato: [
    //   { valore: 0.03 , descr: "0.030" },
    //   { valore: 0.066, descr: "0.066" },
    //   { valore: 0.1  , descr: "0.100" },
    //   { valore: 0.187, descr: "0.187" },
    //   { valore: 0.2  , descr: "0.200" },
    //   { valore: 0.25 , descr: "0.250" },
    //   { valore: 0.375, descr: "0.375" },
    //   { valore: 0.5  , descr: "0.500" },
    //   { valore: 0.72 , descr: "0.720" },
    //   { valore: 0.75 , descr: "0.750" },
    //   { valore: 1    , descr: "1.000" },
    //   { valore: 1.5  , descr: "1.500" },
    //   { valore: 2    , descr: "2.000" },
    //   { valore: 2.25 , descr: "2.250" },
    //   { valore: 2.9  , descr: "2.900" },
    //   { valore: 3    , descr: "3.000" },
    //   { valore: 3.8  , descr: "3.800" },
    //   { valore: 5    , descr: "5.000" },
    //   { valore: 6    , descr: "6.000" },
    //   { valore: 9    , descr: "9.000" },
    //   { valore: 10   , descr: "10.000" },
    //   { valore: 12   , descr: "12.000" },
    //   { valore: 15   , descr: "15.000" },
    //   { valore: 17   , descr: "17.000" },
    //   { valore: 18   , descr: "18.000" },
    //   { valore: 19.5 , descr: "19.500" },
    //   { valore: 20   , descr: "20.000" },
    //   { valore: 23   , descr: "23.000" },
    //   { valore: 24   , descr: "24.000" },
    //   { valore: 25   , descr: "25.000" },
    //   { valore: 26   , descr: "26.000" },
    //   { valore: 27   , descr: "27.000" },
    //   { valore: 28   , descr: "28.000" },
    //   { valore: 30   , descr: "30.000" },
    //   { valore: 34   , descr: "34.000" },
    //   { valore: 45   , descr: "45.000" },
    //   { valore: 50   , descr: "50.000" },
    //   { valore: 52   , descr: "52.000" },
    //   { valore: 54   , descr: "54.000" },
    //   { valore: 55   , descr: "55.000" },
    // ],
    showSpinnerMovimentiRegistrazione: false,
    showSpinnerDatiRegistrazione: false,
    committenteOperazione: null,
    isOnSian: true,
    modalSearch: false, 
    modalSearchPath: '', 
    numOperazione: 1,
    loadingRipristina: false,
    loadingProdottoModal: { riga: null, azione: null, valore: false},
    righeRegistrazioneErrorSecondoLivello: {
      errore: false,
      ordine: null,
    },
    //optionsProdotti: [],
    optionsVaso: [],
    optionsVigna: [],
    optionsSoggetto: [],
    optionsTrasportatore: [],
    //dataOperazione: null,
    //isDataOperazioneChanged: false,
    // Sep 21, 2019
    searchRegistrazioneParams: {
      data_inizio: '', 
      data_fine: '',
      codice_registrazione: null, 
      num_registrazione: "",
      num_documento: "",
      data_documento: '',
      cliente: null,
      committente: null,
      query: '',
      note: '',
      annata: '',
    },

    firstRun: false,
    codiceOperazione: null,
    tipoOperazione: {
      aggiungi: false,
      modifica: false,
      duplica: false,
      visualizza: false,
    },
    disabilitaCodiceOperazione: false,
    isCommittenteChanged: false,
    isNumRegChanged: false,

  },
  getters: {},
  mutations: {

    // December 24, 2022 
    setFormato(state, payload) {
      state.formato = payload 
    },

    // December 20, 2022 
    setIdentificativo(state, payload) {state.identificativo = payload},
    setTitolo(state, payload) {state.titolo = payload},


    setRegistrazioneDaClonare(state, payload) {state.registrazioneDaClonare = payload},

    setNumberOfRegistrazioniImportate(state, payload) {state.numberOfRegistrazioniImportate = payload},

    setRequiredFieldsDati(state, payload) {state.requiredFieldsDati = payload},
    setDisabledFieldsDati(state, payload) {state.disabledFieldsDati = payload},
    setLoadingProdottoModal(state, payload) {state.loadingProdottoModal = payload},
    setRegistrazioneErrors(state, payload) {state.registrazioneErrors = payload},
    setButtonErrors(state, payload) {state.buttonErrors = payload},
    setErroreDatiAcessori(state, payload) {state.erroreDatiAccessori = payload},
    setIsNumRegChanged(state, payload) {state.isNumRegChanged = payload},
    setFirstEntryInRighe(state, payload) {state.firstEntryInRighe = payload},
    setAction(state, payload) {
      state.action = payload
    },
    setForm(state, payload) {
      state.form = payload 
    },
    // Sep 14, 2019
    // Vuex mutations are essentially events: each mutation has a name and a handler.
    // Actions: Actions are just functions that dispatch mutations.
    //
    // Mutations are synchronous, whereas actions can be asynchronous.
    /*
     * - mutation is the only way to modify state
     * - mutation doesn't care about business logic, it just cares about "state"
     * - action is business logic
     * - action can dispatch more than 1 mutation at a time, it just implements the business logic, 
     *   it doesn't care about data changing (which manage by mutation)
     */
    setVerificaRegistrazioni(state, payload) { // Feb 19, 2020
      state.verificaRegistrazioni = payload
    },
    setOptionsVigna(state, payload) {
      state.optionsVigna = payload
    },
    // setShowSpinnerDatiRegistrazione(state, payload) { // Jan 17, 2020
    //   state.showSpinnerDatiRegistrazione = payload
    // },
    setShowSpinnerMovimentiRegistrazione(state, payload) { // Jan 17, 2020
      state.showSpinnerMovimentiRegistrazione = payload
    },
    setCommittenteOperazione(state, payload) { // Nov 28, 2019
      state.committenteOperazione = payload
    },
    setOptionsSoggetto(state, payload) { // Nov 12, 2019
      state.optionsSoggetto = payload.optionsSoggetto;
    },
    setOptionsTrasportatore(state, payload) { // Nov 12, 2019
      state.optionsTrasportatore = payload.optionsTrasportatore;
    },
    setOptionsVaso(state, payload) {
      state.optionsVaso = payload;
    },
    setIsOnSian(state, payload) {
      state.isOnSian = payload
    },
    setIsCommittenteChanged(state, payload) {
      // state.isCommittenteChanged = payload.isCommittenteChanged
      state.isCommittenteChanged = payload
    },

    // Oct 10, 2019
    setModalSearchPath(state, payload) {
      state.modalSearchPath = payload
    },
    setModalSearch(state, payload) {
      state.modalSearch = payload
    },

    setNumOperazione(state, payload) {
      state.numOperazione = payload.numOperazione;
    }, 
    setLoadingRipristina(state, payload) {
      state.loadingRipristina = payload;
    },    
    setRigheRegistrazioneErrorSecondoLivello(state, payload) {
      state.righeRegistrazioneErrorSecondoLivello = payload;
    },
    // setOptionsProdotti(state, payload) { // Oct 01, 2019
    //   state.optionsProdotti = payload;
    // },
    // setLoadingData(state, payload) {
    //   state.loadingData = payload.loadingData;
    // },
    // Sep 21, 2019
    setSearchRegistrazioneParams(state, payload) {
    // notare che si riceve il payload per intero, non la singola proprietà del payload
      state.searchRegistrazioneParams = payload 
    },
    // Sep 19, 2019
    // setIsDataOperazioneChanged(state, payload) {
    //   state.isDataOperazioneChanged = payload
    // },
    // Sep 18, 2019
    // setDataOperazione(state, payload) {
    //   state.dataOperazione = payload.dataOperazione;
    // },
    setErroreGiacenza(state, payload) {
      state.erroreGiacenza = payload;
    },

    setFirstRun(state, payload) { // il payload è un oggetto
      state.firstRun = payload.firstRun;
    },
    setTipoOperazione(state, payload) {
      state.tipoOperazione = payload.tipoOperazione;
    },
    setCodiceOperazione(state, payload) {
      state.codiceOperazione = payload.codiceOperazione;
    },
    setDisabilitaCodiceOperazione(state, payload) {
      state.disabilitaCodiceOperazione = payload.disabilitaCodiceOperazione;
    },
    changeCommittente(state, payload) {
      state.isCommittenteChanged = payload.isCommittenteChanged;
    },
  },

  // actions: {
  //   setTipoOperazioneAction(context, payload) {
  //     context.commit("setTipoOperazione", payload);
  //   },
  //   codiceOperazioneAction(context, payload) {
  //     context.commit("setCodiceOperazione", payload);
  //   },
  //   disabilitaCodiceOperazioneAction(context, payload) {
  //     context.commit("disabilitaCodiceOperazione", payload);
  //   },
  //   changeCommittenteAction(context, payload) {
  //     context.commit("changeCommittente", payload);
  //   },
  // }
};
