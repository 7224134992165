
    <div>
    <b-dropdown
        variant="link"
        no-caret
        right
        toggle-class="text-decoration-none mt-0 pt-0 pb-0 pr-0 pl-0 mb-0 mt-0"
    >
        <template slot="button-content">
            <font-awesome-layers class="fa-lg ml-0">
                <font-awesome-icon icon="circle" />
                <font-awesome-icon
                    icon="ellipsis-h"
                    transform="shrink-6"
                    :style="{ color: 'white' }"
                />
            </font-awesome-layers>
        </template>

        <b-dropdown-text>{{ operatore.email }}</b-dropdown-text>
        <b-dropdown-text :class="getRuoloClass(operatore)" class="small">{{
            getRuolo(operatore)
        }}</b-dropdown-text>

        <b-dd-divider />

        <!--------------------- testo eliminazione operatore --------------->
        <b-dropdown-text
            v-if="
                amministrazioneCliente &&
                (operatore.categorie.includes('STAFF') ||
                    operatore.categorie.includes('ADMIN'))
            "
        >
            Questo operatore può essere rimosso in Modifica Azienda
        </b-dropdown-text>

        <!--------------------- modifica operatore --------------->
        <b-dropdown-item
            @click="useEditOperatore(operatore, azienda)"
            v-if="
                (!elencoOperatori &&
                    amministrazioneCliente &&
                    operatore.categorie.includes('AZIENDA')) ||
                amministrazioneConcessionario
            "
            :disabled="disableUpdateOperatore(operatore)"
            :title="reasonUpdate[index]"
            v-b-tooltip.hover.right.v-info
        >
            <font-awesome-icon
                icon="user-edit"
                class="text-primary mr-2"
                :class="{ 'text-muted': disableUpdateOperatore(operatore) }"
                v-if="
                    (!elencoOperatori &&
                        amministrazioneCliente &&
                        operatore.categorie.includes('AZIENDA')) ||
                    amministrazioneConcessionario
                "
            />Modifica
        </b-dropdown-item>

        <!--------------------- rimuovi operatore --------------->
        <b-dropdown-item
            v-if="
                amministrazioneCliente &&
                operatore.categorie.includes('AZIENDA') &&
                !elencoOperatori
            "
            @click="removeOperatoreFromGroup(operatore, azienda)"
        >
            <font-awesome-icon
                icon="user-minus"
                class="text-warning mr-2"
            />Rimuovi
        </b-dropdown-item>

        <!--------------------- cancella operatore --------------->
        <b-dropdown-item
            v-if="
                (amministrazioneCliente &&
                    operatore.categorie.includes('AZIENDA')) ||
                amministrazioneConcessionario
            "
            @click="useDeleteOperatore(operatore)"
        >
            <font-awesome-icon
                icon="user-times"
                class="text-danger mr-2"
            />Elimina
        </b-dropdown-item>

        <!-- <b-dropdown-item @click=""> -->
        <!--   <font-awesome-icon icon="info-circle" class="text-info mr-2" /> -->
        <!--     Informazioni -->
        <!-- </b-dropdown-item> -->
    </b-dropdown>
</div>
