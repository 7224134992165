<template>
    <span>
        <font-awesome-icon
            icon="circle"
            :style="{color: '#009688'}"
            class="mr-1 small"
            v-show="prodotto.da_mvv"
            title="Prodotto creato automaticamente (MVV-E)"
        />

        <span :class="{'temporaneo': inRegistrazione}" v-html="sintesiProdotto"></span>
        
        <span class="text-muted">{{prodotto.da_designazione ? '(' + prodotto.id + ')' : ''}}</span>
        <!-- <span class="text-muted small ml-1">{{ prodotto.id }}</span> -->

        <!-------------------- DOCUMENTI ---------------------->
        <span
            v-b-tooltip.hover
            :title="documenti(prodotto)"
            v-if="
                checkUserAccess(utente, {servizio: 'ARC', azienda: currentAzienda}) 
                && prodotto.documenti 
                && prodotto.documenti.length > 0 
            "
            @click="useHandleDocumentiProdotto()"
            style="cursor: pointer;"
        >
            <!-- @click="handleArchiviaDocumento(prodotto, inRegistrazione)" -->
            <font-awesome-icon icon="file-alt" class="text-success ml-1" />
        </span>

        <!-------------------- LOCK ---------------------->
        <font-awesome-icon
            icon="lock"
            class="text-warning ml-1"
            v-show="prodotto.toBeLocked && inElencoProdotti"
        />
    </span>
</template>

<script>
// refactor: Jun 28, 2020 
import {DateTime} from "luxon";
import {computed} from "@vue/composition-api";
import {useState} from "@u3u/vue-hooks";

// December 26, 2021-January 09, 2022  
import {checkUserAccess} from "@/utils/access.js";
import {documenti} from '@/components/archiviazione/utils.js'
import {handleDocumentiProdotto} from '@/components/archiviazione/handleDocumentiProdotto.js'


export default {
    name: "SintesiProdotto",

    props: {
        prodotto: {
            type: Object,
            required: true
        },
        // quando si trova in una registrazione temporanea assume il colore del temporaneo
        inRegistrazione: {
            // Oct 04, 2019-Oct 12, 2019
            type: Boolean,
            required: false,
            default: false
        },
        inElencoProdotti: {
            type: Boolean,
            required: false,
            default: false
        },
        inElencoRegistrazioni: {
            type: Boolean,
            required: false,
            default: false
        },
        textOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        action: {
            type: String,
            required: false,
            default: 'add',
        },
        handleArchiviaDocumento: {
            type: Function,
            required: false,
       }
    },

    setup(props, context) {
        const {currentAzienda} = useState("loginModule", [ "currentAzienda" ]);    
        const {utente} = useState("loginModule", ["utente"]);
        const sintesiProdotto = computed(() => sintesi(props.prodotto, props.textOnly))

        const useHandleDocumentiProdotto = () => handleDocumentiProdotto(props, context.root)

        return {
            currentAzienda,
            useHandleDocumentiProdotto,
            utente,
            checkUserAccess,
            documenti,
            sintesiProdotto,
        };
    }
};

export function sintesi(prodotto, textOnly) {
    if (textOnly == null) {
        textOnly = false;
    }
    var _sintesiProdotto = {};
    var _sintesi = [];

    if (prodotto.categoria != null && prodotto.categoria.descrizione != "") {
        _sintesi.push((textOnly ? ' ' : '<span class="font-weight-bold">') + prodotto.categoria.descrizione + (textOnly ? ' ' : '<span class="text-muted"> </span></span>'))
    }
    if (prodotto.cod_dop_igp != null && prodotto.cod_dop_igp.descrizione != "") {
        _sintesi.push((textOnly ? ' ' : '<span class="font-weight-bold">') + prodotto.cod_dop_igp.descrizione + (textOnly ? ' ' : '</span>'))
    }
    _sintesi.push(textOnly ? ' ' : "<br />")
    if (prodotto.colore != null && prodotto.colore.descrizione != "") {
        _sintesi.push(prodotto.colore.descrizione + (textOnly ? ' ' : '<span class="text-muted">. </span>'))
    }
    if (prodotto.stato_fisico != null && prodotto.stato_fisico.descrizione != "") {
        _sintesi.push(prodotto.stato_fisico.descrizione + (textOnly ? ' ' : '<span class="text-muted">. </span>'))
    }
    if (prodotto.annata_codice != null && (prodotto.annata_codice != "" || prodotto.annata_codice != 0)) {
        _sintesi.push((textOnly ? ' ' : '<span class="font-weight-bold">') + prodotto.annata_codice + (textOnly ? ' ' : '<span class="text-muted">. </span></span>'))
    }
    if (prodotto.vigna != null && prodotto.vigna.descrizione != "") {
        if (prodotto.vigna.toBeLocked) {
            _sintesi.push('<span class="font-weight-bold text-warning ml-0 mr-1">L</span>')
        }
        _sintesi.push((textOnly ? ' ' : '<span class="text-muted">Vigna </span>') + prodotto.vigna.descrizione + (textOnly ? ' ' : '<span class="text-muted">. </span>'))
    }
    if (prodotto.atto_certificato != null && prodotto.atto_certificato.descrizione != "") {
        _sintesi.push(prodotto.atto_certificato.descrizione + (textOnly ? ' ' : '<span class="text-muted">. </span>'))
    }
    if (prodotto.classificazione != null && prodotto.classificazione.descrizione != null && prodotto.classificazione.descrizione != "") {
        _sintesi.push(prodotto.classificazione.descrizione.trim() + (textOnly ? ' ' : '<span class="text-muted">. </span>'))
    }
    if (prodotto.biologico != null && prodotto.biologico.descrizione != "") {
        _sintesi.push(prodotto.biologico.descrizione + (textOnly ? ' ' : '<span class="text-muted">. </span>'))
    }

    // certificazione 
    // if (prodotto.id == 125803) debugger
    if (prodotto.num_cert_dop != null && prodotto.num_cert_dop != "") {
        _sintesi.push((textOnly ? ' ' : '<span class="text-muted">N. Cert. </span>') + prodotto.num_cert_dop)
        if (prodotto.data_cert_dop == null || prodotto.data_cert_dop == "") {
             _sintesi.push(textOnly ? ' ' : '<span class="text-muted">. </span>')
        }
    } 
    // January 12, 2023 
    if (prodotto.data_cert_dop != null && prodotto.data_cert_dop != "") {
        _sintesi.push((textOnly ? ' ' : '<span class="text-muted"> del </span>') + (typeof(prodotto.data_cert_dop) == 'string' ? DateToLocaleStringShort(prodotto.data_cert_dop) : DateJSToLocaleStringShort(prodotto.data_cert_dop)) + (textOnly ? ' ' : '<span class="text-muted">. </span>'))
    }

    if (prodotto.cod_partita != null && prodotto.cod_partita != "") {
        _sintesi.push((textOnly ? ' ' : '<span class="text-muted">Part. </span>') + prodotto.cod_partita + (textOnly ? ' ' : '<span class="text-muted">. </span>'))

    }
    if (prodotto.zona_viticola != null && prodotto.zona_viticola != "") {
        _sintesi.push((textOnly ? ' ' : '<span class="text-muted">Zona </span>') + truncate(prodotto.zona_viticola.descrizione, 64) + (textOnly ? ' ' : '<span class="text-muted">. </span>'))
    }
    // if (prodotto.codice_secondario=="FRESCO") { debugger }
    if (prodotto.varieta?.length > 0) {
        if (prodotto.varieta[0].codice) {
            _sintesi.push(textOnly ? ' ' : `<span class="text-muted">Varietà </span>`)
            prodotto.varieta.forEach((v, key) => {
                if (v.codice) {
                    let tmp = ""
                    if (textOnly) {
                        tmp = ' '
                    } else {
                        tmp = `<span class="text-muted">${key + 1}) </span>` + truncate(v.codice.descrizione, 64) + (v.perc_varieta ? " (" + v.perc_varieta + "%) " : " ")
                    }
                    if (v.toBeLocked) {
                        tmp = tmp + '<span class="font-weight-bold text-warning ml-0 mr-1">L</span>'
                    }
                    _sintesi.push(tmp)
                }
            })
        }
    }
    if (prodotto.pratiche_enologiche != null && prodotto.pratiche_enologiche.length != 0) {
        if (prodotto.pratiche_enologiche[0].codice != null) {
            _sintesi.push(textOnly ? ' ' : '<span class="text-muted">Pratiche enol. </span>')
            prodotto.pratiche_enologiche.forEach((v, key, vv) => {
                _sintesi.push((textOnly ? ' ' : `<span class="text-muted">${key + 1}) </span>`) + v.codice + ' ')
            })
        }
    }
    if (prodotto.altre_varieta != null && prodotto.altre_varieta != "") {
        _sintesi.push((textOnly ? ' ' : '<span class="text-muted">Altre var.  </span>') + prodotto.altre_varieta + (textOnly ? ' ' : '<span class="text-muted">. </span>'))
    }
    if (prodotto.menzioni != null && prodotto.menzioni.length != 0) {
        if (prodotto.menzioni[0].codice != null) {
            _sintesi.push(textOnly ? ' ' : '<span class="text-muted">Menzioni </span>')
            prodotto.menzioni.forEach((v, key, vv) => {
                _sintesi.push((textOnly ? ' ' : `<span class="text-muted">${key + 1}) </span>`) + v.descrizione + ' ')
            })
        }
    }
    if (prodotto.committente != null && prodotto.committente != "") {
        _sintesi.push((textOnly ? ' ' : '<span class="text-muted">Committente </span>') + prodotto.committente + (textOnly ? ' ' : '<span class="text-muted">. </span>'))
    }
    if (prodotto.descrizione != null && prodotto.descrizione != "") {
        _sintesi.push((textOnly ? ' ' : '<span class="text-muted">Note </span>') + prodotto.descrizione + (textOnly ? ' ' : '<span class="text-muted">. </span>'))
    }

    if (prodotto.codice_prodotto != null && prodotto.codice_prodotto != "" && _sintesi != "<br />") {
        _sintesi.push((textOnly ? ' ' : '<span class="text-muted"> (</span>') + prodotto.codice_prodotto + (prodotto.codice_secondario == null ? "" : "-" + prodotto.codice_secondario) + (textOnly ? ' ' : '<span class="text-muted">)</span>'))
    }

    // January 06, 2022 
    // if (prodotto.esteseprodotto != null && prodotto.esteseprodotto.codice_aziendale) {
    //     _sintesi.push((textOnly ? ' / ' : '<span class="text-muted"> Cod. </span><b>') + prodotto.esteseprodotto.codice_aziendale + (textOnly ? '' : '</b>'))
    // }

    return _sintesi.join("")
}

function truncate(text, length, clamp) {
    text = text || "";
    clamp = clamp || "...";
    length = length || 30;
    if (text.length <= length) return text;
    var tcText = text.slice(0, length - clamp.length);
    var last = tcText.length - 1;
    while (last > 0 && tcText[last] !== " " && tcText[last] !== clamp[0])
        last -= 1;
    // Fix for case when text dont have any `space`
    last = last || length - clamp.length;
    tcText = tcText.slice(0, last);
    return tcText + clamp;
}

// from ISO date (type 'string')
export function DateToLocaleString(value) {
    var dt = DateTime.fromISO(value);
    return dt.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
}

export function DateToLocaleStringShort(value) {
    var dt = DateTime.fromISO(value);
    return dt.toLocaleString(DateTime.DATE_SHORT);
}

export function DateToLocaleStringLong(value) {
    if (value) {
        var dt = DateTime.fromISO(value);
        return dt.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS, {setZone: true});
    }
    return null
}

export function DateJSToLocaleString(value) {
    var dt = DateTime.fromJSDate(value);
    return dt.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
}

export function DateJSToLocaleStringShort(value) {
    var dt = DateTime.fromJSDate(value);
    return dt.toLocaleString(DateTime.DATE_SHORT);
}

export function DateJSToLocaleStringLong(value) {
    if (value) {
        var dt = DateTime.fromJSDate(value);
        return dt.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS, {setZone: true});
    }
    return null
}
</script>

