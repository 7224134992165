<template>
  <span v-html="testoReleaseNotes"></span>
</template>


<script>
import axios from 'axios'
import { ref } from "@vue/composition-api";


const testoReleaseNotes = ref("")


export default {
  
  name: "ReleaseNotes",

  setup() {

    prendiReleaseNotes()

    return {
      testoReleaseNotes,
    };
  },
};


/*
 * su spaces è stata settata una CORS policy (in settings)
 * per consentire il trasferimento del file del contratto
 * December 25, 2020 
 */
export const prendiReleaseNotes = async () => {
  const releaseNumber = import.meta.env.VITE_APP_VERSIONE
  const options = {
    method: "get",
    url: `/api/release-notes/${releaseNumber}`,
    // url: `https://pub-3504a807a97a46dfbb03920dd3b52eb7.r2.dev/release-notes/${releaseNumber}.html`
  };
  try {
    const response = await axios.request(options);
    testoReleaseNotes.value = response.data
  } catch (error) {
    testoReleaseNotes.value = error
  }
};

</script>
