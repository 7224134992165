<template>
    <b-container>
        <!-- ++{{ cuaa }} -->
        <!-- ++{{ form.namespace }} -->
        <b-form-row class="mt-3">
            <b-col>
                <b-form-group
                    label="Prefisso"
                    :label-class="{ 'font-weight-bold': false }"
                >
                    <b-form-input
                        type="text"
                        v-model="parametri_codici_aziendali.namespace"
                        placeholder="Prefisso(namespace)"
                    />
                </b-form-group>
            </b-col>
        </b-form-row>
    </b-container>
</template>

<script>
import axios from "axios";
// import { ref } from "@vue/composition-api";


import { parametri_codici_aziendali } from '../utente/InfoUtente.vue'


// const form = ref({
//     namespace: null,
//     azienda_id: null,
// });

export default {
    name: "parametri-codici-aziendali",

    props: {
        cuaa: String,
        azienda_id: Number,
    },

    setup(props, context) {

        getParametriCodiciAziendali(props.cuaa, props.azienda_id);

        return {
            parametri_codici_aziendali,
            // onSubmit,
        };
    },
};

// const onSubmit = () => {
//     setParametriCodiciAziendali();
// };

const getParametriCodiciAziendali = async (cuaa) => {
    const path = `/api/azienda/get_parametri_codici_aziendali/${cuaa}`;
    const options = {
        method: "get",
        url: path,
    };
    try {
        const response = await axios.request(options);
        parametri_codici_aziendali.value = response.data;
        parametri_codici_aziendali.value.cuaa = cuaa;
    } catch (error) {
        console.log(error);
    }
};

// const setParametriCodiciAziendali = async () => {
//     const path = `/api/azienda/set_parametri_codici_aziendali/${form.value.azienda_id}`;
//     delete form.value.azienda_id;
//     const options = {
//         method: "post",
//         url: path,
//         data: form.value,
//     };
//     try {
//         await axios.request(options);
//     } catch (error) {
//         console.log(error);
//     }
// };
</script>
