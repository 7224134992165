
<div :class="{ 
  'home': classIsHome, 
  'home-admnistration': classIsHomeAdministration,
  'guidaArea': $route.name == 'guida',
}">

  <!------------------ navbar ------------------>
  <nav-bar />

  <!-- "systemMessageStyleObject" definisce il margine da applicare al "div" per
  spostarlo opportunamente sotto il "systemMessage" e la "navBar" -->

  <div 
      fluid 
      :style="styleObject"
      class="h-100 v-100" 
      v-if="$route.name != 'home-unauthenticated'" 
  >
      <!-- :style="systemMessageStyleObject" -->
    <b-container 
        fluid 
        class="h-100 v-100"
    >
        <!-- v-if="$route.name != 'guida'" -->

      <b-row 
          class="h-100 v-100"
      >
        <b-col 
          sm=12 md=2 lg=2 
          v-if="componentHasSideBar">

          <side-bar class="sidebar" :utente="utente" v-if="!classIsHomeAdministration" />

        </b-col>

        <b-col
          :md="componentHasSideBar ? 10 : 12" 
          :lg="componentHasSideBar ? 10 : 12"  
          sm=12
          :class="{ 
            'homeArea': classIsHomeArea, 
            'mainArea': classIsMainArea,
          }"
        >

         <!--<div v-if="!componentHasSideBar">-->
         <!--   <!-1---------------------------------------------------->
         <!--   Pubblicità--> 
         <!--   ----------------------------------------------------1->-->
         <!--   <div class="ads container mt-0">-->
         <!--     <div class="heading">-->
         <!--       Pubblicità-->
         <!--     </div>-->    
         <!--     <div v-for="(dato, i) in pubblicita_dati" :key="i">-->
         <!--       <pubblicita-->
         <!--         :is_authenticated="isAuthenticated"-->
         <!--         :titolo="dato.titolo"-->
         <!--         :routes="dato.routes"-->
         <!--         :link="dato.link"-->
         <!--         :horizontal="'true'"-->
         <!--       >-->
         <!--         <span v-html="dato.testo" />-->
         <!--       </pubblicita>-->
         <!--     </div>-->
         <!--   </div>--> 
        <!--</div>-->


          <!---------------------------------------------------------------
          LA COMPONENTE STANDARD "ROUTER-VIEW" (https://router.vuejs.org/api/#router-view): 
          1) chiama le componenti dichiarate nel router ("renders the matched component for the given path").  
          2) Passa le props, che arrivano tramite l'eventBus da Login, a tutte le componenti (dichiarate nel router: Prodotto, Vaso, VasoList, VasoUpdate, Logout, Login, ...) 
          ----------------------------------------------------------------->
          <router-view
            :is_authenticated="isAuthenticated"
            :email="email"
            :userId="userId"
            :azienda_id="azienda_id"
            :stabilimento_id="stabilimento_id"
            :cuaa="cuaa"
            :icqrf="icqrf"
            :utente="utente"
          />

        </b-col>
      </b-row>

    <!-- ++{{ styleObject }} -->
    <!-- ++{{ $route.name }} -->
    <!-- ++{{ amministrazioneCliente }} -->
    <!-- ++{{ amministrazioneConcessionario }} -->

    </b-container>

    <!-- <div v-else> -->
    <!--   <guida :is_authenticated="isAuthenticated" /> -->
    <!-- </div> -->

  </div>
  <div v-else
      style="margin-top: 0em; margin-bottom: 0px;"
  >
    <home-unauthenticated />
  </div>

  <footer-bar :is_authenticated="isAuthenticated" />

</div>
