// import Stampe from '../../components/stampe/Stampe.vue';

export const print_routes = [
  // {
  //   path: "/stampe",
  //   name: "stampe",
  //   component: Stampe,
  //   meta: { title: 'Stampe - e-Sian', layout: "no-side-bar", requiresAuth: true, },
  // },
]
