<template>
  <div :class="{ 
    'home': classIsHome, 
    'home-admnistration': classIsHomeAdministration,
    'guidaArea': $route.name == 'guida',
  }">

    <!------------------ navbar ------------------>
    <nav-bar />

    <!-- "systemMessageStyleObject" definisce il margine da applicare al "div" per
    spostarlo opportunamente sotto il "systemMessage" e la "navBar" -->

    <div 
        fluid 
        :style="styleObject"
        class="h-100 v-100" 
        v-if="$route.name != 'home-unauthenticated'" 
    >
        <!-- :style="systemMessageStyleObject" -->
      <b-container 
          fluid 
          class="h-100 v-100"
      >
          <!-- v-if="$route.name != 'guida'" -->

        <b-row 
            class="h-100 v-100"
        >
          <b-col 
            sm=12 md=2 lg=2 
            v-if="componentHasSideBar">

            <side-bar class="sidebar" :utente="utente" v-if="!classIsHomeAdministration" />

          </b-col>

          <b-col
            :md="componentHasSideBar ? 10 : 12" 
            :lg="componentHasSideBar ? 10 : 12"  
            sm=12
            :class="{ 
              'homeArea': classIsHomeArea, 
              'mainArea': classIsMainArea,
            }"
          >

           <!--<div v-if="!componentHasSideBar">-->
           <!--   <!-1---------------------------------------------------->
           <!--   Pubblicità--> 
           <!--   ----------------------------------------------------1->-->
           <!--   <div class="ads container mt-0">-->
           <!--     <div class="heading">-->
           <!--       Pubblicità-->
           <!--     </div>-->    
           <!--     <div v-for="(dato, i) in pubblicita_dati" :key="i">-->
           <!--       <pubblicita-->
           <!--         :is_authenticated="isAuthenticated"-->
           <!--         :titolo="dato.titolo"-->
           <!--         :routes="dato.routes"-->
           <!--         :link="dato.link"-->
           <!--         :horizontal="'true'"-->
           <!--       >-->
           <!--         <span v-html="dato.testo" />-->
           <!--       </pubblicita>-->
           <!--     </div>-->
           <!--   </div>--> 
          <!--</div>-->


            <!---------------------------------------------------------------
            LA COMPONENTE STANDARD "ROUTER-VIEW" (https://router.vuejs.org/api/#router-view): 
            1) chiama le componenti dichiarate nel router ("renders the matched component for the given path").  
            2) Passa le props, che arrivano tramite l'eventBus da Login, a tutte le componenti (dichiarate nel router: Prodotto, Vaso, VasoList, VasoUpdate, Logout, Login, ...) 
            ----------------------------------------------------------------->
            <router-view
              :is_authenticated="isAuthenticated"
              :email="email"
              :userId="userId"
              :azienda_id="azienda_id"
              :stabilimento_id="stabilimento_id"
              :cuaa="cuaa"
              :icqrf="icqrf"
              :utente="utente"
            />

          </b-col>
        </b-row>

      <!-- ++{{ styleObject }} -->
      <!-- ++{{ $route.name }} -->
      <!-- ++{{ amministrazioneCliente }} -->
      <!-- ++{{ amministrazioneConcessionario }} -->

      </b-container>

      <!-- <div v-else> -->
      <!--   <guida :is_authenticated="isAuthenticated" /> -->
      <!-- </div> -->

    </div>
    <div v-else
        style="margin-top: 0em; margin-bottom: 0px;"
    >
      <home-unauthenticated />
    </div>

    <footer-bar :is_authenticated="isAuthenticated" />

  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import { useState, useMutations } from "@u3u/vue-hooks";

// import pubblicita, {pubblicita_dati} from "@/components/pubblicita/pubblicita.vue";
import NavBar from "./components/navigation/NavBar.vue";
import SideBar from "./components/layouts/SideBar.vue";
import FooterBar from "./components/layouts/FooterBar.vue";
import HomeUnauthenticated from "./components/HomeUnauthenticated.vue";
// import Guida from "./components/Guida.vue";
import { EventBus } from "./EventBus.js";
import { showAlert } from "@/utils/eSianUtils.js";

//const default_layout = "default";

export default {
    name: "app",

    components: {
        // pubblicita,
        // Guida,
        HomeUnauthenticated,
        SideBar,
        FooterBar,
        NavBar
    },

    // setup(props, context) {
    //   // const { isAuthenticated, systemMessage, systemMessageStyleObject, navBarStyleObject } = useState("loginModule", ["isAuthenticated", "systemMessage", "systemMessageStyleObject", "navBarStyleObject"]);
    //   // const { setWindowWidth } = useMutations("loginModule", ["setWindowWidth"]);

    //   // December 11, 2020 
    //   window.addEventListener('resize', (e) => {
    //     setWindowWidth(e.target.innerWidth)
    //   })

    //   return {
    //     navBarStyleObject,
    //     systemMessage,
    //     systemMessageStyleObject,
    //     showTop: true,
    //     isAuthenticated,
    //   }
    // },

    computed: {

        styleObject() {
            if (this.amministrazioneCliente || this.amministrazioneConcessionario) {
                return { marginTop: '0em'}
            } else {
                return { marginTop: '4.5em' }
            }
        },

        ...mapState("loginModule", ["isAuthenticated","systemMessage", "systemMessageStyleObject", "navBarStyleObject"]),

        ...mapState("amministrazioneModule", ["amministrazioneCliente","amministrazioneConcessionario"]),

        /*
         * DESCRIZIONE DEL FLUSSO APPLICATIVO (June 12, 2019 ):
         * Entra in App.vue. Il token a questo momento vale "".
         * Poi App si mette in ascolto e non fa molto altro.
         * Passa a Login.vue, dove il token viene cambiato e quindi il computed qui reagisce
         * e lo ricalcola. Essendo una property viene passato giu' via via alle diversi
         * componenti di router-view.
         */
        componentHasSideBar() {
            // se NON è nella lista seguente di routes 
            // la componente ha la sidebar...
            if (
                this.$route.name == null ||
                this.$route.name != "about" &&
                this.$route.name != "guida" &&
                this.$route.name != "utente" &&
                this.$route.name != "strumenti" &&
                //this.$route.name != "importazione-ussd" &&
                this.$route.name != "comunicazioni-list" &&
                this.$route.name != "mvv-list" &&
                this.$route.name != "mvv" &&
                !this.$route.name.startsWith("archivio") &&
                this.$route.name != "elenco-avvisi" &&
                this.$route.name != "home" &&
                this.$route.name != "home-administration" &&
                this.$route.name != "404" &&
                this.$route.name != "logout" &&
                this.$route.name != "login" &&
                !this.$route.name.startsWith("password") &&
                //!this.$route.name.startsWith("confirm-email") &&
                !this.$route.path.startsWith("/amministrazione")
            ) {
                // ...allora ha la sidebar
                return true;
            } else {
                return false;
            }
        },
        classIsHomeAdministration() {
            // se è nella lista, la classe è 'home-administration'
            if (
                this.$route.name == "home-administration" 
                || this.$route.name == "logout-administration"
            ) {
                return true;
            } else {
                return false;
            }
        },

        classIsHome() {
            // se è nella lista, la classe è 'home'
            if (
                this.$route.name == null || this.$route.name == "home" || this.$route.name == "home-unauthenticated" || this.$route.name == "404" || this.$route.name == "logout" || this.$route.name == "login" || this.$route.name.startsWith("password") || this.$route.name.startsWith("confirm-email")
            ) {
                return true;
            } else {
                return false;
            }
        },
        classIsHomeArea() {
            // se è nella lista seguente di routes...
            if (
                this.$route.name == null || this.$route.name == "home" || this.$route.name == "about" || this.$route.name == "home-unauthenticated" || this.$route.name == "home-administration" || this.$route.name == "login" || this.$route.name == "logout" || this.$route.name == "404" || this.$route.name.startsWith("password") || this.$route.name.startsWith("confirm-email")
            ) {
                return true;
            } else {
                return false;
            }
        },
        classIsMainArea() {
            // se NON è nella lista seguente di routes...
            if (
                this.$route.name != null &&
                this.$route.name != "guida" &&
                this.$route.name != "home" &&
                this.$route.name != "about" &&
                this.$route.name != "home-unauthenticated" &&
                this.$route.name != "home-administration" &&
                this.$route.name != "login" &&
                this.$route.name != "404" &&
                !this.$route.name.startsWith("password") &&
                !this.$route.name.startsWith("confirm-email")
            ) {
                return true;
            } else {
                return false;
            }
        }
    },

    data() {
        return {
            // pubblicita_dati,
            //token: "", // June 12, 2019
            //is_authenticated: false,
            username: "",
            userId: 0,
            first_name: "",
            last_name: "",
            email: "",
            azienda_id: 0,
            stabilimento_id: 0,
            cuaa: "",
            icqrf: "",
            utente: null,
            langs: [
                { lang: "it", name: "Italiano" },
                { lang: "en", name: "English" }
            ]
        };
    },

    beforeDestroy() {
        EventBus.$off("Login:App");
        EventBus.$off("Logout:App");
        EventBus.$off("NavBar:App");
        EventBus.$off("NavBar:cuaa:App");
        EventBus.$off("NavBar:icqrf:App");
    },

    created() {
        this.loadListeners();
    },

    methods: {

        ...mapMutations("loginModule", ["setWindowWidth"]),

        loadListeners() {

            // si mette in ascolto (listeners on)...
            EventBus.$on("NavBar:App", event => {
                this.cuaa = event.cuaa;
                this.icqrf = event.icqrf;
                this.azienda_id = event.azienda_id;
                this.stabilimento_id = event.stabilimento_id;
                if (this.cuaa == null && !this.amministrazioneCliente && !this.amministrazioneConcessionario) {
                    showAlert({ message: "Immettere il CUAA dell'azienda" }, this);
                }
            });

            EventBus.$on("Login:App", event => {
                //this.token = event.token; // June 12, 2019
                //this.is_authenticated = event.is_authenticated;
                this.is_admin = false;
                this.utente = event.utente;
                this.first_name = event.utente.first_name;
                this.last_name = event.utente.last_name;
                this.email = event.utente.email;
                this.userId = event.utente.id;
                // ... copia l'oggetto e poi aggiorna una sua proprietà,
                // e poi passo l'oggetto nuovo  (June 12, 2019 )
                //this.setCurrentAzienda({
                //  ...this.$store.state.loginModule.currentAzienda, utente: event.utente
                //});
            });

            EventBus.$on("NavBar:cuaa:App", event => {
                this.cuaa = event.cuaa;
                this.azienda_id = event.azienda_id;
                this.icqrf = null;
                this.stabilimento_id = null;
            });

            EventBus.$on("NavBar:icqrf:App", event => {
                this.icqrf = event.icqrf;
                this.stabilimento_id = event.stabilimento_id;
            });

            EventBus.$on("Logout:App", event => {
                //this.is_authenticated = event.is_authenticated;
                this.cuaa = event.cuaa;
                this.icqrf = event.icqrf;
                this.azienda_id = event.azienda_id;
                this.stabilimento_id = event.stabilimento_id;
            });
        }
    }
};

</script>
