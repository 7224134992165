
<!------ se non è autenticato la navbar non viene mostrata --------->
<div v-if="isAuthenticated">
    <div
        v-show="systemMessage"
        id="systemMessageAlert"
        class="alert alert-warning fixed-top rounded-0"
    >
        {{ systemMessage }}
    </div>

    <b-navbar
        :style="navBarStyleObject"
        id="navbar_top"
        fixed="top"
        toggleable="xl"
        :type="
            checkUserAccess(utente, { categoria: 'CONCESSIONARIO' })
                ? 'dark'
                : checkUserAccess(utente, { categoria: 'CLIENTE' }) &&
                  !checkUserAccess(utente, { categoria: 'STAFF' })
                ? 'dark'
                : 'dark'
        "
        :variant="
            checkUserAccess(utente, { categoria: 'CONCESSIONARIO' })
                ? 'dark'
                : checkUserAccess(utente, { categoria: 'CLIENTE' }) &&
                  !checkUserAccess(utente, { categoria: 'STAFF' })
                ? 'dark'
                : 'dark'
        "
    >
        <b-navbar-toggle target="nav_collapse">
            <template #default="{ expanded }">
                <!-- <b-icon v-if="expanded" icon="chevron-bar-up" font-scale="1.0"></b-icon> -->
                <!-- <b-icon v-else icon="chevron-bar-down"></b-icon> -->
                <font-awesome-icon icon="times" v-if="expanded" />
                <font-awesome-icon icon="bars" v-else />
                <!-- {{ expanded ? "Close" : "Menu" }} -->
            </template>
        </b-navbar-toggle>

        <!-- :to="{ name: 'home', params: { fromNavBar: true }  }" -->
        <b-navbar-brand v-b-toggle.sidebar-1 id="e_sian" class="pt-0 pb-0">
            <logo style="font-size: 1.8rem" class="mr-1" />
            {{ nome }}
        </b-navbar-brand>

        <b-collapse is-nav id="nav_collapse">
            <!---------------------------------------------------------------------
            AMMINISTRAZIONE
            ------------------------------------------------------------------------>
            <b-nav-text
                class="font-weight-bold mr-3"
                style="color: rgb(66, 148, 246); font-size: 1.1rem"
                v-if="
                    checkUserAccess(utente, {
                        categoria: 'CONCESSIONARIO',
                    }) ||
                    (checkUserAccess(utente, { categoria: 'CLIENTE' }) &&
                        checkUserAccess(utente, { categoria: 'ADMIN' }))
                "
            >
                {{
                    checkUserAccess(utente, { categoria: "CONCESSIONARIO" })
                        ? "CONCESSIONARIO"
                        : ""
                }}
                {{
                    checkUserAccess(utente, { categoria: "CLIENTE" }) &&
                    checkUserAccess(utente, { categoria: "ADMIN" })
                        ? "AMMINISTRAZIONE"
                        : ""
                }}
            </b-nav-text>

            <template
                v-if="
                    checkUserAccess(utente, {
                        categoria: 'CONCESSIONARIO',
                    }) ||
                    (checkUserAccess(utente, { categoria: 'CLIENTE' }) &&
                        checkUserAccess(utente, { categoria: 'ADMIN' }))
                "
            >
                <amministrazione />
            </template>

            <!---------------------------------------------------------------------------
            APPLICAZIONE
            --------------------------------------------------------------------------->
            <template v-else>
                <b-navbar-nav>
                    <!--------------------------------------------------------
                    REGISTRAZIONI
                    ---------------------------------------------------------->
                    <b-nav-item
                        v-if="!soloMvv()"
                        :disabled="
                            (categoriaUtente(utente).startsWith('mvv') &&
                                !checkUserAccess(utente, {
                                    categoria: 'READONLY',
                                })) ||
                            selectedCuaaObj == null ||
                            selectedIcqrf == null
                        "
                        :to="{ name: 'elenco-registrazioni' }"
                        :title="$t('registrazione_operazioni')"
                        >{{ $tc("registrazione", 2) }}</b-nav-item
                    >

                    <!--------------------------------------------------------
                    PRODOTTI
                    ---------------------------------------------------------->
                    <b-nav-item
                        :disabled="
                            selectedCuaaObj == null || selectedIcqrf == null
                        "
                        :to="{ name: 'elenco-prodotti' }"
                        :title="$t('prodotti_e_giacenze')"
                        >{{ $tc("prodotto", 2) }}</b-nav-item
                    >

                    <!--------------------------------------------------------
                    ANAGRAFICA 
                    ---------------------------------------------------------->
                    <b-nav-item-dropdown
                        :text="$t('anagrafica')"
                        right
                        no-caret
                        :disabled="
                            selectedCuaaObj == null || selectedIcqrf == null
                        "
                        :title="$t('anagrafica_tooltip')"
                    >
                        <b-dropdown-item
                            :to="{
                                name: 'elenco-soggetti',
                                params: {
                                    trasportatore: false,
                                    solo_mvv: soloMvv(),
                                },
                            }"
                            :title="$t('Anagrafica soggetti')"
                            >{{ $tc("soggetto", 2) }}</b-dropdown-item
                        >

                        <b-dropdown-item
                            :to="{
                                name: 'elenco-trasportatori',
                                params: {
                                    trasportatore: true,
                                    solo_mvv: soloMvv(),
                                },
                            }"
                            :title="$t('Anagrafica trasportatori')"
                            >{{ $t("Trasportatori") }}</b-dropdown-item
                        >

                        <b-dd-divider v-if="!soloMvv()" />

                        <b-dropdown-item
                            v-if="!soloMvv()"
                            :disabled="
                                categoriaUtente(utente).startsWith('mvv')
                            "
                            :to="{ name: 'vaso-list' }"
                            class="d-flex justify-content-between align-items-center ml-0"
                            :title="$t('Anagrafica vasi')"
                            >{{ $tc("vaso", 2) }}</b-dropdown-item
                        >

                        <b-dropdown-item
                            v-if="!soloMvv()"
                            :disabled="
                                categoriaUtente(utente).startsWith('mvv')
                            "
                            :to="{ name: 'vigna-list' }"
                            :title="$t('Anagrafica vigne')"
                            >{{ $tc("vigna", 2) }}</b-dropdown-item
                        >

                        <!-- <b-dd-divider /> -->

                        <!-- <b-dropdown-item -->
                        <!--   :disabled="categoriaUtente(utente).startsWith('mvv')" -->
                        <!--   :to="{ name: 'anagrafica-viticola' }" -->
                        <!--   title="Anagrafica viticola aziendale" -->
                        <!-- > -->
                        <!--   Azienda -->
                        <!-- </b-dropdown-item> -->
                    </b-nav-item-dropdown>
                    <!---------------------------------------------------------->

                    <!--------------------------------------------------------
                     COMUNICAZIONI 
                    ---------------------------------------------------------->
                    <b-nav-item-dropdown
                        v-if="checkUserAccess(utente, { servizio: 'COM' })"
                        :text="$t('Comunicazioni')"
                        right
                        no-caret
                        :disabled="
                            selectedCuaaObj == null || selectedIcqrf == null
                        "
                        title="Comunicazioni al SIAN"
                    >
                        <b-dropdown-item
                            :to="{
                                name: 'comunicazioni-list',
                                params: {
                                    comunicazioneTitle:
                                        'DSTT - Ritiro sotto controllo dei sottoprodotti della vinificazione',
                                    comunicazioneTipo: 'Dstt',
                                },
                            }"
                            >DSTT - Ritiro sottoprodotti</b-dropdown-item
                        >
                        <b-dropdown-item disabled
                            >DCO2 - Introduzione Anidride
                            Carbonica</b-dropdown-item
                        >
                        <b-dropdown-item disabled
                            >DCRS - Centri Temporanei Raccolta
                            Sottoprodotti</b-dropdown-item
                        >
                        <b-dropdown-item disabled
                            >DLSD - Avviamento Lieviti E Sciroppo Di
                            Dosaggio</b-dropdown-item
                        >
                        <b-dropdown-item disabled
                            >DMCR - Elaborazione Mosto</b-dropdown-item
                        >
                        <b-dropdown-item disabled
                            >DMO8 - Detenzione Mosti Con Titolo
                            Alcolometrico 8%Vol</b-dropdown-item
                        >
                        <b-dropdown-item disabled
                            >DPMC - Produzione Una tantum per azienda Mosto
                            Cotto</b-dropdown-item
                        >
                        <b-dropdown-item disabled
                            >DPRA - Dichiarazione Preventiva Pratiche
                            Enologiche</b-dropdown-item
                        >
                        <b-dropdown-item disabled
                            >DPVM - Elaborazioni Prodotti A Base Di Vini E
                            Mosti</b-dropdown-item
                        >
                        <b-dropdown-item disabled
                            >DSCH - Detenzione Sostanze Chimiche Non
                            Ammesse</b-dropdown-item
                        >
                        <b-dropdown-item disabled
                            >DVIN - Detenzione Vinacce Per Usi
                            Diversi</b-dropdown-item
                        >
                        <b-dropdown-item disabled
                            >DVMS - Elaborazioni Con Vini E Mosti Presso
                            Spumantifici</b-dropdown-item
                        >
                    </b-nav-item-dropdown>
                    <!---------------------------------------------------------->

                    <!--------------------------------------------------------
                     MVV 
                    ---------------------------------------------------------->
                    <b-nav-item
                        v-if="checkUserAccess(utente, { servizio: 'MVV' })"
                        :to="{ name: 'mvv-list' }"
                        :disabled="
                            categoriaUtente(utente) == 'readonly' ||
                            selectedCuaaObj == null ||
                            selectedIcqrf == null
                        "
                        :title="$t('MVV_tooltip')"
                        >{{ $t("MVV") }}</b-nav-item
                    >
                    <!---------------------------------------------------------->

                    <!--------------------------------------------------------
                    DOCUMENTI
                        && checkUserAccess(utente, {stato: 'BETA'})
                    ---------------------------------------------------------->
                    <b-nav-item
                        @click="
                            setSourceArchivio('archiviazione');
                            setTarget({ prodotto: null });
                        "
                        v-if="
                            checkUserAccess(utente, {
                                servizio: 'ARC',
                                azienda: currentAzienda,
                            })
                        "
                        :to="{ name: 'archivio-list' }"
                        :disabled="
                            selectedCuaaObj == null || selectedIcqrf == null
                        "
                        :title="$t('Archiviazione documenti aziendali')"
                    >
                        Documenti<b-badge variant="success" class="ml-1"
                            >New!</b-badge
                        >
                    </b-nav-item>
                    <!---------------------------------------------------------->

                    <!-------------------- spark--------------------------->
                    <!-- href="https://localhost:3002" -->
                    <!-- target="_blank" -->
                    <b-nav-item-dropdown text="spark" v-if="isDevelopment">
                        <b-dropdown-item @click="_sparkSIAN">
                            SIAN
                        </b-dropdown-item>
                        <b-dropdown-item @click="_sparkESian">
                            e-Sian
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <!-- <b-nav-item> -->
                    <!--     <b-spinner -->
                    <!--         v-if="sparkESianIsRunning || sparkSIANIsRunning" -->
                    <!--         class="ml-1" -->
                    <!--         type="border" -->
                    <!--         small -->
                    <!--     ></b-spinner> -->
                    <!-- </b-nav-item> -->
                </b-navbar-nav>

                <!-------------------------------------------------------->
                <b-navbar-nav class="ml-auto">
                    <template>
                        <!--------------------------------------------------------
                          SELECT CUAA
                          two-way v-model (May 25, 2021)
                          https://dilshankelsen.com/v-model-with-vuex/
                        ---------------------------------------------------------->
                        <!-- v-model="selectedCuaaObj" -->
                        <b-nav-form
                            id="cuaa_form"
                            class="ml-sm-0 mr-sm-2 mb-sm-0"
                        >
                            <multiselect
                                :disabled="
                                    verificaCampagnaInCorso || isInBozzaMVV
                                "
                                :value="selectedCuaaObj"
                                id="cuaa"
                                @select="useOnChangeCuaa"
                                :show-labels="false"
                                label="ragione_sociale"
                                track-by="cuaa"
                                :multiple="false"
                                :options="optionsCuaa"
                                placeholder="Immettere il CUAA..."
                            >
                                <!-- :disabled="checkUserAccess(utente, {categoria: 'READONLY'})" -->
                                <template slot="noResult">
                                    {{ $t("message.nessun_risultato") }}
                                </template>
                                <template slot="noOptions">{{
                                    $t("nessun_cuaa")
                                }}</template>
                                <template
                                    slot="singleLabel"
                                    slot-scope="props"
                                >
                                    <font-awesome-icon
                                        v-if="props.option.solo_mvv"
                                        icon="circle"
                                        :style="{ color: 'orange' }"
                                    />
                                    <font-awesome-icon
                                        v-if="
                                            props.option
                                                .ambiente_operativo_esercizio
                                        "
                                        icon="circle"
                                        :style="{ color: '#0099CC' }"
                                    />
                                    {{ props.option.ragione_sociale }}
                                    <!-- <b-spinner small class="ml-1" v-show="verificaCampagnaInCorso" /> -->
                                </template>
                                <template
                                    slot="option"
                                    slot-scope="props"
                                    :class="{
                                        esercizio:
                                            props.option
                                                .ambiente_operativo_esercizio,
                                    }"
                                >
                                    <font-awesome-icon
                                        v-if="props.option.solo_mvv"
                                        icon="circle"
                                        :style="{ color: 'orange' }"
                                    />
                                    <font-awesome-icon
                                        v-if="
                                            props.option
                                                .ambiente_operativo_esercizio
                                        "
                                        icon="circle"
                                        :style="{ color: '#0099CC' }"
                                    />
                                    {{ props.option.ragione_sociale }}
                                    <div
                                        class="mt-1"
                                        style="font-size: 0.8rem"
                                    >
                                        {{ props.option.cuaa }}
                                    </div>
                                </template>
                            </multiselect>
                        </b-nav-form>

                        <!--------------------------------------------------------
                          SELECT ICQRF
                          two-way v-model 
                          https://dilshankelsen.com/v-model-with-vuex/
                        ---------------------------------------------------------->
                        <b-nav-form
                            id="icqrf_form"
                            class="mb-0 mr-sm-2 mb-sm-0"
                        >
                            <multiselect
                                :value="selectedIcqrf"
                                :disabled="
                                    verificaCampagnaInCorso || isInBozzaMVV
                                "
                                @input="useOnChangeIcqrf"
                                :show-labels="false"
                                :multiple="false"
                                :allowEmpty="false"
                                label="descrizione"
                                track-by="icqrf"
                                :options="optionsIcqrf"
                                placeholder="Codice ICQRF..."
                            >
                                <!-- :disabled="checkUserAccess(utente, {categoria: 'READONLY'})" -->
                                <template slot="noResult">
                                    {{ $t("message.nessun_risultato") }}
                                </template>
                                <template slot="noOptions"
                                    >Codice ICQRF...</template
                                >
                                <template
                                    slot="singleLabel"
                                    slot-scope="props"
                                >
                                    {{ props.option.descrizione }}
                                    <!-- <font-awesome-icon -->
                                    <!--   icon="circle" -->
                                    <!--   class="mr-1 small" -->
                                    <!--   :class="isCampagnaChiusaCurrentStabilimento ? 'text-danger' : 'text-success'" -->
                                    <!-- /> -->
                                </template>
                                <template slot="option" slot-scope="props">
                                    {{ props.option.descrizione }}
                                    <div
                                        class="mt-1"
                                        style="font-size: 0.8rem"
                                    >
                                        {{ props.option.icqrf }}
                                    </div>
                                </template>
                            </multiselect>
                        </b-nav-form>
                        <!-------------------------------------------------------->

                        <!--------------------------
                        UTENTE 
                        ------------------------->
                        <b-nav-item
                            :to="{ name: 'utente' }"
                            :disabled="
                                selectedCuaaObj == null ||
                                selectedIcqrf == null ||
                                checkUserAccess(utente, {
                                    categoria: 'MVVPLUS',
                                })
                            "
                            v-b-tooltip.hover="
                                utente.email +
                                ' (' +
                                categorieUtente(utente) +
                                ')'
                            "
                        >
                            <b-avatar
                                variant="light"
                                size="sm"
                                class="mr-0 ml-0"
                                :class="classeUtente(utente)"
                            ></b-avatar>
                            <span class="showHide ml-2"
                                >Preferenze ({{ $t(utente.email) }})</span
                            >
                        </b-nav-item>
                        <!-------------------------------------------------------->

                        <!--------------------------------------------------------
                        AVVISI
                        ---------------------------------------------------------->
                        <b-nav-item @click="showAvvisi">
                            <font-awesome-icon
                                :class="{
                                    'text-warning': numeroAvvisi > 0,
                                }"
                                icon="bell"
                                class="mr-1"
                            />
                            <b-badge
                                style="
                                    vertical-align: super;
                                    margin-left: -7px;
                                "
                                class="mr-0"
                                variant="secondary"
                            >
                                <span class="text-white">{{
                                    numeroAvvisi
                                }}</span>
                            </b-badge>
                        </b-nav-item>

                        <b-nav-item
                            v-if="
                                !loading.storici && !verificaCampagnaInCorso
                            "
                            @click="handleStrumenti"
                            right
                            :disabled="
                                blockAccess ||
                                selectedCuaaObj == null ||
                                selectedIcqrf == null
                            "
                            v-b-tooltip.hover
                            :title="
                                statoCampagnaNonAcquisito
                                    ? 'Stato campagna non acquisito dal SIAN. Accesso agli strumenti disabilitato. Click per riprovare a verificare lo stato della campagna per lo stabilimento'
                                    : $t('strumenti_tooltip')
                            "
                        >
                            <font-awesome-icon
                                id="id_cog"
                                class="mr-0 ml-sm-0 ml-0"
                                icon="cog"
                                :class="classeStrumenti"
                            />
                            <span class="showHide ml-2">Strumenti</span>
                        </b-nav-item>
                        <b-nav-item
                            v-else-if="verificaCampagnaInCorso"
                            v-b-tooltip.hover="
                                'Verifica stato campagna in corso...'
                            "
                        >
                            <b-spinner
                                small
                                style="vertical-align: text-top"
                            />
                        </b-nav-item>
                        <b-nav-item
                            v-else-if="loading.storici"
                            :to="{ name: 'strumenti' }"
                            v-b-tooltip.hover="
                                'Importazione storici in corso...'
                            "
                        >
                            <b-spinner
                                small
                                style="vertical-align: text-top"
                            />
                        </b-nav-item>

                        <!------------------------ 
                        guida 
                        ------------------------->
                        <!-- <b-nav-item -->
                        <!--     :to="{ name: 'guida' }" -->
                        <!--     right -->
                        <!--     :title="$t('Guida')" -->
                        <!--     v-if=" -->
                        <!--         checkUserAccess(utente, { stato: 'ALFA' }) -->
                        <!--     " -->
                        <!-- > -->
                        <!--     <font-awesome-icon -->
                        <!--         id="id_cog" -->
                        <!--         class="mr-0 ml-sm-0 ml-0" -->
                        <!--         icon="question-circle" -->
                        <!--     /> -->
                        <!--     <span class="showHide ml-2">Guida</span> -->
                        <!-- </b-nav-item> -->
                    </template>

                    <!-- <b-nav-item-dropdown :text="$t('language')" right v-if="is_authenticated" no-caret> -->
                    <!-- <template slot="button-content"> -->
                    <!-- <font-awesome-icon icon="globe" /> -->
                    <!-- </template> -->
                    <!-- <b-dropdown-item @click.prevent="setI18nLanguage(lang.lang)" ref="changeLanguage" v-for="(lang, i) in langs" :key="`Lang${i}`">{{ lang.name }}</b-dropdown-item> -->
                    <!-- </b-nav-item-dropdown> -->

                    <!-------------------------------- uscita ------------------------->
                    <b-nav-item
                        :to="{
                            name: logoutRoute,
                            params: { fromNavBar: true },
                        }"
                        :title="$t('exit')"
                    >
                        <font-awesome-icon icon="sign-out-alt" />
                        <span class="showHide ml-2">Uscita</span>
                    </b-nav-item>
                </b-navbar-nav>
            </template>
        </b-collapse>
    </b-navbar>

    <!-- <side-bar></side-bar> -->
    <!--------------------------------------------------------------------------
    SIDEBAR
    --------------------------------------------------------------------------->
    <b-sidebar
        id="sidebar-1"
        :visible="showSidebar"
        shadow
        style="margin-top: 74px"
    >
        <template #title>
            <logo class="mr-2 pt-0 pb-0" style="font-size: 1.8rem" />
            {{ nome }}
        </template>
        <SideBarText />
    </b-sidebar>

    <!-- <VasiSIAN></VasiSIAN> -->
    <b-modal
        v-model="showSparkSIANResponse"
        centered
        ok-only
        ok-title="Chiudi"
        ok-variant="light"
        title="spark SIAN"
    >
        <b-skeleton-table v-if="sparkSIANIsRunning" cols="4" rows="3">
        </b-skeleton-table>
        <b-table-simple v-else>
            <tr>
                <th>CodVaso</th>
                <th>TipoVaso</th>
                <th>Descrizione</th>
                <th>Volume</th>
            </tr>
            <tr v-for="result in sparkSIANResponse?.data?.result">
                <td>{{ result.CodVaso }}</td>
                <td>{{ result.TipoVaso }}</td>
                <td>{{ result.Descrizione }}</td>
                <td>{{ result.Volume }}</td>
            </tr>
        </b-table-simple>
    </b-modal>

    <b-modal
        v-model="showSparkESianResponse"
        centered
        ok-only
        ok-title="Chiudi"
        ok-variant="light"
        title="spark e-Sian"
    >
        <b-skeleton-table v-if="sparkESianIsRunning" cols="4" rows="3">
        </b-skeleton-table>
        <b-table-simple v-else>
            <tr>
                <th>Codice</th>
                <th>Tipo</th>
                <th>Descrizione</th>
                <th>Volume</th>
            </tr>
            <tr v-for="result in sparkESianResponse?.data">
                <td>{{ result.codice }}</td>
                <td>
                    {{ result.descrizione_tipo }} ({{ result.codice_tipo }})
                </td>
                <td>{{ result.descrizione }}</td>
                <td>{{ result.volume }}</td>
            </tr>
        </b-table-simple>
    </b-modal>

    <!--------------------------------------------------------------------------
    INFO AMMINISTRATIVE
    --------------------------------------------------------------------------->
    <b-modal
        id="amministrazioneInfoModal"
        size="lg"
        hide-header
        ok-only
        lazy
        centered
        ok-title="Chiudi"
        ok-variant="light"
        :title="
            amministrazioneConcessionario
                ? 'Concessionario: ' + utente.concessionario.codice
                : 'Cliente: ' + utente.cliente.codice
        "
    >
        <amministrazione-info />
    </b-modal>

    <!--------------------- modale Avvisi --------------------->
    <b-modal
        id="modaleAvvisi"
        size="xl"
        centered
        scrollable
        hide-footer
        footer-class="border-top-0 pt-0"
        header-class="border-bottom-0 pb-0"
        @close="setAvvisiModalIsShown(false)"
    >
        <template #modal-title>
            <!-- <span class="pt-5 ml-3">Avvisi</span> -->
            <b-container>
                <b-row>
                    <b-col>
                        <h4>Avvisi</h4>
                    </b-col>
                    <b-col>
                        <b-btn
                            size="sm"
                            variant="primary"
                            @click="getAvvisiFromModaleAvvisi"
                            v-if="
                                !loading.avvisi &&
                                !loading.avvisiMVV &&
                                !loading.avvisiTrasmissioni &&
                                !loading.avvisiArchiviazione
                            "
                            :disabled="
                                loading.avvisi ||
                                loading.avvisiMVV ||
                                loading.avvisiTrasmissioni ||
                                loading.avvisiArchiviazione
                            "
                        >
                            <font-awesome-icon
                                icon="refresh"
                                class="fa-lg"
                            />
                        </b-btn>
                    </b-col>
                </b-row>
            </b-container>
        </template>
        <!-- <template #modal-header="{close}"> -->
        <!--     <b-container class="fluid text-right"> -->
        <!--         <b-row> -->
        <!--             <b-col -->
        <!--                 class="small text-muted text-right ml-3 mt-3 mb-0 pb-0" -->
        <!--                 @click="close()" -->
        <!--                 style="cursor: pointer;" -->
        <!--             > -->
        <!--                 Chiudi -->
        <!--                 <font-awesome-icon icon="times" class="fa-lg" /> -->
        <!--             </b-col> -->
        <!--         </b-row> -->
        <!--     </b-container> -->
        <!-- </template> -->

        <ElencoAvvisi :fromNavBar="true" />

        <template #modal-footer="{ ok, cancel, hide }">
            <b-button size variant="light" @click="cancel()"
                >Chiudi</b-button
            >
        </template>
    </b-modal>
</div>
