<template>
  <!-- <b-modal centered hide-header hide-footer v-model="showOverlayHome"> -->
  <!--   Attendere, prego. Inizializzazione in corso... <b-spinner small /> --> 
  <!-- </b-modal> -->  
  <div>
  </div>
</template>

<script>
import { useState, useRouter } from '@u3u/vue-hooks'

import { loadOptionsServizio } from "@/components/amministrazione/clienti/functions.js";


export default {
  name: "HomeAdministration",

  beforeRouteEnter(to, from, next) { 
    // impostazione del titolo html
    next(vm => {
      if (vm.$store.state.amministrazioneModule.amministrazioneCliente || vm.$store.state.amministrazioneModule.amministrazioneConcessionario) {
        document.title = vm.$store.state.amministrazioneModule.amministrazioneCliente ? 'Amministrazione - e-Sian' : 'Concessionario - e-Sian'
      }
    })
  }, 

  setup(props, context) {
    const { router } = useRouter()
    const { amministrazioneCliente, amministrazioneConcessionario } = useState("amministrazioneModule", ["amministrazioneCliente", "amministrazioneConcessionario"]);

    loadOptionsServizio(context)

    if (amministrazioneCliente.value) {
      router.push({name: 'aziende'})
    } else if (amministrazioneConcessionario.value) {
      router.push({name: 'clienti'})
    }

    return {
      //showOverlayHome,
    }
  }

};

</script>
