export const sidebarModule = {
    namespaced: true,
    state: {
        saldoRegistrazioneKG: null,
        saldoRegistrazioneL: null,
        loadingSidebar: {},
        menuItems: [],
        menuTitle: "",
        menuAction: {
            name: "",
            source: "",
        },
        menuExtraAction: {
            name: "",
            source: "",
        },
        menuSelectedRegistrazioni: false,
        menuSelected: false,
        item: "",
    },
    getters: {},
    mutations: {
        setSaldoRegistrazioneKG(state, payload) {
            state.saldoRegistrazioneKG = payload;
        },
        setSaldoRegistrazioneL(state, payload) {
            state.saldoRegistrazioneL = payload;
        },
        setLoadingSidebar(state, payload) {
            state.loadingSidebar = payload;
        },
        setMenuAction(state, payload) {
            state.menuAction = payload;
        },
        setMenuExtraAction(state, payload) {
            state.menuExtraAction = payload;
        },
        setMenuItems(state, payload) {
            state.menuItems = payload;
        },
        setMenuTitle(state, payload) {
            state.menuTitle = payload;
        },
        setMenuSelectedRegistrazioni(state, payload) {
            state.menuSelectedRegistrazioni = payload;
        },
        setMenuSelected(state, payload) {
            state.menuSelected = payload;
        },
        setMenuItem(state, payload) {
            state.item = payload;
        },
    },
    actions: {},
};
