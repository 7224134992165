/*
 *********************************************************************************
 * EventBus
 *********************************************************************************
 */
import './installCompositionApi.js'
import Vue from 'vue';
  
// Create a new Vue instance and export it
export const EventBus = new Vue();
