// February 03, 2022
// trasmissione, verifica, cancellazione ed importazione registrazioni
import { ref } from "@vue/composition-api";

// May 19, 2023
export const erroreImportazione = ref({
    show: false,
    message: null,
    date: null
});

export const erroriRegistrazioni = ref({
    // verificaRegistrazioni: [],
    // trasmettiRegistrazioni: [],
    // importaRegistrazioni: [],
    // cancellaRegistrazioni: [],
});

export const risultatiRegistrazioni = ref({
    // verificaRegistrazioni: [],
    // trasmettiRegistrazioni: [],
    // importaRegistrazioni: [],
    // cancellaRegistrazioni: [],
});

export const tipoFileImportato = ref(null);
export const lastTipoFileImportato = ref(null);
