<template>
    <!-- <div class="sidebar" :style="systemMessageSidebarStyleObject"> -->
    <div class="sidebar">
        <h6>{{ menuTitle }}</h6>
        <hr />

        <!-- {{ loadingSidebar}} -->
        <b-nav vertical>
            <!-------------------------------------------------------------------------------->
            <div v-for="(menuItem, index) in menuItems" :key="index">
        <!-- ++{{ menuItem.action + source }} -->
                <b-nav-item
                    @click.stop="emitMenuAction(menuItem.action)"
                    :title="menuItem.tooltip"
                    :disabled="
                        disableMenuItem(menuItem) ||
                        loadingSidebar[menuItem.action + source]
                    "
                >
                    <template
                        v-if="
                            menuItem.action.startsWith('trasmetti') &&
                            loadingSidebar[menuItem.action + source]
                        "
                    >
                        <b-spinner class="mr-2" small></b-spinner>
                    </template>
                    <template
                        v-else-if="
                            menuItem.action.startsWith('verifica') &&
                            loadingSidebar[menuItem.action + source]
                        "
                    >
                        <b-spinner class="mr-2" small></b-spinner>
                    </template>
                    <template
                        v-else-if="
                            menuItem.action.startsWith('controlla') &&
                            loadingSidebar[menuItem.action + source]
                        "
                    >
                        <b-spinner class="mr-2" small></b-spinner>
                    </template>
                    <template v-else>
                        <font-awesome-icon
                            v-if="menuItem.icon"
                            :icon="menuItem.icon"
                            class="mr-2"
                        />
                    </template>

                    <template v-if="menuItem.action == 'divider'">
                        <b-dd-divider class="dropdown-divider" />
                    </template>
                    <template v-else>
                        <span v-html="menuItem.text"></span>
                        <!-- {{menuItem}} -->
                    </template>
                </b-nav-item>
            </div>
            <!-------------------------------------------------------------------------------->

            <!------------------------- CANCELLAZIONE REGISTRAZIONI -------------------------->
            <b-nav-item
                v-if="$route.name == 'elenco-registrazioni'"
                @click.stop="handleExtraAction('cancella')"
                v-b-tooltip.hover.topright
                title="Cancella registrazioni selezionate"
                :disabled="
                    !menuSelectedRegistrazioni || disableExtraAction('cancella')
                "
            >
                <template
                    v-if="loadingSidebar['cancellaRegistrazioni' + source]"
                >
                    <b-spinner class="mr-2" small></b-spinner>
                </template>
                <template v-else>
                    <font-awesome-icon icon="trash" class="mr-2" /> </template
            >
                Cancella
            </b-nav-item>
            <!-------------------------------------------------------------------------------->

            <!------------------------- IMPORTAZIONE REGISTRAZIONI -------------------------->
            <b-dd-divider v-if="$route.name == 'elenco-registrazioni'" />
            <b-nav-item
                v-if="$route.name == 'elenco-registrazioni'"
                @click.stop="handleExtraAction('importa')"
                v-b-tooltip.hover.topright
                title="Importa operazioni"
                :disabled="disableExtraAction('importa')"
            >
                <template v-if="loadingSidebar['importaRegistrazioni' + source]">
                    <b-spinner class="mr-2" small></b-spinner>
                </template>
                <template v-else>
                    <font-awesome-icon
                        icon="file-import"
                        class="mr-2"
                    /> </template
                >Importa
            </b-nav-item>
            <!-------------------------------------------------------------------------------->

            <!-------------------------------- VINIFICAZIONE AUTOMATICA -------------------------------->
            <b-nav-item
                v-if="
                    checkUserAccess(utente, {
                        servizio: 'VINIFICAZIONE_AUTOMATICA',
                    }) && $route.name == 'elenco-registrazioni'
                "
                @click.stop="handleExtraAction('vinificazione-list')"
                v-b-tooltip.hover.topright
                title="Vinificazioni automatiche"
            >
                <font-awesome-icon
                    icon="wine-bottle"
                    class="mr-2 text-vinificazione"
                />
                Vinifica...
            </b-nav-item>
            <!-------------------------------------------------------------------------------->
        </b-nav>

        <!------------- Saldo ------------------>
        <!-- <div class="mt-5 text-right" v-if="showSaldi"> -->
        <!--   <!-1- <b-link class="small text-muted" v-b-toggle.saldi>Nascondi...</b-link> -1-> -->
        <!--   <!-1- <b-collapse id="saldi" visible appear> -1-> -->
        <!--     <table class="table table-sm w-100"> -->
        <!--       <tr> -->
        <!--         <td colspan="2" class="text-center small">Saldi</td> -->
        <!--       </tr> -->
        <!--       <tr> -->
        <!--         <td style="width: 95%" class="text-right mr-0 pr-0"> -->
        <!--           <span :class="{ -->
        <!--             'text-danger': saldoRegistrazioneKG < 0, -->
        <!--             'text-success': saldoRegistrazioneKG > 0 -->
        <!--           }"> -->
        <!--             {{ !isNaN(saldoRegistrazioneKG) ? saldoRegistrazioneKG : 0 | NumberToLocaleString }}</span> -->
        <!--         </td> -->
        <!--         <td class="text-left text-muted ml-0 pl-1" style="width: 5%"> -->
        <!--           KG -->
        <!--         </td> -->
        <!--       </tr> -->
        <!--       <tr> -->
        <!--         <td style="width: 95%" class="text-right mr-0 pr-0"> -->
        <!--           <span :class="{ -->
        <!--                         'text-danger': saldoRegistrazioneL < 0, -->
        <!--                         'text-success': saldoRegistrazioneL > 0 -->
        <!--                         }">{{ !isNaN(saldoRegistrazioneL) ? saldoRegistrazioneL : 0 | NumberToLocaleString }}</span> -->
        <!--         </td> -->
        <!--         <td class="text-left text-muted ml-0 pl-1" style="width: 5%"> -->
        <!--           L -->
        <!--         </td> -->
        <!--       </tr> -->
        <!--     </table> -->
        <!--   <!-1- </b-collapse> -1-> -->
        <!-- </div> -->

        <!---------------------------------------------------
       Pubblicità 
        ----------------------------------------------------->
        <!-- <div class="ads"> -->
        <!--   <div class="heading"> -->
        <!--     Pubblicità -->
        <!--   </div> -->
        <!--   <div v-for="(dato, i) in pubblicita_dati" :key="i"> -->
        <!--     <pubblicita -->
        <!--       :is_authenticated="isAuthenticated" -->
        <!--       :titolo="dato.titolo" -->
        <!--       :routes="dato.routes" -->
        <!--       :link="dato.link" -->
        <!--     > -->
        <!--       <span v-html="dato.testo" /> -->
        <!--     </pubblicita> -->
        <!--   </div> -->
        <!-- </div> -->
    </div>
</template>

<style>
.sidebar {
    top: 5em;
    margin-bottom: 1rem;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1000;
    width: 150px;
}
</style>

<script>
import { mapState, mapMutations } from "vuex";
import { useState, useMutations } from "@u3u/vue-hooks";
import { ref, set } from "@vue/composition-api";

import { EventBus } from "../../EventBus.js";
import { checkUserAccess } from "../../utils/access.js";
import { categoriaUtente } from "../utente/categoriaUtente.js";
// import pubblicita, { pubblicita_dati } from "../pubblicita/pubblicita.vue";
import { loadingCurrent, loading } from "@/utils/getProgress.js";

export default {
    name: "SideBar",

    // components: {
    //     pubblicita,
    // },

    props: {
        utente: Object,
        // {
        //     type: Object,
        //     required: true,
        // },
    },

    setup(props, context) {
        const { currentAzienda, currentStabilimento } = useState(
            "loginModule",
            ["currentAzienda", "currentStabilimento"]
        );
        const { setMenuAction, setMenuExtraAction } = useMutations(
            "sidebarModule",
            ["setMenuAction", "setMenuExtraAction"]
        );
        // const { route } = useRouter()
        const { firstEntryInRighe } = useState("operazioneModule", [
            "firstEntryInRighe",
        ]);
        const { preferenze, systemMessageSidebarStyleObject } = useState(
            "loginModule",
            ["preferenze", "systemMessageSidebarStyleObject"]
        );
        const {
            saldoRegistrazioneKG,
            saldoRegistrazioneL,
            menuTitle,
            menuAction,
            loadingSidebar,
        } = useState("sidebarModule", [
            "saldoRegistrazioneKG",
            "saldoRegistrazioneL",
            "menuTitle",
            "menuAction",
            "loadingSidebar",
        ]);
        // const {utente} = useState("loginModule", ['utente'])

        const source = ref(
            `/${currentAzienda.value?.cuaa}/${currentStabilimento.value?.icqrf}`
        );

        // set(loadingSidebar.value, 'verificaVasi' + source.value, false)

        // const showSaldi = computed(() => preferenze.value.mostraSaldi && firstEntryInRighe.value && (saldoRegistrazioneKG != null || saldoRegistrazioneL != null || $route.name == 'aggiungi-registrazione' || $route.name == 'modifica-registrazione' || $route.name == 'clone-registrazione'))

        // const currentRoute = computed(() => route.value.name)

        /*
         * passa i valori dall'azione scelta dall'utente all'event-bus
         * sul canale "sideBarAction", con l'azione "action"
         */
        const emitMenuAction = (val) => {
            EventBus.$emit("sideBarAction", val);
            setMenuAction({ name: val, source: menuTitle.value });
        };

        // const disabledMenuItem = ref({})
        // watchEffect(() => {
        //     debugger
        //     if (menuAction.value.name) {
        //         Object.entries(loadingSidebar.value).forEach(([key, value]) => {
        //             if (key.startsWith('verifica')) {
        //                 set(disabledMenuItem.value, "verificaRegistrazioni", value)
        //             }
        //             if (key.startsWith('trasmetti')) {
        //                 set(disabledMenuItem.value, "trasmettiRegistrazioni", value)
        //             }
        //         })
        //     }
        // })

        const handleExtraAction = (val) => {
            // il receiver è in "ElencoRegistrazioni.js" e in "VinificazioneList.js"
            EventBus.$emit("sideBarExtraAction", val);
            setMenuExtraAction({ name: val, source: menuTitle.value.trim() });
        };

        const disableExtraAction = (extraAction) => {
            if (
                (extraAction == "importa" &&
                    loadingSidebar.value["importaRegistrazioni" + source.value]) ||
                !checkUserAccess(props.utente, { servizio: "IUS" }) ||
                categoriaUtente(props.utente) != ""
            ) {
                return true;
            }
            if (
                extraAction == "cancella" &&
                loadingSidebar.value["cancellaRegistrazioni" + source.value]
            ) {
                return true;
            }
            return false;
        };

        return {
            loadingSidebar,
            // January 05, 2023
            source,

            // utente,
            loading,
            // disabledMenuItem,
            disableExtraAction,
            loadingCurrent,
            systemMessageSidebarStyleObject,
            emitMenuAction,
            handleExtraAction,
            // currentRoute,
            categoriaUtente,
            saldoRegistrazioneKG,
            saldoRegistrazioneL,
            // showSaldi,
            checkUserAccess,
        };
    },

    data() {
        return {
            // pubblicita_dati,
        };
    },

    computed: {
        ...mapState("strumentiModule", [
            "temporaneo",
            "importato",
            "componente",
        ]),
        ...mapState("loginModule", ["isAuthenticated", "termineInitOptions"]),
        // Sep 26, 2019
        ...mapState("sidebarModule", [
            // "loadingSidebar",
            // "loadingSidebarTrasmetti",
            "menuItems",
            "menuTitle",
            "menuExtraAction",
            "item",
            "menuSelectedRegistrazioni",
        ]),
    },

    methods: {
        // Sep 26, 2019
        ...mapMutations("sidebarModule", [
            "setMenuAction",
            "setMenuExtraAction",
            "setMenuItems",
            "setMenuTitle",
            "setMenuItem",
            "setMenuSelectedRegistrazioni",
        ]),

        disableMenuItem(menuItem) {
            // disabilitazione per la vinificazione (in VinificazioneList.vue)
            // if (menuItem.action ? menuItem.action.startsWith("struttura") : false) {
            if (this.menuTitle.trim().startsWith("Vinificazioni")) {
                // August 12, 2022
                if (menuItem.disabled) {
                    return true;
                }

                // if (disableMenuItemVinificazione(menuItem, this.utente)) {
                //     return (menuItem.disabled != null ? menuItem.disabled : true)
                // }
            }

            if (
                this.menuTitle.trim() == "Vasi" ||
                this.menuTitle.trim() == "Vigne"
            ) {
                if (
                    (menuItem.action.startsWith("aggiungi") ||
                        menuItem.action.startsWith("trasmetti") ||
                        //menuItem.action.startsWith("esporta") ||
                        menuItem.action.startsWith("verifica")) &&
                    (categoriaUtente(this.utente) == "readonlymvvplus" ||
                        categoriaUtente(this.utente) == "readonly")
                ) {
                    return true;
                }
            } else if (
                this.menuTitle.trim() == "Soggetti" ||
                this.menuTitle.trim() == "Trasportatori"
            ) {
                if (
                    (menuItem.action.startsWith("aggiungi") ||
                        menuItem.action.startsWith("trasmetti") ||
                        menuItem.action.startsWith("verifica")) &&
                    categoriaUtente(this.utente) == "readonly"
                ) {
                    return true;
                }
            } else if (
                this.menuTitle.trim() == "Operazioni" &&
                (menuItem.action.startsWith("aggiungi") ||
                    menuItem.action.startsWith("trasmetti") ||
                    menuItem.action.startsWith("verifica")) &&
                categoriaUtente(this.utente) == "readonlymvvplus"
            ) {
                return true;
            } else {
                // per tutti gli altri menu...
                // solo READONLY
                if (
                    (menuItem.action.startsWith("aggiungi") ||
                        menuItem.action.startsWith("trasmetti") ||
                        menuItem.action.startsWith("verifica") ||
                        menuItem.action.startsWith("controlla")) &&
                    categoriaUtente(this.utente) == "readonly"
                ) {
                    return true;
                } else if (
                    (menuItem.action.startsWith("stampa") ||
                        menuItem.action.startsWith("visualizza") ||
                        menuItem.action.startsWith("controlla")) &&
                    categoriaUtente(this.utente) == "mvvonly"
                    //categoriaUtente(this.utente).startsWith('mvv')
                ) {
                    return true;
                } else if (
                    menuItem.action.startsWith("controlla") &&
                    categoriaUtente(this.utente) == "mvvplus"
                    //categoriaUtente(this.utente).startsWith('mvv')
                ) {
                    return true;
                    // READONLY+MVVPLUS
                } else if (
                    menuItem.action.startsWith("controlla") &&
                    categoriaUtente(this.utente) == "readonlymvvplus"
                ) {
                    return true;
                } else if (
                    menuItem.action.startsWith("aggiungi")
                        ? !this.termineInitOptions
                        : false
                ) {
                    return true;
                }
            }
            return false;
        },
    },
};
</script>
