<template>
  <table class="table">
    <tr>
      <th>Ragione sociale</th>
      <th>CUAA</th>
    </tr>
    <tr v-for="(azienda, i) in aziende" :key="i">
      <td> {{ azienda.ragione_sociale }} </td>
      <td> {{ azienda.cuaa }} </td>
    </tr>
  </table>
</template>

<script>
import { useState } from "@u3u/vue-hooks";

export default {
  name: "operatore-aziende",

  setup(props, context) {
    const { currentOperatore } = useState("amministrazioneModule", ["currentOperatore"]) 

    return {
      aziende: currentOperatore.value.aziende_di_cui_e_operatore,
    };
  }
};
</script>