import { flatten } from 'flat'
import { computed, getCurrentInstance } from "@vue/composition-api"

// January 18, 2023 

// il locale è fisso! da cambiare in caso di multilingua
export function numberToLocaleString(value, digits) {
    let _digits = digits == null ? 3 : digits
    if (value == null) {
        return 0;
    } else if (value == 0) {
        return 0;
    } else {
        var n = parseFloat(value) //.toFixed(3);
        return n.toLocaleString("it-IT", {
            maximumFractionDigits: _digits,
            minimumFractionDigits: _digits,
        });
    }
}

// November 18, 2022 
// helpers vuex e router per migrazione vue to vue3
// degli hooks
//

export const useRouter = (root) => {
    if (!root) {
        const instance = getCurrentInstance();
        // https://juejin.cn/post/6918365587248775175
        if (!instance) throw new Error("[eSianUtils] Not found vue instance.");
        root = instance.setupContext.root;
    }
    const route = computed(() => root.$route);
    return { route, router: root.$router };
};


// Se usata in una funzione dichiarata fuori dal setup
// c'è bisogno di passargli il context.root
export const useState = (module, states, root) => {
    if (!root) {
        const instance = getCurrentInstance()
        // https://juejin.cn/post/6918365587248775175
        if (!instance) throw new Error('[eSianUtils] Not found vue instance.');
        root = instance.setupContext.root;
    }
    let returnedStates = {}
    states.forEach(
        state => {
            return returnedStates[state] = computed(
                () => root.$store.state[module][state]
            );
        }
    )
    return returnedStates
}

export const useMutations = (module, mutations, root) => {
    if (!root) {
        const instance = getCurrentInstance()
        if (!instance) throw new Error('[eSianUtils] Not found vue instance.');
        root = instance.setupContext.root;
    }
    let returnedMutations = {}
    mutations.forEach(
        mutation => {
            // dichiaro la funzione 
            const mutationFn = (payload) =>
                root.$store.commit(module + '/' + mutation, payload);
            // la assegno alla proprietà dell'oggetto e ritorno l'oggetto/contenitore
            return returnedMutations[mutation] = mutationFn
        }
    )
    return returnedMutations
}


// August 15, 2019 
export function getFields(refsList, refs) {
    // August 21, 2019 
    // le refs sono disponibili quando onMounted (non onCreated)
    var fields = {};
    refsList.forEach(x => {
        if (refs[x] != null) {
            refs[x].$validator.fields.items.forEach(x => {
                fields[x.name] = x.flags;
            });
        }
    });
    return fields
}

export function isEmpty(ref) {
    return (ref == null || ref == "")
}

// Aug 16, 2019
export function ValidateState(field, context) {

    function hasErrors(field, errors) {
        if (errors.items.length > 0) {
            return errors.items.findIndex(x=>x.field===field) != -1 
        }
        return null
    }

    function isDirty(field, fields) {
        const _field = fields.items.find(x=>x.name===field)
        if (_field != null) {
            return _field.flags.dirty 
        }
        return null
    }

    function isValidated(field, fields) {
        const _field = fields.items.find(x=>x.name===field)
        if (_field != null) {
            return _field.flags.validated
        }
        return null
    }

    const errors = context.root.$validator != null ? context.root.$validator._base.errors : null
    const fields = context.root.$validator != null ? context.root.$validator._base.fields : null

    if (
        fields.items.length > 0 && 
        (isDirty(field, fields) || isValidated(field, fields))
    ) {
        if (hasErrors(field, errors)) {
            return false 
        } else {
            return null
        }
    }
    return null
}


/*
 * Dec 01, 2019
 * success=azione avviata dall'utente conclusasi senza errori
 * error=azione avviata dall'utente conclusasi con errori
 * info=informazione del sistema in casi in cui non si ha un esito di un'operazione dell'utente
 * warning=informazione del siste che richiede un'azione dall'utente
 */

export function UserException(message, variant, title) {
    this.message = message;
    this.name = "UserException";
    this.variant = variant;
    this.title = title;
}

// July 18, 2019
// Sep 08, 2019
// Dec 03, 2019
function showAlert(arg1, root) {

    // const { preferenze } = useState("loginModule", ["preferenze"]);
    const preferenze = computed(
        () => root.$store.state.loginModule.preferenze
    );

    const isError = (arg1.error != null)
    const defaultDuration = isError ? 24000 : 8000

    // assegnazione dei default comuni a error e non 
    var solid = arg1.solid != null ? arg1.solid : true;
    var append = arg1.append != null ? arg1.append : false;
    var duration = arg1.secs != null ? arg1.secs * 1000 : defaultDuration;
    if (arg1.secs == 0) {
        var noAutoHide = true;
    }
    var toaster = arg1.position != null ? arg1.position : "b-toaster-top-right";

    let message = null
    // è un ERROR e va processato (oggetto che può essere complesso)
    if (isError) {
        console.log(arg1);// Feb 04, 2020
        // assegnazione dei default in caso di error
        variant == null ? variant = "danger" : variant = arg1.variant;
        title == null ? title = "Errore" : title = arg1.title;
        let error = arg1.error
        let errorUrl = ""
        if (arg1.error.config) {
            errorUrl = " (" + arg1.error.config.url != null ? arg1.error.config.url : error.response.config.url + ")"
        }

        // se non c'è response può essere perchè manca la rete 
        // il Network Error è intercettato dagli interceptor axios
        if (!error.response) {
            message = arg1.message ? arg1.message + errorUrl : (error.message != undefined ? error.message : error);
            // così non visualizza eventuali errori in uscita per scadenza refresh token
            // Apr 10, 2021
            // return 
            // il Network Error è intercettato dagli interceptor axios
            // in main.js
            // if (error.message != "Network Error") {
            // }

        // se c'è una response...
        } else {

            // c'è un response.data
            if (error.response.data != undefined) {

                // ci sono detail
                if (error.response.data.detail != undefined) {
                    message = arg1.message ? arg1.message : error.response.data.detail;

                // ci sono errori
                } else if (error.response.data.errori != undefined) {
                    var errori = error.response.data.errori;
                    for (let i = 0; i < errori.length; i++) {
                        for (let k = 0; k < errori[i].messaggi.length; k++) {
                            message =
                                message +
                                errori[i].campo.toUpperCase() +
                                ": " +
                                errori[i].messaggi[k];
                        }
                    }

                // response data è un object...
                } else if (typeof error.response.data == 'object' && error.response.status != 500) {

                    let response_data = Array.isArray(Object.values(error.response)) ? Object.values(error.response)[0] : Object.values(error.response)
                    // https://www.npmjs.com/package/flat
                    // message = Object.entries(flatten(response_data, {delimiter: ' - '}))[0][1]
                    message = Object.entries(flatten(response_data, {delimiter: ' - '}))
                } else if (arg1.error.name != null) { // Nov 23, 2019
                    variant = arg1.error.variant
                    variant == 'info' ? title = "Informazione" : variant == 'warning' ? title = 'Attenzione' : 'Errore';
                    message = arg1.message ? arg1.message : arg1.error.message

                } else {
                    message = arg1.message ? arg1.message : error.response.data;
                }

            } // c'è response 
        } 
    
    // non è un error (ma un semplice messaggio)...
    } else {
        message = arg1.message
        //message = message + " (" + errorUrl + ")"
        // assegnazione default
        var variant =  arg1.variant == null ? "info" : (arg1.variant=='error'? 'danger' : arg1.variant );
        var title = arg1.title // Mar 12, 2020
        if (title == null) {
            title = "Informazione" 
            if (variant == "info" || variant==null) {
                title = "Informazione" 
                toaster = "b-toaster-bottom-right"
            } else if (variant == "error") {
                title = "Errore";
            } else if (variant == "success") {
                title = "Successo";
                toaster = "b-toaster-bottom-right"
            } else if (variant == "warning") {
                title = "Attenzione";
            } else if (variant == "danger") {
                title = "Errore";
            }
        }
    }

    // visualizza il messaggio (toast)
    if (preferenze.value.registrazioni_alert || isError || arg1.variant=='error') {
        root.$bvToast.toast(message, {
            title: title,
            variant: variant,
            autoHideDelay: duration,
            noAutoHide: noAutoHide,
            solid: solid,
            appendToast: append,
            toaster: toaster
        });
    }
}

// April 06, 2019
function forceUpper(e, obj, prop) {
    const start = e.target.selectionStart;
    e.target.value = e.target.value.toUpperCase();
    this.$set(obj, prop, e.target.value);
    e.target.setSelectionRange(start, start);
}

export { showAlert, forceUpper };
