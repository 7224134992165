import axios from 'axios'
import {ref} from "@vue/composition-api";
import {useMutations} from "@u3u/vue-hooks";

export const showSkeletonArchivio = ref(false)

export const results = ref([]),
    count = ref(null),
    bytes_total = ref(null),
    total = ref(null),
    total_pages = ref(null),
    expired = ref(null),
    soft_expired = ref(null),
    page_size = ref(null),
    url_path = ref("")


export const getDocumenti = async (context, url="/api/archiviazione", params) => {
    const {setIsChangedArchivio} = useMutations("archivioModule", ["setIsChangedArchivio"]);
    if (params.tags) {
        params.tags = params.tags.map(x => x.id).join()
    }
    const options = {
        method: 'get',
        url: url,
        params: params
    }
    showSkeletonArchivio.value = true
    try {
        const response = await axios.request(options)
        url_path.value = response.data.url_path;
        // results.value = response.data.results ? response.data.results : [];
        page_size.value = response.data.page_size;
        count.value = response.data.count;
        bytes_total.value = response.data.bytes_total;
        total.value = response.data.total;
        total_pages.value = response.data.total_pages;
        expired.value = response.data.expired;
        soft_expired.value = response.data.soft_expired; 
        // resetta il trigger che fa ricaricare i documenti dal server (watch)
        setIsChangedArchivio(false);
        showSkeletonArchivio.value = false
        if (response.data.results) {
            return response.data.results
        } else {
            return []
        }
        return response.data.results ? response.data.results : response.data
    } catch (error) {
        showSkeletonArchivio.value = false
        console.log(error)
    }
}
