const VignaList = () => import('../components/vigna/VignaList.vue');
const VignaUpdate = () => import('../components/vigna/VignaUpdate.vue');
const VignaCreate = () => import('../components/vigna/VignaCreate.vue');


export const vigna_routes = [
    {
      path: "/vigna-list",
      name: "vigna-list",
      component: VignaList,
      meta: { title: 'Elenco Vigne - e-Sian', requiresAuth: true, },
    },
    {
      path: "/vigna-create",
      name: "vigna-create",
      component: VignaCreate,
      meta: { title: 'Aggiunta vigna' + '- e-Sian', requiresAuth: true, },
      // meta: { title: app.$i18n.t('aggiungi-vigna') + '- e-Sian', }
    },
    {
      path: "/vigna-update/:id",
      name: "vigna-update",
      component: VignaUpdate,
      meta: { title: 'Modifica vigna' + '- e-Sian', requiresAuth: true, },
    },
]

